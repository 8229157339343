import React, { Component } from "react";
import { connect } from "react-redux";
import { LoadScriptNext } from "@react-google-maps/api";
import { GlLoader, GlParagraph } from "@adl/foundation";
import { Element, scroller } from "react-scroll";

import { Map } from "../../../../../../../../../CheckoutView/components/PickupCollectDetails/components/SelectAddressView/components/MapView/MapView";
import InputWithKeyboard from "../../../../../../../../../InputWithKeyboard/InputWithKeyboard";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../../../../../utils/translationCopies";
import { roundDistance } from "../../../../../../../../../CheckoutView/components/PickupCollectDetails/components/SelectAddressView/components/LocationsList/LocationsList";
import { setInputWithKeyboardValue } from "../../../../../../../../../../actions/inputWithKeyboardActions";
import { setUserActionTime } from "../../../../../../../../../../actions/pageActions";

import map_pin from "../../../../../../../../../../assets/icons/map_pin.svg";
import search from "../../../../../../../../../../assets/icons/search_small.svg";
import my_location from "../../../../../../../../../../assets/icons/my_location.svg";

import "./StoresListMap.css";

const GOOGLE_MAPS_LIBRARIES = ["places", "geometry"];
export const MAP_ZOOM = {
  defualt: 14,
  selected: 19,
};

class StoreAvailability extends Component {
  state = {
    loading: false,
    locations: null,
    markerMap: {},
    mapZoom: MAP_ZOOM.defualt,
    openedMapInfoWindow: {},
    mapCenter: null,
    map: {},
    selectedLocation: {},
    searchCoordinates: {},
    distance: 10,
    isFromDragging: false,
    isDraggingEnd: true,
  };

  constructor(props) {
    super(props);
    this.locationSearchTimer = null;
  }

  componentDidMount() {
    const { isFromDragging } = this.state;
    const { storeList, handleSSMIDChange } = this.props;

    if (storeList?.content?.length > 0) {
      const _locations = storeList.content;
      const _initialMapCenter = {
        latitude: parseFloat(
          storeList.content[0].store.ssmFields.location.latitude
        ),
        longitude: parseFloat(
          storeList.content[0].store.ssmFields.location.longitude
        ),
      };
      const _openedMapInfoWindow = {
        [storeList.content[0].store.ssmId]: storeList.content[0].store.ssmId,
      };

      this.setState({
        locations: _locations,
      });

      if (!isFromDragging) {
        this.setState({
          mapCenter: _initialMapCenter,
          openedMapInfoWindow: _openedMapInfoWindow,
          mapZoom: MAP_ZOOM.selected,
          selectedLocation: {
            id: storeList.content[0].store.ssmId,
          },
        });
      }

      handleSSMIDChange(storeList.content[0].store.ssmId);
    }
    if (storeList?.content?.length === 0 || storeList.length === 0) {
      this.setState({
        locations: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { openedMapInfoWindow, isFromDragging } = this.state;
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
      storeList,
      handleSSMIDChange,
      getNearbyStoresBySSMID,
      defaultSSMID,
    } = this.props;

    if (prevProps !== this.props) {
      if (
        selctedInputWithKeyboardId &&
        prevProps.selctedInputWithKeyboardValue[selctedInputWithKeyboardId] !==
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId]
      ) {
        clearTimeout(this.locationSearchTimer);
        this.locationSearchTimer = setTimeout(() => {
          if (
            selctedInputWithKeyboardValue[selctedInputWithKeyboardId]
              ?.length === 0
          ) {
            getNearbyStoresBySSMID(defaultSSMID);
          } else {
            this.handleInputChange(
              selctedInputWithKeyboardValue[selctedInputWithKeyboardId]
            );
          }
        }, 600);
      }

      if (prevProps.storeList !== storeList) {
        if (storeList?.content?.length > 0) {
          const _locations = storeList.content;
          const _mapCenter = {
            latitude: parseFloat(
              storeList.content[0].store.ssmFields.location.latitude
            ),
            longitude: parseFloat(
              storeList.content[0].store.ssmFields.location.longitude
            ),
          };

          const _openedMapInfoWindow = {
            [storeList.content[0].store.ssmId]:
              !openedMapInfoWindow[storeList.content[0].store.ssmId],
          };

          this.setState({
            locations: _locations,
          });

          if (!isFromDragging) {
            this.setState({
              mapCenter: _mapCenter,
              openedMapInfoWindow: _openedMapInfoWindow,
              mapZoom: MAP_ZOOM.selected,
              selectedLocation: {
                id: storeList.content[0].store.ssmId,
              },
            });
          }

          handleSSMIDChange(storeList.content[0].store.ssmId);
        }

        if (storeList?.content?.length === 0 || storeList.length === 0) {
          this.setState({
            locations: [],
          });
        }
      }
    }
  }

  handleInputChange = (locationSearchText) => {
    const { map, distance } = this.state;
    const { getNearbyStoresByGeoCoordinates } = this.props;
    //... TODO
    this.setState({ loading: true, isFromDragging: false });

    let _request = {
      query: locationSearchText,
      fields: ["name", "geometry"],
    };

    if (!locationSearchText) {
      this.setState({ searchCoordinates: {}, loading: false });
    }

    if (locationSearchText && map) {
      let _service = new window.google.maps.places.PlacesService(map);

      _service.findPlaceFromQuery(_request, (results, status) => {
        let _coordinates;
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const _latitude = results[0].geometry.location.lat();
          const _longitude = results[0].geometry.location.lng();
          _coordinates = {
            latitude: _latitude,
            longitude: _longitude,
          };
        }

        if (
          status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          _coordinates = {};
        }

        this.setState({
          searchCoordinates: _coordinates,
          loading: false,
        });

        getNearbyStoresByGeoCoordinates(
          _coordinates.latitude,
          _coordinates.longitude,
          distance
        );
      });
    }
  };

  onMapLoad = (map) => {
    if (map) {
      this.setState({ map });
    }
  };

  onMarkerLoad = (marker, place) => {
    this.setState({
      markerMap: {
        [place.id]: marker,
      },
    });
  };

  onLocationClick = (location) => {
    this.props.resetTimoutTimer();
    const { handleSSMIDChange } = this.props;
    const { openedMapInfoWindow } = this.state;

    if (location.coordinates.lat) {
      this.setState({
        openedMapInfoWindow: {
          [location.id]: !openedMapInfoWindow[location.id],
        },
        mapZoom: MAP_ZOOM.selected,
        mapCenter: {
          latitude: parseFloat(location.coordinates.lat),
          longitude: parseFloat(location.coordinates.long),
        },
        selectedLocation: location,
      });
    }

    handleSSMIDChange(location.id);
    this.scrollTo(location.id);
  };

  onMarkerCloseClick = (location) => {
    this.props.resetTimoutTimer();

    const { openedMapInfoWindow } = this.state;

    this.setState({
      openedMapInfoWindow: {
        [location.id]: !openedMapInfoWindow[location.id],
      },
      mapZoom: MAP_ZOOM.defualt,
    });
  };

  onZoomChanged = () => {
    const { map, mapZoom, mapCenter, distance } = this.state;
    const { getNearbyStoresByGeoCoordinates } = this.props;

    if (map.zoom && map.zoom !== mapZoom) {
      this.setState({ mapZoom: map.zoom, isFromDragging: true });

      clearTimeout(this.locationSearchTimer);
      this.locationSearchTimer = setTimeout(() => {
        getNearbyStoresByGeoCoordinates(
          mapCenter.latitude,
          mapCenter.longitude,
          distance
        );
      }, 600);
    }
  };

  onDragStart = () => {
    this.props.setUserActionTime({
      epoch: Date.now(),
    });
    this.setState({
      isDraggingEnd: false,
    });
  };

  onDragEnd = () => {
    const { map, mapZoom, distance } = this.state;
    const { getNearbyStoresByGeoCoordinates } = this.props;

    const _currentMapCenter = map.getCenter();
    const _latitude = _currentMapCenter.lat();
    const _longitude = _currentMapCenter.lng();

    if (map.zoom && map.zoom !== mapZoom) {
      this.setState({ mapZoom: map.zoom });
    }

    this.setState({
      mapCenter: {
        latitude: _latitude,
        longitude: _longitude,
      },
      isDraggingEnd: true,
      isFromDragging: true,
    });

    clearTimeout(this.locationSearchTimer);
    this.locationSearchTimer = setTimeout(() => {
      getNearbyStoresByGeoCoordinates(_latitude, _longitude, distance);
    }, 600);
  };

  onBoundsChanged = () => {
    const { map, distance, isDraggingEnd } = this.state;

    const _currentMapCenter = map.getCenter();
    const _currentBounds = map.getBounds();

    const _mapRadius =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        _currentBounds.getNorthEast(),
        _currentMapCenter
      );
    const _distance = Math.floor(_mapRadius / 1000);

    if (isDraggingEnd) {
      if (distance !== _distance) {
        this.setState({ distance: _distance });
      }
    }
  };

  handleDefaultLocationClick = () => {
    const { defaultSSMID, getNearbyStoresBySSMID, setInputWithKeyboardValue } =
      this.props;
    getNearbyStoresBySSMID(defaultSSMID);
    setInputWithKeyboardValue("locationSearchText", "");
  };

  mapLocations = (_locations) => {
    let mappedLocations;

    if (_locations) {
      mappedLocations = _locations.map((location) => {
        return {
          id: location?.store?.ssmId,
          name: location?.store?.name,
          street: location?.store?.legacy?.address?.street,
          city: location?.store?.legacy?.address?.city,
          country: location?.store?.legacy?.address?.countryCode,
          postal_code: location?.store?.legacy?.address?.zipCode,
          coordinates: {
            lat: location?.store?.ssmFields?.location?.latitude,
            long: location?.store?.ssmFields?.location?.longitude,
          },
          distance: location?.distanceMeters / 1000,
        };
      });
    }
    return mappedLocations;
  };

  scrollTo = (_element) => {
    scroller.scrollTo(_element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "list-map-locations-container",
    });
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    const {
      locations,
      markerMap,
      mapZoom,
      openedMapInfoWindow,
      mapCenter,
      searchCoordinates,
      distance,
      selectedLocation,
    } = this.state;
    const { loading } = this.props;

    const decimalSeparator = ".";
    const mappedLocations = this.mapLocations(locations);

    return (
      <LoadScriptNext
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        libraries={GOOGLE_MAPS_LIBRARIES}
      >
        <div className="store-availability">
          <div className="locations-list list-map-locations">
            <div className="search-input-container nearby-stores-input-container">
              <div className="icon nearby-stores-search-icon">
                <div>
                  <img src={search} alt="search"></img>
                </div>
              </div>
              <InputWithKeyboard
                id="locationSearchText"
                placeholder={this.getTranslation(
                  TRANSLATIONS_KEY_LIST.search_by_city_dictrict_or_address
                )}
              />
              <div
                className="icon nearby-stores-location-icon"
                onClick={() => this.handleDefaultLocationClick()}
              >
                <div>
                  <img src={my_location} alt="my_location"></img>
                </div>
              </div>
            </div>

            {this.props.ENABLE_DEBUG_COMPONENT &&
              (searchCoordinates.latitude || distance) && (
                <div className="debug-info">
                  Coordinates: {searchCoordinates.latitude},{" "}
                  {searchCoordinates.longitude}
                  {`DISTANCE: ${distance}km`}
                </div>
              )}
            <div
              className="locations-container"
              id="list-map-locations-container"
            >
              {loading && (
                <div className="locations-loader">
                  <GlLoader />
                </div>
              )}
              {!loading &&
                mappedLocations &&
                mappedLocations.map((store) => {
                  return (
                    <Element name={store.id} key={store.id}>
                      <div
                        className="locations-container__item"
                        key={store.id}
                        onClick={() => this.onLocationClick(store)}
                      >
                        <div
                          className={
                            "nearby-store-name " +
                            (selectedLocation.id === store.id ? "selected" : "")
                          }
                        >
                          {store.name}
                        </div>
                        <div className="street">{store.street}</div>
                        <div className="item">
                          <div className="pin-city">
                            {store.postal_code} {store.city}
                          </div>
                          <div className="icon-container">
                            <div>{`${roundDistance(
                              store.distance,
                              decimalSeparator
                            )}`}</div>
                            <img src={map_pin} alt="map_pin"></img>
                          </div>
                        </div>
                      </div>
                    </Element>
                  );
                })}
              {!loading && locations?.length === 0 && (
                <div className="no-results-label">
                  <GlParagraph>
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.map_search_no_result
                    )}
                  </GlParagraph>
                </div>
              )}
            </div>
          </div>
          <div className="locations-map">
            {mappedLocations && mapCenter && (
              <Map
                locations={mappedLocations}
                openedMapInfoWindow={openedMapInfoWindow}
                markerMap={markerMap}
                mapCenter={mapCenter}
                mapZoom={mapZoom}
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
                onLoad={this.onMapLoad}
                onZoomChanged={this.onZoomChanged}
                onMarkerLoad={this.onMarkerLoad}
                onMarkerClick={this.onLocationClick}
                onMarkerCloseClick={this.onMarkerCloseClick}
                onBoundsChanged={this.onBoundsChanged}
              />
            )}
          </div>
        </div>
      </LoadScriptNext>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selectedLocale,
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
    setUserActionTime: (val) => dispatch(setUserActionTime(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreAvailability);
