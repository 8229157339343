export const translations_pt_PT = {
  all_products: "Todos os produtos",
  go_to_top: "Voltar ao topo",
  more_filters: "Mais filtros",
  less_filters: "Menos filtros",
  sort_by: "Ordenar por",
  clear_all: "Apagar tudo",
  colors_available: "<count> cores disponíveis",
  colors_available_plp: "<count> cores",

  details: "Detalhes",
  how_to_style: "Como complementar",
  reviews: "Avaliações",
  gallery: "Galeria",
  highlights: "Destaques",

  select_size: "Selecionar tamanho",
  store_availability: "Ver outras lojas",

  size_guidance: "Guia de tamanhos",
  add_to_bag: "Adicionar ao saco de compras",
  scan_to_order_online: "Faz a leitura para encomendares online",
  shopping_bag: "Saco de compras",
  bag_empty: "O teu saco de compras está vazio",

  max_cart_item_count_reached: "Máx. itens atingidos",

  browse_online: (
    <>
      Vai em frente - compra algo giro para ti! <br />
      Explora este quiosque para verificar o stock nesta loja ou encomendar
      artigos online.
    </>
  ),

  order_online: "Faz a leitura para encomendares online",

  start_browsing: "Explorar a loja",
  total: "Total ",
  including_tax: "(Incl. imposto)",
  checkout: "Pagamento",
  delete_bag: "Limpar saco",

  continue_on_this_device: "Pagar aqui",
  checkout_on_your_device: "Faz o pagamento com o teu smartphone",

  checkout_on_your_device_instruction:
    "Lê o código QR para transferires o teu saco para a app adidas ou para o website no teu smartphone.",

  employee_checkout: "Check-out de funcionário",
  employee_checkout_title: "Check-out com um funcionário",
  employee_checkout_instruction:
    "Leia este código QR para transferir sua bolsa e pagar pelos itens.",
  employee_id_instruction:
    "Por favor, insira seu ID de funcionário e atualize o código QR.",
  employee_id_update_qr_code: "Atualizar código QR",
  employe_id_validation_error: "ID de funcionário inválido",
  change_employee_id: "Alterar ID de funcionário",

  only_cash_on_delivery_option:
    "Nota: só aceitamos a entrega em numerário como método de pagamento para encomendas no quiosque.",

  yes_over_16_years_of_age: "Tenho mais de 16 anos",

  choose_your_shipping_method: "Seleciona o método de envio",
  order_summary: "Resumo do encomenda",
  promo_code: "Código promocional",
  place_order: "Realizar encomenda",
  order_now_pay_on_delivery: "Encomenda agora, paga quando for entregue",

  read_more_reviews: "Ler mais comentários",

  edit_address: "Editar morada",
  save_details: "Guardar",
  edit_details: "Editar detalhes",
  select_pickup_point: "Selecionar esta localização",
  select_store: "Selecionar esta loja",
  back_to_list: "Voltar a produtos",

  sure_to_delete_cart: "Queres limpar este saco?",
  cart_delete_message:
    "Todos os itens adicionados serão eliminados e a sessão terminará.",
  cancel: "Cancelarx",

  add_voucher_heading: "Adicionar um voucher ou código promocional",
  add_voucher_instruction:
    "Diferenciação maiúsculas/minúsculas. Escreve tal como vês na imagem.",
  add_voucher_disclaimer:
    "Alguns artigos podem ser excluídos de determinados códigos promocionais. Consulta os Termos e Condições.",
  apply: "Aplicar",

  loading: "A carregar...",
  your_order_in_progress: "A carregar...",
  your_order_is_placed: "Encomenda concluída!",
  session_ends_in: "A sessão termina em:",
  estimated_delivery: "Data de entrega prevista:",
  confirmation_email_sent_to: "Foi enviado um e-mail de confirmação para:",
  order_number: "Número da encomenda:",
  start_new_session: "Iniciar nova sessão",
  extend_session: "Continuar",

  map_search_no_result:
    "Lamentamos, não encontramos nenhum resultado. Tenta de novo.",

  enter_your_details: "Informações de contacto",
  first_name: "Nome",
  last_name: "Apelido",
  street: "Rua",
  house_number: "Número",
  zip_code: "Código postal",
  town_city: "Localidade",
  email_address: "Endereço de e-mail",
  phone_number: "Número de telefone",

  change_store: "Alterar loja",
  change_pickup_point: "Alterar ponto de recolha",

  search_by_city_dictrict_or_address: "Procurar por cidade, distrito ou morada",

  processing: "A processar...",

  error_occurred: "Lamentamos, mas ocorreu um erro",

  retry_place_order: "Tenta encomendar de novo",

  retry_start_checkout: "Tenta pagar novamente",

  please_select_your_size: "Selecionar tamanho",
  bring_it_to_me: "Bring it to me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Chamar um colaborador da loja",
  do_you_need_assistance_title: "Precisas de ajuda? Iremos até ti",
  do_you_need_assistance_instruction:
    "Toca no botão e um dos nossos colaboradores irá encontrar-se contigo em breve.",
  do_you_need_assistance_sure_to_delete:
    "Tens a certeza de que queres eliminar este pedido?", //verify
  do_you_need_assistance_delete_btn_label: "Eliminar pedido", //verify

  we_will_bring_it_here: "Ou que tragam até ti ",
  bring_it_to_me_instruction:
    "Toca no botão, mantém-te junte deste ecrã e iremos ao teu encontro com os artigos que pedires dentro de 3 a 5 minutos.",

  bitm_none_active_title: "Encontraste algo giro?",
  bitm_none_active_instruction: (
    <>
      Nós vamos ter contigo Basta selecionar o(s) artigo(s) e o tamanho que
      pretendes e tocar em "BRING IT TO ME" neste quiosque ou na app da adidas.
    </>
  ),
  bitm_sure_to_delete: "Tens a certeza de que queres eliminar este pedido?", //verify
  bitm_delete_btn_label: "Eliminar pedido",

  bitm_task_todo: "Pedido em espera",
  bitm_task_in_progress: "A caminho!",
  bitm_task_complete: "Tudo feito!",

  bitm_qr_title: "Explora enquanto esperas",
  bitm_qr_description:
    "Lê o código QR para finalizares o pedido na app. Aproveita o momento para passear na loja - nós iremos ao teu encontro quando os teus artigos estiverem prontos.",
  restart_checkout: "Algo errado? Atualizar página",

  try_again: "Tenta de novo",

  session_expiring_title: "Já terminaste? ",
  session_expiring_body: "Sessão atual terminada em",

  nearbystore_availability_overlay_title:
    "Verificar disponibilidade noutras lojas",

  available_in_nearby_store: "Tamanhos disponíveis nesta localização",

  nearby_store_no_sizes_found:
    "O artigo que procuras está esgotado neste local.",

  please_try_selecting_another_store: "Tenta uma loja diferente.",

  in_stock_at_this_store: "Em stock nesta loja",

  employee_id: "ID do colaborador",

  add_employee_id_heading: "Adicionar ID de colaborador",

  add_employee_id_instruction:
    "Pede ajuda a um colaborador da loja para continuar.",

  verify_checkout:
    "Por favor, verifica novamente os teus dados antes de guardar",

  search: "Procurar",

  search_result_see_all: "Ver tudo <search-text>",

  search_no_result: "Lamentamos, não foram encontrados resultados",

  try_searching_for_something_else: "Tentar procurar outra coisa.",

  start_new_search: "Iniciar nova pesquisa",

  showing_results_for: "Procurar resultados para",

  no_available_sizes_found: "Não há tamanhos disponíveis neste momento",

  online: "Online",
  in_store: "Em loja",

  tap_to_get_started: "Toque para começar", //not used
  your_journey_starts_here: "A sua jornada começa aqui",
  app_header_title:
    "Navegue aqui e descubra os produtos mais recentes e incríveis",
  landingpage_filter_heading: "Para quem você está comprando",
  landingpage_signup_heading: "desbloqueie o melhor da adidas",
  landingpage_signup_cta:
    "Cadastre-se para comprar nossos equipamentos exclusivos para membros",
  landingpage_events_cta: "Explore nossos próximos lançamentos e eventos",
  landingpage_instore_services_cta: "Descubra nossos serviços na loja",
  eventspage_heading: "Descubra nossos eventos adidas",
  eventspage_sub_heading:
    "Obtenha o máximo de sua experiência de compra com nossos eventos exclusivos.",

  eventspage_store_event: "Evento na loja",
  eventspage_event_details: "Detalhes do evento",
  eventspage_raffle_timelines: "Prazos de rifa",
  eventspage_raffle_results: "Resultados de rifa",
  eventspage_sign_up_before: "Inscreva-se antes de",
  eventspage_scan_and_sign_up: "Escaneie e inscreva-se",

  instoreservicepage_heading: "Descubra nossos serviços na loja",
  instoreservicepage_sub_heading:
    "Obtenha o máximo de sua experiência de compra com nossos serviços exclusivos.",

  membershippage_title: "PARTICIPE DO PROGRAMA DE MEMBROS DE GRAÇA",
  membershippage_body:
    "Comece a gastar seus pontos adiClub para ser recompensado com ainda mais do que você ama.",
  membershippage_feature_membership_discount: "descontos para membros",
  membershippage_feature_freeshipping: "envio grátis",
  membershippage_feature_birthdaygifts: "presentes de aniversário",
  membershippage_feature_priorityshopping: "compras prioritárias",
  membershippage_feature_specialevents: "eventos especiais",

  change_language: "mudar idioma",
};
