export const translations_ja_JP = {
  all_products: "全ての商品",
  go_to_top: "トップに戻る",
  more_filters: "その他の検索条件",
  less_filters: "検索条件を減らす",
  sort_by: "並べ替え",
  clear_all: "すべてクリア",
  colors_available: "<count>色のカラーバリエーション",
  colors_available_plp: "<count>色",

  details: "詳細",
  how_to_style: "みんなのスタイリング",
  reviews: "レビュー",
  gallery: "ギャラリー",
  highlights: "ハイライト",

  select_size: "サイズを選択",
  store_availability: "他の店舗を見る",

  size_guidance: "サイズガイド",
  add_to_bag: "カートに入れる",
  scan_to_order_online: "オンラインショップでご購入の場合はこちらをスキャン",
  shopping_bag: "オンラインショップでご購入の場合はこちらをスキャン",
  bag_empty: "カートは空です",

  max_cart_item_count_reached: "アイテムの総数が上限に達しました",

  browse_online: (
    <>
      今すぐ気になるアイテムを手に入れましょう。
      <br />
      このキオスクから、当店の在庫状況を確認するか、オンラインショップでご注文ください。
    </>
  ),
  order_online: "オンラインでご注文",

  start_browsing: "この店舗を見る",
  total: "合計",
  including_tax: "(税込み)",
  checkout: "購入手続き",
  delete_bag: "カートを空にする",

  continue_on_this_device: "こちらからチェックアウト",
  checkout_on_your_device: "スマートフォンを使ってチェックアウト",

  checkout_on_your_device_instruction:
    "QRコードをスキャンすると、このカートの中身をお使いのスマートフォン上のadidasアプリまたはウェブサイトに転送できます。",

  employee_checkout: "従業員チェックアウト",
  employee_checkout_title: "従業員とのチェックアウト",
  employee_checkout_instruction:
    "このQRコードをスキャンして、バッグを移動し商品代金を支払います。",
  employee_id_instruction: "従業員IDを入力し、QRコードを更新してください。",
  employee_id_update_qr_code: "QRコードを更新",
  employe_id_validation_error: "無効な従業員ID",
  change_employee_id: "従業員IDの変更",

  only_cash_on_delivery_option:
    "ご注意：キオスクでのご注文は、代引きでの決済のみ承ります。予めご了承ください。",

  yes_over_16_years_of_age: "年齢は16歳以上です",

  choose_your_shipping_method: "配送方法を選択してください",
  order_summary: "ご注文内容",
  promo_code: "プロモーションコード",
  place_order: "注文を確定する",
  order_now_pay_on_delivery: "今すぐ注文、お支払いは代引きで",

  read_more_reviews: "レビューをもっと見る",

  edit_address: "住所を編集",
  save_details: "保存する",
  edit_details: "詳細を編集",
  select_pickup_point: "この店舗を選択",
  select_store: "この店舗を選択",
  back_to_list: "商品に戻る",

  sure_to_delete_cart: "このカートを空にしますか？",
  cart_delete_message:
    "追加したすべてのアイテムが削除され、セッションが終了します。",
  cancel: "キャンセル",

  add_voucher_heading: "クーポンまたはプロモーションコードを入力",
  add_voucher_instruction:
    "大文字、小文字を区別し、表示されている通りに入力してください ",
  add_voucher_disclaimer:
    "一部プロモーションコード適用対象外の商品があります。 ご利用規約をご確認ください。",
  apply: "適用する",

  loading: "ご注文を処理しています",
  your_order_in_progress: "読み込み中…",
  your_order_is_placed: "ご注文完了！",
  session_ends_in: "セッション終了までの残り時間",
  estimated_delivery: "予定お届け日",
  confirmation_email_sent_to: "確認のEメールが次の宛先に送信されました",
  order_number: "ご注文番号：",
  start_new_session: "他の商品を注文する",
  extend_session: "続ける",

  map_search_no_result:
    "申し訳ございません。お探しの条件に一致する結果が見つかりませんでした。 お手数ですが、もう一度やり直してください。",

  enter_your_details: "ご連絡先情報",
  first_name: "名",
  last_name: "苗字",
  street: "番地・部屋番号",
  house_number: "電話番号",
  zip_code: "郵便番号",
  town_city: "市区町村",
  email_address: "メールアドレス",
  phone_number: "電話番号",

  change_store: "店舗を変更する",
  change_pickup_point: "お受け取り場所を変更",

  search_by_city_dictrict_or_address: "都道府県、市区町村または住所で検索",

  processing: "処理中…",

  error_occurred: "申し訳ありません。エラーが発生しました",

  retry_place_order: "もう一度注文を送信してみてください",

  retry_start_checkout: "もう一度チェックアウトし直してください",

  please_select_your_size: "サイズを選択",
  bring_it_to_me: "BRING IT TO ME",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "店舗スタッフを呼ぶ",
  do_you_need_assistance_title: "何かお困りですか？スタッフをお呼びください",
  do_you_need_assistance_instruction: "何か、お困りですか？",
  do_you_need_assistance_sure_to_delete:
    "このリクエストを削除してよろしいですか？",
  do_you_need_assistance_delete_btn_label: "リクエストを削除する",

  we_will_bring_it_here: "または、こちらでお待ちください",
  bring_it_to_me_instruction:
    "ボタンをタップして、この画面のままお待ちください。リクエストされたアイテムは、3～5分後にこちらにお届けします。",

  bitm_none_active_title: "気になるアイテムが見つかりましたか？",
  bitm_none_active_instruction: (
    <>
      ご希望の商品をお持ちします
      必要なアイテムとサイズを選択して、このキオスクから、またはadidasアプリで「BRING
      IT TO ME」をタップするだけでリクエストできます。
    </>
  ),

  bitm_sure_to_delete: "このリクエストを削除してよろしいですか？",
  bitm_delete_btn_label: "リクエストを削除する",

  bitm_task_todo: "順番にリクエストをお伺いします",
  bitm_task_in_progress: "ただ今お届け中です",
  bitm_task_complete: "すべて完了",

  bitm_qr_title: "店内をご覧になってしばらくお待ちください",
  bitm_qr_description:
    "QRコードをスキャンして、アプリでリクエストを完了してください。商品の準備ができ次第スタッフがお持ちします。それまで店内を自由にご覧ください。",
  restart_checkout: "エラーが発生したようです。 ページの更新",

  try_again: "やり直す",

  session_expiring_title: "終了しますか？",
  session_expiring_body: "このセッションは1分後に終了します",

  nearbystore_availability_overlay_title: "他の店舗の在庫を確認する",

  available_in_nearby_store: "この店舗で在庫のあるサイズ",

  nearby_store_no_sizes_found:
    "お探しのアイテムは、この店舗では現在在庫切れです。",

  please_try_selecting_another_store: "別の店舗をご利用ください。",

  in_stock_at_this_store: "この店舗に在庫があります",

  employee_id: "従業員ID",

  add_employee_id_heading: "従業員IDの入力",

  add_employee_id_instruction: "続けるには、店舗スタッフをお呼びください。",

  verify_checkout: "保存する前に内容をもう一度確認してください",

  search: "検索",

  search_result_see_all: "すべての<search-text>を見る",

  search_no_result: "該当する検索結果がありません",

  try_searching_for_something_else: "他の条件で検索してみてください。",

  start_new_search: "検索を始める",

  showing_results_for: "の検索結果",

  no_available_sizes_found: "現在、すべてのサイズが在庫切れとなっています。",

  online: "オンライン",
  in_store: "店舗",

  tap_to_get_started: "タップして開始", //not used
  your_journey_starts_here: "ここからあなたの旅が始まります",
  app_header_title:
    "最新かつ最高の製品を見つけるためにここをブラウズしてください",
  landingpage_filter_heading: "誰のためにお買い物ですか",
  landingpage_signup_heading: "アディダスのベストを手に入れよう",
  landingpage_signup_cta:
    "メンバー限定のギアをショッピングするためにサインアップしてください",
  landingpage_events_cta: "今後のリリースやイベントを見てみる",
  landingpage_instore_services_cta: "店内サービスを探す",
  eventspage_heading: "アディダスのイベントを見つけよう",
  eventspage_sub_heading:
    "独占的なイベントでお買い物の体験を最大限に活用しましょう。",

  eventspage_store_event: "店舗イベント",
  eventspage_event_details: "イベント詳細",
  eventspage_raffle_timelines: "抽選スケジュール",
  eventspage_raffle_results: "抽選結果",
  eventspage_sign_up_before: "サインアップ期限",
  eventspage_scan_and_sign_up: "スキャンしてサインアップ",

  instoreservicepage_heading: "店内サービスを見つけよう",
  instoreservicepage_sub_heading:
    "独占的なサービスでお買い物の体験を最大限に活用しましょう。",

  membershippage_title: "無料でメンバーシッププログラムに参加しましょう",
  membershippage_body:
    "adiClubポイントを使って、さらに多くの報酬を得るためにお金を使い始めましょう。",
  membershippage_feature_membership_discount: "会員割引",
  membershippage_feature_freeshipping: "無料配送",
  membershippage_feature_birthdaygifts: "誕生日プレゼント",
  membershippage_feature_priorityshopping: "優先的なショッピング",
  membershippage_feature_specialevents: "特別なイベント",

  change_language: "言語を変更",
};
