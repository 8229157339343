import React, { Component } from "react";

import ModalView from "../../../../../../../ModalView/ModalView";

import { Events, animateScroll as scroll, scroller } from "react-scroll";

import { InView } from "react-intersection-observer";

import "./FullScreenGallery.css";

import { GlIcon } from "@adl/foundation";

class FullScreenGallery extends Component {
  state = {
    showModal: false,
    activePicture: null,
  };

  setShowModal = () => {
    this.setState({ showModal: false });
    this.props.setShowFullScreenGallery(false);
  };
  componentDidMount() {
    this.setState({ showModal: this.props.showFullScreenGallery });

    this.props.resetTimoutTimer();

    this.setState({
      activePicture: this.props?.galleryData[0]?.large?.href
        .split("/")
        [this.props?.galleryData[0]?.large?.href.split("/").length - 1].replace(
          ".",
          "_"
        ),
    });

    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});

    let { startFromPictureIndex, galleryData } = this.props;
    let _pictureToStartFrom = galleryData[startFromPictureIndex]?.large?.href
      .split("/")
      [
        galleryData[startFromPictureIndex]?.large?.href.split("/").length - 1
      ].replace(".", "_");

    setTimeout(() => {
      this.scrollToWithContainer(_pictureToStartFrom);
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showFullScreenGallery !== this.props.showFullScreenGallery) {
      this.setState({ showModal: this.props.showFullScreenGallery });
      this.props.resetTimoutTimer();
    }
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  scrollToTop() {
    console.log("scroll to top requested !");
    scroll.scrollToTop();
  }

  scrollToWithContainer(picture) {
    this.props.resetTimoutTimer();
    this.setState({ activePicture: picture });
    scroller.scrollTo(picture, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "fullscreen-gallery-container",
    });
  }

  render() {
    let { showModal, galleryData } = this.props;

    return (
      <>
        <ModalView
          show={showModal}
          onClose={() => {
            this.setShowModal();
            this.props.resetTimoutTimer();
          }}
        >
          <div className="animate-top-left">
            {/* {JSON.stringify(galleryData)} */}
            <div
              id="fullscreen-gallery-container"
              className="fullscreen-gallery-container "
            >
              <div
                className="btn-close"
                onClick={() => {
                  this.props.resetTimoutTimer();
                  this.setShowModal();
                }}
              >
                <GlIcon name="close" />
              </div>

              <div className="bullets-container">
                <div className="bullets">
                  {galleryData.map((x) => (
                    <div
                      className={
                        this.state.activePicture ===
                        x?.large?.href
                          .split("/")
                          [x?.large?.href.split("/").length - 1].replace(
                            ".",
                            "_"
                          )
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        this.scrollToWithContainer(
                          x?.large?.href
                            .split("/")
                            [x?.large?.href.split("/").length - 1].replace(
                              ".",
                              "_"
                            )
                        );
                      }}
                      key={x?.large?.href
                        .split("/")
                        [x?.large?.href.split("/").length - 1].replace(
                          ".",
                          "_"
                        )}
                    >
                      {/* {x?.large?.href
                        .split("/")
                        [x?.large?.href.split("/").length - 1].replace(
                          ".",
                          "_"
                        )} */}
                    </div>
                  ))}
                </div>
              </div>

              <div className="image-wrapper">
                {galleryData &&
                  galleryData.map((x) => (
                    <div key={x.large.href}>
                      <InView
                        as="div"
                        id={"marker_" + x.large.href}
                        onChange={(inView, entry) => {
                          if (inView && this.state.activePicture) {
                            this.props.resetTimoutTimer();
                            this.setState({
                              activePicture: x?.large?.href
                                .split("/")
                                [x?.large?.href.split("/").length - 1].replace(
                                  ".",
                                  "_"
                                ),
                            });
                          }
                        }}
                      >
                        <div className="background-loader">
                          <div
                            name={x?.large?.href
                              .split("/")
                              [x?.large?.href.split("/").length - 1].replace(
                                ".",
                                "_"
                              )}
                          ></div>
                          <img
                            className="xgallery-image"
                            src={
                              window.screen.height < 2161
                                ? x.large.href
                                : x.large.href?.replace("w_1200", "w_2160")
                            }
                            alt="gallery"
                          />
                        </div>
                      </InView>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </ModalView>
      </>
    );
  }
}

export default FullScreenGallery;
