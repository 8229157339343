import React, { Component } from "react";
import ReactPlayer from "react-player";

import "./VideoView.css";

import { getMedia } from "../../utils/media";

const VIDEO = "video";

class VideoView extends Component {
  state = {
    currentPlayingIndex: 0,
    contentArray: [],
  };

  componentDidMount() {
    this.setState({
      contentArray: this.props.media,
    });

    this.downloadMedia(this.props.media);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.media) !== JSON.stringify(this.props.media)) {
      this.setState({
        contentArray: this.props.media,
      });

      this.downloadMedia(this.props.media);
    }
  }

  downloadMedia = (contentArray) => {
    let _contentArray = contentArray;

    for (let i = 0; i < _contentArray.length; i++) {
      console.log(i);
      console.log(_contentArray.length);
      if (_contentArray[i].type === VIDEO) {
        console.log("----- FETCHING BLOB-----");
        console.log(_contentArray[i].url);

        if (!_contentArray[i].blob) {
          getMedia(_contentArray[i].url).then(
            (result) => {
              console.log(i);
              console.log(result.responseURL);
              console.log(result.response);
              var _mediaBlob = window.URL.createObjectURL(result.response);

              console.log(_mediaBlob);

              let _contentArray = this.state.contentArray;
              let _match = _contentArray.filter(
                (x) => x.url === result.responseURL
              );
              if (_match && _match.length > 0) {
                _match[0].blob = _mediaBlob;
              }

              console.log(_match);

              this.setState({
                contentArray: _contentArray,
              });
              console.log("----- FETCHING BLOB END-----");
            },
            (Error) => {
              console.log(Error);
            }
          );
        }
      }
    }
  };

  onVideoEnded = () => {
    console.log("ended video");

    let { contentArray } = this.state;

    if (this.state.currentPlayingIndex === contentArray.length - 1) {
      this.setState({
        currentPlayingIndex: 0,
      });
    } else {
      this.setState({
        currentPlayingIndex: this.state.currentPlayingIndex + 1,
      });
    }
  };

  render() {
    // let { videoBlob } = this.props;

    let { contentArray } = this.state;

    const videosWithBlob = contentArray.filter((x) => x.blob?.length > 0);

    return (
      <div className="video-component-wrapper">
        {/* <h2>{this.state.currentPlayingIndex}</h2> */}
        {/* <h2>{media[this.state.currentPlayingIndex].url}</h2> */}
        <div className="container-video">
          {
            <ReactPlayer
              // url={sampleVideoUrl}
              url={videosWithBlob[this.state.currentPlayingIndex]?.blob || null}
              loop={videosWithBlob.length === 1}
              onEnded={(e) => {
                this.onVideoEnded();
              }}
              muted={true}
              playing={true}
            />
          }
        </div>

        <div className="video-debug-info hidden">
          <div>
            <p>Debug Info</p>
          </div>
          <div>
            <p>
              Now playing media from index : {this.state.currentPlayingIndex}
            </p>
          </div>
          <div>
            <p>
              Original source:{" "}
              {contentArray[this.state.currentPlayingIndex]?.url}
            </p>
          </div>

          <div>
            <p>
              Playing from local?:{" "}
              {contentArray[this.state.currentPlayingIndex]?.blob
                ? "yes"
                : "no, its downloading now !"}
            </p>
          </div>

          <div>
            <p>
              Playing from local path:{" "}
              {contentArray[this.state.currentPlayingIndex]?.blob}
            </p>
          </div>

          <div>
            <p>
              CAUTION: please note cache is active only after you build and
              serve from normal server (not from yarn start)
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoView;
