import ProductsAPI from "./ProductsAPI/productsAPI";
import SsmAPI from "./SsmAPI/SsmAPI";
import SamAPI from "./SamAPI/SamAPI";
import MapsAPI from "./MapsAPI/MapsAPI";
import ProductsAvailabilityAPI from "./ProductsAvailabilityAPI/ProductsAvailabilityAPI";
import SseAPI from "./SseAPI/SseAPI";
import EventsAPI from "./EventsAPI/eventsAPI";
import MembershipAPI from "./MembershipAPI/membershipAPI";
import ApiBase from "./APIBase/APIBase";

const appSettingsAPI = require("./AppSettingsAPI/appSettingsAPI");

class V2_API {
  remoteBackendAccessToken;
  appSettings;
  config;
  apiKey;

  constructor(apiKey) {
    this.apiKey = apiKey;

    ApiBase.apiKey = apiKey;
  }
  appSettingsAPI = () => {
    return appSettingsAPI;
  };

  productsAPI = () => {
    return new ProductsAPI().getAllFunctions();
  };

  eventsAPI = () => {
    return new EventsAPI().getAllFunctions();
  };

  ssmAPI = () => {
    return new SsmAPI().getAllFunctions();
  };

  samAPI = () => {
    return new SamAPI().getAllFunctions();
  };

  productsAvailabilityAPI = () => {
    return new ProductsAvailabilityAPI().getAllFunctions();
  };

  mapsAPI = () => {
    return new MapsAPI().getAllFunctions();
  };

  getLocalSSEAPI = () => {
    return new SseAPI().getLocalSSEEventSource();
  };

  membershipAPI = () => {
    return new MembershipAPI().getAllFunctions();
  };
}

export default V2_API;
