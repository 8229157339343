export const translations_nl_NL = {
  all_products: "Alle artikelen",
  go_to_top: "Terug naar boven",
  more_filters: "Meer filters",
  less_filters: "Minder filters",
  sort_by: "Sorteren op",
  clear_all: "Alles wissen",
  colors_available: "<count> beschikbare kleuren",
  colors_available_plp: "<count> kleuren",

  details: "Details",
  how_to_style: "Stijltips",
  reviews: "Reviews",
  gallery: "Galerij",
  highlights: "Highlights",

  select_size: "Kies maat",
  store_availability: "Bekijk andere winkels",

  size_guidance: "Maattabel",
  add_to_bag: "In winkelwagen",
  scan_to_order_online: "Scan om online te bestellen",
  shopping_bag: "Winkelwagen",
  bag_empty: "Je winkelwagen is leeg",

  max_cart_item_count_reached: "Max. items bereikt",

  browse_online: (
    <div>
      <p>
        Ga ervoor en scoor iets leuks voor jezelf! <br />
      </p>
      <p>
        Gebruik deze kiosk om de voorraad in de winkel te bekijken of online
        items te bestellen.
      </p>
    </div>
  ),

  order_online: "Bestel online",

  start_browsing: "Bekijk de voorraad", //not used anywhere
  total: "Totaal ",
  including_tax: "(incl. BTW)",
  checkout: "Afrekenen",
  delete_bag: "Winkelwagen verwijderen",

  continue_on_this_device: "Betaal hier",
  checkout_on_your_device: "Betaal met je telefoon",

  checkout_on_your_device_instruction:
    "Scan de QR-code om je winkelwagen naar de adidas-app of -website op je telefoon te sturen.",

  employee_checkout: "Medewerker afrekenen",
  employee_checkout_title: "Afrekenen met een medewerker",
  employee_checkout_instruction:
    "Scan deze QR-code om uw tas over te dragen en te betalen voor uw artikelen.",
  employee_id_instruction: "Voer uw medewerkers-ID in en werk de QR-code bij.",
  employee_id_update_qr_code: "QR-code bijwerken",
  employe_id_validation_error: "Ongeldige werknemers-ID",
  change_employee_id: "Medewerker-ID wijzigen",

  only_cash_on_delivery_option:
    "Opmerking: we accepteren alleen 'contant bij levering' als betalingsmethode voor bestellingen via de kiosk.",

  yes_over_16_years_of_age: "Ik ben ouder dan 16 jaar",

  choose_your_shipping_method: "Selecteer verzendwijze",
  order_summary: "Besteloverzicht",
  promo_code: "Kortingscode",
  place_order: "Bestelling plaatsen",
  order_now_pay_on_delivery:
    "Bestel nu, betaal wanneer je bestelling bezorgd is",

  read_more_reviews: "Lees meer reviews",

  edit_address: "Adres wijzigen",
  save_details: "Opslaan",
  edit_details: "Details bewerken",
  select_pickup_point: "Selecteer deze locatie",
  select_store: "Selecteer deze winkel",
  back_to_list: "Terug naar producten",

  sure_to_delete_cart: "Wil je deze winkelwagen verwijderen?",
  cart_delete_message:
    "Alle items die je hebt toegevoegd worden verwijderd en je sessie wordt beëindigd.",
  cancel: "Annuleren",

  add_voucher_heading: "Voeg een voucher of kortingscode toe",
  add_voucher_instruction: "Hoofdlettergevoelig, invoeren zoals weergegeven", // please check why not used anywhere ?
  add_voucher_disclaimer:
    "Sommige items zijn mogelijke uitgesloten van bepaalde promotiecodes. Bekijk de Algemene Voorwaarden.",
  apply: "Toevoegen",

  loading: "Laden...",
  your_order_in_progress: "Laden...",
  your_order_is_placed: "Bestelling voltooid!",
  session_ends_in: "Sessie eindigt over",
  estimated_delivery: "Geschatte levertijd",
  confirmation_email_sent_to: "Er is een bevestigingsbericht verzonden naar",
  order_number: "Bestelnummer",
  start_new_session: "Nieuwe sessie starten",
  extend_session: "Blijf doorgaan",

  map_search_no_result:
    "Sorry, we konden geen resultaten vinden. Probeer het opnieuw.",

  enter_your_details: "Contactgegevens",
  first_name: "Voornaam",
  last_name: "Achternaam",
  street: "Straat",
  house_number: "Nummer",
  zip_code: "Postcode",
  town_city: "Plaats",
  email_address: "E-mailadres",
  phone_number: "Telefoonnummer",

  change_store: "Wijzig winkel",
  change_pickup_point: "Ophaalpunt wijzigen",

  search_by_city_dictrict_or_address: "Zoeken op stad, wijk of adres",

  processing: "Bezig met verwerken...",

  error_occurred: "Sorry, er ging iets mis",

  retry_place_order: "Probeer opnieuw een bestelling te plaatsen",

  retry_start_checkout: "Probeer het opnieuw",

  please_select_your_size: "Kies maat",
  bring_it_to_me: "Bring it to me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Roep winkelmedewerker",
  do_you_need_assistance_title: "Hulp nodig? We komen naar je toe",
  do_you_need_assistance_instruction: "Heb je hulp nodig?",
  do_you_need_assistance_sure_to_delete:
    "Weet je zeker dat je dit verzoek wilt verwijderen?",
  do_you_need_assistance_delete_btn_label: "Verzoek verwijderen",

  we_will_bring_it_here: "Of laat het naar je toe brengen",
  bring_it_to_me_instruction:
    "Tik op de knop, blijf in de buurt van dit scherm en de gevraagde items zijn binnen 3 á 5 minuten bij je.",

  bitm_none_active_title: "Iets leuks gevonden?",
  bitm_none_active_instruction:
    "Laten ons het naar je toe brengen! Selecteer de gewenste items en maat en klik op BRING IT TO ME in deze kiosk of in de adidas app.",

  bitm_sure_to_delete: "Weet je zeker dat je dit verzoek wilt verwijderen?", // key not found taking value from help-request key
  bitm_delete_btn_label: "Verzoek verwijderen",

  bitm_task_todo: "Verzoek in de wachrij",
  bitm_task_in_progress: "Komt eraan!",
  bitm_task_complete: "Klaar!",

  bitm_qr_title: "Kijk rond terwijl je wacht",
  bitm_qr_description:
    "Scan de QR-code om je aanvraag via de app te voltooien. Kijk gerust rond in de winkel, we komen naar je toe wanneer je items klaar zijn.",
  restart_checkout: "Ging er iets mis? Pagina verversen",

  try_again: "Probeer opnieuw",

  session_expiring_title: "Ben je klaar?",
  session_expiring_body: "Huidige sessie eindigt over",

  nearbystore_availability_overlay_title:
    "Controleer beschikbaarheid in andere winkels",

  available_in_nearby_store: "Beschikbare maten op deze locatie",

  nearby_store_no_sizes_found:
    "Het item dat je zoekt is momenteel niet op voorraad op deze locatie.",

  please_try_selecting_another_store: "Probeer een andere winkel.",

  in_stock_at_this_store: "Op voorraad in deze winkel",

  employee_id: "Werknemer-ID",

  add_employee_id_heading: "Werknemer-ID toevoegen",

  add_employee_id_instruction:
    "Vraag een winkelmedewerker voor hulp om door te gaan.",

  verify_checkout: "Controleer je gegevens voordat je ze opslaat",

  search: "Zoeken",

  search_result_see_all: "Bekijk alle <search-text>",

  search_no_result: "Sorry, geen resultaten gevonden",

  try_searching_for_something_else: "Probeer iets anders te zoeken.", // is not used, some elements in search ui were removed after DE rollout.

  start_new_search: "Nieuwe zoekopdracht starten", // is not used, some elements in search ui were removed after DE rollout.

  showing_results_for: "Zoekresultaten voor",

  no_available_sizes_found: "Er zijn momenteel geen maten beschikbaar",

  online: "Online",
  in_store: "In de winkel",

  tap_to_get_started: "Tap om te beginnen", //not used
  your_journey_starts_here: "Je reis begint hier",
  app_header_title: "Ontdek de laatste & meest populaire items",
  landingpage_filter_heading: "Voor wie ben je aan het shoppen?",
  landingpage_signup_heading: "Profiteer van het beste van adidas",
  landingpage_signup_cta: "Word lid om exclusieve producten te shoppen",
  landingpage_events_cta: "Bekijk onze toekomstige releases & events",
  landingpage_instore_services_cta: "Ontdek services in deze winkel",
  eventspage_heading: "Ontdek onze adidas-evenementen",
  eventspage_sub_heading:
    "Haal alles uit je shopping experience met onze exclusieve events.",

  eventspage_store_event: "Winkel evenement",
  eventspage_event_details: "Evenement details",
  eventspage_raffle_timelines: "Tijdlijnen voor loterijen",
  eventspage_raffle_results: "Loterij uitslagen",
  eventspage_sign_up_before: "Meld u aan vóór",
  eventspage_scan_and_sign_up: "Scan en meld u aan",

  instoreservicepage_heading: "Ontdek al onze services in de winkel",
  instoreservicepage_sub_heading:
    "Haal alles uit je shopping experience met onze exclusieve services.",

  membershippage_title: "Meld je aan bij het gratis lidmaatschapsprogramma",
  membershippage_body:
    "Geef je adiClub-punten uit om beloond te worden met nog meer van wat je leuk vindt.",
  membershippage_feature_membership_discount: "ledenkorting",
  membershippage_feature_freeshipping: "gratis verzending",
  membershippage_feature_birthdaygifts: "verjaardagscadeaus",
  membershippage_feature_priorityshopping: "voorkeursshoppen",
  membershippage_feature_specialevents: "speciale evenementen",

  change_language: "taal wijzigen",
};
