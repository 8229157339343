import React from "react";

import {
  GlButton,
  GlLoader,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
} from "@adl/foundation";

import ModalView from "../../../../../ModalView/ModalView";

import "./DeleteBITMConfirmationModal.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

function DeleteBITMConfirmationModal(props) {
  let { isBitmTaskDelete_inProgress, bitmDeleteError } = props;

  const _getTranslation = (key) => {
    return translateString(key);
  };

  return (
    <div className="delete-bitm">
      <ModalView
        showCloseBtn={true}
        show={props.show}
        onClose={() => {
          props.onClose();
        }}
        className="modal-centered"
      >
        <div className="delete-bitm-confirmation__content">
          <div className="upper">
            <div className="title">
              {_getTranslation(TRANSLATIONS_KEY_LIST.bitm_sure_to_delete)}
            </div>

            <div className="body">
              <p>
                {_getTranslation(TRANSLATIONS_KEY_LIST.bitm_delete_message)}
              </p>
            </div>
          </div>

          <div className="lower">
            {bitmDeleteError && (
              <div className="bitm-delete-error">
                {_getTranslation(TRANSLATIONS_KEY_LIST.error_occurred)}
              </div>
            )}

            <div className="button-container">
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.dark}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => {
                    props.onClose();
                  }}
                  icon={null}
                >
                  {_getTranslation(TRANSLATIONS_KEY_LIST.cancel)}
                </GlButton>
              </div>
              <div>
                {!isBitmTaskDelete_inProgress && (
                  <GlButton
                    aria-label="label"
                    theme={GL_BUTTON_THEME.light}
                    variant={GL_BUTTON_VARIANT.secondary}
                    onClick={() => {
                      props.onConfirm();
                    }}
                    icon={null}
                  >
                    {_getTranslation(
                      TRANSLATIONS_KEY_LIST.bitm_delete_btn_label
                    )}
                  </GlButton>
                )}
                {isBitmTaskDelete_inProgress && (
                  <div className="loader-wrapper">
                    <GlLoader></GlLoader>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalView>
    </div>
  );
}

export default DeleteBITMConfirmationModal;
