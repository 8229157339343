import React, { Component } from "react";
import {
  GlButton,
  GL_BUTTON_VARIANT,
  GlPrice,
  GlPriceItem,
  GL_PRICE_ITEM_THEME,
  GlStarRating,
  GlCallout,
  GL_CALLOUT_TYPE,
  GlTabItem,
  GlTab,
} from "@adl/foundation";

import "./ProductSidePanel.css";

import SizeGrid from "./component/SizeGrid/SizeGrid";

import { API } from "../../../../../../API/index";

import SizeGuidance from "./component/SizeGuidance/SizeGuidance";

import BITMConfirmationModal from "./component/BITMConfirmationModal/BITMConfirmationModal";

import { connect } from "react-redux";

import { addToCart } from "../../../../../../actions/cartActions";

import { setActiveBITMDetails } from "../../../../../../actions/productActions";

import * as helpers from "../../../../../../utils/helpers";

// import { log, MSG_TYPE } from "../../../../../../utils/logger";

import NearbyStoreAvailabilityModal from "./component/NearbyStoreAvailabilityModal/NearbyStoreAvailabilityModal";

import {
  getScanToOrderUrl,
  getBITMUrl,
} from "../../../../../../utils/productUrl";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../../../../../actions/sidebarMenuActions";

import { setInputWithKeyboardValue } from "../../../../../../actions/inputWithKeyboardActions";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

import cartEmptyIcon from "../../../../../../assets/icons/cart-empty.svg";

import { MAX_CART_ITEM_LIMIT } from "../../../../../../utils/constants";

import * as analytics from "../../../../../../analytics";

import { SIZE_AVAILABILITY_TYPE } from "./constants";

import QRCode from "react-qr-code";

const SIZE_CATEGORY = {
  shoes: "shoes",
};

class ProductSidePanel extends Component {
  state = {
    orderableSizes_online: null,
    sizeSelected: null,
    cartItems: [],
    productId: null,
    isSizeGuidanceVisible: false,
    orderableSizes_instore: null,
    isBITMOverlayOpen: false,
    isStoreAvailabilityOverlayOpen: false,
    sizeAvailabilityFetch_inProgress: true,
    inStoreAvailabilityFetch_inProgress: false,
    inStoreAvailabilityFetchError: false,
    selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.inStore,
  };

  componentDidMount() {
    if (this.props.product?.product_id) {
      this.fetchAvailability();
      this.fetchInStoreAvailability();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product?.product_id !== this.props.product?.product_id) {
      this.fetchAvailability();
      this.fetchInStoreAvailability();
      this.setState({ sizeSelected: null });
    }
  }

  showStoreAvailabilityOverlay = () => {
    this.setState({ isStoreAvailabilityOverlayOpen: true });
  };

  setSizeAvailabilityType = (value) => {
    if (value === SIZE_AVAILABILITY_TYPE.online) {
      if (!this.state?.sizeSelected?.available_online) {
        this.setState({
          sizeSelected: null,
        });
      }
    } else {
      if (!this.state?.sizeSelected?.available_in_store) {
        this.setState({
          sizeSelected: null,
        });
      }
    }

    this.setState({
      selectedSizeAvailabilityType: value,
    });
  };

  fetchAvailability = () => {
    this.setState({
      productId: this.props.product?.product_id,
      sizeAvailabilityFetch_inProgress: true,
    });
    API.productsAvailabilityAPI
      .getProductAvailability(
        this.props.product?.product_id,
        this.props.selectedLocale
      )
      .then((x) => {
        const _data = x.response.data.data;

        if (_data.product_id === this.props.product?.product_id) {
          let _sizesAvailable_ecom = _data?._embedded.variations;

          _sizesAvailable_ecom.forEach((element) => {
            if (element.orderable === true && element.stock_level === -1) {
              element.stock_level = 10;
            }
          });

          let _orderableSizes_online = _sizesAvailable_ecom?.filter(
            (y) => y.orderable === true
          );

          this.setState({
            orderableSizes_online: _orderableSizes_online,
            sizeAvailabilityFetch_inProgress: false,
          });

          if (
            _orderableSizes_online.length > 0 &&
            this.state.orderableSizes_instore?.length === 0
          ) {
            this.setState({
              selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.online,
            });
          }
        }
      })
      .catch((e) => {
        this.setState({
          sizeAvailabilityFetch_inProgress: false,
          selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.inStore,
        });
      });
  };

  fetchInStoreAvailability = () => {
    this.setState({
      inStoreAvailabilityFetchError: false,
      inStoreAvailabilityFetch_inProgress: true,
    });

    const product_id = this.props.product?.product_id;
    const ssmid = this.props.appSettings?.value?.player?._SSMID;

    let sizeCountryCode = "FR"; // for European size name DEFAULT

    //get size country code from config
    const localeConfig = helpers.getLocaleConfig(this.props.selectedLocale);

    if (
      localeConfig[0]?.productSizeCountryCode &&
      localeConfig[0]?.productSizeCountryCode.length === 2
    ) {
      sizeCountryCode = localeConfig[0]?.productSizeCountryCode;
    }

    this.setState({
      sizeCountryCode,
    });

    if (ssmid) {
      API.samAPI
        .getProductAvailabilityInStore(product_id, ssmid)
        .then((x) => {
          console.log(x?.data?.data);
          const availableSizes = x?.data?.data?.filter((y) => y.quantity > 0);

          const availableSizesFormatted = availableSizes?.reduce(
            (acc, item) => {
              let _size = item.sizes[sizeCountryCode];

              if (this.props?.selectedLocale?.split("_")[1] === "JP") {
                _size = parseFloat(_size) / 10 + "cm";
              }

              acc.push({
                technical_size: item.technicalSize,
                orderable: true,
                variation_product_id: `${product_id}_${item.technicalSize}`,
                stock_level: item.quantity,
                size: _size,
              });
              return acc;
            },
            []
          );

          this.setState({
            orderableSizes_instore: availableSizesFormatted,
            inStoreAvailabilityFetchError: false,
            inStoreAvailabilityFetch_inProgress: false,
          });

          if (availableSizesFormatted.length > 0) {
            this.setState({
              selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.inStore,
            });
          } else {
            this.setState({
              selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.online,
            });
          }
        })
        .catch((e) => {
          console.log("Error fetching store availability - ", e);
          this.setState({
            inStoreAvailabilityFetchError: true,
            inStoreAvailabilityFetch_inProgress: false,
            selectedSizeAvailabilityType: SIZE_AVAILABILITY_TYPE.online,
          });
        });
    }
  };

  onSizeClicked = (e, size) => {
    this.setState({ sizeSelected: size });
  };

  onBITMrequest = (e, size) => {
    //todo: show overlay for confirming on BITM
    // after that make API call.

    this.setState({
      isBITMOverlayOpen: true,
    });
  };

  onBITMrequestOverlayConfimed = () => {
    this.setState({
      isBITMCreationInProgress: true,
      isBITMCreationError: false,
    });

    let { _SSMID, description, bitmRequestType } =
      this.props.appSettings?.value?.player;

    let _bitmTasks = [];

    let _technicalSize = this.state?.sizeSelected?.technical_size;

    let _sku = `${this.props.product.product_id}_${_technicalSize}`;
    API.samAPI
      .createBITMTask(
        this.props.product.product_id,
        _technicalSize,
        _SSMID,
        this.props.selectedLocale.split("_")[1],
        description,
        bitmRequestType
      )
      .then((response) => {
        // set bitm info in redux store
        _bitmTasks.push({
          sku: _sku,
          product: this.props.product,
          size: this.state?.sizeSelected,
          task: response?.data?.data || {},
          refreshedAt: new Date().toTimeString(),
        });

        setTimeout(() => {
          this.props.setSideBarActiveItem(MENU_ITEM_NAMES.bitm);
          this.setState({
            isBITMCreationInProgress: false,
            isBITMCreationError: false,
            isBITMOverlayOpen: false,
            isBITMCreated: true,
          });
        }, 100);

        this.props.setActiveBITMDetails(_bitmTasks);
      })
      .catch((e) => {
        this.setState({
          isBITMCreationError: true,
          isBITMCreationInProgress: false,
        });
      });
  };

  onAddtoCart = (e, value) => {
    let _cartItems = this.state.cartItems;

    let _quantity = 1;

    this.props.addToCart({
      sku: `${this.state.sizeSelected.variation_product_id}`,

      product: this.props.product,
      size: this.state.sizeSelected,
      orderableSizes_online: this.state.orderableSizes_online,
      quantity: _quantity,
      productReviewStatistics: this.props.productReviewStatistics,
    });

    this.setState({
      cartItems: _cartItems,
    });

    //Log PDP Add to Cart analytics
    analytics.addToCart({
      hostname: this.props.appSettings.value.player.hostname,
      productId: this.props.product?.product_id,
      productName: this.props.product?.product_name,
      color: this.props.product?.original_color,
      currency: this.props.product?.display_currency,
      orginalPrice: this.props.product?.original_price,
      price: this.props.product.discount_price
        ? this.props.product.discount_price
        : this.props.product.original_price,
      rating: this.props.productReviewStatistics?.average_overall_rating,
      reviewCount: this.props.productReviewStatistics?.total_review_count,
      sizeCategory: this.props.product?.category,
      stockStatus: this.props.product?.orderable,
      quantity: _quantity,
      size: this.state.sizeSelected?.size,
    });

    setTimeout(() => {
      this.props.setSideBarActiveItem(MENU_ITEM_NAMES.cart);
    }, 200);
  };

  getPriceJSX = (discountPrice, originalPrice, displayCurrency) => {
    return (
      <GlPrice>
        {discountPrice ? (
          <>
            <GlPriceItem theme={GL_PRICE_ITEM_THEME.sale}>
              {helpers.formatPrice(discountPrice, this.props.selectedLocale)}
            </GlPriceItem>
            <GlPriceItem theme={GL_PRICE_ITEM_THEME.crossed}>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        ) : (
          <>
            <GlPriceItem>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        )}
      </GlPrice>
    );
  };

  getInclusiveTaxLabelJSX = () => {
    if (this.props.selectedLocale === "en_IN") {
      return (
        <div>
          <div className="including-taxes">
            {this.getTranslation(TRANSLATIONS_KEY_LIST.including_tax)}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  setSizeGuidanceVisible = (val) => {
    this.setState({ isSizeGuidanceVisible: val });
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let {
      product,
      productSizeGuideHTML,
      productReviewStatistics,
      appSettings,
    } = this.props;

    let {
      orderableSizes_online,
      sizeSelected,
      isSizeGuidanceVisible,
      orderableSizes_instore,
      isBITMOverlayOpen,
      isStoreAvailabilityOverlayOpen,
      sizeAvailabilityFetch_inProgress,
      inStoreAvailabilityFetch_inProgress,
      inStoreAvailabilityFetchError,
    } = this.state;

    // transforming data for generating qr code and return default url
    let {
      country,
      _SSMID: ssmid,
      hostname,
      region,
      city,
    } = appSettings?.value?.player;

    let appData = {
      country,
      ssmid,
      hostname,
      region,
      city,
      productId: product?.product_id,
      shareUrl: product?.share_url,
      sku: sizeSelected?.variation_product_id,
    };

    let qrUrl = getScanToOrderUrl(appData) || product?.share_url;
    let bitmURL = getBITMUrl(appData) || product?.share_url;
    //console.log("#### > QR URL: " + qrUrl);

    const _CART_TOTAL_COUNT = helpers.getCartItemsCount(this.props.cartItems);

    const _ENABLE_BITM =
      (product?.size_category?.toLowerCase() === SIZE_CATEGORY.shoes &&
        this.props?.appSettings?.value?.player?.enableBringItToMe) ||
      false;

    const _ENABLE_CALL_STORE_ASSOCIATE =
      this.props?.appSettings?.value?.player?.enableCallStoreAssociate || false;

    const _TEMPORARILY_DISABLE_BITM =
      !sizeSelected?.available_in_store || this.props.activeBITM?.length > 0;

    const _TEMPORARILY_DISABLE_HELP = this.props.activeHelpRequest?.length > 0;

    const _ENABLE_NEARBY_STORE_AVAIL_INFO =
      this.props?.appSettings?.value?.enabledNearbyStoreAvailability || false;

    const _ENABLE_ONLINE_SIZE_AVAILABILITY_TAB =
      orderableSizes_online?.length > 0 ? true : false;
    const _ENABLE_STORE_SIZE_AVAILABILITY_TAB =
      orderableSizes_instore?.length > 0 ? true : false;

    //todo: cleanup
    console.log(
      "selectedSizeAvailabilityType",
      this.state.selectedSizeAvailabilityType
    );

    return (
      <>
        <BITMConfirmationModal
          show={isBITMOverlayOpen}
          onClose={() => {
            this.props.resetTimoutTimer();
            this.setState({ isBITMOverlayOpen: false });
          }}
          onConfirm={() => {
            this.props.resetTimoutTimer();
            this.onBITMrequestOverlayConfimed();
          }}
          selectedLocale={this.props.selectedLocale}
          qrUrl={bitmURL}
          isBITMCreationInProgress={this.state.isBITMCreationInProgress}
          isBITMCreationError={this.state.isBITMCreationError}
          appData={appData}
        ></BITMConfirmationModal>

        <div className="details-wrapper product-sidepanel">
          <section>
            <div className="pre-header">
              <div className="pre-header__text">{product?.subtitle}</div>
              {productReviewStatistics?.total_review_count > 0 && (
                <div
                  className="pre-header__rating"
                  onClick={() => this.props.scrollToReviews()}
                >
                  <GlStarRating
                    rating={productReviewStatistics?.average_overall_rating}
                  />
                  <div className="count">
                    {productReviewStatistics?.total_review_count}
                  </div>
                </div>
              )}
            </div>
          </section>
          <section>
            <div className="heading">
              <div>
                <h4>{product?.product_name}</h4>
              </div>
              <div>{product?.original_color}</div>
            </div>
          </section>

          <div className="price">
            {this.getPriceJSX(
              product?.discount_price,
              product?.original_price,
              product?.display_currency
            )}
          </div>

          {this.getInclusiveTaxLabelJSX()}

          {
            <>
              {product?.orderable && (
                <section style={{ marginTop: "1.3rem" }}>
                  <NearbyStoreAvailabilityModal
                    show={isStoreAvailabilityOverlayOpen}
                    onClose={() => {
                      this.props.resetTimoutTimer();
                      this.setState({ isStoreAvailabilityOverlayOpen: false });
                    }}
                    selectedLocale={this.props.selectedLocale}
                    sizeCountryCode={this.state.sizeCountryCode}
                    SSMID={this.props.appSettings?.value?.player?._SSMID}
                    productId={product?.product_id}
                    resetTimoutTimer={this.props.resetTimoutTimer}
                  ></NearbyStoreAvailabilityModal>

                  <div className="heading">
                    <div className="size-info-header">
                      {!sizeAvailabilityFetch_inProgress &&
                        (orderableSizes_online?.length > 0 ||
                          orderableSizes_instore?.length > 0) && (
                          <p className="bold">
                            {this.getTranslation(
                              TRANSLATIONS_KEY_LIST.select_size
                            )}
                          </p>
                        )}

                      {_ENABLE_NEARBY_STORE_AVAIL_INFO && (
                        <p
                          className="bold underline"
                          onClick={() => {
                            this.props.setInputWithKeyboardValue(
                              "locationSearchText",
                              ""
                            );
                            this.showStoreAvailabilityOverlay();
                          }}
                        >
                          {this.getTranslation(
                            TRANSLATIONS_KEY_LIST.store_availability
                          )}
                        </p>
                      )}
                    </div>

                    {sizeAvailabilityFetch_inProgress &&
                      inStoreAvailabilityFetch_inProgress && (
                        <div className={"sizegrid-container border_top"}>
                          {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                            <div key={x} className="skeleton-size-item">
                              <div></div>
                            </div>
                          ))}
                        </div>
                      )}

                    {(!sizeAvailabilityFetch_inProgress ||
                      !inStoreAvailabilityFetch_inProgress) &&
                      (orderableSizes_online?.length > 0 ||
                        orderableSizes_instore?.length > 0) && (
                        <>
                          {(!sizeAvailabilityFetch_inProgress ||
                            !inStoreAvailabilityFetch_inProgress) && (
                            <div className="retail-online-size-selector">
                              <GlTab>
                                {_ENABLE_ONLINE_SIZE_AVAILABILITY_TAB && (
                                  <GlTabItem
                                    active={
                                      SIZE_AVAILABILITY_TYPE.online ===
                                      this.state.selectedSizeAvailabilityType
                                    }
                                    id="tab-sizes-online"
                                    label={this.getTranslation(
                                      TRANSLATIONS_KEY_LIST.online
                                    )}
                                    onClick={() => {
                                      this.setSizeAvailabilityType(
                                        SIZE_AVAILABILITY_TYPE.online
                                      );
                                    }}
                                  />
                                )}
                                {_ENABLE_STORE_SIZE_AVAILABILITY_TAB && (
                                  <GlTabItem
                                    active={
                                      SIZE_AVAILABILITY_TYPE.inStore ===
                                      this.state.selectedSizeAvailabilityType
                                    }
                                    id="tab-sizes-retail"
                                    label={this.getTranslation(
                                      TRANSLATIONS_KEY_LIST.in_store
                                    )}
                                    onClick={() => {
                                      this.setSizeAvailabilityType(
                                        SIZE_AVAILABILITY_TYPE.inStore
                                      );
                                    }}
                                  />
                                )}
                              </GlTab>
                            </div>
                          )}

                          <SizeGrid
                            orderableSizes_online={orderableSizes_online}
                            orderableSizes_instore={orderableSizes_instore}
                            onSizeClicked={this.onSizeClicked}
                            sizeSelected={this.state.sizeSelected}
                            sizeAvailabilityType={
                              this.state.selectedSizeAvailabilityType
                            }
                          ></SizeGrid>
                        </>
                      )}
                  </div>
                </section>
              )}
              {
                /* in-store size availability error info/retry section */
                inStoreAvailabilityFetchError && (
                  <>
                    <GlCallout type="urgent">
                      <div>
                        {this.getTranslation(
                          TRANSLATIONS_KEY_LIST.in_stock_at_this_store
                        )}
                      </div>
                      <div>
                        {this.getTranslation(
                          TRANSLATIONS_KEY_LIST.error_occurred
                        )}
                        <div
                          className="link-btn"
                          onClick={() => {
                            this.fetchInStoreAvailability();
                          }}
                        >
                          {this.getTranslation(TRANSLATIONS_KEY_LIST.try_again)}
                        </div>
                      </div>
                    </GlCallout>
                  </>
                )
              }
              {!sizeAvailabilityFetch_inProgress &&
                (orderableSizes_online?.length > 0 ||
                  orderableSizes_instore?.length > 0) && (
                  <section>
                    <div className="pre-header">
                      <div className="size-info-legend-container">
                        {productSizeGuideHTML?.length > 800 && (
                          <div
                            className="size-guidance-link"
                            onClick={(e) => {
                              this.setSizeGuidanceVisible(true);
                            }}
                          >
                            {this.getTranslation(
                              TRANSLATIONS_KEY_LIST.size_guidance
                            )}
                          </div>
                        )}
                      </div>

                      <SizeGuidance
                        showSizeGuidance={isSizeGuidanceVisible}
                        setSizeGuidanceVisible={this.setSizeGuidanceVisible}
                        productSizeGuideHTML={productSizeGuideHTML}
                      ></SizeGuidance>
                    </div>
                  </section>
                )}
              <section>
                <div>
                  {/* {!(_CART_TOTAL_COUNT > MAX_CART_ITEM_LIMIT - 1) && (
                    <>
                      {!sizeSelected && (
                        <div>
                          {getTranslation(
                            this.props.selectedLocale,
                            TRANSLATIONS_KEY_LIST.select_size
                          )}
                        </div>
                      )}
                    </>
                  )} */}

                  {_CART_TOTAL_COUNT > MAX_CART_ITEM_LIMIT - 1 && (
                    <GlCallout type={GL_CALLOUT_TYPE.urgent}>
                      {this.getTranslation(
                        TRANSLATIONS_KEY_LIST.max_cart_item_count_reached
                      )}
                    </GlCallout>
                  )}

                  <div>
                    {!sizeAvailabilityFetch_inProgress &&
                      (orderableSizes_online?.length || 0) === 0 &&
                      (orderableSizes_instore?.length || 0) === 0 && (
                        <GlCallout type={GL_CALLOUT_TYPE.urgent}>
                          {this.getTranslation(
                            TRANSLATIONS_KEY_LIST.no_available_sizes_found
                          )}
                        </GlCallout>
                      )}
                  </div>

                  {/* <div>{true && <>{"sorry no size available"}</>}</div> */}

                  <div>
                    {!sizeAvailabilityFetch_inProgress &&
                      (orderableSizes_online?.length > 0 ||
                        orderableSizes_instore?.length > 0) &&
                      !sizeSelected && (
                        <p
                          style={{
                            padding: "0px",
                            margin: "0px",
                            marginTop: "0.75rem",
                          }}
                        >
                          {this.getTranslation(
                            TRANSLATIONS_KEY_LIST.please_select_your_size
                          )}
                        </p>
                      )}
                    {!sizeAvailabilityFetch_inProgress &&
                      orderableSizes_online?.length > 0 && (
                        <div
                          className={`button-wrapper ${
                            sizeSelected?.available_online ? "" : "disabled"
                          }`}
                        >
                          {!(_CART_TOTAL_COUNT > MAX_CART_ITEM_LIMIT - 1) && (
                            <GlButton
                              aria-label="label"
                              variant={GL_BUTTON_VARIANT.secondary}
                              icon={null}
                              disabled={
                                !sizeSelected || !sizeSelected?.available_online
                              }
                              onClick={(e) => {
                                this.onAddtoCart(e, sizeSelected);
                              }}
                            >
                              <div className="add-to-cart-button">
                                <div>
                                  {this.getTranslation(
                                    TRANSLATIONS_KEY_LIST.add_to_bag
                                  )}
                                </div>
                                <div className="primary_btn-image">
                                  <img src={cartEmptyIcon} alt="up-arrow"></img>
                                </div>
                              </div>
                            </GlButton>
                          )}
                        </div>
                      )}
                  </div>

                  {_ENABLE_BITM &&
                    !sizeAvailabilityFetch_inProgress &&
                    orderableSizes_instore?.length > 0 && (
                      <div
                        className={`btn-container button-wrapper ${
                          _TEMPORARILY_DISABLE_BITM ? "disabled" : ""
                        }`}
                      >
                        <GlButton
                          aria-label="label"
                          variant={GL_BUTTON_VARIANT.primary}
                          icon={"arrow-right-long"}
                          disabled={_TEMPORARILY_DISABLE_BITM}
                          onClick={(e) => {
                            this.props.resetTimoutTimer();
                            this.onBITMrequest(e, sizeSelected);
                          }}
                        >
                          {this.getTranslation(
                            TRANSLATIONS_KEY_LIST.bring_it_to_me
                          )}
                        </GlButton>
                      </div>
                    )}

                  {_ENABLE_CALL_STORE_ASSOCIATE && (
                    <div>
                      <div>
                        {this.getTranslation(TRANSLATIONS_KEY_LIST.need_help)
                          ?.length > 0 && (
                          <p
                            style={{
                              padding: "0px",
                              margin: "0px",
                              marginTop: "1.125rem",
                              marginBottom: "-1.125rem",
                            }}
                          >
                            {this.getTranslation(
                              TRANSLATIONS_KEY_LIST.need_help
                            )}
                          </p>
                        )}
                        <div
                          className={`btn-container button-wrapper ${
                            _TEMPORARILY_DISABLE_HELP ? "disabled" : ""
                          }`}
                          style={{ marginTop: "1.9rem" }}
                        >
                          <GlButton
                            aria-label="label"
                            variant={GL_BUTTON_VARIANT.secondary}
                            icon={"arrow-right-long"}
                            disabled={
                              this.props.activeHelpRequest?.length > 0 || false
                            }
                            onClick={(e) => {
                              this.props.setSideBarActiveItem(
                                MENU_ITEM_NAMES.help
                              );

                              //Log Call Store Associate analytics
                              analytics.callStoreAssociate({
                                hostname:
                                  this.props.appSettings.value.player.hostname,
                                productId: this.props.product.product_id,
                              });
                            }}
                          >
                            {this.getTranslation(
                              TRANSLATIONS_KEY_LIST.call_store_associate
                            )}
                          </GlButton>
                        </div>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </section>
            </>
          }

          {/* <section>
            <div>
              <div className="underline">en_Store availability</div>
            </div>
          </section> */}

          {this.state?.sizeSelected && product?.share_url && (
            // <>{product?.share_url}</>
            <section className="pdp-qr-code-section">
              <div>
                <div className="bold">
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.scan_to_order_online
                  )}
                </div>
                <div className="pdp-qr-code">
                  {
                    <>
                      {/* <QRCode value={"https://www.adidas.de"} size={370} /> */}
                      <QRCode size={370} value={qrUrl} />
                      <p className="qr-url-debug-text hidden">{qrUrl}</p>
                    </>
                  }
                </div>
              </div>
            </section>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  appSettings,
  cartItems,
  activeBITM,
  activeHelpRequest,
}) {
  return {
    selectedLocale,
    appSettings,
    cartItems,
    activeBITM,
    activeHelpRequest,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (val) => dispatch(addToCart(val)),
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setActiveBITMDetails: (val) => dispatch(setActiveBITMDetails(val)),
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSidePanel);
