export const MAX_CART_ITEM_LIMIT = 10;

export const LOCATION_TYPE = {
  home: "home",
  store: "store",
  pick_up_point: "pick_up_point",
};

export const PAYMENT_OPTIONS = {
  cashOnDelivery: "CASH_ON_DELIVERY_SKAVA",
  payInStore: "PayInStoreSkava",
  payInPostamat: "PayInPostamatSkava",
};

export const ACCEPTED_PAYMENT_OPTIONS = [
  PAYMENT_OPTIONS.cashOnDelivery,
  PAYMENT_OPTIONS.payInStore,
  PAYMENT_OPTIONS.payInPostamat,
];

export const DELIVERY_PAYMENT_MAPPING = {
  home: PAYMENT_OPTIONS.cashOnDelivery,
  store: PAYMENT_OPTIONS.payInStore,
  pick_up_point: PAYMENT_OPTIONS.payInPostamat,
};

export const SAM_REQUEST_STATUS_TYPE = {
  todo: "TODO",
  inProgress: "IN_PROGRESS",
  complete: "DONE",
};

export const KEYBOARD_IDS = {
  voucherCode: "voucherCode",
  employeeId: "employeeId",
};
