import React, { Component } from "react";
import { LoadScriptNext, GoogleMap } from "@react-google-maps/api";

import "./PickupCollectDetails.css";

import SelectAddressView from "./components/SelectAddressView/SelectAddressView";
import CurrentAddressSelectionView from "./components/CurrentAddressSelectionView/CurrentAddressSelectionView";

import PickupCollectUserDetailsSummary from "./components/PickupCollectUserDetailsSummary/PickupCollectUserDetailsSummary";
import EditUserDetailsCompact from "./components/EditUserDetailsCompact/EditUserDetailsCompact";

import * as helpers from "../../../../utils/helpers";

const GOOGLE_MAPS_LIBRARIES = ["places"];

class PickupCollectDetails extends Component {
  state = {
    isUserDetailsInEditMode: true,
    isAddressSelectionMode: true,
    selectedAddress: null,
    hiddenMap: {},
  };

  setAddress = (val) => {
    this.setState({
      selectedAddress: val,
      isAddressSelectionMode: false,
    });

    this.props.onAddressListItemSelected(val);
  };

  onLocationSelectForOrderDelivery = (address) => {
    this.setAddressSelectionMode(false);
    this.setState({ selectedAddress: address });
    this.props.onAddressListItemSelected(address);
  };

  setUserDetailInEditView = (val) => {
    this.setState({ isUserDetailsInEditMode: val });

    if (val === true || this.state.isAddressSelectionMode === true) {
      this.props.onFormInEditMode(true);
    } else {
      this.props.onFormInEditMode(false);
    }
  };

  setAddressSelectionMode = (val) => {
    this.setState({ isAddressSelectionMode: val });

    if (val === true || this.state.isUserDetailsInEditMode === true) {
      this.props.onFormInEditMode(true);
    } else {
      this.props.onFormInEditMode(false);
    }
  };

  componentDidMount() {
    let { userDetails, addressSelected } = this.props;

    // if (!userDetails) {
    //   this.setUserDetailInEditView(true);
    // } else {
    const isUserDetailsValid = this.validateEnteredUserDetails(userDetails);
    if (isUserDetailsValid) {
      this.setUserDetailInEditView(false);
    } else {
      this.setUserDetailInEditView(true);
    }
    // }
    if (addressSelected) {
      this.setUserDetailInEditView(false);
      this.setAddress(addressSelected);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.deliveryOptionSelected !== this.props.deliveryOptionSelected
    ) {
      this.setState({
        isUserDetailsInEditMode: false,
        isAddressSelectionMode: false,
      });
    }
  }

  validateEnteredUserDetails = (_userDetails) => {
    return helpers.validateUserDetailsForStorePickup(_userDetails);
  };

  onHiddenMapLoad = (map) => {
    if (map) {
      this.setState({ hiddenMap: map });
    }
  };

  render() {
    let {
      deliveryOptionSelected,
      userDetails,
      checkoutObject,
      deliveryOptionObject,
      locationDetailsSaveError,
      storePickupUserDetailsSaveError } = this.props;

    let props_addressSelected = this.props.addressSelected;

    let {
      isUserDetailsInEditMode,
      isAddressSelectionMode,
      selectedAddress,
      hiddenMap,
    } = this.state;

    if (props_addressSelected) {
      selectedAddress = props_addressSelected;
    }

    let _isAddressSelected = selectedAddress && selectedAddress?.id?.length > 0;

    let _decimalSeparator =
      checkoutObject?.order_summary?.total_price?.display_value?.indexOf(
        ","
      ) !== -1
        ? ","
        : ".";
    let addressSelectionMode = isAddressSelectionMode || locationDetailsSaveError;
    let userDetailsInEditMode = isUserDetailsInEditMode || locationDetailsSaveError || storePickupUserDetailsSaveError === undefined || storePickupUserDetailsSaveError;

    return (
      <>
        <div className="map-based-selection-wrapper">
          {/* {`is map address selected ${_isAddressSelected}`} */}

          {!addressSelectionMode && _isAddressSelected ? (
            <CurrentAddressSelectionView
              deliveryOptionSelected={deliveryOptionSelected}
              setAddressSelectionMode={this.setAddressSelectionMode}
              selectedAddress={selectedAddress}
              deliveryOptionObject={deliveryOptionObject}
              selectedLocale={this.props.selectedLocale}
            ></CurrentAddressSelectionView>
          ) : (
            <>
              <div
                className={`${
                  addressSelectionMode || !_isAddressSelected ? "" : "hidden"
                }`}
              >
                <LoadScriptNext
                  googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  libraries={GOOGLE_MAPS_LIBRARIES}
                  onLoad={this.HandleScriptLoad}
                >
                  <div style={{ display: "none" }}>
                    <GoogleMap
                      onLoad={this.onHiddenMapLoad}
                      mapContainerStyle={{ height: "0px", width: "0px" }}
                    />
                  </div>
                  <SelectAddressView
                    hiddenMap={hiddenMap}
                    setAddressSelectionMode={this.setAddressSelectionMode}
                    setAddress={this.setAddress}
                    checkoutObject={checkoutObject}
                    deliveryOptionSelected={deliveryOptionSelected}
                    onLocationSearchResult={this.props.onLocationSearchResult}
                    previousLocationSearchResult={
                      this.props.previousLocationSearchResult
                    }
                    currentAddress={this.props.currentAddress}
                    onLocationSelected={this.onLocationSelected}
                    onLocationSelectForOrderDelivery={
                      this.onLocationSelectForOrderDelivery
                    }
                    decimalSeparator={_decimalSeparator}
                    onCheckoutLocationInformationUpdated={
                      this.props.onCheckoutLocationInformationUpdated
                    }
                    selectedLocale={this.props.selectedLocale}
                    locationDetailsSaveError={locationDetailsSaveError}
                  ></SelectAddressView>
                </LoadScriptNext>
              </div>
            </>
          )}

          {userDetailsInEditMode ? (
            <EditUserDetailsCompact
              userDetails={userDetails}
              onUserDetailsChanged={this.props?.onUserDetailsChanged}
              setUserDetailInEditView={this.setUserDetailInEditView}
              onCheckoutCustomerInformationUpdated={
                this.props.onCheckoutCustomerInformationUpdated
              }
              selectedLocale={this.props.selectedLocale}
              storePickupUserDetailsSaveError={storePickupUserDetailsSaveError}
              selectedAddress={this.state.selectedAddress}
              addressSelectionMode={addressSelectionMode}
            ></EditUserDetailsCompact>
          ) : (
            <PickupCollectUserDetailsSummary
              userDetails={userDetails}
              setUserDetailInEditView={this.setUserDetailInEditView}
              selectedLocale={this.props.selectedLocale}
            ></PickupCollectUserDetailsSummary>
          )}
        </div>
      </>
    );
  }
}

export default PickupCollectDetails;
