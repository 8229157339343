export const SET_EXTERNAL_PDP_TRIGGER = "SET_EXTERNAL_PDP_TRIGGER";
export const SET_ACTIVE_BITM_DETAILS = "SET_ACTIVE_BITM_DETAILS";

export function setExternalPDPTrigger(value) {
  return { type: SET_EXTERNAL_PDP_TRIGGER, value };
}

export function setActiveBITMDetails(value) {
  return { type: SET_ACTIVE_BITM_DETAILS, value };
}
