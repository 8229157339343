import React, { Component } from "react";
import { GlTab, GlTabItem } from "@adl/foundation";

import "./Navigation.css";

class Navigation extends Component {
  state = {
    activeTabIndex: 0,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.activeTabIndex !== this.props.activeTabIndex) {
      this.setState({ activeTabIndex: this.props.activeTabIndex });
    }
  }

  handleOnClickTab = (x) => {
    this.setState({ activeTabIndex: x.id });
    this.props.setActiveTabIndex(x.id);
  };

  render() {
    let { navigationTabs, sticky } = this.props;
    let { activeTabIndex } = this.state;

    let tabs = navigationTabs.filter((x) => x.available === true);

    return (
      <>
        <div>
          <GlTab
            scrollTo={activeTabIndex}
            className={`${sticky ? "sticky" : ""}`}
          >
            {tabs.map((x) => (
              <GlTabItem
                key={x.id}
                id={x.id}
                label={x.localizedLabel || x.label}
                active={x.id === activeTabIndex}
                onClick={() => this.handleOnClickTab(x)}
              />
            ))}
            {/* {JSON.stringify(activeTabIndex)} */}
          </GlTab>
        </div>
      </>
    );
  }
}

export default Navigation;
