import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import "./index.css";
import App from "./App";
import { GlApp } from "@adl/foundation";
import { ReactComponent as Sprite } from "./adl-foundation-v51.0.0/dist/adidas/assets/sprite.svg";

// import "@adl/foundation/dist/adidas/foundation-adidas.css";

//import reportWebVitals from "./reportWebVitals";

import reducer from "./reducers";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <BrowserRouter>
    <GlApp>
      <React.StrictMode>
        <Sprite className="svgiconsprite hidden" />
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </GlApp>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.log("on service worker update called");
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  },
});
