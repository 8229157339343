import React, { Component } from "react";
import RequestHelpEmpty from "./components/RequestHelpEmpty/RequestHelpEmpty";
import RequestHelpItems from "./components/RequestHelpItems/RequestHelpItems";
import DeleteRequestHelpConfirmationModal from "./components/DeleteRequestHelpConfirmationModal/DeleteRequestHelpConfirmationModal";

import { connect } from "react-redux";

import { setActiveHelpRequestDetails } from "../../../../actions/storeAssociateHelpActions";

import "./RequestHelp.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../../../actions/sidebarMenuActions";

import { API } from "../../../../API/index";

let mounted;

class RequestHelp extends Component {
  state = {
    showDeleteTaskConfirmation: false,
    isTaskCreation_inProgress: false,
    isTaskCreateError: false,
    isTaskDelete_inProgress: false,
    isTaskDeleteError: false,
  };
  componentDidMount() {
    mounted = true;
  }

  componentWillUnmount() {
    mounted = false;
  }

  onRequestHelp = () => {
    this.setState({
      isTaskCreation_inProgress: true,
    });

    let { _SSMID, description, associateHelpRequestType } =
      this.props.appSettings?.value?.player;

    let _associateHelpRequestTask = [];

    API.samAPI
      .createAssociateHelpRequestTask(
        _SSMID,
        this.props.selectedLocale.split("_")[1],
        description,
        associateHelpRequestType
      )
      .then((response) => {
        _associateHelpRequestTask.push({
          task: response?.data?.data || {},
          refreshedAt: new Date().toTimeString(),
        });

        setTimeout(() => {
          this.setState({
            isTaskCreation_inProgress: false,
            isTaskCreated: true,
          });
        }, 100);

        this.props.setActiveHelpRequestDetails(_associateHelpRequestTask);
      })
      .catch((e) => {
        this.setState({
          isTaskCreation_inProgress: false,
          isTaskCreateError: true,
        });
      });
  };

  onDeleteTask = (taskId) => {
    if (mounted)
      this.setState({
        isTaskDelete_inProgress: true,
        isTaskDeleteError: false,
      });

    if (API.samAPI)
      API.samAPI
        .deleteTask(taskId)
        .then((response) => {
          if (response.data.success) {
            setTimeout(() => {
              if (mounted)
                this.setState({
                  showDeleteTaskConfirmation: false,
                  isTaskDelete_inProgress: false,
                  isTaskDeleteError: false,
                });

              // clear the redux
              this.props.setActiveHelpRequestDetails(
                this.props.activeHelpRequest.filter(
                  (x) => x.task?.id !== taskId
                )
              );
            }, 500);

            setTimeout(() => {
              if (this.props.sideMenu !== MENU_ITEM_NAMES.help)
                this.props.setSideBarActiveItem(null);
            }, 200);
          } else {
            setTimeout(() => {
              if (mounted)
                this.setState({
                  isTaskDeleteError: true,
                  isTaskDelete_inProgress: false,
                });
            }, 3000);
          }
        })
        .catch((e) => {
          this.setState({
            isTaskDelete_inProgress: false,
            isTaskDeleteError: true,
          });
        });
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let {
      showDeleteTaskConfirmation,
      isTaskDelete_inProgress,
      isTaskDeleteError,
    } = this.state;

    return (
      <>
        <div
          className="bitm-request-container animate-right"
          onClick={(e) => this.props.onClose()}
        >
          <div
            className="bitm-request"
            onClick={(e) => {
              this.props.resetTimoutTimer();
              e.stopPropagation();
            }}
          >
            <section className="header">
              <div>
                <h4>
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.call_store_associate
                  )}
                </h4>
              </div>
              {/* {this.props.activeBITM} */}
              {this.props.activeHelpRequest?.length === 0 && (
                <RequestHelpEmpty
                  selectedLocale={this.props.selectedLocale}
                  onClose={this.props.onClose}
                  onRequestHelp={this.onRequestHelp}
                  isTaskCreateError={this.state.isTaskCreateError}
                  isTaskCreation_inProgress={
                    this.state.isTaskCreation_inProgress
                  }
                ></RequestHelpEmpty>
              )}
            </section>

            {this.props.activeHelpRequest?.length > 0 && (
              <>
                <RequestHelpItems
                  onshowDeleteTaskConfirmation={() => {
                    if (mounted)
                      this.setState({ showDeleteTaskConfirmation: true });
                  }}
                ></RequestHelpItems>
              </>
            )}
          </div>
        </div>

        {showDeleteTaskConfirmation && (
          <DeleteRequestHelpConfirmationModal
            show={true}
            onClose={() => {
              this.props.resetTimoutTimer();
              this.setState({ showDeleteTaskConfirmation: false });
            }}
            onConfirm={() => {
              this.props.resetTimoutTimer();
              this.onDeleteTask(this.props.activeHelpRequest[0]?.task?.id);
            }}
            selectedLocale={this.props.selectedLocale}
            isTaskDelete_inProgress={isTaskDelete_inProgress}
            isTaskDeleteError={isTaskDeleteError}
          ></DeleteRequestHelpConfirmationModal>
        )}
      </>
    );
  }
}

function mapStateToProps({ activeHelpRequest, selectedLocale, appSettings }) {
  return {
    activeHelpRequest,
    selectedLocale,
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveHelpRequestDetails: (val) =>
      dispatch(setActiveHelpRequestDetails(val)),
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestHelp);
