import React, { Component } from "react";
import { connect } from "react-redux";

import { GlLoader, GlParagraph } from "@adl/foundation";

import "./SelectAddressView.css";

import { API } from "../../../../../../API/index";

import LocationsList from "./components/LocationsList/LocationsList";

import search from "../../../../../../assets/icons/search.svg";

import my_location from "../../../../../../assets/icons/my_location.svg";

import * as sampleData from "../../../../../../data/sampleData";

import MapView from "./components/MapView/MapView";

import { LOCATION_TYPE } from "../../../../../../utils/constants";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../../../../../utils/translationCopies";

import InputWithKeyboard from "../../../../../InputWithKeyboard/InputWithKeyboard";

export const MAP_ZOOM = {
  defualt: 14,
  selected: 19,
};

let searchTimeout;

const SEARCH_TIME_OUT_VALUE = 1000;
const isNewGooglePlaces = true;

class SelectAddressView extends Component {
  state = {
    locationType: null,
    locations: null,
    selectedAddress: null,
    locationSearchText: "",
    defaultLocationSearchText: this.props.currentAddress,
    showSearchTextBox: true,
    mapZoom: MAP_ZOOM.defualt,
    openedMapInfoWindow: {},
    loading: false,
    mapCenter: null,
  };

  updateSearchTextBoxVisibility = (val) => {
    this.setState({ showSearchTextBox: val });
  };
  componentDidMount() {
    //this.getAdidasLocationsData();
    this.setState({ loading: true });

    const _previousLocationSearchResult =
      this.props.previousLocationSearchResult;

    const { deliveryOptionSelected } = this.props;

    if (_previousLocationSearchResult) {
      if (deliveryOptionSelected === LOCATION_TYPE.store) {
        this.setState({ locations: _previousLocationSearchResult.stores });
      } else if (deliveryOptionSelected === LOCATION_TYPE.pick_up_point) {
        this.setState({
          locations: _previousLocationSearchResult.pick_up_points,
        });
      }
    }

    this.getAdidasLocationsData(this.props.currentAddress);
  }

  componentDidUpdate(prevProps) {
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
      hiddenMap,
      currentAddress,
    } = this.props;

    if (prevProps.hiddenMap !== hiddenMap) {
      this.getAdidasLocationsData(currentAddress);
    }

    if (
      selctedInputWithKeyboardId === "locationSearchText" &&
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      this.handleLocationSearchTextChange(
        selctedInputWithKeyboardId,
        selctedInputWithKeyboardValue
      );
    }
  }

  handleLocationSearchTextChange = (
    selctedInputWithKeyboardId,
    selctedInputWithKeyboardValue
  ) => {
    this.setState({ loading: true });
    this.setState({
      locationSearchText:
        selctedInputWithKeyboardValue[selctedInputWithKeyboardId],
    });

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (selctedInputWithKeyboardValue[selctedInputWithKeyboardId]) {
        if (
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId].length > 3
        ) {
          this.getAdidasLocationsData(
            selctedInputWithKeyboardValue[selctedInputWithKeyboardId]
          );
        }

        if (
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId].length === 0
        ) {
          this.getAdidasLocationsData(this.state.defaultLocationSearchText);
        }
      }
    }, SEARCH_TIME_OUT_VALUE);
  };

  getAdidasLocationsData = (locationSearchText) => {
    if (isNewGooglePlaces) {
      this.getAdidasLocationsData_NEW(locationSearchText);
    } else {
      this.getAdidasLocationsData_OLD(locationSearchText);
    }
  };

  getAdidasLocationsData_NEW = (locationSearchText) => {
    /*
      Google Places API Web Service is designed for server side applications. Using it on client side will throw CORS error
      As explained in the Notes at the beginning of the Place Details documentation:

      "If you're building a client-side application, take a look at the Google Places API for Android, the Google Places API for iOS, and the Places Library in the Google Maps JavaScript API."

      Reference: https://stackoverflow.com/questions/42180788/how-to-use-cors-to-implement-javascript-google-places-api-request

    */

    const { deliveryOptionSelected, hiddenMap } = this.props;

    let _checkoutId = this.props.checkoutObject?.id;
    let _request = {
      query: locationSearchText,
      fields: ["name", "geometry"],
    };
    let _service = new window.google.maps.places.PlacesService(hiddenMap);

    _service.findPlaceFromQuery(_request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const _latitude = results[0].geometry.location.lat();
        const _longitude = results[0].geometry.location.lng();

        if (deliveryOptionSelected === LOCATION_TYPE.store) {
          this.fetchStoreList(_checkoutId, _latitude, _longitude);
        } else if (deliveryOptionSelected === LOCATION_TYPE.pick_up_point) {
          this.fetchPickupPointsList(_checkoutId, _latitude, _longitude);
        }
      }

      if (
        status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS
      ) {
        this.setState({ locations: [], loading: false });
      }
    });
  };

  getAdidasLocationsData_OLD = (locationSearchText) => {
    if (!locationSearchText || locationSearchText.length < 3) {
      return;
    }
    // first find the co-ordinates for the given search text, for example now for london
    let _latitude;
    let _longitude;

    API.mapsAPI.getGooglePlace(locationSearchText).then((x) => {
      let { deliveryOptionSelected } = this.props;

      console.log(x);

      let _location = x.data?.data?.candidates[0]?.geometry?.location;

      if (_location?.lat) {
        _latitude = _location?.lat;
        _longitude = _location?.lng;

        this.setState({
          locationSearchCoordinates: {
            _latitude: _location.lat,
            _longitude: _location.lng,
          },
        });
      } else {
        this.setState({
          locationSearchCoordinates: {
            _latitude: null,
            _longitude: null,
          },
        });
      }

      console.log(`${_latitude}${_longitude}`);

      let _checkoutId = this.props.checkoutObject?.id;

      API.mapsAPI
        .getAdidasNearestStoresList(
          _checkoutId,
          _latitude,
          _longitude,
          this.props.selectedLocale
        )
        .then((x) => {
          console.log("#######");
          console.log(x);
          const dummyData = sampleData.SAMPLE_CHECKOUT_NEAREST_STORES;

          if (x?.status === 200) {
            this.setState({
              // stores: x?.data?.data?.stores,
              stores: dummyData?.stores,

              locationType: LOCATION_TYPE.store,
            });
          } else {
          }
        })
        .catch((e) => {
          console.log("####### Store list fetch error !");
          //todo : only for dev purpose when actial response is error
          const dummyData = sampleData.SAMPLE_CHECKOUT_NEAREST_STORES;
          console.log("Using dummy data for UI to render (for dev purposes)");
          console.log(dummyData);

          if (dummyData.stores) {
            this.setState({
              stores: dummyData?.stores,
              locationType: LOCATION_TYPE.store,
            });
          }
        });
      if (deliveryOptionSelected === LOCATION_TYPE.store) {
        this.fetchStoreList(_checkoutId, _latitude, _longitude);
      } else if (deliveryOptionSelected === LOCATION_TYPE.pick_up_point) {
        this.fetchPickupPointsList(_checkoutId, _latitude, _longitude);
      }
    });
  };

  fetchStoreList = (_checkoutId, _latitude, _longitude) => {
    API.mapsAPI
      .getAdidasNearestStoresList(
        _checkoutId,
        _latitude,
        _longitude,
        this.props.selectedLocale
      )
      .then((x) => {
        if (x?.status === 200) {
          this.setState({
            locations: x?.data?.data?.stores,
            locationType: LOCATION_TYPE.store,
          });

          this.props.onLocationSearchResult(
            x?.data?.data?.stores,
            this.props.deliveryOptionSelected
          );
          this.setState({ loading: false });
        } else {
        }
      })
      .catch((e) => {
        console.log("####### Store list fetch error !");
        //todo : only for dev purpose when actial response is error
        const dummyData = sampleData.SAMPLE_CHECKOUT_NEAREST_STORES;
        console.log("Using dummy data for UI to render (for dev purposes)");

        if (dummyData.stores) {
          this.setState({
            locations: dummyData?.stores,
            locationType: LOCATION_TYPE.store,
          });

          this.props.onLocationSearchResult(
            dummyData?.stores,
            this.props.deliveryOptionSelected
          );
        }
      });
  };

  fetchPickupPointsList = (_checkoutId, _latitude, _longitude) => {
    API.mapsAPI
      .getAdidasNearestPickupPointsList(
        _checkoutId,
        _latitude,
        _longitude,
        this.props.selectedLocale
      )
      .then((x) => {
        if (x?.status === 200) {
          console.log(x?.data?.data?.pick_up_points[0]);
          this.setState({
            locations: x?.data?.data?.pick_up_points,
            locationType: LOCATION_TYPE.pick_up_point,
          });
          this.props.onLocationSearchResult(
            x?.data?.data?.pick_up_points,
            this.props.deliveryOptionSelected
          );
          this.setState({ loading: false });
        } else {
        }
      })
      .catch((e) => {
        console.log("####### Store list fetch error !");
        //todo : only for dev purpose when actial response is error
        const dummyData = sampleData.SAMPLE_CHECKOUT_NEAREST_PICKUP_POINTS;
        console.log("Using dummy data for UI to render (for dev purposes)");

        if (dummyData.pick_up_points) {
          this.setState({
            locations: dummyData?.pick_up_points,
            locationType: LOCATION_TYPE.pick_up_point,
          });

          this.props.onLocationSearchResult(
            dummyData?.pick_up_point,
            this.props.deliveryOptionSelected
          );
        }
      });
  };

  getAdidasLocationsDataFromMap = (_latitude, _longitude) => {
    const { deliveryOptionSelected } = this.props;
    let _checkoutId = this.props.checkoutObject?.id;
    this.setState({ loading: true });
    this.setState({ locationSearchText: "" });

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      if (deliveryOptionSelected === LOCATION_TYPE.store) {
        this.fetchStoreList(_checkoutId, _latitude, _longitude);
      } else if (deliveryOptionSelected === LOCATION_TYPE.pick_up_point) {
        this.fetchPickupPointsList(_checkoutId, _latitude, _longitude);
      }
    }, SEARCH_TIME_OUT_VALUE);
  };

  onLocationSelect = (location, isSetAddress) => {
    this.setState({ selectedAddress: location });

    // this.props.onFormInEditMode(false);

    if (isSetAddress) {
      this.props.setAddress(location);
    }
  };

  onLocationSelectForOrderDelivery = (address) => {
    this.props.onLocationSelectForOrderDelivery(address);
  };

  handleMapZoomChange = (zoom) => {
    this.setState({ mapZoom: zoom });
  };

  handleMapInfoWindowOpen = (location) => {
    this.setState({
      openedMapInfoWindow: {
        [location.id]: !this.state.openedMapInfoWindow[location.id],
      },
    });
  };

  handleMapCenterChange = (_latitude, _longitude) => {
    this.setState({
      mapCenter: {
        latitude: _latitude,
        longitude: _longitude,
      },
    });
  };

  handleDefaultLocationClick = () => {
    this.setState({ loading: true });
    this.setState({ locationSearchText: "" });

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      this.getAdidasLocationsData(this.state.defaultLocationSearchText);
    }, SEARCH_TIME_OUT_VALUE);
  };

  render() {
    let { deliveryOptionSelected, decimalSeparator } = this.props;
    let {
      selectedAddress,
      locations,
      showSearchTextBox,
      mapZoom,
      openedMapInfoWindow,
      loading,
      mapCenter,
    } = this.state;

    if (selectedAddress) {
      // selectedAddress.name = selectedAddress?.name.split("__")[0];
      selectedAddress.name = selectedAddress?.name || "";
    }

    return (
      <>
        <section className="map-container">
          <div className="location-list">
            {showSearchTextBox && (
              <>
                <div className="search-input-container">
                  <div className="icon">
                    <div>
                      <img src={search} alt="search"></img>
                    </div>
                  </div>
                  <InputWithKeyboard
                    id="locationSearchText"
                    placeholder={getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.search_by_city_dictrict_or_address
                    )}
                  />
                  <div
                    className="icon"
                    onClick={this.handleDefaultLocationClick}
                  >
                    <div>
                      <img src={my_location} alt="my_location"></img>
                    </div>
                  </div>
                </div>
              </>
            )}
            {loading && (
              <div className="locations-loader">
                <GlLoader />
              </div>
            )}
            {locations && locations.length === 0 && !loading && (
              <div className="no-results-label">
                <GlParagraph>
                  {/* {"No result found, please try with different address."} */}
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.map_search_no_result
                  )}
                </GlParagraph>
              </div>
            )}
            {!loading && (
              <LocationsList
                loading={loading}
                locations={locations}
                onStoreSelect={this.onStoreSelect}
                onLocationSelect={this.onLocationSelect}
                onMapLocationselect={this.onMapLocationselect}
                updateSearchTextBoxVisibility={
                  this.updateSearchTextBoxVisibility
                }
                selectedAddress={selectedAddress}
                onLocationSelectForOrderDelivery={
                  this.onLocationSelectForOrderDelivery
                }
                handleMapZoomChange={this.handleMapZoomChange}
                handleMapInfoWindowOpen={this.handleMapInfoWindowOpen}
                decimalSeparator={decimalSeparator}
                onCheckoutLocationInformationUpdated={
                  this.props.onCheckoutLocationInformationUpdated
                }
                selectedLocale={this.props.selectedLocale}
                locationType={deliveryOptionSelected}
                locationDetailsSaveError={this.props.locationDetailsSaveError}
              />
            )}
          </div>
          <MapView
            className="map"
            locations={loading ? [] : locations}
            locationType={deliveryOptionSelected}
            selectedAddress={selectedAddress}
            onLocationSelect={this.onLocationSelect}
            getAdidasLocationsDataFromMap={this.getAdidasLocationsDataFromMap}
            mapZoom={mapZoom}
            handleMapZoomChange={this.handleMapZoomChange}
            openedMapInfoWindow={openedMapInfoWindow}
            handleMapInfoWindowOpen={this.handleMapInfoWindowOpen}
            mapCenter={mapCenter}
            handleMapCenterChange={this.handleMapCenterChange}
          />
        </section>
        {/* <p onClick={(e) => setAddressSelectionMode(false)}>
          helper: close Map view
        </p>
        <p onClick={(e) => setAddress(selectedAddress)}>
          helper: select a dummy address (from component state)
        </p> */}

        <div className="section-clear"></div>
      </>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selectedLocale,
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

export default connect(mapStateToProps, null)(SelectAddressView);
