//productsAPI.js
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import { setup } from "axios-cache-adapter";

import { BFF_API } from "../constants";

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

let databaseName = "ssk-membership";

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: databaseName,
});

const api = setup({
  baseURL: BFF_API,
  cache: {
    maxAge: 5 * 60 * 1000, // set cache time to 5 minutes
    exclude: { query: false }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

export function getMembershipUIdata(locale) {
  let _url = `${BFF_API}/user/membership/program?locale=${locale}`;

  return api
    .get(_url)
    .then((response) => {
      return response?.data?.data;
    })
    .catch((e) => {
      console.log("API - problem getting events data");
      console.log("error", e);
      return null;
    });
}
