import React, { Component } from "react";
import { GlCarousel, GL_CAROUSEL_ARROW_TYPE } from "@adl/foundation";

import { connect } from "react-redux";

import "./CartGalleryView.css";

class CartGalleryView extends Component {
  state = {
    galleryCurrentPage: 0,
  };

  onGalleryPageMoved = (page) => {
    this.setState({
      galleryCurrentPage: page,
    });
  };

  onGalleryThumbClick = (e, index) => {
    this.setState({
      galleryCurrentPage: index,
    });
  };

  getGalleryThumbnails(media) {
    if (!media) return;

    let _media = media;

    //_media = media.filter((x) => x.type === "image");

    _media = this.addImageSeqNoToList(_media);

    return _media.map((x) => (
      <div
        className="img"
        key={"thumb_" + x.small}
        onClick={(e) => this.onGalleryThumbClick(e, x.index)}
      >
        <img src={x.small} alt="gallery" />
        {this.state.galleryCurrentPage === x.index ? (
          <div className="highlighter"></div>
        ) : (
          <></>
        )}
      </div>
    ));
  }

  addImageSeqNoToList = (imageList) => {
    let _index = 0;

    const _updated = imageList.reduce((acc, item) => {
      const x = { ...item, index: _index++ };
      acc.push(x);
      return acc;
    }, []);

    return _updated;
  };

  render() {
    let _media = [];

    this.props?.cartItems.forEach((item) => {
      _media.push({
        small: item?.product?._links.image_small?.href,
        large: item?.product?._links.image_large?.href,
      });
    });

    return (
      <>
        {_media && (
          <>
            <div className="cart-gallery-wrapper">
              <div className="img-container">
                <GlCarousel
                  activeItem={0}
                  arrowType={GL_CAROUSEL_ARROW_TYPE.square}
                  className="st-carousel__four-items"
                  currentPage={this.state.galleryCurrentPage}
                  itemsToScroll={1}
                  itemsVisiblePerPage={1}
                  onMovedToPageN={(page) => this.onGalleryPageMoved(page)}
                >
                  {_media &&
                    _media.map((x) => (
                      <div className="cart-carousel-div" key={x.large}>
                        <div className="cart-carousel-div-img">
                          <img
                            className="xgallery-image"
                            src={x.large}
                            alt="gallery"
                          />
                        </div>
                      </div>
                    ))}
                </GlCarousel>
              </div>
            </div>

            {_media?.length > 1 && (
              <div className="gallery-wrapper">
                <div className="pdp-gallery-thumnail-container">
                  <div className="content">
                    {this.getGalleryThumbnails(_media)}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps({ cartItems }) {
  return {
    cartItems,
  };
}

export default connect(mapStateToProps, null)(CartGalleryView);
