import React from "react";

function getBulletsJSX(bullets) {
  let _bullets = bullets?.filter((x) => x.index !== 0);

  let _halfCount = _bullets?.length / 2;

  let _firstPathCount = Math.ceil(_halfCount);
  // let _secondPathCount = Math.floor(_halfCount);

  let _firstHalfBullets = [];
  let _secondHalfBullets = [];

  for (var i = 0; i < _bullets?.length; i++) {
    if (i < _firstPathCount) {
      _firstHalfBullets.push(_bullets[i]);
    } else {
      _secondHalfBullets.push(_bullets[i]);
    }
  }

  return (
    <>
      <ul>
        {_firstHalfBullets.map((x) => (
          <li key={x}>{x.indexOf("•") === -1 ? "• " + x : x}</li>
        ))}
      </ul>

      <ul>
        {_secondHalfBullets.map((x) => (
          <li key={x}>{x.indexOf("•") === -1 ? "• " + x : x}</li>
        ))}
      </ul>
    </>
  );
}

function ProductSpecifications(props) {
  return (
    <div className="specifications">
      <div className="heading">
        <h5>{props?.details?.items?.length > 0 && props?.details?.heading}</h5>
      </div>
      <div className="bullets">{getBulletsJSX(props?.details?.items)}</div>
    </div>
  );
}

export default ProductSpecifications;
