import React, { Component } from "react";

import "./SidebarMenu.css";

import { connect } from "react-redux";

import ShoppingBag from "./components/ShoppingBag/ShoppingBag";
import Bitm from "./components/BITM/BITM";

import RequestHelp from "./components/RequestHelp/RequestHelp";

import Search from "./components/Search/Search";

import LanguageOptions from "./components/LanguageOptions/LanguageOptions";

import Menu from "./components/Menu/Menu";

import ModalView from "../ModalView/ModalView";

import { setActiveBITMDetails } from "../../actions/productActions";

import { setActiveHelpRequestDetails } from "../../actions/storeAssociateHelpActions";

import { SAM_REQUEST_STATUS_TYPE } from "../../utils/constants";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../actions/sidebarMenuActions";

import CheckoutView from "./../CheckoutView/CheckoutView";

import { API } from "../../API/index";

let handle_BITMCheck;

let handle_HelpRequestTaskCheck;

const COMPLETE_TASK_REMOVE_AFTER_SEC = 10; //in seconds

class SidebarMenu extends Component {
  state = { currentOpenMenu: null };

  componentDidMount() {
    this.props.setSideBarActiveItem(MENU_ITEM_NAMES.home);

    if (handle_BITMCheck) {
      clearInterval(handle_BITMCheck);
    }

    if (handle_HelpRequestTaskCheck) {
      clearInterval(handle_HelpRequestTaskCheck);
    }

    // check every 5 seconds
    handle_BITMCheck = setInterval(() => {
      this.checkTask("BITM");
    }, 5000);

    // check every 6 seconds
    handle_HelpRequestTaskCheck = setInterval(() => {
      this.checkTask("HELP");
    }, 6000);
  }

  updateCurrentOpenMenu = (openItem) => {
    if (this.props.sideMenu?.value === openItem) {
      this.setState({ currentOpenMenu: null });
      this.props.setSideBarActiveItem(null);
    } else {
      this.setState({ currentOpenMenu: openItem });
      this.props.setSideBarActiveItem(openItem);
    }
  };

  checkTask = (taskType) => {
    const time_diff_ms = (dt2, dt1) => {
      var diff = dt2.getTime() - dt1.getTime();
      return diff;
    };

    if (this.props.sideMenu === MENU_ITEM_NAMES.checkout) {
      return;
    }

    let _activeTask;

    if (taskType === "BITM" && this.props.activeBITM?.length > 0) {
      _activeTask = this.props.activeBITM[0];
    } else if (
      taskType === "HELP" &&
      this.props.activeHelpRequest?.length > 0
    ) {
      _activeTask = this.props.activeHelpRequest[0];
    }

    if (_activeTask) {
      let _taskId = _activeTask?.task?.id;

      if (API.samAPI && _taskId)
        API.samAPI
          .getTask(_taskId)
          .then((response) => {
            if (true) {
              let _noUpdateSince =
                time_diff_ms(
                  new Date(Date.parse(new Date().toJSON())),
                  new Date(Date.parse(_activeTask?.task?.updatedAt))
                ) / 1000;

              console.log(
                "Task complete and last update time was (elapsed in sec)",
                _noUpdateSince
              );
              if (
                _noUpdateSince > COMPLETE_TASK_REMOVE_AFTER_SEC &&
                _activeTask?.task?.status === SAM_REQUEST_STATUS_TYPE.complete
              ) {
                setTimeout(() => {
                  if (taskType === "BITM") {
                    this.props.setActiveBITMDetails([]);
                    setTimeout(() => {
                      if (this.props.sideMenu === MENU_ITEM_NAMES.bitm) {
                        this.props.setSideBarActiveItem(null);
                      }
                    }, 3000);
                  } else {
                    this.props.setActiveHelpRequestDetails([]);
                    setTimeout(() => {
                      if (this.props.sideMenu === MENU_ITEM_NAMES.help) {
                        this.props.setSideBarActiveItem(null);
                      }
                    }, 3000);
                  }
                }, 2000);
              }

              let _responseBITMTask = response?.data?.data;

              if (
                JSON.stringify(_activeTask.task) !==
                JSON.stringify(_responseBITMTask)
              ) {
                _activeTask.task = _responseBITMTask;

                if (taskType === "BITM") {
                  this.props.setActiveBITMDetails([_activeTask]);
                  if (this.props.sideMenu !== MENU_ITEM_NAMES.bitm) {
                    this.props.setSideBarActiveItem(MENU_ITEM_NAMES.bitm);
                  }
                } else {
                  this.props.setActiveHelpRequestDetails([_activeTask]);
                  if (this.props.sideMenu !== MENU_ITEM_NAMES.help) {
                    this.props.setSideBarActiveItem(MENU_ITEM_NAMES.help);
                  }
                }
              }
            }
          })
          .catch((e) => {
            if (e.message !== "Network Error") {
              console.log("task not found");

              if (taskType === "BITM") {
                clearInterval(handle_BITMCheck);

                this.props.setActiveBITMDetails([]);

                setTimeout(() => {
                  if (this.props.sideMenu === MENU_ITEM_NAMES.bitm) {
                    this.props.setSideBarActiveItem(null);
                  }
                }, 5000);
              } else {
                clearInterval(handle_HelpRequestTaskCheck);

                this.props.setActiveHelpRequestDetails([]);

                setTimeout(() => {
                  if (this.props.sideMenu === MENU_ITEM_NAMES.help) {
                    this.props.setSideBarActiveItem(null);
                  }
                }, 5000);
              }
            }
          });
    }
  };

  render() {
    let { sideMenu } = this.props;

    const languageOptions =
      this.props.appSettings?.value?.multiLanguage?.values?.filter(
        (x) => x?.enabled === true
      ) || [];

    return (
      <>
        {sideMenu === MENU_ITEM_NAMES.checkout && (
          <ModalView
            show={true}
            onClose={() => {
              this.updateCurrentOpenMenu(null);
            }}
            className="modal-left-aligned-content modal-full-width"
          >
            <div style={{ paddingBottom: "1rem" }}>
              <CheckoutView></CheckoutView>
            </div>
          </ModalView>
        )}

        <div
          className={`side-panel ${
            sideMenu !== "plp" ? "overlay-background" : ""
          }`}
          // onClick={() => alert(1)}
          onClick={() => this.props.setSideBarActiveItem(null)}
        >
          {sideMenu === MENU_ITEM_NAMES.cart && (
            <div className={`content `}>
              {
                <ShoppingBag
                  onClose={() => this.props.setSideBarActiveItem(null)}
                  resetTimoutTimer={this.props.resetTimoutTimer}
                ></ShoppingBag>
              }
            </div>
          )}

          {sideMenu === MENU_ITEM_NAMES.bitm && (
            <div className={`content `}>
              {
                <Bitm
                  onClose={() => this.props.setSideBarActiveItem(null)}
                  resetTimoutTimer={this.props.resetTimoutTimer}
                ></Bitm>
              }
            </div>
          )}

          {sideMenu === MENU_ITEM_NAMES.help && (
            <div className={`content `}>
              {
                <RequestHelp
                  onClose={() => this.props.setSideBarActiveItem(null)}
                  resetTimoutTimer={this.props.resetTimoutTimer}
                ></RequestHelp>
              }
            </div>
          )}

          {sideMenu === MENU_ITEM_NAMES.search && (
            <div className={`content `}>
              {
                <Search
                  onClose={() => this.props.setSideBarActiveItem(null)}
                ></Search>
              }
            </div>
          )}

          {sideMenu === MENU_ITEM_NAMES.languageOptions && (
            <div className={`content `}>
              {
                <LanguageOptions
                  languageOptions={languageOptions}
                  onClose={() => this.props.setSideBarActiveItem(null)}
                  resetTimoutTimer={this.props.resetTimoutTimer}
                ></LanguageOptions>
              }
            </div>
          )}

          <Menu
            onMenuItemClick={this.updateCurrentOpenMenu}
            languageOptions={languageOptions}
          ></Menu>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  sideMenu,
  activeBITM,
  activeHelpRequest,
  appSettings,
}) {
  return {
    sideMenu: sideMenu,
    activeBITM,
    activeHelpRequest,
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setActiveBITMDetails: (val) => dispatch(setActiveBITMDetails(val)),
    setActiveHelpRequestDetails: (val) =>
      dispatch(setActiveHelpRequestDetails(val)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
