export const getPriceType = (price, orginalPrice) => {
  if (orginalPrice === price) {
    return "FULL PRICE";
  }

  if (orginalPrice > price) {
    return "ON SALE";
  }
};

export const getStockStatus = (orderable) => {
  if (orderable) {
    return "In Stock";
  }

  if (!orderable) {
    return "Out of Stock";
  }
};
