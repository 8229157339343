import APIBase from "../APIBase/APIBase";

class SsmAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIPostRequest = (_url, _postData) => {
    return this.axiosApi({
      url: _url,
      method: "post",
      data: _postData,
      cache: {
        maxAge: 8 * 60 * 60 * 1000, // set cache time to 8 hour
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return { response };
    });
  };

  #getNearbyStoresForSSMId = async (ssmId, distance) => {
    console.log("##### getNearbyStoresForSSMId called");
    await this.#getHeaders();

    let _postData = {
      nearStoreRequest: {
        distance: distance, // example "10km"
        ssmId: ssmId, //example eg. "9990094660"
      },
    };
    let _url = `/ssm/stores/?businessModel=Own Retail&lifecycleStatus=Open&ssmFields=LOCATION`;

    _url = encodeURI(_url);
    console.log(_url);
    return this.#handleAPIPostRequest(_url, _postData);
  };

  #getNearbyStoresForGeoCoordinates = async (lat, long, distance) => {
    await this.#getHeaders();

    let _postData = {
      nearGeoLocationRequest: {
        distance: distance, //example "10km"
        latitude: lat, //example 49.56798
        longitude: long, //example 10.88565
      },
    };

    let _url = `/ssm/stores/?businessModel=Own Retail&lifecycleStatus=Open&ssmFields=LOCATION`;

    _url = encodeURI(_url);

    return this.#handleAPIPostRequest(_url, _postData);
  };

  getAllFunctions = () => {
    return {
      getNearbyStoresForSSMId: this.#getNearbyStoresForSSMId,
      getNearbyStoresForGeoCoordinates: this.#getNearbyStoresForGeoCoordinates,
    };
  };
}

export default SsmAPI;
