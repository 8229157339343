import React, { Component } from "react";

import { PAGE_NAMES, setCurrentPageName } from "../../../actions/pageActions";

import "./EventsOverlayView.css";

import { connect } from "react-redux";

// import sampleEventImage from "../../../assets/images/events-hero-sample-image.png";
// import sampleQrImage from "../../../assets/images/sample_qr-image.png";

import { GlHeading } from "@adl/foundation";
import NewBackButton from "../../NewBackButton/NewBackButton";
import QRCode from "react-qr-code";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../../utils/translationCopies";

class EventsOverlayView extends Component {
  state = { genderList: [], isProductListDataLoadError: null };

  componentDidMount() {
    if (PAGE_NAMES.eventDetailsPage !== this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.eventDetailsPage);
    }
  }

  componentDidUpdate(prevProps) {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { eventDetails } = this.props;
    const isVerticalScreen = window.screen.height > window.screen.width;

    return (
      <div className="event-container">
        {isVerticalScreen && (
          <div className="image-placeholder">
            <div className="event-details-container hero">
              <img alt="event" src={eventDetails?.imageUrl}></img>

              <div className="event-name-block">
                <div className="sub-heading ">
                  <span>{eventDetails?.title}</span>
                </div>

                {eventDetails?.subtitle && (
                  <div className="sub-heading thin ">
                    <span>{"some text here"}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="details-container">
          <div className="container-left">
            <div className="subline">
              {" "}
              {this.getTranslation(
                TRANSLATIONS_KEY_LIST.eventspage_store_event
              )}
            </div>
            <div className="heading">
              {" "}
              <GlHeading as="h2" fontStyle="regular" className="heading">
                {eventDetails?.title}
              </GlHeading>{" "}
            </div>
            <div className="sub-heading">
              <GlHeading as="h3" fontStyle="regular" className="sub-heading ">
                {this.getTranslation(
                  TRANSLATIONS_KEY_LIST.eventspage_event_details
                )}
              </GlHeading>{" "}
            </div>
            <div className="description">
              {eventDetails?.description
                ?.replaceAll("<p>", "")
                ?.replaceAll("&nbsp;", "")
                ?.split("</p>")
                .map((x) => (
                  <p>{x}</p>
                ))}

              {/* <p>
                Fitness isn't just great for your body. Boost your mood by
                moving.
              </p>

              <p>
                Whatever exercises you like, we can help you get started. Enter
                the draw now for a chance to win a workout pack, including
                training accessories, resistance bands, a skipping rope and
                fitness mat.
              </p>

              <p>
                If you haven't already, enter the Commit to Getting Fit
                challenge in the adidas Training APP to enter the prize draw
                again and double your chances of winning.
              </p> */}
            </div>

            {eventDetails?.signUpDeadlineDate && (
              <>
                <div className="sub-heading">
                  {" "}
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.eventspage_raffle_timelines
                  )}
                </div>
                <div className="timeline">
                  <div className="row">
                    <div className="progress-container">
                      <div
                        class={`progress-container-left ${
                          eventDetails?.raffleDate ? "with-right-border" : ""
                        }`}
                      >
                        &nbsp;
                      </div>
                      <div className="circle-container">
                        <div className="icon">
                          <div className="dot"></div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="heading">01</div>
                      <div className="sub-heading">
                        {" "}
                        {this.getTranslation(
                          TRANSLATIONS_KEY_LIST.eventspage_sign_up_before
                        )}
                      </div>
                      <div className="description">
                        {eventDetails?.signUpDeadlineDate}
                      </div>
                    </div>
                  </div>

                  {eventDetails?.raffleDate && (
                    <div className="row">
                      <div className="progress-container">
                        <div className="progress-container-left">&nbsp;</div>
                        <div className="circle-container">
                          <div className="icon">
                            <div className="dot"></div>
                          </div>
                        </div>
                      </div>

                      <div className="item">
                        <div className="heading">02</div>
                        <div className="sub-heading">
                          {" "}
                          {this.getTranslation(
                            TRANSLATIONS_KEY_LIST.eventspage_raffle_results
                          )}
                        </div>
                        <div className="description">
                          {eventDetails?.raffleDate}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="container-right qr-code-wrapper">
            {!isVerticalScreen && (
              <div className="row event-pic-small">
                <img alt="event" src={eventDetails?.imageUrl}></img>
              </div>
            )}
            <QRCode value={eventDetails?.shareUrl} size={370} />
            <div className="qr-code label">
              {" "}
              {this.getTranslation(
                TRANSLATIONS_KEY_LIST.eventspage_scan_and_sign_up
              )}
            </div>
          </div>
        </div>
        <NewBackButton
          onClick={() => {
            this.props.setCurrentPageName(PAGE_NAMES.eventsPage);
          }}
        ></NewBackButton>
      </div>
    );
  }
}

function mapStateToProps({ appSettings, selectedTranslationLocale }) {
  return {
    appSettings,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsOverlayView);
