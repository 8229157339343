export const translations_ko_KR = {
  all_products: "모든 제품",
  go_to_top: "맨 위로 이동",
  more_filters: "필터 늘리기",
  less_filters: "필터 줄이기",
  sort_by: "정렬 기준",
  clear_all: "모두 지우기",
  colors_available: "<count>개의 색상",
  colors_available_plp: "<count>개의 색상",

  details: "세부정보",
  how_to_style: "스타일 제안",
  reviews: "리뷰",
  gallery: "갤러리",
  highlights: "하이라이트",

  select_size: "사이즈 선택하기",
  store_availability: "다른 매장 확인하기",

  size_guidance: "사이즈 가이드",
  add_to_bag: "장바구니에 추가",
  scan_to_order_online: "스캔으로 온라인 주문하기",
  shopping_bag: "장바구니",
  bag_empty: "장바구니가 비어있습니다",

  max_cart_item_count_reached: "최대 수량 도달",

  browse_online: (
    <>
      "지금 쇼핑을 시작하고 장바구니를 채워보세요.
      <br />이 키오스크를 검색하여 이 매장의 재고를 확인하거나 온라인으로 제품을
      주문하세요."
    </>
  ),

  order_online: "온라인으로 주문하기",

  start_browsing: "매장 둘러보기",
  total: "합계",
  including_tax: "(세금 포함)",
  checkout: "체크아웃",
  delete_bag: "장바구니 비우기",

  continue_on_this_device: "키오스크에서 계속하기",
  checkout_on_your_device: "휴대폰으로 확인하기",

  checkout_on_your_device_instruction:
    "QR 코드를 스캔해 장바구니를 아디다스 앱 또는 모바일 웹사이트로 전송할 수 있습니다.",

  employee_checkout: "직원 체크아웃",
  employee_checkout_title: "직원과 체크아웃",
  employee_checkout_instruction:
    "가방을 옮기고 상품을 지불하기 위해 이 QR 코드를 스캔하십시오.",
  employee_id_instruction: "직원 ID를 입력하고 QR 코드를 업데이트하십시오.",
  employee_id_update_qr_code: "QR 코드 업데이트",
  employe_id_validation_error: "잘못된 직원 ID",
  change_employee_id: "직원 ID 변경",

  only_cash_on_delivery_option:
    "참고: 키오스크 주문의 결제 방법은 Cash on Delivery(배송 시 지불)만 허용됩니다.",

  yes_over_16_years_of_age: "16세 이상입니다",

  choose_your_shipping_method: "배송 방법 선택하기",
  order_summary: "주문 내역",
  promo_code: "프로모션 코드",
  place_order: "주문하기",
  order_now_pay_on_delivery: "지금 주문하고, 배송 시에 결제하세요",

  read_more_reviews: "리뷰 더 보기",

  edit_address: "주소 편집하기",
  save_details: "저장하기",
  edit_details: "세부 정보 편집",
  select_pickup_point: "이 위치 선택하기",
  select_store: "이 매장 선택하기",
  back_to_list: "제품으로 돌아가기",

  sure_to_delete_cart: "장바구니를 비우시겠습니까?",
  cart_delete_message: "추가한 모든 항목이 삭제되고 세션이 종료됩니다.",
  cancel: "취소하기",

  add_voucher_heading: "쿠폰 또는 프로모션 코드 추가",
  add_voucher_instruction: "대소문자 구분, 표시된 대로 입력",
  add_voucher_disclaimer:
    "일부 제품은 특정 프로모션 코드에서 제외될 수 있습니다. 이용 약관을 확인하세요.",
  apply: "적용하기",

  loading: "불러오는 중...",
  your_order_in_progress: "주문 처리 중입니다",
  your_order_is_placed: "주문 완료!",
  session_ends_in: "세션 종료 시간: <분> 후",
  estimated_delivery: "예상 배송: <placeholder>",
  confirmation_email_sent_to:
    "다음 주소로 확정 메일이 발송되었습니다: <email address>",
  order_number: "주문 번호:",
  start_new_session: "새 세션 시작하기",
  extend_session: "계속하기",

  map_search_no_result: "검색 결과가 없습니다",

  enter_your_details: "상세 연락처",
  first_name: "이름",
  last_name: "성",
  street: "도로명",
  house_number: "번지",
  zip_code: "우편번호",
  town_city: "시/도",
  email_address: "이메일 주소",
  phone_number: "전화번호",

  change_store: "매장 변경",
  change_pickup_point: "픽업 지점 변경하기",

  search_by_city_dictrict_or_address: "도시, 지역 또는 주소별로 검색",

  processing: "처리 중...",

  error_occurred: "죄송합니다. 오류가 발생했습니다",

  retry_place_order: "다시 주문해 주세요",

  retry_start_checkout: "다시 체크아웃하기",

  please_select_your_size: "사이즈 선택",
  bring_it_to_me: "Bring it to me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "매장 직원에게 문의하기",
  do_you_need_assistance_title: <>고객 지원</>,
  do_you_need_assistance_instruction:
    "버튼을 누르면 매장 직원 중 한 명이 곧 귀하에게 올 것입니다.",
  do_you_need_assistance_sure_to_delete: "이 요청을 삭제하시겠습니까?",
  do_you_need_assistance_delete_btn_label: "이 요청을 삭제하시겠습니까?",

  we_will_bring_it_here: "제품 가져오기 서비스 이용하기",
  bring_it_to_me_instruction:
    "아디다스 앱이 없나요? 스크린 근처에서 기다리시면 약 3-5분 후에 이곳으로 요청하신 제품을 가져다드립니다.",

  bitm_none_active_title: "마음에 드는 제품을 찾았나요?",
  bitm_none_active_instruction: (
    <>
      직접 가져다드립니다! 원하는 제품과 사이즈를 선택하고 이 키오스크 또는
      아디다스 앱에서 BRING IT TO ME를 탭하세요.
    </>
  ),

  bitm_sure_to_delete: "이 요청을 삭제하시겠습니까?", //verify
  bitm_delete_btn_label: "요청 삭제하기",

  bitm_task_todo: "대기 중인 요청",
  bitm_task_in_progress: "현재 이동 중입니다",
  bitm_task_complete: "완료!",

  bitm_qr_title: "기다리는 동안 둘러보기",
  bitm_qr_description:
    "QR 코드를 스캔하고 앱에서 요청을 완료하세요. 매장을 마음껏 둘러보세요. 제품이 준비되면 바로 찾아갑니다.",
  restart_checkout: "문제가 발생했나요? 페이지 새로고침",

  try_again: "다시 시도하기",

  session_expiring_title: "완료 되었나요?",
  session_expiring_body: "곧 세션이 종료됩니다",

  nearbystore_availability_overlay_title: "다른 매장의 재고 여부 확인",

  available_in_nearby_store: "현재 위치에서 구입 가능한 사이즈",

  nearby_store_no_sizes_found:
    "찾고 계신 제품은 현재 이 매장에 재고가 없습니다.",

  please_try_selecting_another_store: "다른 매장을 이용해주세요.",

  in_stock_at_this_store: "매장에 재고가 있습니다",

  employee_id: "직원 ID",

  add_employee_id_heading: "직원 ID 추가하기",

  add_employee_id_instruction: "계속하려면 매장 직원에게 도움을 요청하세요.",

  verify_checkout: "저장하기 전에 세부정보를 다시 확인해주세요",

  search: "검색",

  search_result_see_all: "<search-text> 모두 보기",

  search_no_result: "죄송합니다. 결과를 찾을 수 없습니다. 다시 시도해 주세요.",

  try_searching_for_something_else: "다른 검색어를 입력해 주세요.",

  start_new_search: "검색 다시 시작하기",

  showing_results_for: "에 대한 검색 결과",

  no_available_sizes_found: "현재 구입 가능한 사이즈가 없습니다",

  online: "온라인",
  in_store: "매장",

  tap_to_get_started: "시작하려면 탭하세요", // not used
  your_journey_starts_here: "당신의 여정이 여기에서 시작됩니다",
  app_header_title: "최신 및 최고의 제품을 찾아보세요",
  landingpage_filter_heading: "누구를 위해 쇼핑하시나요",
  landingpage_signup_heading: "아디다스의 최고를 경험하세요",
  landingpage_signup_cta: "회원 전용 기어를 쇼핑하려면 가입하세요",
  landingpage_events_cta: "다가오는 출시 및 이벤트 살펴보기",
  landingpage_instore_services_cta: "점내 서비스 찾아보기",
  eventspage_heading: "아디다스 이벤트 살펴보기",
  eventspage_sub_heading: "독점적인 이벤트로 쇼핑 경험을 최대한 활용하세요.",

  eventspage_store_event: "매장 이벤트",
  eventspage_event_details: "이벤트 세부 정보",
  eventspage_raffle_timelines: "추첨 일정",
  eventspage_raffle_results: "추첨 결과",
  eventspage_sign_up_before: "등록 기한",
  eventspage_scan_and_sign_up: "스캔하여 등록하기",

  instoreservicepage_heading: "점내 서비스 살펴보기",
  instoreservicepage_sub_heading:
    "독점적인 서비스로 쇼핑 경험을 최대한 활용하세요.",

  membershippage_title: "무료 회원 프로그램에 가입하세요",
  membershippage_body:
    "adiClub 포인트를 사용하여 좋아하는 것을 더욱 즐겁게 쇼핑하세요.",
  membershippage_feature_membership_discount: "회원 전용 할인",
  membershippage_feature_freeshipping: "무료 배송",
  membershippage_feature_birthdaygifts: "생일 선물",
  membershippage_feature_priorityshopping: "우선 쇼핑",
  membershippage_feature_specialevents: "특별 이벤트",

  change_language: "언어 변경",
};
