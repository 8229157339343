import { API } from "../API/index";

export const RECEIVE_APP_SETTINGS = "RECEIVE_APP_SETTINGS";

export const SET_LOCALE = "SET_LOCALE";

export const SET_SELECTED_LANG_LOCALE = "SET_SELECTED_LANG_LOCALE";

export function setLocale(localeSettings) {
  return { type: SET_LOCALE, localeSettings };
}

export function setLanguageLocale(languageLocale) {
  return { type: SET_SELECTED_LANG_LOCALE, languageLocale };
}

export function receiveAppSettings(settings) {
  return { type: RECEIVE_APP_SETTINGS, settings };
}

export const fetchAppSettings = () => (dispatch) => {
  /* Overrides for testing and debugging purpose
   * values from query string will override the ones from app settings config file */
  let pageQueryString = window.location?.search?.replace("?", "").split("&");
  let marketOverrideLocale;
  let translationOverrideLocale;

  pageQueryString.forEach((param) => {
    if (param.split("=")[0] === "marketOverrideLocale") {
      marketOverrideLocale = param.split("=")[1];
    }

    if (param.split("=")[0] === "translationOverrideLocale") {
      translationOverrideLocale = param.split("=")[1];
    }
  });

  API.appSettingsAPI
    .getAppSettings()
    .then((response) => dispatch(receiveAppSettings(response?.response?.data)))

    .then((x) => {
      const localeSettings = {
        marketLocale: marketOverrideLocale || x.settings?.value?.default_locale,
        languageLocale:
          translationOverrideLocale ||
          (x.settings?.value?.workaround?.translation?.override_enabled
            ? x.settings?.value?.workaround?.translation?.reference_locale
            : x.settings?.value?.default_locale),
      };

      return localeSettings;
    })
    .then((localeSettings) => dispatch(setLocale(localeSettings)))
    .then((localeSettings) =>
      dispatch(setLanguageLocale(localeSettings?.localeSettings?.marketLocale))
    )

    .catch((e) => {
      console.log("Unable to fetch App settings", e);
    });
};
