import React from "react";

import { GlButton, GL_BUTTON_THEME, GL_BUTTON_VARIANT } from "@adl/foundation";

import ModalView from "../../../../../ModalView/ModalView";

import "./DeleteCartConfirmationModal.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

function CheckoutConfirmationModal(props) {
  const _getTranslation = (key) => {
    return translateString(key);
  };

  return (
    <div>
      <ModalView
        showCloseBtn={true}
        show={props.show}
        onClose={() => {
          props.onClose();
        }}
        className="modal-centered"
      >
        <div className="delete-cart-confirmation__content">
          <div className="upper">
            <div className="title">
              {_getTranslation(TRANSLATIONS_KEY_LIST.sure_to_delete_cart)}
            </div>

            <div className="body">
              <p>
                {_getTranslation(TRANSLATIONS_KEY_LIST.cart_delete_message)}
              </p>
            </div>
          </div>

          <div className="lower">
            <div></div>

            <div className="button-container">
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.dark}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => {
                    props.onClose();
                  }}
                  icon={null}
                >
                  {_getTranslation(TRANSLATIONS_KEY_LIST.cancel)}
                </GlButton>
              </div>
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => {
                    props.onConfirm();
                  }}
                  icon={null}
                >
                  {_getTranslation(TRANSLATIONS_KEY_LIST.delete_bag)}
                </GlButton>
              </div>
            </div>
          </div>
        </div>
      </ModalView>
    </div>
  );
}

export default CheckoutConfirmationModal;
