import React, { Component } from "react";
import { connect } from "react-redux";

import "./Menu.css";

import homeIcon from "../../../../assets/icons/home.svg";
import homeIcon_black from "../../../../assets/icons/home_black.svg";
import cartIcon from "../../../../assets/icons/cart.svg";
import cartIcon_dark from "../../../../assets/icons/cart_dark.svg";
import bitm from "../../../../assets/icons/bitm.svg";
import bitm_dark from "../../../../assets/icons/bitm_dark.svg";

import help from "../../../../assets/icons/help.svg";
import help_dark from "../../../../assets/icons/help_black.svg";

import search from "../../../../assets/icons/search.svg";
import search_dark from "../../../../assets/icons/search_black.svg";

import settings_dark from "../../../../assets/icons/settings_dark.svg";
import settings from "../../../../assets/icons/settings.svg";

import { setExternalPDPTrigger } from "../../../../actions/productActions";

import { setSearchInput } from "../../../../actions/searchActions";

import { setLanguageLocale } from "../../../../actions/appSettingsActions";
import {
  PAGE_NAMES,
  setCurrentPageName,
} from "../../../../actions/pageActions";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../../../actions/sidebarMenuActions";

import * as helpers from "../../../../utils/helpers";

import flagOverrides from "./flagoverrides";

const packageInfo = require("../../../../../package.json");

class Menu extends Component {
  state = {
    nextLangLocale: null,
  };

  componentDidMount() {
    this.setLanguageChangeFlag();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.selectedTranslationLocale) !==
      JSON.stringify(this.props.selectedTranslationLocale)
    ) {
      this.setLanguageChangeFlag();
    }
  }

  onMenuItemClick = (e, menuItemName) => {
    this.props.onMenuItemClick(menuItemName);
  };

  onHomeClick = (e) => {
    const enabledLandingPage =
      (this.props.appSettings?.value?.landing_page?.enabled &&
        (this.props.appSettings?.value?.landing_page?.filter_section?.enabled ||
          this.props.appSettings?.value?.landing_page?.membership_section
            ?.enabled ||
          this.props.appSettings?.value?.landing_page?.events_section
            ?.enabled ||
          this.props.appSettings?.value?.landing_page?.instore_services_section
            ?.enabled)) ||
      false;

    this.onMenuItemClick(e, MENU_ITEM_NAMES.landingPage);

    this.props.setCurrentPageName(
      enabledLandingPage ? PAGE_NAMES.landingPage : PAGE_NAMES.plp
    );

    this.props.setSearchInput(null);
  };

  onCountryFlagClick = () => {
    this.setLanguageChangeFlag();
    setTimeout(() => {
      this.props.setLanguageLocale(
        this.state.nextLangLocale?.locale || this.state.nextLangLocale
      );
    }, 300);
  };

  setLanguageChangeFlag = () => {
    const currentTranslationSelection = this.props.selectedTranslationLocale;
    const currentSelectedMarketLocale = this.props.selectedLocale;

    let availableLanguageLocale;

    if (this.props?.appSettings?.value?.multiLanguage?.values) {
      availableLanguageLocale =
        this.props?.appSettings?.value?.multiLanguage?.values?.[0]?.locale;
    } else {
      availableLanguageLocale =
        this.props?.appSettings?.value?.multiLanguage?.locales?.[0];
    }

    let nextLangLocale = null;
    let nextLangLocaleFlag = null;

    if (currentTranslationSelection === currentSelectedMarketLocale) {
      nextLangLocale = availableLanguageLocale;

      if (this.props?.appSettings?.value?.multiLanguage?.values) {
        nextLangLocaleFlag =
          this.props?.appSettings?.value?.multiLanguage?.values?.[0]
            ?.flag_country_code ||
          this.props?.appSettings?.value?.multiLanguage?.values?.[0]?.locale?.split(
            "_"
          )?.[1];
      } else {
        nextLangLocaleFlag =
          this.props?.appSettings?.value?.multiLanguage?.locales?.[0].split(
            "_"
          )?.[1];
      }
    } else {
      nextLangLocale = currentSelectedMarketLocale;

      const defaultLocaleFlagCode = (availableLanguageLocale =
        this.props?.appSettings?.value?.default_locale_flag_country_code);

      if (defaultLocaleFlagCode) {
        nextLangLocaleFlag = defaultLocaleFlagCode;
      } else {
        nextLangLocaleFlag = currentSelectedMarketLocale?.split("_")?.[1];
      }
    }

    this.setState({
      nextLangLocale: nextLangLocale,
      nextLangLocaleFlag: nextLangLocaleFlag,
    });
  };

  getFlagJSX = () => {
    const nextLangLocaleFlag = this.state?.nextLangLocaleFlag?.toLowerCase();
    const imgPublicPath = `/adidas/assets/flags/${nextLangLocaleFlag}.svg`;

    const flagImageOverride = flagOverrides.filter(
      (x) => x.locale === this.state?.nextLangLocale
    )[0]?.flagPath;

    const flagPath = flagImageOverride ? flagImageOverride : imgPublicPath;

    return <img src={flagPath} alt={this.state?.nextLangLocale} />;
  };

  getFlagJSXWhenMultiLang = (locale) => {
    const flagCountryCode = locale?.split("_")?.[1].toLowerCase();

    const imgPublicPath = `/adidas/assets/flags/${flagCountryCode}.svg`;

    const flagImageOverride = flagOverrides.filter(
      (x) => x.locale === locale
    )[0]?.flagPath;

    const flagPath = flagImageOverride ? flagImageOverride : imgPublicPath;

    return <img className="multi-lang-flag" src={flagPath} alt={locale} />;
  };

  render() {
    let { sideMenu, currentPage, availableBrowserStorage } = this.props;
    let cartArticlesCount = helpers.getCartItemsCount(this.props.cartItems);

    let shouldEnableBITM =
      this.props?.appSettings?.value?.player?.enableBringItToMe || false;

    let shouldEnableHelp =
      this.props?.appSettings?.value?.player?.enableCallStoreAssociate || false;

    let shouldEnableMultiLanguage =
      this.props?.appSettings?.value?.multiLanguage?.enabled || false;

    let shouldEnableSearch =
      this.props?.appSettings?.value?.enableSearch || false;

    let shouldEnableSettings = false; // todo: // work in progress

    return (
      <>
        <div
          className="sidebar-wrapper-menu"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (window._setUserActionTime) window._setUserActionTime();
          }}
        >
          <div className="primary-menu">
            <div
              className={`${
                sideMenu === MENU_ITEM_NAMES.home &&
                currentPage === PAGE_NAMES.landingPage
                  ? "highlighted"
                  : ""
              }`}
              onClick={(e) => this.onHomeClick(e)}
            >
              <div className="icon-container">
                <img
                  src={
                    sideMenu === MENU_ITEM_NAMES.home &&
                    currentPage === PAGE_NAMES.landingPage
                      ? homeIcon_black
                      : homeIcon
                  }
                  alt="home"
                />
              </div>
            </div>

            {/* search start  */}
            {shouldEnableSearch && (
              <div
                className={`${
                  sideMenu === MENU_ITEM_NAMES.search ? "highlighted" : ""
                } ${shouldEnableSearch ? "" : "feature-unavailable"}`}
                onClick={
                  shouldEnableSearch
                    ? (e) => {
                        this.onMenuItemClick(e, MENU_ITEM_NAMES.search);
                      }
                    : null
                }
              >
                <img
                  src={
                    sideMenu === MENU_ITEM_NAMES.search ? search_dark : search
                  }
                  alt="search"
                />
              </div>
            )}

            {/* search end  */}

            <div
              className={`${
                sideMenu === MENU_ITEM_NAMES.cart ? "highlighted" : ""
              }`}
              onClick={(e) => {
                this.onMenuItemClick(e, MENU_ITEM_NAMES.cart);
              }}
            >
              <div className="icon-container">
                <img
                  src={
                    sideMenu === MENU_ITEM_NAMES.cart ? cartIcon_dark : cartIcon
                  }
                  alt="cart"
                />

                {cartArticlesCount > 0 && (
                  <div className="cart-count">{cartArticlesCount}</div>
                )}
              </div>
            </div>
            {shouldEnableBITM && (
              <div
                className={`${
                  sideMenu === MENU_ITEM_NAMES.bitm ? "highlighted" : ""
                } ${shouldEnableBITM ? "" : "feature-unavailable"}`}
                onClick={
                  shouldEnableBITM
                    ? (e) => {
                        this.onMenuItemClick(e, MENU_ITEM_NAMES.bitm);
                      }
                    : null
                }
              >
                <img
                  src={sideMenu === MENU_ITEM_NAMES.bitm ? bitm_dark : bitm}
                  alt="cart"
                />
              </div>
            )}

            {shouldEnableHelp && (
              <div
                className={` 
              ${sideMenu === MENU_ITEM_NAMES.help ? "highlighted" : ""}
              
              ${shouldEnableHelp ? "" : "feature-unavailable"}`}
                onClick={
                  shouldEnableHelp
                    ? (e) => {
                        this.onMenuItemClick(e, MENU_ITEM_NAMES.help);
                      }
                    : null
                }
              >
                <img
                  src={sideMenu === MENU_ITEM_NAMES.help ? help_dark : help}
                  alt="help"
                />
              </div>
            )}

            {shouldEnableMultiLanguage &&
              this.props?.languageOptions?.length === 1 && (
                <div
                  className={`sidebar-menu-flag
                `}
                  onClick={() => this.onCountryFlagClick()}
                >
                  {this.getFlagJSX()}
                </div>
              )}

            <div className="debug-info-active-locale hidden">
              <p>{this.props.appSettings?.value?.default_locale}</p>
            </div>

            {shouldEnableMultiLanguage &&
              this.props?.languageOptions?.length > 1 && (
                <div
                  className={` 
                ${
                  sideMenu === MENU_ITEM_NAMES.languageOptions
                    ? "highlighted"
                    : ""
                }
                
                "lang-option"`}
                  onClick={(e) =>
                    this.onMenuItemClick(e, MENU_ITEM_NAMES.languageOptions)
                  }
                >
                  <div className="lang-option__flag">
                    {this.getFlagJSXWhenMultiLang(
                      this.props?.selectedTranslationLocale
                    )}
                  </div>
                </div>
              )}

            {/* <div className="debug">
              <div>{"RFID INPUT SIMLN"}</div>
            </div>
            <div
              className="debug"
              onClick={(e) => this.props.setExternalPDPTrigger("H04240")}
            >
              <div>{"H04240"}</div>
            </div>

            <div
              className="debug"
              onClick={(e) => this.props.setExternalPDPTrigger("FL8782")}
            >
              <div>{"FL8782"}</div>
            </div>
            <div
              className="debug"
              onClick={(e) => this.props.setExternalPDPTrigger("FL8784")}
            >
              <div>{"FL8784"}</div>
            </div>

            <div
              className="debug"
              onClick={(e) => this.props.setExternalPDPTrigger("GN2888")}
            >
              <div>{"GN2888"}</div>
            </div>

            <div
              className="debug"
              onClick={(e) => this.props.setExternalPDPTrigger("FY0306")}
            >
              <div>{"FY0306"}</div>
            </div> */}
          </div>
          <div className="secondary-menu" style={{ opacity: "1" }}>
            {/* <div>
              <img src="images/settings.svg" alt="settings" />
            </div> */}

            {shouldEnableSettings && (
              <div
                className={` ${
                  sideMenu === MENU_ITEM_NAMES.settings ? "highlighted" : ""
                } ${shouldEnableSettings ? "" : "feature-unavailable"}`}
                onClick={
                  shouldEnableSettings
                    ? (e) => this.onMenuItemClick(e, MENU_ITEM_NAMES.settings)
                    : null
                }
              >
                <img
                  src={
                    sideMenu === MENU_ITEM_NAMES.settings
                      ? settings_dark
                      : settings
                  }
                  alt="cart"
                />
              </div>
            )}

            <div className="app-version-info">
              <div>{"version"}</div>
              <div>{packageInfo?.version}</div>
            </div>
          </div>
        </div>
        <div className="storage-used-info">
          <div>{"storage"}</div>
          <div>
            <ul>
              <li>
                {`quota: ${Math.round(
                  availableBrowserStorage?.quota / 1000000
                )} MB`}
              </li>
              <li>{`used:  ${Math.round(
                (availableBrowserStorage?.usage /
                  availableBrowserStorage?.quota) *
                  100
              )} %`}</li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  cartItems,
  sideMenu,
  currentPage,
  appSettings,
  selectedLocale,
  selectedTranslationLocale,
  availableBrowserStorage,
}) {
  return {
    cartItems,
    sideMenu,
    currentPage,
    appSettings,
    selectedLocale,
    selectedTranslationLocale,
    availableBrowserStorage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),
    setSearchInput: (val) => dispatch(setSearchInput(val)),
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setLanguageLocale: (val) => dispatch(setLanguageLocale(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
