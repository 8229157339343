export class EventsListModel {
  constructor(
    id,
    title,
    description,
    signUpStartDate,
    signUpDeadlineDate,
    raffleDate,
    eventStartDate,
    eventEndDate,
    shareUrl,
    imageUrl
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.signUpStartDate = signUpStartDate;
    this.signUpDeadlineDate = signUpDeadlineDate;
    this.raffleDate = raffleDate;
    this.eventStartDate = eventStartDate;
    this.eventEndDate = eventEndDate;
    this.shareUrl = shareUrl;
    this.imageUrl = imageUrl;
  }
}
