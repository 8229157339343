import { combineReducers } from "redux";
import { SET_SIDE_MENU, MENU_ITEM_NAMES } from "../actions/sidebarMenuActions";

import {
  CART_ADD_SKU,
  CART_REMOVE_SKU,
  CART_UPDATE_SKU,
  CART_REMOVE_ALL,
} from "../actions/cartActions";

import {
  RECEIVE_APP_SETTINGS,
  SET_LOCALE,
  SET_SELECTED_LANG_LOCALE,
} from "../actions/appSettingsActions";

import {
  SET_EXTERNAL_PDP_TRIGGER,
  SET_ACTIVE_BITM_DETAILS,
} from "../actions/productActions";

import { SET_USER_SELECTED_SEARCH_SUGGESTION } from "../actions/searchActions";

import { SET_ACTIVE_HELP_REQUEST_DETAILS } from "../actions/storeAssociateHelpActions";

import {
  SET_CURRENT_PAGE,
  SET_USER_ACTION_TIME,
  PAGE_NAMES,
  SET_AVAILABLE_BROWSER_STORAGE,
} from "../actions/pageActions";

import {
  SET_INPUT_WITH_KEYBOARD_ID,
  SET_INPUT_WITH_KEYBOARD_VALUE,
  CLEAR_INPUT_WITH_KEYBOARD_VALUE,
  SET_INITIAL_INPUT_WITH_KEYBOARD_VALUE,
} from "../actions/inputWithKeyboardActions";

import { RECEIVE_EVENTS_LIST } from "../actions/eventActions";

function currentPage(state = PAGE_NAMES.interactionVideo, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return action.value;

    case SET_EXTERNAL_PDP_TRIGGER:
      return PAGE_NAMES.pdp;

    default:
      return state;
  }
}

function sideMenu(state = MENU_ITEM_NAMES.home, action) {
  switch (action.type) {
    case SET_SIDE_MENU:
      if (state === action.value) {
        return MENU_ITEM_NAMES.home;
      } else {
        if (!action.value) {
          return MENU_ITEM_NAMES.home;
        }
        return action.value;
      }
    default:
      return state;
  }
}

function cartItems(state = [], action) {
  let _match;

  switch (action.type) {
    case CART_ADD_SKU:
      _match = state.filter((x) => x.sku === action.item.sku);

      if (_match.length > 0) {
        _match[0].quantity += 1;
        return [...state];
      } else {
        return [action.item, ...state];
      }

    case CART_UPDATE_SKU:
      _match = state.filter((x) => x.sku === action.item.sku);

      if (_match.length > 0) {
        _match[0] = action.item;
        return [...state];
      } else {
        return [...state, action.item];
      }

    case CART_REMOVE_SKU:
      _match = state.filter((x) => x.sku !== action.item.sku);
      return [..._match];

    case CART_REMOVE_ALL:
      return [];
    default:
      return [...state];
  }
}

function appSettings(state = {}, action) {
  console.log(action);

  switch (action.type) {
    case RECEIVE_APP_SETTINGS:
      return action.settings ? action.settings : {};
    default:
      return state;
  }
}

function selectedLocale(state = null, action) {
  switch (action.type) {
    case SET_LOCALE:
      return action.localeSettings?.marketLocale;
    default:
      return state;
  }
}

function selectedTranslationLocale(state = null, action) {
  switch (action.type) {
    case SET_SELECTED_LANG_LOCALE:
      return action?.languageLocale;
    default:
      return state;
  }
}

function pdpRequest(state = null, action) {
  switch (action.type) {
    case SET_EXTERNAL_PDP_TRIGGER:
      return action.value;
    default:
      return state;
  }
}

function activeBITM(state = [], action) {
  switch (action.type) {
    case SET_ACTIVE_BITM_DETAILS:
      return action.value;
    default:
      return state;
  }
}

function activeHelpRequest(state = [], action) {
  switch (action.type) {
    case SET_ACTIVE_HELP_REQUEST_DETAILS:
      return action.value;
    default:
      return state;
  }
}

function selctedInputWithKeyboardId(state = "", action) {
  switch (action.type) {
    case SET_INPUT_WITH_KEYBOARD_ID:
      return action.id;
    default:
      return state;
  }
}

function selctedInputWithKeyboardValue(state = "", action) {
  switch (action.type) {
    case SET_INPUT_WITH_KEYBOARD_VALUE:
      return {
        ...state,
        [action.id]: action.value,
      };
    case CLEAR_INPUT_WITH_KEYBOARD_VALUE:
      return (state = "");
    case SET_INITIAL_INPUT_WITH_KEYBOARD_VALUE:
      return {
        ...state,
      };
    default:
      return state;
  }
}

function UserLastActivityAtTime(state = null, action) {
  switch (action.type) {
    case SET_USER_ACTION_TIME:
      return { epoch: Date.now() };
    case SET_EXTERNAL_PDP_TRIGGER:
      return { epoch: Date.now() };
    case SET_INPUT_WITH_KEYBOARD_VALUE:
      return { epoch: Date.now() };

    default:
      return state;
  }
}

function userSelectedSearchSuggestion(state = null, action) {
  switch (action.type) {
    case SET_USER_SELECTED_SEARCH_SUGGESTION:
      return action.value;
    default:
      return state;
  }
}

/* function to set user session id
   starting a new session only if action is SET_USER_ACTION_TIME
   and current session.session_id is null
*/
function session(state = null, action) {
  switch (action.type) {
    case SET_USER_ACTION_TIME:
      if (!state?.session_id) {
        return {
          session_id: "user@" + Date.now(),
          utcTimeString: new Date().toUTCString(),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}

function eventsList(state = [], action) {
  switch (action.type) {
    case RECEIVE_EVENTS_LIST:
      return action.eventsList ? action.eventsList : [];
    default:
      return state;
  }
}

function availableBrowserStorage(state = {}, action) {
  switch (action.type) {
    case SET_AVAILABLE_BROWSER_STORAGE:
      return action.value;
    default:
      return state;
  }
}

export default combineReducers({
  sideMenu,
  cartItems,
  appSettings,
  selectedLocale,
  selectedTranslationLocale,
  currentPage,
  pdpRequest,
  activeBITM,
  activeHelpRequest,
  selctedInputWithKeyboardId,
  selctedInputWithKeyboardValue,
  UserLastActivityAtTime,
  userSelectedSearchSuggestion,
  session,
  eventsList,
  availableBrowserStorage,
});
