import React, { Component } from "react";
import { GlCarousel, GlIcon, GL_CAROUSEL_ARROW_TYPE } from "@adl/foundation";

import FullScreenGallery from "./components/FullScreenGallery/FullScreenGallery";

import "./ProductGallery.css";

class ProductGallery extends Component {
  state = {
    galleryCurrentPage: 0,
    isGalleryInFullScreen: false,
  };

  componentDidMount() {}
  componentDidUpdate() {}

  setShowFullScreenGallery = (val) => {
    this.setState({ isGalleryInFullScreen: val });
  };

  onGalleryPageMoved = (page) => {
    this.props.resetTimoutTimer();
    this.setState({
      galleryCurrentPage: page,
    });
  };

  onGalleryThumbClick = (e, index) => {
    this.props.resetTimoutTimer();
    this.setState({
      galleryCurrentPage: index,
    });
  };

  getGalleryThumbnails(media) {
    if (!media) return;

    let _media = media;

    _media = media.filter((x) => x.type === "image");

    _media = this.addImageSeqNoToList(_media);

    return _media.map((x) => (
      <div
        className="img"
        key={"thumb_" + x.small.href}
        onClick={(e) => this.onGalleryThumbClick(e, x.index)}
      >
        <img src={x.small.href} alt="gallery" />
        {this.state.galleryCurrentPage === x.index ? (
          <div className="highlighter"></div>
        ) : (
          <></>
        )}
      </div>
    ));
  }

  addImageSeqNoToList = (imageList) => {
    // let accumulatedValues = [];

    // const updatedList = imageList.reduce((acc, item) => {
    //   //const x = [{ ...item.value, index: i++ }];
    //   const x = item;
    //   accumulatedValues.push(x);
    //   return acc;
    // }, []);

    let _index = 0;

    const _updated = imageList.reduce((acc, item) => {
      const x = { ...item, index: _index++ };
      acc.push(x);
      return acc;
    }, []);

    return _updated;
  };

  render() {
    let media = this.props.media;

    let { isGalleryInFullScreen } = this.state;

    let _media = null;

    if (media && media.length > 0)
      _media = media?.filter((x) => x.type === "image");

    return (
      <>
        {/* {JSON.stringify(_media)} */}
        {this.state.isGalleryInFullScreen && (
          <FullScreenGallery
            galleryData={_media}
            showModal={isGalleryInFullScreen}
            setShowFullScreenGallery={this.setShowFullScreenGallery}
            startFromPictureIndex={this.state.galleryCurrentPage}
            resetTimoutTimer={() => this.props.resetTimoutTimer()}
          ></FullScreenGallery>
        )}
        {_media && (
          <>
            <div className="new-gallery-wrapper">
              <div
                className="btn-fullscreen-gallery"
                onClick={() => this.setState({ isGalleryInFullScreen: true })}
              >
                <GlIcon name="expand"></GlIcon>
              </div>
              <div className="img-container">
                <GlCarousel
                  activeItem={0}
                  arrowType={GL_CAROUSEL_ARROW_TYPE.square}
                  className="st-carousel__four-items"
                  currentPage={this.state.galleryCurrentPage}
                  itemsToScroll={1}
                  itemsVisiblePerPage={1}
                  onMovedToPageN={(page) => this.onGalleryPageMoved(page)}
                >
                  {_media &&
                    _media.map((x) => (
                      <div className="my-carousel-div" key={x.large.href}>
                        <div className="my-carousel-div-img background-loader">
                          <img
                            className="xgallery-image"
                            src={x.large.href}
                            alt={"product"}
                          />
                        </div>
                      </div>
                    ))}
                </GlCarousel>
              </div>
            </div>

            <div className="gallery-wrapper">
              <div className="pdp-gallery-thumnail-container">
                <div className="content">
                  {this.getGalleryThumbnails(_media)}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default ProductGallery;
