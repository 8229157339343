import { setup } from "axios-cache-adapter";
import { log, MSG_TYPE } from "../../../utils/logger";
import { BFF_API } from "../constants";
import {
  // ACCEPTED_PAYMENT_OPTIONS,
  DELIVERY_PAYMENT_MAPPING,
  LOCATION_TYPE,
} from "../../../../src/utils/constants";

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const nocacheApi = setup({
  // `axios-cache-adapter` options
  cache: {
    maxAge: 5 * 60 * 1000,
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      // methods: ["put", "patch", "delete"],
    },
  },
});
// IMPORTANT: handle exception while calling the API functions

const getUserInfoData = (deliveryInfo) => {
  let userInfo = deliveryInfo?.userDetails;
  return {
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    phone: userInfo.phoneNumber,
    house_number: userInfo.houseNumber,
    address1: userInfo.street,
    city: userInfo.city,
    state_code: userInfo.city,
    postal_code: userInfo.zipCode,
    country: deliveryInfo.countryCode,
    country_code: deliveryInfo.countryCode,
  };
};

const getAddressInfoData = (deliveryInfo) => {
  let addressInfo = deliveryInfo?.addressInfo;
  let userInfo = deliveryInfo?.userDetails;
  return {
    first_name: userInfo.firstName,
    last_name: userInfo.lastName,
    phone: userInfo.phoneNumber,
    house_number: addressInfo.street,
    address1: addressInfo.street,
    city: addressInfo.city,
    state_code: addressInfo.city,
    postal_code: addressInfo.postal_code,
    country: addressInfo.countryCode,
    country_code: addressInfo.countryCode,
  };
};

const transformDeliveryOptions = (deliveryOptionsResponse) => {
  const updatedDeliveryOptions =
    deliveryOptionsResponse?.data?.data?._embedded?.delivery_options;

  let deliveryOptions = [];

  updatedDeliveryOptions.forEach((option) => {
    if (!option.details)
      deliveryOptions.push(extractDeliveryOption(option, option.id));
    else deliveryOptions.push(option);
  });
  return deliveryOptions;
};

const extractDeliveryOption = (deliveryOptionsObject) => {
  const linesObject = deliveryOptionsObject?.shipping?.lines;
  let shippingObject =
    linesObject.find((line) => line.id === "me")?.shipping_methods[0] || {};
  if (deliveryOptionsObject?.shipping?.location)
    shippingObject["location"] = deliveryOptionsObject?.shipping?.location;
  return {
    ...deliveryOptionsObject,
    details: shippingObject,
  };
};

const extractShippingMethod = (deliveryOptions, deliveryOption) => {
  const deliveryObject =
    deliveryOptions &&
    deliveryOptions.find((option) => option.id === deliveryOption);

  return deliveryObject?.details || {};
};

// const extractPaymentOptions = (paymentOptions) => {
//   const extractedPaymentOptions = paymentOptions?.data?.data?.payment_options;
//   const filteredPaymentOptions = extractedPaymentOptions.filter((option) =>
//     ACCEPTED_PAYMENT_OPTIONS.includes(option.id)
//   );
//   return filteredPaymentOptions || null;
// };

// const extractPaymentOptionId = (paymentOptions) => {
//   const filteredPaymentOptions = paymentOptions.find((option) =>
//     ACCEPTED_PAYMENT_OPTIONS.includes(option.id)
//   );
//   return filteredPaymentOptions.id || "";
// };

// todo: temporary - mobile api..ongoing change related
const extractPaymentOptionsTemp = (paymentOptions, deliveryOption) => {
  const extractedPaymentOptions = paymentOptions?.data?.data?.payment_options;
  const filteredPaymentOptions = extractedPaymentOptions.filter(
    (option) => option.id === DELIVERY_PAYMENT_MAPPING[deliveryOption]
  );
  return filteredPaymentOptions || null;
};

// todo: temporary - mobile api..ongoing change related
const extractPaymentOptionIdTemp = (paymentOptions, deliveryOption) => {
  const filteredPaymentOptions = paymentOptions.find(
    (option) => option.id === DELIVERY_PAYMENT_MAPPING[deliveryOption]
  );
  return filteredPaymentOptions.id || "";
};

export const getTime = () => {
  log("get root API/ called", MSG_TYPE.READY);
  nocacheApi({
    url: `${BFF_API}/epoch`,
    method: "get",
  }).then(async (response) => {
    return { response };
  });
};

export const getExistingCheckout = (checkoutId, locale) => {
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}?locale=${locale}`,
    method: "get",
  }).then(async (response) => {
    return { response };
  });
};

export const startCheckout = (cartItems, locale) => {
  // console.log(cartItems);

  let _cartDataIssue = false;

  const _items = cartItems.reduce((acc, item) => {
    if (!item?.product.product_id || !item?.sku || item?.quantity < 1) {
      _cartDataIssue = true;
    }
    const x = {
      product_id: item.product.product_id,
      variation_product_id: item?.sku,
      quantity: item?.quantity,
    };
    acc.push(x);
    return acc;
  }, []);

  // console.log(_items);

  if (_cartDataIssue) {
    console.log("Cart data issues: " + _cartDataIssue);
    return new Promise((resolve, reject) => {
      throw new Error("Cart data incorrect");
    });
  }

  var _postData = {
    items: _items,
  };

  // return;

  return nocacheApi({
    url: `${BFF_API}/start-checkout?locale=${locale}`,
    method: "post",
    data: _postData,
  }).then(async (response) => {
    return { response };
  });
};

export const getDeliveryOptions = (checkoutId, locale) => {
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/delivery_options?locale=${locale}`,
    method: "get",
  }).then(async (response) => {
    return {
      deliveryOptions: transformDeliveryOptions(response),
    };
  });
};

const setDeliveryOption = (
  checkoutId,
  locale,
  deliveryOption,
  deliveryInfo
) => {
  let _postData;
  switch (deliveryOption) {
    case "home":
      _postData = getUserInfoData(deliveryInfo);
      return nocacheApi({
        url: `${BFF_API}/checkouts/${checkoutId}/delivery_option_home?locale=${locale}`,
        method: "put",
        data: _postData,
      }).then(async (response) => {
        return response;
      });
    case "store":
      _postData = {
        location_id: deliveryInfo?.storeAddress?.id,
        geo_location: {
          latitude: deliveryInfo?.storeAddress?.coordinates?.lat,
          longitude: deliveryInfo?.storeAddress?.coordinates?.long,
        },
      };
      return nocacheApi({
        url: `${BFF_API}/checkouts/${checkoutId}/delivery_option_store?locale=${locale}`,
        method: "put",
        data: _postData,
      }).then(async (response) => {
        return response;
      });
    case "pick_up_point":
      _postData = {
        location_id: deliveryInfo?.pickupPointAddress?.id,
        geo_location: {
          latitude: deliveryInfo?.pickupPointAddress?.coordinates?.lat,
          longitude: deliveryInfo?.pickupPointAddress?.coordinates?.long,
        },
      };
      return nocacheApi({
        url: `${BFF_API}/checkouts/${checkoutId}/delivery_option_pick_up_point?locale=${locale}`,
        method: "put",
        data: _postData,
      }).then(async (response) => {
        return response;
      });
    default:
      break;
  }
};

const setShippingMethod = (checkoutId, locale, shippingMethodId) => {
  const _postData = {
    shipping_method_id: shippingMethodId,
  };
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/shipping_method?locale=${locale}`,
    method: "put",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

const setBillingInfo = (checkoutId, locale, deliveryOption, deliveryInfo) => {
  let _postData;
  switch (deliveryOption) {
    case LOCATION_TYPE.home:
      _postData = getUserInfoData(deliveryInfo);
      break;
    case LOCATION_TYPE.store:
      _postData = getAddressInfoData(deliveryInfo);
      break;
    case LOCATION_TYPE.pick_up_point:
      _postData = getAddressInfoData(deliveryInfo);
      break;
    default:
      break;
  }
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/billing_info?locale=${locale}`,
    method: "put",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

const setCustomerEmailId = (checkoutId, locale, emailId) => {
  const _postData = {
    email: emailId,
  };
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/customer?locale=${locale}`,
    method: "patch",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

const getPaymentOptions = (checkoutId, locale) => {
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/payment_options?locale=${locale}`,
    method: "get",
  }).then(async (response) => {
    return response;
  });
};

const setPaymentMethod = (checkoutId, locale, paymentOptionId) => {
  const _postData = {
    id: paymentOptionId,
  };
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/payment_method?locale=${locale}`,
    method: "put",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

const placeOrder = (checkoutId, locale) => {
  const _postData = {
    checkout_id: checkoutId,
  };
  return nocacheApi({
    url: `${BFF_API}/place-order?locale=${locale}`,
    method: "post",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

export const updateCheckoutDeliveryInformation = async (
  checkoutId,
  locale,
  deliveryInfo
) => {
  try {
    if (!checkoutId || !locale || !deliveryInfo?.deliveryOption)
      throw new Error("Data from UI is missing");

    const setDeliveryOptionResponse = await setDeliveryOption(
      checkoutId,
      locale,
      deliveryInfo.deliveryOption,
      deliveryInfo
    );
    const deliveryOptionsResponse = await getDeliveryOptions(
      checkoutId,
      locale
    );

    if (
      !setDeliveryOptionResponse?.data?.data ||
      !deliveryOptionsResponse?.deliveryOptions
    )
      throw new Error("Data missing in response");

    const checkout = setDeliveryOptionResponse?.data?.data;
    const deliveryOptionsDetailed = deliveryOptionsResponse?.deliveryOptions;

    return {
      checkout,
      deliveryOptionsDetailed,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const updateCheckoutShippingInformation = async (
  checkoutId,
  locale,
  deliveryInfo,
  deliveryOptions
) => {
  try {
    if (!checkoutId || !locale || !deliveryInfo?.deliveryOption)
      throw new Error("Data from UI is missing");

    const shippingMethod = extractShippingMethod(
      deliveryOptions,
      deliveryInfo.deliveryOption
    );
    if (shippingMethod?.id === "" || shippingMethod?.id === undefined)
      throw new Error("Data missing in response");
    const setShippingMethodResponse = await setShippingMethod(
      checkoutId,
      locale,
      shippingMethod?.id || ""
    );

    if (!setShippingMethodResponse?.data?.data)
      throw new Error("Data missing in response");

    const checkout = setShippingMethodResponse?.data?.data;

    return {
      checkout,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const updateCheckoutCustomerInformation = async (
  checkoutId,
  locale,
  deliveryInfo
) => {
  try {
    if (!checkoutId || !locale || !deliveryInfo?.deliveryOption)
      throw new Error("Data from UI is missing");

    await setCustomerEmailId(
      checkoutId,
      locale,
      deliveryInfo?.userDetails?.email
    );
    const setBillingInfoResponse = await setBillingInfo(
      checkoutId,
      locale,
      deliveryInfo.deliveryOption,
      deliveryInfo
    );

    if (!setBillingInfoResponse?.data?.data)
      throw new Error("Data missing in response");

    const checkout = setBillingInfoResponse?.data?.data;

    return {
      checkout,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const getPaymentOptionsInformation = async (
  checkoutId,
  locale,
  deliveryOption
) => {
  try {
    if (!checkoutId || !locale) throw new Error("Data from UI is missing");

    const getPaymentOptionsResponse = await getPaymentOptions(
      checkoutId,
      locale
    );
    // const paymentOptions = extractPaymentOptions(getPaymentOptionsResponse);
    const paymentOptions = extractPaymentOptionsTemp(
      getPaymentOptionsResponse,
      deliveryOption //todo: temp
    );

    if (
      !getPaymentOptionsResponse?.data?.data ||
      !(paymentOptions && paymentOptions.length)
    )
      throw new Error("Data missing in response");

    return {
      paymentOptions,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const applyVoucherCode = (checkoutId, locale, voucherCode) => {
  const _postData = {
    voucher_code: voucherCode,
  };
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/voucher?locale=${locale}`,
    method: "put",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

export const applyAndValidateVoucherCode = async (
  checkoutId,
  locale,
  voucherCode
) => {
  try {
    if (!checkoutId || !locale || !voucherCode)
      throw new Error("Data from UI is missing");

    const voucherResponse = await applyVoucherCode(
      checkoutId,
      locale,
      voucherCode
    );

    if (!voucherResponse?.data?.data)
      throw new Error("Data missing in response");

    return {
      voucherResponse: voucherResponse?.data?.data,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const applyEmployeeId = (checkoutId, locale, employeeId) => {
  const _postData = {
    employee_id: employeeId,
  };
  return nocacheApi({
    url: `${BFF_API}/checkouts/${checkoutId}/employee?locale=${locale}`,
    method: "patch",
    data: _postData,
  }).then(async (response) => {
    return response;
  });
};

export const applyAndValidateEmployeeId = async (
  checkoutId,
  locale,
  employeeId
) => {
  try {
    if (!checkoutId || !locale || !employeeId)
      throw new Error("Data from UI is missing");

    const employeeResponse = await applyEmployeeId(
      checkoutId,
      locale,
      employeeId
    );

    if (!employeeResponse?.data?.data)
      throw new Error("Data missing in response");

    return {
      employeeResponse: employeeResponse?.data?.data,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

export const endCheckout = async (
  checkoutId,
  locale,
  paymentOptions,
  deliveryOption
) => {
  try {
    if (!checkoutId || !locale) throw new Error("Data from UI is missing");

    // todo: temp
    // const paymentOptionId = extractPaymentOptionId(paymentOptions);

    const paymentOptionId = extractPaymentOptionIdTemp(
      paymentOptions,
      deliveryOption
    );

    const setPaymentMethodResponse = await setPaymentMethod(
      checkoutId,
      locale,
      paymentOptionId
    );
    if (!setPaymentMethodResponse?.data?.data)
      throw new Error("Data missing in response");

    const placeOrderResponse = await placeOrder(checkoutId, locale);
    // FOR DEBUGGING:
    // const placeOrderResponse = {}

    // if (!setPaymentMethodResponse?.data?.data) throw new Error("Data missing in response");
    if (!placeOrderResponse?.data?.data)
      throw new Error("Data missing in response");

    return {
      orderCompletionDetails: placeOrderResponse?.data?.data,
    };
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};
