export const translations_es_ES = {
  all_products: "Todos los productos",
  go_to_top: "Volver arriba",
  more_filters: "Más filtros",
  less_filters: "Menos filtros",
  sort_by: "Ordenar por",
  clear_all: "Borrar todo",
  colors_available: "<count> colores disponibles",
  colors_available_plp: "<count> colores",

  details: "Detalles",
  how_to_style: "Cómo combinarlo",
  reviews: "Valoraciones",
  gallery: "Galería",
  highlights: "Destacados",

  select_size: "Elegir talla",
  store_availability: "Ver otras tiendas",

  size_guidance: "Guía de tallas",
  add_to_bag: "Añadir al carrito",
  scan_to_order_online: "Escanear y comprar online",
  shopping_bag: "Tu carrito",
  bag_empty: "Tu carrito está vacío",

  max_cart_item_count_reached: "Máximo de artículos alcanzado",

  browse_online: (
    <>
      Adelante, ¡encuentra algo que te guste!
      <br />
      Navega en este kiosco para consultar los artículos de esta tienda o
      realizar pedidos online.
    </>
  ),

  order_online: "Pedido online",

  start_browsing: "Explorar tienda",
  total: "Total ",
  including_tax: "c/ impuestos",
  checkout: "Completar pedido",
  delete_bag: "Vaciar carrito",

  continue_on_this_device: "Pagar",
  checkout_on_your_device: "Pagar con el teléfono",

  checkout_on_your_device_instruction:
    "Escanea el código QR para transferir el contenido de este carrito a la app de adidas o a la página web en tu teléfono móvil.",

  employee_checkout: "Salida de Empleado",
  employee_checkout_title: "Salida con un empleado",
  employee_checkout_instruction:
    "Escanee este código QR para transferir su bolsa y pagar por sus productos.",
  employee_id_instruction:
    "Por favor, ingrese su ID de empleado y actualice el código QR.",
  employee_id_update_qr_code: "Actualizar código QR",
  employe_id_validation_error: "ID de empleado no válido.",
  change_employee_id: "Cambiar ID de Empleado",

  only_cash_on_delivery_option:
    "Aviso: sólo podemos aceptar el pago contrarreembolso para pedidos del kiosco.",

  yes_over_16_years_of_age: "Soy mayor de 16 años",

  choose_your_shipping_method: "Elige el modo de envío",
  order_summary: "Resumen del pedido",
  promo_code: "Código promocional",
  place_order: "Realizar pedido",
  order_now_pay_on_delivery: "Haz tu pedido ahora y paga cuando lo recibas",

  read_more_reviews: "Leer más comentarios",

  edit_address: "Modificar dirección",
  save_details: "Guardar",
  edit_details: "Editar datos",
  select_pickup_point: "Seleccionar esta ubicación",
  select_store: "Seleccionar esta tienda",
  back_to_list: "Volver a productos",

  sure_to_delete_cart: "¿Quieres vaciar el carrito?",
  cart_delete_message:
    "Todos los artículos que has añadido se eliminarán y tu sesión finalizará.",
  cancel: "Cancelar",

  add_voucher_heading: "Añade un cupón descuento o un código promocional",
  add_voucher_instruction:
    "Comprueba las mayúsculas y minúsculas. Escribe el código tal y como aparece.",
  add_voucher_disclaimer:
    "Algunos artículos pueden excluir el uso de ciertos códigos promocionales. Consulta las condiciones legales.",
  apply: "Aplicar",

  loading: "Cargando...",
  your_order_in_progress: "Cargando...",
  your_order_is_placed: "¡Pedido completado!",
  session_ends_in: "La sesión termina en:",
  estimated_delivery: "Entrega estimada:",
  confirmation_email_sent_to:
    "Se ha enviado un correo electrónico de confirmación a:",
  order_number: "Pedido nº:",
  start_new_session: "Nueva sesión",
  extend_session: "Continuar",

  map_search_no_result:
    "No hemos encontrado ningún resultado. Vuelve a intentarlo.",

  enter_your_details: "Datos de contacto",
  first_name: "Nombre",
  last_name: "Apellidos",
  street: "Calle",
  house_number: "Número",
  zip_code: "Código postal",
  town_city: "Ciudad/Municipio",
  email_address: "Dirección de correo electrónico",
  phone_number: "Número de teléfono",

  change_store: "Cambiar de tienda",
  change_pickup_point: "Cambiar punto de recogida",

  search_by_city_dictrict_or_address: "Buscar por ciudad, distrito o dirección",

  processing: "Procesando...",

  error_occurred: "Lo sentimos, algo ha fallado",

  retry_place_order: "Intenta realizar el pedido de nuevo",

  retry_start_checkout: "Vuelve a intentarlo",

  please_select_your_size: "Elegir talla",
  bring_it_to_me: "Bring it to me",
  need_help: <br />,

  call_store_associate: "Pedir ayuda",
  do_you_need_assistance_title: "¿Necesitas ayuda? Nosotros te buscamos",
  do_you_need_assistance_instruction:
    "Toca el botón para que uno de nuestros colaboradores venga a ayudarte.",
  do_you_need_assistance_sure_to_delete:
    "¿Seguro que quieres eliminar esta solicitud?",
  do_you_need_assistance_delete_btn_label: "Eliminar",

  we_will_bring_it_here: "O pide que te lo traigamos",
  bring_it_to_me_instruction:
    "Toca el botón y permanece cerca de esta pantalla. Te llevamos los artículos solicitados en unos 3 o 5 minutos.",

  bitm_none_active_title: "¿Has encontrado algo que te guste?",
  bitm_none_active_instruction: (
    <>
      ¡Nosotros te lo llevamos! Solo tienes que seleccionar los artículos y la
      talla que buscas y pulsar "BRING IT TO ME" en este kiosco o en la app de
      adidas.
    </>
  ),

  bitm_sure_to_delete: "¿Seguro que quieres eliminar esta solicitud?",
  bitm_delete_btn_label: "Eliminar",

  bitm_task_todo: "Solicitud en cola",
  bitm_task_in_progress: "¡Ya llega!",
  bitm_task_complete: "¡Hecho!",

  bitm_qr_title: "Sigue buscando mientras esperas",
  bitm_qr_description:
    "Escanea el código QR para finalizar tu solicitud en la app. Puedes pasear por la tienda. Nosotros te llevamos tus artículos cuando estén listos.",
  restart_checkout: "¿Algún problema? Actualizar página",

  try_again: "Reintentar",

  session_expiring_title: "¿Has terminado?",
  session_expiring_body: "Esta sesión termina en",

  nearbystore_availability_overlay_title:
    "Comprueba la disponibilidad en otras tiendas",

  available_in_nearby_store: "Tallas disponibles en esta tienda",

  nearby_store_no_sizes_found:
    "El artículo que buscas está agotado en esta tienda.",

  please_try_selecting_another_store: "Probar en otra tienda.",

  in_stock_at_this_store: "Disponible en esta tienda",

  employee_id: "Identificación de empleado",

  add_employee_id_heading: "Agregar identificación de empleado",

  add_employee_id_instruction:
    "Pide ayuda a un miembro de nuestro equipo para continuar.",

  verify_checkout: "Comprueba tus datos antes de guardar",

  search: "Buscar",

  search_result_see_all: "Ver todo en <search-text>",

  search_no_result: "Lo sentimos, no hemos encontrado ningún resultado",

  try_searching_for_something_else: "Prueba a realizar otra búsqueda.",

  start_new_search: "Nueva búsqueda",

  showing_results_for: "Resultados de búsqueda para",

  no_available_sizes_found: "No hay tallas disponibles en este momento",

  online: "Online",
  in_store: "En tienda",

  tap_to_get_started: "Toca para empezar", //not used
  your_journey_starts_here: "Tu viaje comienza aquí",
  app_header_title: "Explora aquí y descubre los últimos y mejores productos",
  landingpage_filter_heading: "¿Para quién estás comprando?",
  landingpage_signup_heading: "Descubre lo mejor de adidas",
  landingpage_signup_cta:
    "Regístrate para comprar nuestra exclusiva equipación para miembros",
  landingpage_events_cta: "Explora nuestros próximos lanzamientos y eventos",
  landingpage_instore_services_cta: "Descubre nuestros servicios en tienda",
  eventspage_heading: "Descubre nuestros eventos de adidas",
  eventspage_sub_heading:
    "Obtén el máximo provecho de tu experiencia de compra con nuestros eventos exclusivos.",

  eventspage_store_event: "Evento en tienda",
  eventspage_event_details: "Detalles del evento",
  eventspage_raffle_timelines: "Plazos del sorteo",
  eventspage_raffle_results: "Resultados del sorteo",
  eventspage_sign_up_before: "Regístrate antes de",
  eventspage_scan_and_sign_up: "Escanear y registrarse",

  instoreservicepage_heading: "Descubre nuestros servicios en tienda",
  instoreservicepage_sub_heading:
    "Obtén el máximo provecho de tu experiencia de compra con nuestros servicios exclusivos.",

  membershippage_title: "ÚNETE AL PROGRAMA DE MEMBRESÍA GRATUITO",
  membershippage_body:
    "Empieza a gastar tus puntos de adiClub para obtener más recompensas de lo que amas.",
  membershippage_feature_membership_discount: "descuentos para miembros",
  membershippage_feature_freeshipping: "envío gratuito",
  membershippage_feature_birthdaygifts: "regalos de cumpleaños",
  membershippage_feature_priorityshopping: "compras prioritarias",
  membershippage_feature_specialevents: "eventos especiales",

  change_language: "cambiar idioma",
};
