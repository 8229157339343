import React, { Component } from "react";

import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlParagraph,
} from "@adl/foundation";

import "./LocationsList.css";

import map_pin from "../../../../../../../../assets/icons/map_pin.svg";
import calendar from "../../../../../../../../assets/icons/calendar.svg";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../../../utils/translationCopies";

import { MAP_ZOOM } from "../../SelectAddressView";

const dayNames = {
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
  0: "sunday",
};

const distanceUnit = {
  KILOMETER: "km",
  METER: "m",
};

function getStoreCollectionDetails(openingHours, maxDays) {
  let _date = new Date();
  _date.setDate(_date.getDate() + maxDays);

  let _dayIndex = _date.getDay();
  let _today = dayNames[_dayIndex];
  let _openingHours = openingHours[_today];

  let _iterationCount = 0;

  if (_openingHours?.indexOf(":") === -1) {
    do {
      _iterationCount++;
      _date.setDate(_date.getDate() + _iterationCount);
      _dayIndex = _date.getDay();
      _today = dayNames[_dayIndex];
      _openingHours = openingHours[_today];
      if (_openingHours.indexOf(":") !== -1) {
        break;
      }
    } while (_iterationCount > 7);
  }

  return _openingHours;
}

function getPickupAvailabilityInfo(availability) {
  if (availability?.items_available === true) {
    return (
      new Date(availability?.from).toLocaleString().replace(":00 ", " ") +
      " - " +
      new Date(availability?.to).toLocaleString().replace(":00 ", " ")
    );
  } else {
    return "";
  }
}

export function roundDistance(value, decimalSeparator) {
  let _value = parseFloat(value);

  _value = _value.toFixed(2);

  if (_value < 1) {
    _value = _value * 1000 + " " + distanceUnit.METER;
  } else {
    _value =
      _value.replace(".", decimalSeparator) + " " + distanceUnit.KILOMETER;
  }

  return _value;

  //return _value.toFixed(2).replace(".", decimalSeparator);
}

function LocationItem(x, decimalSeparator) {
  return (
    <>
      <div className="locations-container__item">
        <GlParagraph className="store-name">{`${x.name}`}</GlParagraph>
        <div className="location-item-details">
          <div className="location-item-details__left">
            {x?.estimated_delivery?.availability_text && (
              <GlParagraph className="estimated-delivery">{`${x?.estimated_delivery?.availability_text}`}</GlParagraph>
            )}

            {x?.opening_hours && (
              <GlParagraph className="opening-hours">{`${getStoreCollectionDetails(
                x?.opening_hours,
                x?.estimated_delivery?.max_days
              )}`}</GlParagraph>
            )}

            {x?.work_time && (
              <GlParagraph className="estimated-delivery opening-hours">{`${getPickupAvailabilityInfo(
                x?.availability
              )}`}</GlParagraph>
            )}

            <GlParagraph className="street">{`${x.street}`}</GlParagraph>
            <GlParagraph className="pin-city">{`${x.postal_code}`}</GlParagraph>
          </div>
          <div className="location-item-details__right">
            <div>{`${roundDistance(x?.distance, decimalSeparator)}`}</div>
            <div>
              <img src={map_pin} alt="map-pin"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function LocationDetails(
  x,
  onBackToClick,
  onLocationSelect,
  onLocationSelectForOrderDelivery,
  decimalSeparator,
  locale,
  locationType,
  locationDetailsSaveError
) {
  let openingHoursArray;
  let workTime;

  if (x?.opening_hours) openingHoursArray = Object.entries(x?.opening_hours);

  if (x?.work_time) workTime = Object.entries(x?.work_time);

  const _SELECT_LOCATION =
    locationType === "store"
      ? getTranslation(locale, TRANSLATIONS_KEY_LIST.select_store)
      : getTranslation(locale, TRANSLATIONS_KEY_LIST.select_pickup_point);

  const _BACK_TO_LIST = getTranslation(
    locale,
    TRANSLATIONS_KEY_LIST.back_to_list
  );

  return (
    <>
      <div className="locations-container__item">
        <GlParagraph className="store-name">{`${x.name}`}</GlParagraph>

        <div className="location-item-details">
          <div className="location-item-details__left">
            {x?.estimated_delivery?.availability_text && (
              <GlParagraph className="estimated-delivery">{`${x?.estimated_delivery?.availability_text}`}</GlParagraph>
            )}

            {!x?.estimated_delivery?.availability_text && (
              <GlParagraph className="estimated-delivery opening-hours">{`${getPickupAvailabilityInfo(
                x?.availability
              )}`}</GlParagraph>
            )}
          </div>
          <div className="location-item-details__right">
            <div>{`${roundDistance(x?.distance, decimalSeparator)}`}</div>
            <div>
              <img src={map_pin} alt="map-pin"></img>
            </div>
          </div>
        </div>
      </div>

      <div className="opening-hours-list-container">
        <div className="item">
          <div className="icon-container">
            <img src={calendar} alt="calendar"></img>
          </div>
          {openingHoursArray && (
            <div className="row">
              <div className="day-name">
                {openingHoursArray.map((x) => (
                  <div key={x.id + x[0]}>{x[0]}</div>
                ))}
              </div>
              <div>
                {openingHoursArray.map((x) => (
                  <div key={x.id + x[1]}>{x[1]}</div>
                ))}
              </div>
            </div>
          )}

          {workTime && (
            <div className="row">
              <div className="day-name">
                {workTime.map((x) => (
                  <div key={x.id + x[1].day}>{x[1].day}</div>
                ))}
              </div>
              <div>
                {workTime.map((x) => (
                  <div key={x.id + x[1].times[0]}>{x[1].times[0]}</div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="item">
          <div className="icon-container">
            <img src={map_pin} alt="map_pin"></img>
          </div>
          <div>
            <div>
              <GlParagraph className="street">{`${x.street}`}</GlParagraph>
            </div>
            <div>
              <GlParagraph className="pin-city">{`${x.postal_code} ${x.city}`}</GlParagraph>
            </div>
          </div>
        </div>
      </div>

      <div>
        {locationDetailsSaveError && (
          <div className="error-text">
            {getTranslation(locale, TRANSLATIONS_KEY_LIST.error_occurred)}
          </div>
        )}
        <GlButton
          aria-label="label"
          theme={GL_BUTTON_THEME.light}
          variant={GL_BUTTON_VARIANT.secondary}
          onClick={(e) => onLocationSelectForOrderDelivery()}
        >
          {_SELECT_LOCATION}
        </GlButton>

        <div className="tertiary-btn" onClick={(e) => onBackToClick(null)}>
          <GlButton aria-label="label" variant={GL_BUTTON_VARIANT.tertiary}>
            {_BACK_TO_LIST}
          </GlButton>
        </div>
      </div>

      {/* {JSON.stringify(entries)} */}
    </>
  );
}

class LocationsList extends Component {
  state = {
    detailedLocation: null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { selectedAddress, locations } = this.props;

    // Clean selected address name
    if (selectedAddress) {
      selectedAddress.name = selectedAddress?.name.split("__")[0];
    }

    if (prevProps.selectedAddress !== selectedAddress) {
      this.setState({
        detailedLocation: selectedAddress,
      });
    }

    if (prevProps.locations !== locations) {
      this.setState({
        detailedLocation: null,
      });

      this.props.updateSearchTextBoxVisibility(true);
    }
  }

  handleRequestDetailedView = (locationItem) => {
    const {
      onLocationSelect,
      handleMapZoomChange,
      updateSearchTextBoxVisibility,
      handleMapInfoWindowOpen,
    } = this.props;
    const isSetAddress = false;

    this.setState({
      detailedLocation: locationItem,
    });
    handleMapInfoWindowOpen(locationItem);
    onLocationSelect(locationItem, isSetAddress);
    handleMapZoomChange(MAP_ZOOM.selected);
    updateSearchTextBoxVisibility(false);
  };

  handleCloseDetailedView = () => {
    const {
      handleMapZoomChange,
      updateSearchTextBoxVisibility,
      handleMapInfoWindowOpen,
    } = this.props;

    this.setState({
      detailedLocation: null,
    });
    handleMapInfoWindowOpen({});
    handleMapZoomChange(MAP_ZOOM.defualt);
    updateSearchTextBoxVisibility(true);
  };

  onLocationSelect = () => {
    const isSetAddress = true;

    this.props.onLocationSelect(this.state.detailedLocation, isSetAddress);
  };

  onLocationSelectForOrderDelivery = () => {
    this.props.onLocationSelectForOrderDelivery(this.state.detailedLocation);
    this.props.onCheckoutLocationInformationUpdated(this.props.selectedAddress);
  };

  handledOnLocationSelected = () => {};

  render() {
    let _locations = this.props?.locations;

    let { decimalSeparator } = this.props;

    let { detailedLocation } = this.state;

    return (
      <div className="locations-container">
        {!detailedLocation &&
          _locations &&
          _locations.map((x) => (
            <div key={x?.id} onClick={(e) => this.handleRequestDetailedView(x)}>
              {LocationItem(x, decimalSeparator)}
            </div>
          ))}

        {detailedLocation && _locations && (
          <div>
            {LocationDetails(
              detailedLocation,
              this.handleCloseDetailedView,
              this.onLocationSelect,
              this.onLocationSelectForOrderDelivery,
              decimalSeparator,
              this.props.selectedLocale,
              this.props.locationType,
              this.props.locationDetailsSaveError
            )}
          </div>
        )}

        {/* <div>{JSON.stringify(props.locations)}</div> */}
      </div>
    );
  }
}

export default LocationsList;
