//productsAPI.js
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import { setup } from "axios-cache-adapter";

import { BFF_API } from "../constants";

import {
  transformMGWProductList,
  transformMGWFilters,
  transformMGWProduct,
  transformRetailProductList,
  transformMGWProductDescriptionData,
  transformSAMProductDescription,
  transformRetailCatalogFilters,
} from "../../../utils/dataTransformations";

import {
  translateFilters,
  translateSortOptions,
  getRefRefinementsAndSortOptions,
  translateFiltersAppliedTitle,
} from "../../../utils/dataTranslations";

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

let databaseName = "ssk-products";

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: databaseName,
});

const api = setup({
  baseURL: BFF_API,
  cache: {
    maxAge: 60 * 60 * 1000, // set cache time to 60 minutes
    exclude: { query: false }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

export function deleteProductsDatabase() {
  console.log("clearing storage..");

  const DBDeleteRequest = window.indexedDB.deleteDatabase(databaseName);

  DBDeleteRequest.onerror = (event) => {
    console.error("Error deleting indexedDB database - " + databaseName);
  };

  DBDeleteRequest.onsuccess = (event) => {
    console.log("Database deleted indexedDB successfully - " + databaseName);
    console.log(event.result);
  };
}

export async function getRetailProducts(
  _api,
  query,
  refinements,
  sort,
  page,
  ssmid
) {
  let queryStrings = [];

  if (query) {
    queryStrings.push(`query=${query}`);
  }

  if (page) {
    queryStrings.push(`page=${page}`);
  }

  if (ssmid) {
    queryStrings.push(`ssmid=${ssmid}`);
  }

  // refinements = refinements?.replace("sportsCategory", "categoryCluster");

  if (refinements) {
    queryStrings.push(`${refinements}`);
  }

  let _url = `${BFF_API}/retail-catalog/products`;

  if (queryStrings.length > 0) {
    let queryStringFlattened = queryStrings.join("&");

    queryStringFlattened = encodeURI(queryStringFlattened);

    _url = `${_url}?${queryStringFlattened}`;
  }

  let productListResponse = await api.get(_url);

  /* now lets get the filters*/
  let retailFiltersUrl = `${BFF_API}/retail-catalog/filters?${queryStrings.join(
    "&"
  )}`;

  let filterDataResponse = await api.get(retailFiltersUrl);

  let transformedRetailFilters = transformRetailCatalogFilters(
    filterDataResponse?.data
  );

  const transformedRetailProductList = transformRetailProductList(
    productListResponse?.data?.content
  );

  let returnObject = {
    total: productListResponse?.data?.page?.totalPages * 10, // total pages * no. of items per page currently its 10
    limit: 10, // value not coming from api, but it is seen to be fixed size 10
    products: transformedRetailProductList,
    title: null,
    refinements: transformedRetailFilters,
    sorting_options: [],
  };

  return returnObject;
}

export function getProducts(
  _api,
  query,
  refinements,
  sort,
  page,
  locale,
  refLocale = null //for translations of api data,
) {
  let queryStrings = [];

  if (query) {
    queryStrings.push(`query=${query}`);
  }

  if (refinements) {
    queryStrings.push(`${refinements}`);
  }

  if (sort) {
    queryStrings.push(`sort_option_id=${sort}`);
  }

  if (page) {
    queryStrings.push(`page=${page}`);
  }

  if (locale) {
    queryStrings.push(`locale=${locale}`);
  }

  let _url = `${BFF_API}/products`;

  if (queryStrings.length > 0) {
    let queryStringFlattened = queryStrings.join("&");

    queryStringFlattened = encodeURI(queryStringFlattened);

    _url = `${_url}?${queryStringFlattened}`;
  }

  return api.get(_url).then(async (response) => {
    const transformedProductList = transformMGWProductList(
      response.data.data?._embedded?.products
    );

    const transformedRefinementList = transformMGWFilters(
      response.data.data?.refinements
    );

    let returnObject = {
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
      products: transformedProductList,
      title: response.data.data?.title || null,
      refinements: transformedRefinementList,
      sorting_options: response.data.data?.sorting_options,
    };

    /* start - translation of filter sort and product name data
       using corresponding API data from other market
     */

    let filtersTitle = response.data.data?.title || null;
    let selectedRefinmentsFromApiResponse =
      response.data.data?.selected_refinements || null;

    if (refLocale !== locale) {
      try {
        if (refLocale?.indexOf("_") !== -1 && refLocale?.length === 5) {
          const refMarketProductListURL = _url.replace(locale, refLocale);

          let { referenceRefinements, referenceSortOptions } =
            getRefRefinementsAndSortOptions();

          let refMarketRefinements;
          let refSortingOptions;

          if (!referenceRefinements || referenceRefinements?.length === 0) {
            const response = await api.get(refMarketProductListURL);
            refMarketRefinements = response?.data?.data?.refinements;
            refSortingOptions = response?.data?.data?.sorting_options;
          } else {
            refMarketRefinements = referenceRefinements;
            refSortingOptions = referenceSortOptions;
          }

          if (selectedRefinmentsFromApiResponse) {
            const translatedTitle = translateFiltersAppliedTitle(
              filtersTitle,
              selectedRefinmentsFromApiResponse
            );
            returnObject.title = translatedTitle;
          }

          /* translate the filter display name also its options in other language(of market let say x), 
          looking up the data from other market x */
          try {
            translateFilters(
              refMarketRefinements,
              refLocale,
              returnObject?.refinements,
              locale
            );
          } catch (e) {
            console.log("error in translating filters (plp data)", e);
          }

          try {
            translateSortOptions(
              refSortingOptions,
              refLocale,
              returnObject?.sorting_options,
              locale
            );
          } catch (e) {
            console.log("error in translating sorting option (plp data)", e);
          }
        }
      } catch (e) {
        console.log(
          "error occured - filter translation using reference locale/filter"
        );
        console.log(e);
      }
    }

    /* end - translation of filter sort and product name data
       using corresponding API data from other market
     */

    return returnObject;
  });
}

export function getProduct(_api, productId, locale, refLocale) {
  if (refLocale) {
    locale = refLocale;
  }

  let _url = `${BFF_API}/products/${productId}`;

  if (locale) {
    _url = `${BFF_API}/products/${productId}?locale=${locale}`;
  }
  return api.get(_url).then((response) => {
    const transformedProductData = transformMGWProduct(response?.data?.data);
    return transformedProductData;
  });
}

export function getProductFromRetailSam(_api, articleId, ssmid) {
  let _url = `${BFF_API}/sam/articles/${articleId}`;

  if (ssmid) {
    _url = `${BFF_API}/sam/articles/${articleId}?ssmid=${ssmid}`;
  }

  return api.get(_url).then((response) => {
    console.log("Product details from SAM Api", response);

    const transformedProductData = transformSAMProductDescription(
      response?.data?.data
    );

    console.log("transformed SAM Api data ", transformedProductData);
    return transformedProductData;
  });
}

export async function getProductDescription(
  _api,
  productId,
  locale,
  refLocale = null
) {
  let _url = `${BFF_API}/products/${productId}/description`;

  if (refLocale) {
    _url = `${BFF_API}/products/${productId}/description?locale=${refLocale}`;
  } else if (locale) {
    _url = `${BFF_API}/products/${productId}/description?locale=${locale}`;
  }

  try {
    const response = await api.get(_url);
    const transformedData = transformMGWProductDescriptionData(
      productId,
      response
    );
    return transformedData;
  } catch (_) {
    /* try now from actual locale (no additional translations) */
    _url = `${BFF_API}/products/${productId}/description?locale=${locale}`;

    try {
      const response = await api.get(_url);
      const transformedData = transformMGWProductDescriptionData(
        productId,
        response
      );

      return transformedData;
    } catch (_) {
      return null;
    }
  }
}

export async function getProductSizeGuide(
  _api,
  productId,
  locale,
  refLocale = null
) {
  let _url = `${BFF_API}/products/${productId}/sizeguide`;

  if (refLocale) {
    _url = `${BFF_API}/products/${productId}/sizeguide?locale=${refLocale}`;
  } else if (locale) {
    _url = `${BFF_API}/products/${productId}/sizeguide?locale=${locale}`;
  }

  try {
    const response = await api.get(_url);

    return response;
  } catch (_) {
    /* try now from actual locale (no additional translations) */
    _url = `${BFF_API}/products/${productId}/sizeguide?locale=${locale}`;

    try {
      const response = await api.get(_url);

      return response;
    } catch (_) {
      return null;
    }
  }
}

export async function getProductReviews(
  _api,
  productId,
  locale,
  page = null,
  refLocale = null
) {
  let _url = `${BFF_API}/products/${productId}/reviews`;
  let queryStringArray = [];

  if (refLocale) {
    queryStringArray.push(`locale=${refLocale}`);
  } else if (locale) {
    queryStringArray.push(`locale=${locale}`);
  }

  if (page) {
    queryStringArray.push(`page=${page}`);
  }

  if (queryStringArray.length > 0) {
    _url = _url + "?" + queryStringArray.join("&");
  }

  try {
    const response = await api.get(_url);

    return response;
  } catch (_) {
    _url = `${BFF_API}/products/${productId}/reviews`;
    queryStringArray = [];

    if (locale) {
      queryStringArray.push(`locale=${locale}`);
    }

    if (queryStringArray.length > 0) {
      _url = _url + "?" + queryStringArray.join("&");
    }

    /* try now from actual locale (no additional translations) */

    try {
      const response = await api.get(_url);

      return response;
    } catch (_) {
      return null;
    }
  }
}

export function getNextSetProductReviews(
  _api,
  productId,
  locale,
  page,
  refLocale = null
) {
  return getProductReviews(_api, productId, locale, page, refLocale);
}

export async function getProductReviewStatistics(
  _api,
  productId,
  modelId,
  locale,
  refLocale = null
) {
  let _url = `${BFF_API}/review/statistics?productId=${productId}&modelId=${modelId}`;

  if (refLocale) {
    _url = `${BFF_API}/review/statistics?productId=${productId}&modelNumber=${modelId}&locale=${refLocale}`;
  } else if (locale) {
    _url = `${BFF_API}/review/statistics?productId=${productId}&modelNumber=${modelId}&locale=${locale}`;
  }

  try {
    const response = await api.get(_url);

    return response;
  } catch (_) {
    /* try now from actual locale (no additional translations) */
    _url = `${BFF_API}/review/statistics?productId=${productId}&modelNumber=${modelId}&locale=${locale}`;

    try {
      const response = await api.get(_url);

      return response;
    } catch (_) {
      return null;
    }
  }
}

export async function getProductUGC(
  _api,
  productId,
  modelId,
  locale,
  refLocale = null
) {
  let _url = `${BFF_API}/content/product_ugc?resource=${productId}/${modelId}`;

  if (refLocale) {
    _url = `${BFF_API}/content/product_ugc?resource=${productId}/${modelId}&locale=${refLocale}`;
  } else if (locale) {
    _url = `${BFF_API}/content/product_ugc?resource=${productId}/${modelId}&locale=${locale}`;
  }

  try {
    const response = await api.get(_url);

    return response;
  } catch (_) {
    /* try now from actual locale (no additional translations) */
    _url = `${BFF_API}/content/product_ugc?resource=${productId}/${modelId}&locale=${locale}`;

    try {
      const response = await api.get(_url);

      return response;
    } catch (_) {
      return null;
    }
  }
}

export function getSearchSuggestions(query, locale) {
  let _url = `/search-suggestions?query=${query}`;

  if (locale && query) {
    _url = `/search-suggestions?query=${query}&locale=${locale}`;

    _url = encodeURI(_url);

    return api.get(_url).then((response) => {
      return response;
    });
  }
}
