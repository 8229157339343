import React, { Component } from "react";
import { connect } from "react-redux";

import "./ProductView.css";
import "./ProductDetails.css";
import "./ImageThumbnails.css";
import "./ColorMenu.css";

import ProductDetails from "./components/ProductDetails/ProductDetails";

import { API } from "../../API/index";

import { setExternalPDPTrigger } from "../../actions/productActions";

import * as analytics from "../../analytics";
import NewBackButton from "../NewBackButton/NewBackButton";

class ProductView extends Component {
  state = {
    pdpOverlayStackData: [],
    galleryCurrentPage: 0,
    currentProductDescription: {},
    currentProduct: {},
    productSizeGuideHTML: null,
    productReviewStatistics: null,
    productUGC: null,
    productReviews: null,
    productReviewsNextSet_URL: null,
    isDataFetchError: false,
  };

  componentDidMount() {
    this.props.setCurrentPageAsPDP();

    this.processLoadingData(null);
  }

  processLoadingData = (pdpOverlayStackData) => {
    let _pdpOverlayStackData =
      pdpOverlayStackData || this.props.pdpOverlayStackData;

    let _currentProduct = {};

    if (_pdpOverlayStackData && _pdpOverlayStackData?.length !== 0) {
      _currentProduct =
        _pdpOverlayStackData[_pdpOverlayStackData.length - 1].product;

      this.setState({
        pdpOverlayStackData: _pdpOverlayStackData,
        currentProduct: _currentProduct,
      });

      if (!_currentProduct.description_headline) {
        this.fetchProduct(_currentProduct);
      }

      this.fetchProductDescription(_currentProduct.product_id);

      this.fetchProductSizeGuide(_currentProduct.product_id);

      this.fetchProductReviewStatistics(
        _currentProduct.product_id,
        _currentProduct.model_number
      );

      this.fetchProductReviews(_currentProduct);

      this.fetchProductUGC(
        _currentProduct.product_id,
        _currentProduct.model_number
      );
    }
  };

  fetchProduct = (_currentProduct) => {
    let _api = this.props._api;

    API.productsAPI
      .getProduct(
        _api,
        _currentProduct.product_id,
        this.props.selectedLocale,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        _currentProduct.gallery_media = x?.gallery_media;
        _currentProduct.color_variations = x?.color_variations;
        _currentProduct.size_category = x?.size_category;
        _currentProduct.share_url = x?.share_url;
        _currentProduct.product_name = x?.product_name;

        this.setState({
          currentProduct: _currentProduct,
        });
      })
      .catch((e) => {
        this.setState({
          productNotFoundinMGW: true,
        });

        //fallback - getting details from SAM API
        console.log("#### Product not found in MGW !");

        API.productsAPI
          .getProductFromRetailSam(
            _api,
            _currentProduct.product_id,
            this.props?.appSettings?.value?.player?._SSMID
          )
          .then((description) => {
            console.log(
              "#### Product details fetched from retail/sam API, and then transformed into description model !"
            );
            console.log("#### Description!", description);

            this.setState({
              currentProductDescription: description,
            });
          });
      });
  };

  fetchProductDescription = (productId) => {
    let _api = this.props._api;

    API.productsAPI
      .getProductDescription(
        _api,
        productId,
        this.props.selectedLocale,
        this.props.selectedTranslationLocale
      )
      .then((description) => {
        this.setState({ currentProductDescription: description });
      })
      .catch(() => {
        this.setState({
          isDataFetchError: true,
        });
      });
  };

  fetchProductSizeGuide = (productId) => {
    let _api = this.props._api;
    this.setState({
      productSizeGuideHTML: null,
    });
    API.productsAPI
      .getProductSizeGuide(
        _api,
        productId,
        this.props.selectedLocale,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        const _html = x?.data?.data;

        this.setState({
          productSizeGuideHTML: _html,
        });
      })
      .catch(() => {});
  };

  fetchProductReviews = (currentProduct) => {
    let _api = this.props._api;
    this.setState({
      productReviews: null,
      productReviewsNextSet_URL: null,
    });
    API.productsAPI
      .getProductReviews(
        _api,
        currentProduct.product_id,
        this.props.selectedLocale,
        1,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        const _data = x?.data?.data;

        this.setState({
          productReviews: _data?._embedded?.reviews,
          productReviewsNextSet_URL: _data?._links?.next,
        });

        //Log PDP View analytics
        analytics.pdpView({
          hostname: this.props.appSettings.value.player.hostname,
          productId: currentProduct.product_id,
          productName: currentProduct.product_name,
          color: currentProduct.original_color,
          currency: currentProduct.display_currency,
          orginalPrice: currentProduct.original_price,
          price: currentProduct.discount_price
            ? currentProduct.discount_price
            : currentProduct.original_price,
          rating: this.state.productReviewStatistics.average_overall_rating,
          reviewCount: this.state.productReviewStatistics.total_review_count,
          sizeCategory: currentProduct.category,
          stockStatus: currentProduct.orderable,
          quantity: currentProduct.purchase_limit,
        });
      })
      .catch(() => {});
  };

  getUrlParameter = (name, url) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(url);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  onFetchNextSetOfReviews = () => {
    let productId = this.state.currentProduct.product_id;
    let queryString = this.state.productReviewsNextSet_URL?.href.split("?")[1];

    let nextPage = this.getUrlParameter("page", "?" + queryString);

    let _api = this.props._api;

    API.productsAPI
      .getNextSetProductReviews(
        _api,
        productId,
        this.props.selectedLocale,
        nextPage,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        const _data = x?.data?.data;

        this.setState({
          productReviews: [
            ...this.state.productReviews,
            ..._data?._embedded?.reviews,
          ],
          productReviewsNextSet_URL: _data?._links?.next,
        });
      })
      .catch(() => {});
  };

  fetchProductReviewStatistics = (productId, model_number) => {
    let _api = this.props._api;
    this.setState({
      productReviewStatistics: null,
    });
    API.productsAPI
      .getProductReviewStatistics(
        _api,
        productId,
        model_number,
        this.props.selectedLocale,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        const productReviewStatistics = x?.data?.data;

        this.setState({
          productReviewStatistics: productReviewStatistics,
        });
      })
      .catch(() => {});
  };

  fetchProductUGC = (productId, model_number) => {
    let _api = this.props._api;
    this.setState({
      productUGC: null,
    });
    API.productsAPI
      .getProductUGC(
        _api,
        productId,
        model_number,
        this.props.selectedLocale,
        this.props.selectedTranslationLocale
      )
      .then((x) => {
        const productUGC = x?.data?.data;

        let _gallery =
          productUGC?._embedded?.content[0]?.elements[1]?.gallery?.posts;

        const _reducedGalleryList = _gallery?.reduce((acc, item) => {
          const id = item?.id;
          const url = item?.elements[0]?.image?.url;
          acc.push({ id, url });
          return acc;
        }, []);

        let _ugcData = {
          label: productUGC?._embedded?.content[0]?.elements[0]?.text?.text,
          gallery: _reducedGalleryList,
        };

        this.setState({
          productUGC: _ugcData,
        });
      })
      .catch(() => {});
  };

  componentDidUpdate(prevProps, nextProps) {
    if (
      JSON.stringify(prevProps.pdpOverlayStackData) !==
      JSON.stringify(this.props.pdpOverlayStackData)
    ) {
      let pdpOverlayStackData = this.props.pdpOverlayStackData;

      this.setState({
        pdpOverlayStackData: [...pdpOverlayStackData],
      });

      this.setState({
        isDataFetchError: true,
      });

      this.processLoadingData(pdpOverlayStackData);
    }

    if (
      JSON.stringify(prevProps.selectedTranslationLocale) !==
      JSON.stringify(this.props.selectedTranslationLocale)
    ) {
      let pdpOverlayStackData = this.props.pdpOverlayStackData;

      this.processLoadingData(pdpOverlayStackData);
    }
  }

  render() {
    let {
      currentProduct,
      currentProductDescription,
      productSizeGuideHTML,
      productReviewStatistics,
      productUGC,
      productReviews,
      productReviewsNextSet_URL,
      pdpOverlayStackData,
      isDataFetchError,
    } = this.state;

    if (pdpOverlayStackData?.length !== 0) {
      currentProduct =
        pdpOverlayStackData[pdpOverlayStackData.length - 1].product;
    }

    const _stackdata = pdpOverlayStackData?.reduce((acc, item) => {
      const x = item.id;
      acc.push(x);
      return acc;
    }, []);

    console.log(
      "#### Product data status: ",
      this.state?.productNotFoundinMGW === true
        ? "Not found in mGW"
        : "found in mGW"
    );

    return (
      <div
        className="pdp__wrapper "
        id="pdp__wrapper"
        onScroll={() => {
          if (window._setUserActionTime) window._setUserActionTime();
        }}
      >
        {/* {JSON.stringify(currentProductDescription)} */}
        <div className="left">
          {/* <div
            className="back-button hidden"
            onClick={(e) => this.onBackButtonClicked(e)}
          >
            <img src="images/arrow-left.svg"></img>
          </div> */}
          {/* <div className="plp-top-navigation" style={{ background: "#edeff0" }}>
            <div
              className="back-button"
              onClick={(e) => this.props.onBackButtonClicked(e)}
            >
              <img src="images/arrow-left.svg"></img>
            </div>
            <div></div>
          </div> */}

          {/* pdp  */}

          {
            // Products viewing history while i this PDP
            _stackdata.length > 0 && (
              <div className="debug-pdp-product-list-stack hidden">
                {
                  <>
                    <span>PDP items stack</span>
                    {_stackdata.map((x) => (
                      <div key={x}>{x}</div>
                    ))}
                  </>
                }
              </div>
            )
          }

          {/* <div
            className="pdp-back-button"
            onClick={(e) => this.props.onBackButtonClicked(e)}
          >
            <img src="images/arrow-left.svg" alt="left-arrow"></img>
          </div> */}

          <ProductDetails
            product={currentProduct}
            productDescription={currentProductDescription}
            selectedLocale={this.props.selectedLocale}
            setExternalPDPTrigger={this.props.setExternalPDPTrigger}
            productUGC={productUGC}
            productReviews={productReviews}
            productReviewStatistics={productReviewStatistics}
            productReviewsNextSet_URL={productReviewsNextSet_URL}
            onFetchNextSetOfReviews={() => this.onFetchNextSetOfReviews()}
            productSizeGuideHTML={productSizeGuideHTML}
            isDataFetchError={isDataFetchError}
            onRequestReload={() => this.processLoadingData(pdpOverlayStackData)}
            resetTimoutTimer={this.props.resetTimoutTimer}
            selectedTranslationLocale={this.props.selectedTranslationLocale}
          ></ProductDetails>
          <NewBackButton
            onClick={(e) => this.props.onBackButtonClicked(e)}
          ></NewBackButton>
          {/* pdp end */}
        </div>
      </div>
    );
  }
}

function mapStateToProps({
  appSettings,
  selectedLocale,
  selectedTranslationLocale,
}) {
  return {
    appSettings,
    selectedLocale,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
