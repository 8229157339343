export const EVENTS = {
  home_start: "Home - Start",
  plp_view: "PLP - View",
  filter_apply: "Filter - Apply",
  search_view: "Search - View",
  search_search_term: "Search - Search Term",
  pdp_view: "PDP - View",
  pdp_add_to_cart: "PDP - Add to cart",
  pdp_call_store_associate: "PDP - Call Store Associate",
  cart_view: "Cart - View",
  cart_remove_product_from_cart: "Cart - Remove Product From Cart",
  cart_start_checkout: "Cart - Start Checkout",
  cart_continue_on_kiosk: "Cart - Continue on Kiosk",
  retail_view_bitm: "Retail - View Bring it to me",
  retail_bitm: "Retail - Bring it to me",
  retail_cancel_bitm: "Retail - Cancel Bring it to me",
  end_session: "End Session",
};

export const EVENT_PROPERTIES = {
  timestamp: "timestamp",
  localtime: "localtime",
  screen_name: "screen::name",
  screen_type: "screen::type",
  event_source: "event::source",
  filter_selection: "filter::selection",
  search_term: "search::term",
  search_results: "search::results",
  product_articleid: "product::articleid",
  product_articleidsize: "product::articleidsize",
  product_color: "product::color",
  product_currency: "product::currency",
  product_name: "product::name",
  product_originalprice: "product::originalprice",
  product_price: "product::price",
  product_pricetype: "product::pricetype",
  product_rating: "product::rating",
  product_reviewcount: "product::reviewcount",
  product_sizecategory: "product::sizecategory",
  product_stockstatus: "product::stockstatus",
  product_quantity: "product::quantity",
  product_earlyaccess: "product::earlyaccess",
  product_modelid: "product::modelid",
  product_size: "product::size",
  product_type: "product::type",
  cart_itemquantity: "cart::itemquantity",
  cart_products: "cart::products",
  cart_value: "cart::value",
  access_type: "access::type",
  store_id: "store::id",
  store_name: "store::name",
  store_city: "store::city",
  store_country: "store::country",
  store_statusmessage: "store::statusmessage",
  store_distance: "store::distance",
  store_ssmid: "store::ssmid",
  store_hostname: "store::hostname",
};
