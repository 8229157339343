const countryConfig = [
  {
    countryCode: "GB",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "DE",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "RU",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "US",
    regionCode: "NAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "SG",
    regionCode: "APAC",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "MY",
    regionCode: "APAC",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "AE",
    regionCode: "EM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "ES",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "FR",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "CL",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "7510",
  },
  {
    countryCode: "AR",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "7310",
  },
  {
    countryCode: "ZA",
    regionCode: "EM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "CO",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{9}$",
    reportingUnit: "6510",
  },
  {
    countryCode: "MX",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "6410",
  },
  {
    countryCode: "PE",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "6720",
  },
  {
    countryCode: "BR",
    regionCode: "LAM",
    employeeIdRegex: "^\\d{11}$",
    reportingUnit: "",
  },
  {
    countryCode: "JP",
    regionCode: "APAC",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "IN",
    regionCode: "EM",
    employeeIdRegex: "^\\d{4}$",
    reportingUnit: "",
  },
  {
    countryCode: "TR",
    regionCode: "EM",
    employeeIdRegex: "^\\d{4}$",
    reportingUnit: "",
  },
  {
    countryCode: "NL",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "IT",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "PT",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "PL",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "SE",
    regionCode: "WE",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "CA",
    regionCode: "NAM",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "KR",
    regionCode: "APAC",
    employeeIdRegex: "^\\d{6}$",
    reportingUnit: "",
  },
  {
    countryCode: "fallback",
    employeeIdRegex: "^(?!\\s*$)[\\S]+$", //Non empty value
    reportingUnit: "",
  },
];

const getCountryConfig = (countryCode) => {
  const foundConfig = countryConfig.filter(
    (x) => x.countryCode === countryCode
  );

  if (foundConfig.length === 0) {
    return countryConfig.filter((x) => x.countryCode === "fallback");
  }

  return foundConfig;
};

const validateEmployeeId = (employeeId, locale) => {
  const countryCode = locale.split("_")[1];
  const countryConfig = getCountryConfig(countryCode);
  const employeeIdRegex = new RegExp(countryConfig[0].employeeIdRegex);

  return employeeIdRegex.test(employeeId);
};

const getFormattedEmployeeId = (employeeId, locale) => {
  if (!locale) {
    return employeeId;
  }

  const countryCode = locale.split("_")[1];
  const countryConfig = getCountryConfig(countryCode);

  if (countryCode.toLowerCase() === "co") {
    const storeCode = employeeId.substring(0, 3);
    const staffId = employeeId.slice(3);
    return `${countryConfig[0].countryCode}${storeCode}${countryConfig[0].reportingUnit}${staffId}`;
  }

  if (countryCode.toLowerCase() === "mx") {
    return `${countryConfig[0].countryCode}${countryConfig[0].reportingUnit}${employeeId}`;
  }

  return `${countryConfig[0].reportingUnit}${employeeId}`;
};

module.exports = { validateEmployeeId, getFormattedEmployeeId };
