import React, { Component } from "react";

import UserDetailsSummary from "./components/UserDetailsSummary/UserDetailsSummary";
import EditUserDetails from "./components/EditUserDetails/EditUserDetails";

import "./HomeDeliveryDetails.css";

// import * as helpers from "../../../../utils/helpers";
// todo: remove commented code after tests
class HomeDeliveryDetails extends Component {
  state = {
    // isEditView: false,
  };

  // componentDidMount() {
  //   let { userDetails } = this.props;

  //   if (!this.userDetailsAvailable(userDetails)) {
  //     this.setEditView(true);
  //   } else {
  //     this.setEditView(false);
  //   }
  // }

  componentDidUpdate() {}

  // setEditView = (val) => {
  //   this.setState({ isEditView: val });

  //   this.props.onFormInEditMode(val);
  // };

  // userDetailsAvailable = (_userDetails) => {
  //   return helpers.validateUserDetailsForHomeDelivery(_userDetails);
  // };

  render() {
    // let { isEditView } = this.state;

    let { userDetails, homeDetailsSaveError, isHomeEditView, setEditView } = this.props;

    let editableView = isHomeEditView || homeDetailsSaveError;

    return (
      <>
        <div className="user-details-wrapper">
          {editableView && (
            <EditUserDetails
              userDetails={userDetails}
              onUserDetailsChanged={this.props.onUserDetailsChanged}
              setEditView={setEditView}
              onCheckoutInformationUpdated={
                this.props.onCheckoutInformationUpdated
              }
              selectedLocale={this.props.selectedLocale}
              homeDetailsSaveError={homeDetailsSaveError}
            ></EditUserDetails>
          )}

          {!editableView && (
            <UserDetailsSummary
              userDetails={userDetails}
              setEditView={setEditView}
              selectedLocale={this.props.selectedLocale}
            ></UserDetailsSummary>
          )}
        </div>
      </>
    );
  }
}

export default HomeDeliveryDetails;
