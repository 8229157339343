export const SIDE_MENU = "SIDE_MENU";
export const SET_SIDE_MENU = "SET_SIDE_MENU";

export const MENU_ITEM_NAMES = {
  cart: "cart",
  home: "plp",
  checkout: "checkout",
  pdp: "pdp",
  bitm: "bitm",
  help: "help",
  search: "search",
  settings: "settings",
  languageOptions: "languageOptions",
};

export function setSideBarActiveItem(value) {
  return { type: SET_SIDE_MENU, value };
}
