export const translations_pl_PL = {
  all_products: "Wszystkie produkty",
  go_to_top: "Powrót na górę",
  more_filters: "Więcej filtrów",
  less_filters: "Mniej filtrów",
  sort_by: "Sortuj według",
  clear_all: "Wyczyść wszystko",
  colors_available: "Dostępne kolory: <count>",
  colors_available_plp: "kolory: <count>",

  details: "Szczegóły",
  how_to_style: "Jak to nosić?",
  reviews: "Opinie",
  gallery: "Galeria",
  highlights: "Najciekawsze",

  select_size: "Wybierz rozmiar",
  store_availability: "W innych sklepach",

  size_guidance: "O rozmiarach",
  add_to_bag: "Dodaj do koszyka",
  scan_to_order_online: "Zeskanuj i zamów online",
  shopping_bag: "Koszyk",
  bag_empty: "Twój koszyk jest pusty",

  max_cart_item_count_reached: "Osiągnięto maksymalną liczbę produktów:",

  browse_online: (
    <>
      Śmiało, zrób sobie przyjemność! <br />
      Przeglądaj ten kiosk, aby sprawdzić dostępność produktów w tym sklepie,
      lub zamów produkty online.
    </>
  ),

  order_online: "Zeskanuj i zamów online",

  start_browsing: "Przeglądaj sklep",
  total: "Razem ",
  including_tax: "(z PTU)",
  checkout: "Finalizuj",
  delete_bag: "Wyczyść koszyk",

  continue_on_this_device: "Finalizuj tutaj",
  checkout_on_your_device: "Finalizuj za pomocą telefonu",

  checkout_on_your_device_instruction:
    "Zeskanuj kod QR, aby przenieść koszyk na stronę adidas lub do aplikacji adidas na telefonie.",

  employee_checkout: "Finalizowanie pracownika",
  employee_checkout_title: "Finalizowanie z pracownikiem",
  employee_checkout_instruction:
    "Zeskanuj ten kod QR, aby przenieść swój koszyk i zapłacić za zakupy.",
  employee_id_instruction:
    "Proszę wprowadzić swój numer identyfikacyjny pracownika i zaktualizować kod QR.",
  employee_id_update_qr_code: "Zaktualizuj kod QR",
  employe_id_validation_error: "Niewłaściwy identyfikator pracownika",
  change_employee_id: "Zmień identyfikator pracownika",

  only_cash_on_delivery_option:
    "Uwaga: akceptujemy tylko płatność gotówką przy odbiorze jako metodę płatności za zamówienia złożone w kiosku.",

  yes_over_16_years_of_age: "Mam ukończone 16 lat",

  choose_your_shipping_method: "Wybierz sposób wysyłki",
  order_summary: "Podsumowanie zamówienia",
  promo_code: "Kod promocyjny",
  place_order: "Złóż zamówienie",
  order_now_pay_on_delivery: "Zamów teraz, zapłać, gdy dostaniesz",

  read_more_reviews: "Zobacz więcej opinii",

  edit_address: "Edytuj adres",
  save_details: "Zapisz",
  edit_details: "Edytuj szczegóły",
  select_pickup_point: "Wybierz tę lokalizację",
  select_store: "Wybierz ten sklep",
  back_to_list: "Powrót do produktów",

  sure_to_delete_cart: "Chcesz wyczyścić ten koszyk?",
  cart_delete_message:
    "Wszystkie dodane przez Ciebie produkty zostaną usunięte, a sesja zostanie zakończona.",
  cancel: "Anuluj",

  add_voucher_heading: "Dodaj kod zniżkowy lub promocyjny",
  add_voucher_instruction:
    "Zwróć uwagę na wielkość znaków, wpisz dokładnie tak, jak je widzisz",
  add_voucher_disclaimer:
    "Niektóre produkty mogą być wyłączone z niektórych kodów promocyjnych. Sprawdź Regulamin.",
  apply: "Zastosuj",

  loading: "Ładowanie...",
  your_order_in_progress: "Ładowanie...",
  your_order_is_placed: "Zamówienie skompletowane!",
  session_ends_in: "Sesja kończy się za:",
  estimated_delivery: "Szacowany czas dostawy:",
  confirmation_email_sent_to:
    "Wiadomość potwierdzająca została wysłana na adres:",
  order_number: "Numer zamówienia:",
  start_new_session: "Nowa sesja",
  extend_session: "Dalej",

  map_search_no_result:
    "Niestety, nie można znaleźć żadnych wyników. Spróbuj ponownie.",

  enter_your_details: "Dane kontaktowe",
  first_name: "Imię",
  last_name: "Nazwisko",
  street: "Ulica",
  house_number: "Numer",
  zip_code: "Kod pocztowy",
  town_city: "Miejscowość",
  email_address: "Adres e-mail",
  phone_number: "Numer telefonu",

  change_store: "Zmień sklep",
  change_pickup_point: "Zmień punkt odbioru",

  search_by_city_dictrict_or_address:
    "Wyszukaj według miasta, dzielnicy lub adresu",

  processing: "Przetwarzanie...",

  error_occurred: "Niestety, wystąpił problem",

  retry_place_order: "Spróbuj złożyć zamówienie ponownie",

  retry_start_checkout: "Spróbuj ponownie sfinalizować transakcję",

  please_select_your_size: "Wybierz rozmiar",
  bring_it_to_me: "Proszę mi przynieść",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Zawołaj pracownika",
  do_you_need_assistance_title: "Potrzebujesz pomocy? Przyjdziemy do ciebie",
  do_you_need_assistance_instruction: "Potrzebujesz pomocy?",
  do_you_need_assistance_sure_to_delete:
    "Czy na pewno chcesz usunąć tę prośbę?", //verify
  do_you_need_assistance_delete_btn_label: "Usuń prośbę", //verify

  we_will_bring_it_here: "Albo przynieś to tutaj",
  bring_it_to_me_instruction:
    "Dotknij przycisk, pozostań blisko ekranu, a my przyniesiemy zamówione przez Ciebie produkty za około 3 do 5 minut.",

  bitm_none_active_title: "Widzisz coś fajnego?",
  bitm_none_active_instruction:
    "Przyniesiemy Ci to! Po prostu wybierz produkt i rozmiar, który chcesz mieć, i naciśnij PROSZĘ MI PRZYNIEŚĆ – w tym kiosku lub w aplikacji adidas.",

  bitm_sure_to_delete: "Czy na pewno chcesz usunąć tę prośbę?", //verify
  bitm_delete_btn_label: "Usuń prośbę",

  bitm_task_todo: "Prośba oczekuje...",
  bitm_task_in_progress: "Zmierza w Twoją stronę!",
  bitm_task_complete: "Gotowe!",

  bitm_qr_title: "Przeglądaj podczas oczekiwania",
  bitm_qr_description:
    "Zeskanuj kod QR, aby zakończyć żądanie w aplikacji. Możesz spokojnie chodzić po sklepie, pójdziemy do Ciebie, gdy wszystko będzie gotowe.",
  restart_checkout: "Coś nie tak? Odśwież stronę",

  try_again: "Spróbuj ponownie",

  session_expiring_title: "Zakończono?",
  session_expiring_body: "Bieżąca sesja kończy się za 1",

  nearbystore_availability_overlay_title:
    "Sprawdź dostępność w innych sklepach",

  available_in_nearby_store: "Dostępne rozmiary w tej lokalizacji",

  nearby_store_no_sizes_found:
    "Produkt, którego szukasz, nie jest obecnie dostępny w tym miejscu.",

  please_try_selecting_another_store: "Wybierz inny sklep.",

  in_stock_at_this_store: "Wybierz inny sklep.",

  employee_id: "Identyfikator pracownika",

  add_employee_id_heading: "Dodaj identyfikator pracownika",

  add_employee_id_instruction:
    "Poproś o pomoc pracownika sklepu, aby kontynuować.",

  verify_checkout: "Sprawdź swoje dane przed zapisaniem",

  search: "Szukaj",

  search_result_see_all: "Zobacz wszystkie <search-text>",

  search_no_result: "Niestety, nie znaleziono żadnych wyników",

  try_searching_for_something_else: "Spróbuj wyszukać coś innego.",

  start_new_search: "Nowe wyszukiwanie",

  showing_results_for: "Szukaj wyników dla",

  no_available_sizes_found: "Obecnie nie ma dostępnych rozmiarów",

  online: "Online",
  in_store: "W sklepie",

  tap_to_get_started: "Dotknij, aby rozpocząć", //not used
  your_journey_starts_here: "Twoja podróż zaczyna się tutaj",
  app_header_title: "Przeglądaj i odkrywaj najnowsze i najlepsze produkty",
  landingpage_filter_heading: "Dla kogo robisz zakupy",
  landingpage_signup_heading: "Odblokuj najlepsze produkty Adidas",
  landingpage_signup_cta:
    "Zarejestruj się, aby kupować nasze produkty dostępne tylko dla członków",
  landingpage_events_cta: "Odkryj nasze nadchodzące premiery i wydarzenia",
  landingpage_instore_services_cta: "Odkryj nasze usługi stacjonarne",
  eventspage_heading: "Odkryj nasze wydarzenia Adidas",
  eventspage_sub_heading:
    "Osiągnij maksimum z zakupów z naszymi wyjątkowymi wydarzeniami.",

  eventspage_store_event: "Wydarzenie sklepowe",
  eventspage_event_details: "Szczegóły wydarzenia",
  eventspage_raffle_timelines: "Harmonogram losowań",
  eventspage_raffle_results: "Wyniki losowań",
  eventspage_sign_up_before: "Zarejestruj się przed",
  eventspage_scan_and_sign_up: "Zeskanuj i zarejestruj się",

  instoreservicepage_heading: "Odkryj nasze usługi stacjonarne",
  instoreservicepage_sub_heading:
    "Osiągnij maksimum z zakupów z naszymi wyjątkowymi usługami.",

  membershippage_title: "DOŁĄCZ DO PROGRAMU CZŁONKOWSKIEGO BEZPŁATNIE",
  membershippage_body:
    "Zacznij wydawać swoje punkty adiClub, aby otrzymać jeszcze więcej nagród z tego, co kochasz.",
  membershippage_feature_membership_discount: "rabaty dla członków",
  membershippage_feature_freeshipping: "darmowa dostawa",
  membershippage_feature_birthdaygifts: "prezenty urodzinowe",
  membershippage_feature_priorityshopping: "preferencyjne zakupy",
  membershippage_feature_specialevents: "specjalne wydarzenia",

  change_language: "zmień język",
};
