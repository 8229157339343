export const translations_en = {
  all_products: "All products",
  go_to_top: "Back to top",
  more_filters: "More filters",
  less_filters: "Less filters",
  sort_by: "Sort by",
  clear_all: "Clear all",
  colors_available: "<count> colours available",
  colors_available_plp: "<count> colours",

  details: "Details",
  how_to_style: "How to style",
  reviews: "Reviews",
  gallery: "Gallery",
  highlights: "Highlights",

  select_size: "Select size",
  store_availability: "Check other stores",

  size_guidance: "Size guide",
  add_to_bag: "Add to bag",
  scan_to_order_online: "Scan to order online",
  shopping_bag: "Shopping bag",
  bag_empty: "Your bag is empty",

  max_cart_item_count_reached: "Max. items reached",

  browse_online: (
    <>
      Go ahead – get yourself something nice!
      <br />
      Browse this kiosk to check the stock at this store or order items online.
    </>
  ),

  order_online: "order online",

  start_browsing: "Browse the store",
  total: "Total",
  including_tax: "(Incl. Tax)",
  checkout: "Checkout",
  delete_bag: "Clear bag",

  continue_on_this_device: "Check out here",
  checkout_on_your_device: "Check out with your phone",

  checkout_on_your_device_instruction:
    "Scan the QR code to transfer your bag to the adidas app or website on your phone.",

  employee_checkout: "Employee Checkout",
  employee_checkout_title: "Check out with a employee",
  employee_checkout_instruction:
    "Scan this QR code to transfer your bag and pay for your items.",
  employee_id_instruction:
    "Please enter your employee ID and update the QR code.",
  employee_id_update_qr_code: "Update QR Code",
  employe_id_validation_error: "Invalid Employee ID",
  change_employee_id: "Change Employee ID",

  only_cash_on_delivery_option:
    "Note: we only accept Cash on Delivery as payment method for kiosk orders.",

  yes_over_16_years_of_age: "I’m over 16 years old",

  choose_your_shipping_method: "Select shipping method",
  order_summary: "Order summary",
  promo_code: "Promo code",
  place_order: "Place order",
  order_now_pay_on_delivery: "Order now, pay when it’s delivered",

  read_more_reviews: "Read more reviews",

  edit_address: "Edit Address",
  save_details: "Save",
  edit_details: "Edit Details",
  select_pickup_point: "Select this location",
  select_store: "Select this store",
  back_to_list: "Back to products",

  sure_to_delete_cart: "Want to clear this bag?",
  cart_delete_message:
    "All the items you’ve added will be deleted and your session will end.",
  cancel: "Cancel",

  add_voucher_heading: "Add a voucher or promo code",
  add_voucher_instruction: "Case sensitive, enter as seen",
  add_voucher_disclaimer:
    "Some items may be excluded from some promo codes. Check the promo Terms & Conditions.",
  apply: "Apply",

  loading: "Loading...",
  your_order_in_progress: "Loading...",
  your_order_is_placed: "Order complete!",
  session_ends_in: "Session ends in",
  estimated_delivery: "Estimated Delivery",
  confirmation_email_sent_to: "A confirmation email has been sent to",
  order_number: "Order number",
  start_new_session: "Start new session",
  extend_session: "Keep going",

  map_search_no_result:
    "Sorry, we couldn't find any results. Please try again.",

  enter_your_details: "Contact details",
  first_name: "First name",
  last_name: "Last name",
  street: "Street",
  house_number: "Number",
  zip_code: "Zip code",
  town_city: "Town/city",
  email_address: "Email address",
  phone_number: "Phone number",

  change_store: "Change store",
  change_pickup_point: "Change pick-up point",

  search_by_city_dictrict_or_address: "Search by city, district or address",

  processing: "Processing...",

  error_occurred: "Sorry, something went wrong",

  retry_place_order: "Try placing order again",

  retry_start_checkout: "Try checking out again",

  please_select_your_size: "Select size",
  bring_it_to_me: "Bring it to me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Call store associate",
  do_you_need_assistance_title: <>Need help? We'll come to you</>,
  do_you_need_assistance_instruction:
    "Tap the button and one of our store associates will be with you shortly.",
  do_you_need_assistance_sure_to_delete:
    "Are you sure you want to delete this request?", //verify
  do_you_need_assistance_delete_btn_label: "delete request", //verify

  we_will_bring_it_here: "Or have it brought here",
  bring_it_to_me_instruction:
    "Tap the button, stay near this screen, and we’ll bring your requested items here in about 3-5 minutes.",

  bitm_none_active_title: "Found something nice?",
  bitm_none_active_instruction:
    "Let us bring it to you! Simply select the item(s) and size you want, and tap BRING IT TO ME on this kiosk or in the adidas app. ",

  bitm_sure_to_delete: "Are you sure you want to delete this request?", //verify
  bitm_delete_btn_label: "Delete request",

  bitm_task_todo: "Request in queue",
  bitm_task_in_progress: "Coming your way!",
  bitm_task_complete: "All done!",

  bitm_qr_title: "Browse while you wait",
  bitm_qr_description:
    "Scan the QR code to finish your request on the app. Feel free to walk around the store – we’ll come your way when your items are ready.",
  restart_checkout: "Something wrong? Refresh page",

  try_again: "Try again",

  session_expiring_title: "Are you done?",
  session_expiring_body: "Current session ending in",

  nearbystore_availability_overlay_title: "Check availability at other stores",

  available_in_nearby_store: "Available sizes at this location",

  nearby_store_no_sizes_found:
    "The item you're looking for is currently out of stock at this location.",

  please_try_selecting_another_store: "Please try a different store.",

  in_stock_at_this_store: "In stock at this store",

  employee_id: "Employee ID",

  add_employee_id_heading: "Add Employee ID",

  add_employee_id_instruction:
    "Please ask a store associate for help to continue.",

  verify_checkout: "Please double-check your details before saving",

  search: "Search",

  search_result_see_all: "See all <search-text>",

  search_no_result: "Sorry, no results found",

  try_searching_for_something_else: "Try searching for something else.  ",

  start_new_search: "Start new search",

  showing_results_for: "Search results for",

  no_available_sizes_found: "There are no sizes available at this moment",

  online: "Online",
  in_store: "In Store",

  tap_to_get_started: "Tap to get started", // not used
  your_journey_starts_here: "Your journey starts here",
  app_header_title: "Browse here and discover latest and greatest products",
  landingpage_filter_heading: "who are you shopping for",
  landingpage_signup_heading: "unlock the best for adidas",
  landingpage_signup_cta: "Sign up to shop our member-exclusive gear",
  landingpage_events_cta: "explore our upcoming releases and events",
  landingpage_instore_services_cta: "discover our in-store services",
  eventspage_heading: "discover our adidas events",
  eventspage_sub_heading:
    "Get the most from your shopping experience with our exclusive events.",

  eventspage_store_event: "Store event",
  eventspage_event_details: "Event details",
  eventspage_raffle_timelines: "Raffle timelines",
  eventspage_raffle_results: "Raffle results",
  eventspage_sign_up_before: "Signup before",
  eventspage_scan_and_sign_up: "Scan and sign up",

  instoreservicepage_heading: "discover our in-store Services",
  instoreservicepage_sub_heading:
    "Get the most from your shopping experience with our exclusive services.",

  membershippage_title: "JOIN THE MEMBERSHIP PROGRAM FOR FREE",
  membershippage_body:
    "Start spending your adiClub points to get rewarded with even more out of what you love.",
  membershippage_feature_membership_discount: "member discounts",
  membershippage_feature_freeshipping: "free shipping",
  membershippage_feature_birthdaygifts: "birthday gifts",
  membershippage_feature_priorityshopping: "priority shopping",
  membershippage_feature_specialevents: "special events",
  change_language: "change language",
};
