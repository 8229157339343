import React, { Component } from "react";
import { connect } from "react-redux";

import { GlInput } from "@adl/foundation";

import {
  setInputWithKeyboardId,
  setInputWithKeyboardValue,
} from "../../actions/inputWithKeyboardActions";

class InputWithKeyboard extends Component {
  handleOnFocus = (event) => {
    setTimeout(() => {
      this.props.setInputWithKeyboardId(event.target.id);
    }, 100);
  };

  handleOnChange = (event) => {
    this.props.setInputWithKeyboardValue(
      this.props.selctedInputWithKeyboardId,
      event.target.value
    );
  };

  render() {
    const { id, placeholder, selctedInputWithKeyboardValue } = this.props;

    return (
      <GlInput
        onFocus={(event) => this.handleOnFocus(event)}
        onChange={(event) => this.handleOnChange(event)}
        id={id}
        placeholder={placeholder}
        value={selctedInputWithKeyboardValue[id]}
      />
    );
  }
}

function mapStateToProps({
  selctedInputWithKeyboardId,
  selctedInputWithKeyboardValue,
}) {
  return {
    selctedInputWithKeyboardId,
    selctedInputWithKeyboardValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
    setInputWithKeyboardId: (val) => dispatch(setInputWithKeyboardId(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputWithKeyboard);
