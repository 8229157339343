export const translations_ru_RU = {
  all_products: "ВСЕ ТОВАРЫ",
  go_to_top: "наверх",
  more_filters: "больше фильтров",
  less_filters: "меньше фильтров",
  sort_by: "Сортировка",
  clear_all: "чистить все",
  colors_available: "Доступные цвета: <count>",
  colors_available_plp: "Цветов: <count>",

  details: "Описание",
  how_to_style: "Делись фотографиями",
  reviews: "Отзывы",
  gallery: "Галерея",
  highlights: "Особенности",

  select_size: "Выбрать размер",
  store_availability: "Проверка доступности в магазине",

  size_guidance: "Подобрать размер?",
  add_to_bag: "Добавить в корзину",
  scan_to_order_online: "Отсканируй, чтобы оформить заказ на сайте",
  shopping_bag: "КОРЗИНА",

  max_cart_item_count_reached:
    "Достигнуто максимальное количество товаров в корзине",

  bag_empty: "В КОРЗИНЕ НЕТ ТОВАРОВ",
  browse_online:
    "Проверить наличие товара в магазине или оформить заказ онлайн",

  order_online: "Заказать онлайн",

  start_browsing: "За покупками",
  total: "ИТОГО",
  including_tax: "",
  checkout: "ОФОРМИТЬ",
  delete_bag: "Очистить корзину",

  continue_on_this_device: "Продолжить на личном устройстве",
  checkout_on_your_device: "Продолжить на личном устройстве",

  checkout_on_your_device_instruction:
    "Просканируй QR код чтобы продолжить в adidas app или adidas.ru на личном устройстве",

  employee_checkout: "Выход сотрудника",
  employee_checkout_title: "Выход с сотрудником",
  employee_checkout_instruction:
    "Отсканируйте этот QR-код, чтобы передать вашу сумку и оплатить товары.",
  employee_id_instruction:
    "Пожалуйста, введите свой идентификационный номер сотрудника и обновите QR-код.",
  employee_id_update_qr_code: "Обновить QR-код",
  employe_id_validation_error: "Неверный идентификатор сотрудника",
  change_employee_id: "Изменить идентификатор сотрудника",

  only_cash_on_delivery_option:
    "Продолжить оформление заказа на личном устройстве",
  yes_over_16_years_of_age: "Да, я старше 16 лет",

  choose_your_shipping_method: "Выбери способ доставки",
  order_summary: "ИНФОРМАЦИЯ О ЗАКАЗЕ",
  promo_code: "Введи промо-код",
  place_order: "Сделай заказ",
  order_now_pay_on_delivery: "Закажи сейчас и оплати при получении",

  read_more_reviews: "СМОТРЕТЬ ЕЩЕ",

  edit_address: "Изменить адрес",
  save_details: "Сохранить данные",
  edit_details: "Изменить данные",
  select_pickup_point: "Выбери пункт выдачи",
  select_store: "Выбери магазин",
  back_to_list: "Назад к списку",

  sure_to_delete_cart: "Вы действительно хотите очистить корзину?",
  cart_delete_message: "Все товары и личные данные будут удалены",
  cancel: "Отменить",

  add_voucher_heading: "Ввести промокод",
  add_voucher_instruction: "Важно - промокод чувствителен к регистру",
  add_voucher_disclaimer:
    "Промокод может не применятся на ряд продуктов, в случае сложностей с получением скидки пожалуйста обратитесь к консультанту по спорту",
  apply: "Применить",

  loading: "Пожалуйста подождите",
  your_order_in_progress: "Размещаем заказ",
  your_order_is_placed: "Ваш заказ создан",
  session_ends_in: "Сессия автоматически закончится через",
  estimated_delivery: "Планируемый срок доставки",
  confirmation_email_sent_to: "Детали заказа высланы по почту",
  order_number: "Номер заказа",
  start_new_session: "Начать новую сессию",
  extend_session: "Продолжить сессию",
  map_search_no_result:
    "По этому адресу ничего не найдено, пожалуйста попробуйте другой адрес",

  enter_your_details: "Пожалуйста заполните информацию ниже",
  first_name: "Имя",
  last_name: "Фамилия",
  street: "Улица",
  house_number: "Номер дома",
  zip_code: "Индекс",
  town_city: "Город",
  email_address: "Почтовый адрес",
  phone_number: "Номер телефона",

  change_store: "Изменить магазин",
  change_pickup_point: "Изменить пункт выдачи заказа",

  search_by_city_dictrict_or_address: "Искать по городу, району или адресу",

  processing: "Почти готово",

  error_occurred:
    "Возникла ошибка, пожалуйста обратитесь к консультанту по спорту",

  retry_place_order: "Повторно разместить заказ",

  retry_start_checkout: "Начать оформление заказа сначала",

  bring_it_to_me: "Принести",
  need_help: "Нужна помощь",

  call_store_associate: "Вызов консультанта по спорту",
  do_you_need_assistance_title: "Вам нужна помощь?",
  do_you_need_assistance_instruction:
    "Пожалуйста нажмите на кнопку ниже и ожидайте, консультант по спорту скоро подойдет",
  do_you_need_assistance_sure_to_delete: "Отменить запрос?",
  do_you_need_assistance_delete_btn_label: "Отменить запрос",

  please_select_your_size: "Пожалуйста выберите свой размер",

  we_will_bring_it_here: "Мы принесем вещь сюда",
  bring_it_to_me_instruction:
    "Завершите запрос на устройстве и ожидайте - вещь принесут в течение 3-5 минут",

  bitm_none_active_title: "Что вам принести?",
  bitm_none_active_instruction:
    "Запроси продукт своего размера для примерки и мы принесем его тебе - к данному устройству или найдем тебя в магазине",

  bitm_sure_to_delete: "Точно отменить запрос?",
  bitm_delete_btn_label: "Отменить запрос",

  bitm_task_todo: "Запрос в очереди",
  bitm_task_in_progress: "Запрос выполняется",
  bitm_task_complete: "Готово",

  bitm_qr_title: "Скоро найдем вас в магазине",
  bitm_qr_description:
    "Просканируй QR код на своем телефоне. Можете перемещаться по магазину пока мы обрабатываем ваш запрос",
  restart_checkout: "Заказать заново",

  try_again: "Попробовать снова ?",

  session_expiring_title: "Сессия скоро истекает",

  session_expiring_body: "Сессия обновится через ",

  nearbystore_availability_overlay_title:
    "Выберите магазин, чтобы проверить наличие товара в нем",

  available_in_nearby_store: "Доступность в выбранном магазине",

  nearby_store_no_sizes_found:
    "К сожалению, нет доступных размеров в выбранном магазине.",

  please_try_selecting_another_store:
    "Пожалуйста попробуйте выбрать другой магазин",

  in_stock_at_this_store: "В наличии в этом магазине",

  employee_id: "Табельный номер сотрудника",

  add_employee_id_heading: "_ru Add Employee ID",

  add_employee_id_instruction:
    "Здесь можно указать табельный номер сотрудника, который помог Вам с выбором",

  verify_checkout: "Пожалуйста проверьте корректность адреса",

  search: "Поиск",
  search_result_see_all: "Смотреть все <search-text>",

  search_no_result: "Ничего не найдено",

  try_searching_for_something_else:
    "Проверь написание или попробуй перефразировать поисковой запрос.",

  start_new_search: "Начать поиск",

  showing_results_for: "Результаты поиска для",

  no_available_sizes_found: "_ru no available sizes found",

  online: "В сети",
  in_store: "В магазине",

  tap_to_get_started: "Коснитесь, чтобы начать", //not used
  your_journey_starts_here: "Ваше путешествие начинается здесь",
  app_header_title: "Ищите здесь и откройте для себя новейшие и лучшие товары",
  landingpage_filter_heading: "Для кого вы покупаете",
  landingpage_signup_heading: "разблокируйте лучшее для adidas",
  landingpage_signup_cta:
    "Зарегистрируйтесь, чтобы покупать наши эксклюзивные товары для членов",
  landingpage_events_cta: "Исследуйте наши предстоящие релизы и события",
  landingpage_instore_services_cta:
    "Откройте для себя наши сервисы в магазинах",
  eventspage_heading: "Откройте для себя события adidas",
  eventspage_sub_heading:
    "Получите максимум от своего опыта покупок с нашими эксклюзивными мероприятиями.",

  eventspage_store_event: "Событие в магазине",
  eventspage_event_details: "Детали события",
  eventspage_raffle_timelines: "Сроки розыгрышей",
  eventspage_raffle_results: "Результаты розыгрыша",
  eventspage_sign_up_before: "Регистрация до",
  eventspage_scan_and_sign_up: "Отсканируйте и зарегистрируйтесь",

  instoreservicepage_heading: "Откройте для себя наши сервисы в магазинах",
  instoreservicepage_sub_heading:
    "Получите максимум от своего опыта покупок с нашими эксклюзивными сервисами.",

  membershippage_title: "ПРИСОЕДИНЯЙТЕСЬ К ПРОГРАММЕ УЧАСТНИКОВ БЕСПЛАТНО",
  membershippage_body:
    "Начните тратить свои баллы adiClub, чтобы получать еще больше от того, что вы любите.",
  membershippage_feature_membership_discount: "скидки для участников",
  membershippage_feature_freeshipping: "бесплатная доставка",
  membershippage_feature_birthdaygifts: "подарки на день рождения",
  membershippage_feature_priorityshopping: "приоритетные покупки",
  membershippage_feature_specialevents: "особые события",

  change_language: "изменить язык",
};
