export const translations_es_MX = {
  all_products: "Todos los productos",
  go_to_top: "Volver al inicio",
  more_filters: "Más filtros",
  less_filters: "Menos filtros",
  sort_by: "Ordenar por",
  clear_all: "Borrar todo",
  colors_available: "<count> colores disponibles",
  colors_available_plp: "<count> colores",

  details: "Detalles",
  how_to_style: "Completa tu look",
  reviews: "Valoraciones",
  gallery: "Galería",
  highlights: "Destacados",

  select_size: "Seleccionar talla",
  store_availability: "Ver otras tiendas",

  size_guidance: "Guía de tallas",
  add_to_bag: "Añadir al carrito",
  scan_to_order_online: "Escanea para hacer un pedido online",
  shopping_bag: "Carrito de compra",
  bag_empty: "El carrito está vacío",

  max_cart_item_count_reached: "Máximo de artículos alcanzados",

  browse_online: (
    <>
      Adelante, ¡consigue algo bonito! <br />
      Navega por este quiosco para consultar el stock en esta tienda o pedir
      artículos online.
    </>
  ),

  order_online: "Pedido online",

  start_browsing: "Explorar la tienda",
  total: "Total ",
  including_tax: "(Impuestos incluidos) ",
  checkout: "Pagar",
  delete_bag: "Borrar carrito",

  continue_on_this_device: "Pagar",
  checkout_on_your_device: "Pagar con tu teléfono",

  checkout_on_your_device_instruction:
    "Escaneá el código QR para transferir tu carrito a la app de adidas o a la página web en tu teléfono.",

  employee_checkout: "Salida de Empleado",
  employee_checkout_title: "Salida con un empleado",
  employee_checkout_instruction:
    "Escanee este código QR para transferir su bolsa y pagar por sus productos.",
  employee_id_instruction:
    "Por favor, ingrese su ID de empleado y actualice el código QR.",
  employee_id_update_qr_code: "Actualizar código QR",
  employe_id_validation_error: "ID de empleado no válido.",
  change_employee_id: "Cambiar ID de Empleado",

  only_cash_on_delivery_option:
    "Nota: Sólo podemos aceptar el pago contra reembolso para los pedidos del quiosco.",

  yes_over_16_years_of_age: "Tengo más de 16 años",

  choose_your_shipping_method: "Seleccionar método de envío",
  order_summary: "Resumen del pedido",
  promo_code: "Código promocional",
  place_order: "Realizar pedido",
  order_now_pay_on_delivery:
    "Realiza tu pedido ahora y paga cuando se entregue",

  read_more_reviews: "Leer más reseñas",

  edit_address: "Editar dirección",
  save_details: "Guardar",
  edit_details: "Editar datos",
  select_pickup_point: "Seleccionar ubicación",
  select_store: "Seleccionar esta tienda",
  back_to_list: "Volver a productos",

  sure_to_delete_cart: "¿Quieres vaciar este carrito?",
  cart_delete_message:
    "Todos los artículos que has añadido se eliminarán y tu sesión finalizará.",
  cancel: "Cancelar",

  add_voucher_heading: "Añadir un cupón o código promocional",
  add_voucher_instruction:
    "Verifica las mayúsculas y minúsculas. Escribe el código tal y como aparece.",
  add_voucher_disclaimer:
    "Algunos artículos pueden estar excluidos de ciertos códigos promocionales. Consulta los Términos y condiciones.",
  apply: "Aplicar",

  loading: "Cargando...",
  your_order_in_progress: "Cargando...",
  your_order_is_placed: "¡Pedido completo!",
  session_ends_in: "La sesión termina en:",
  estimated_delivery: "Entrega estimada:",
  confirmation_email_sent_to:
    "Se envió un correo electrónico de confirmación a:",
  order_number: "Número de pedido:",
  start_new_session: "Iniciar nueva sesión",
  extend_session: "Sigue avanzando",

  map_search_no_result:
    "Lo sentimos, no hemos encontrado ningún resultado. Inténtalo de nuevo.",

  enter_your_details: "Datos de contacto",
  first_name: "Nombre",
  last_name: "Apellido",
  street: "Calle",
  house_number: "Número",
  zip_code: "Código postal",
  town_city: "Ciudad/Municipio",
  email_address: "Correo electrónico",
  phone_number: "Número de teléfono",

  change_store: "Cambiar de tienda",
  change_pickup_point: "Cambiar punto de recogida",

  search_by_city_dictrict_or_address: "Buscar por ciudad, distrito o dirección",

  processing: "Procesando...",

  error_occurred: "Lo sentimos, algo salió mal",

  retry_place_order: "Intenta realizar el pedido de nuevo",

  retry_start_checkout: "Intenta pagar de nuevo",

  please_select_your_size: "Seleccionar talla",
  bring_it_to_me: "Tráemelo",
  need_help: <br />,

  call_store_associate: "Llamar a un miembro de nuestro equipo",
  do_you_need_assistance_title: "¿Necesitas ayuda? Nosotros te buscamos",
  do_you_need_assistance_instruction:
    "Toca el botón y uno de nuestros colaboradores te ayudará.",
  do_you_need_assistance_sure_to_delete:
    "¿Estás seguro de que deseas eliminar esta solicitud?",
  do_you_need_assistance_delete_btn_label: "Eliminar solicitud",

  we_will_bring_it_here: "O te lo llevamos donde estás",
  bring_it_to_me_instruction:
    "Toca el botón y permanece cerca de esta pantalla. Te llevaremos los artículos solicitados aquí en unos 3-5 minutos.",

  bitm_none_active_title: "¿Encontraste algo de tu gusto?",
  bitm_none_active_instruction: (
    <>
      ¡Nosotros te lo llevamos! Simplemente selecciona el/los artículo/s y la
      talla que deseas y pulsa en TRÁEMELO en este quiosco o en la app de
      adidas.
    </>
  ),

  bitm_sure_to_delete: "¿Estás seguro de que deseas eliminar esta solicitud?",
  bitm_delete_btn_label: "Eliminar solicitud",

  bitm_task_todo: "Solicitud en cola",
  bitm_task_in_progress: "¡Tu pedido está en camino!",
  bitm_task_complete: "¡Listo!",

  bitm_qr_title: "Navega mientras esperas",
  bitm_qr_description:
    "Escanea el código QR para finalizar tu solicitud en la app. Puedes pasear por la tienda. Nosotros te llevaremos tus artículos cuando estén listos.",
  restart_checkout: "¿Algo salió mal? Actualizar página",

  try_again: "Volver a intentar",

  session_expiring_title: "¿Ya terminaste?",
  session_expiring_body: "La sesión actual termina en",

  nearbystore_availability_overlay_title:
    "Comprobar disponibilidad en otras tiendas",

  available_in_nearby_store: "Tallas disponibles en esta tienda",

  nearby_store_no_sizes_found:
    "El artículo que estás buscando está agotado en esta tienda.",

  please_try_selecting_another_store: "Probar en otra tienda.",

  in_stock_at_this_store: "Disponible en esta tienda",

  employee_id: "ID de empleado",

  add_employee_id_heading: "Agregar ID de empleado",

  add_employee_id_instruction:
    "Pide ayuda a un miembro de nuestro equipo para continuar.",

  verify_checkout: "Comprueba tus datos antes de guardar",

  search: "Buscar",

  search_result_see_all: "Ver todo <search-text>",

  search_no_result: "Lo sentimos, no hemos encontrado resultados",

  try_searching_for_something_else: "Intenta buscar otra cosa.",

  start_new_search: "Iniciar una nueva búsqueda",

  showing_results_for: "Resultados de búsqueda para <search-text>",

  no_available_sizes_found: "Actualmente no hay tallas disponibles",

  online: "Online",
  in_store: "En la tienda",

  tap_to_get_started: "Toca para comenzar", //not used
  your_journey_starts_here: "Tu viaje comienza aquí",
  app_header_title:
    "Explora aquí y descubre los productos más recientes y sorprendentes",
  landingpage_filter_heading: "¿Para quién estás comprando?",
  landingpage_signup_heading: "Descubre lo mejor de adidas",
  landingpage_signup_cta:
    "Regístrate para comprar nuestra equipación exclusiva para miembros",
  landingpage_events_cta: "Explora nuestros próximos lanzamientos y eventos",
  landingpage_instore_services_cta: "Descubre nuestros servicios en tienda",
  eventspage_heading: "Descubre nuestros eventos de adidas",
  eventspage_sub_heading:
    "Aprovecha al máximo tu experiencia de compra con nuestros eventos exclusivos.",

  eventspage_store_event: "Evento en tienda",
  eventspage_event_details: "Detalles del evento",
  eventspage_raffle_timelines: "Plazos del sorteo",
  eventspage_raffle_results: "Resultados del sorteo",
  eventspage_sign_up_before: "Regístrate antes de",
  eventspage_scan_and_sign_up: "Escanear y registrarse",

  instoreservicepage_heading: "Descubre nuestros servicios en tienda",
  instoreservicepage_sub_heading:
    "Aprovecha al máximo tu experiencia de compra con nuestros servicios exclusivos.",

  membershippage_title: "ÚNETE AL PROGRAMA DE MEMBRESÍA GRATUITO",
  membershippage_body:
    "Comienza a gastar tus puntos de adiClub para obtener aún más recompensas por lo que amas.",
  membershippage_feature_membership_discount: "descuentos para miembros",
  membershippage_feature_freeshipping: "envío gratuito",
  membershippage_feature_birthdaygifts: "regalos de cumpleaños",
  membershippage_feature_priorityshopping: "compras prioritarias",
  membershippage_feature_specialevents: "eventos especiales",

  change_language: "cambiar idioma",
};
