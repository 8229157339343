import React, { Component } from "react";

import { connect } from "react-redux";

import InputWithKeyboard from "../../../InputWithKeyboard/InputWithKeyboard";

import { setInputWithKeyboardValue } from "../../../../actions/inputWithKeyboardActions";

import "./Search.css";

import search from "../../../../assets/icons/search.svg";

import Product from "../../../ProductListView/components/ProductCard/ProductCard";

import { setExternalPDPTrigger } from "../../../../actions/productActions";

import { setSearchInput } from "../../../../actions/searchActions";

import { API } from "../../../../API/index";

import {
  PAGE_NAMES,
  setCurrentPageName,
} from "../../../../actions/pageActions";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../../../actions/sidebarMenuActions";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import * as analytics from "../../../../analytics";

// import { GlButton, GL_BUTTON_VARIANT, GL_BUTTON_THEME } from "@adl/foundation";

let handle_InputTextChangedSetTimeout;

let handle_OpenPDPSetTimeout;

class Search extends Component {
  state = {
    searchText: this.props.userSelectedSearchSuggestion,
    suggestions: null,
    productSuggestions: null,
    shouldFetchData: false,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({
      suggestions: [],
      productSuggestions: [],
    });

    if (this.state.searchText?.length > 3) {
      this.fetchData(this.state.searchText);
    }
    this.focusSearchText();

    //Log Search View analytics
    analytics.searchView({
      hostname: this.props.appSettings.value.player.hostname,
    });
  }

  focusSearchText = () => {
    document.getElementById("search_input_text").focus();
  };

  componentWillUnmount() {
    this.setState({ searchText: "" });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selctedInputWithKeyboardId, selctedInputWithKeyboardValue } =
      this.props;

    if (
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      if (
        selctedInputWithKeyboardValue[selctedInputWithKeyboardId] !==
        this.state.searchText
      ) {
        let enteredText =
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId];

        if (enteredText?.length > 0) {
          this.fetchData(enteredText);
        }

        this.setState({
          searchText: enteredText,
        });

        this.setState({ shouldFetchData: false });
      }
    }
  }

  fetchData = (enteredText) => {
    this.setState({
      suggestions: [],
      productSuggestions: [],
      isLoading: false,
    });

    if (!enteredText) {
      enteredText = this.props.searchText;
    }

    if (enteredText?.length > 0) {
      this.setState({ isLoading: true });
      clearTimeout(handle_InputTextChangedSetTimeout);

      handle_InputTextChangedSetTimeout = setTimeout(() => {
        this.setState({ shouldFetchData: true });
        API.productsAPI
          .getSearchSuggestions(enteredText, this.props.selectedLocale)
          .then((response) => {
            const data = response?.data?.data;
            if (data) {
              this.setState({
                suggestions: data?.suggestions,
                productSuggestions: data?.product_suggestions,
              });

              //Log Search Term analytics
              analytics.searchTerm({
                hostname: this.props.appSettings.value.player.hostname,
                enteredText: enteredText,
                searchResults:
                  data?.product_suggestions
                    .map((product_suggestion) => product_suggestion.product_id)
                    .toString() || "",
              });
            }
            this.setState({ isLoading: false });
          }).catch = (e) => {
          this.setState({
            suggestions: [],
            productSuggestions: [],
            isLoading: false,
          });
        };
      }, 1000);
    }
  };

  handleClear = (event) => {
    this.props.setInputWithKeyboardValue("search_input_text", "");
    this.setState({
      searchText: "",
    });

    this.focusSearchText();
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { suggestions, productSuggestions, searchText, isLoading } = this.state;

    let { selectedLocale } = this.props;

    let seeAllSearchText = this.getTranslation(
      TRANSLATIONS_KEY_LIST.search_result_see_all
    );

    seeAllSearchText = seeAllSearchText.replace(
      "<search-text>",
      `"${searchText}"`
    );

    productSuggestions?.forEach((item) => {
      item.image_url = item?._links?.image_small?.href;
    });

    let isJapanMarket = selectedLocale?.split("_")[1] === "JP";

    return (
      <>
        <div
          className="search-view-container animate-right"
          onClick={(e) => this.props.onClose()}
        >
          <div className="search-view" onClick={(e) => e.stopPropagation()}>
            <div className="search-input">
              <div className="left">
                <div>
                  <img className="icon" src={search} alt="search" />
                </div>
                <div>
                  <InputWithKeyboard
                    id="search_input_text"
                    placeholder={this.getTranslation(
                      TRANSLATIONS_KEY_LIST.search
                    )}
                  />
                </div>
              </div>

              <div className="right">
                {this.state.searchText?.length > 0 && (
                  <p onClick={(e) => this.handleClear(e)}>
                    {this.getTranslation(TRANSLATIONS_KEY_LIST.clear_all)}
                  </p>
                )}
              </div>
            </div>

            <div className="placeholder debug-info hidden">
              <div>
                <h2>{this.state.searchText}</h2>
                <p>{"Your search result will appear here."} </p>
                <p>{"shouldFetchData: " + this.state.shouldFetchData}</p>
              </div>
            </div>

            {!isLoading &&
              suggestions?.length === (isJapanMarket ? 0 : 1) &&
              productSuggestions?.length === 0 &&
              searchText?.length > 0 && (
                <div className="no-result-info-container">
                  <div>
                    <img className="icon" src={search} alt="search" />
                  </div>
                  <div>
                    <h4>
                      {this.getTranslation(
                        TRANSLATIONS_KEY_LIST.search_no_result
                      )}
                    </h4>
                  </div>

                  {/* below components were asked to be removed */}
                  {/* <div>
                    <p>
                      {getTranslation(
                        selectedLocale,
                        TRANSLATIONS_KEY_LIST.try_searching_for_something_else
                      )}
                    </p>
                  </div>

                  <div
                    className="btn-start-new-search"
                    onClick={(e) => this.handleClear(e)}
                  >
                    <GlButton
                      aria-label="label"
                      theme={GL_BUTTON_THEME.light}
                      variant={GL_BUTTON_VARIANT.secondary}
                      disabled={false}
                    >
                      {getTranslation(
                        selectedLocale,
                        TRANSLATIONS_KEY_LIST.start_new_search
                      )}
                    </GlButton>
                  </div> */}
                </div>
              )}

            {}

            {isLoading && (
              <div className="search-result-container-skeleton">
                <div className="suggestions-list">
                  <ul>
                    <li className="suggestion-list-item-skeleton small"></li>
                    <li className="suggestion-list-item-skeleton"></li>
                    <li className="suggestion-list-item-skeleton medium"></li>
                    <li className="suggestion-list-item-skeleton"></li>
                    <li className="suggestion-list-item-skeleton small"></li>
                  </ul>
                </div>

                <div className="product-grid-skeleton-wrapper">
                  {[1, 2, 3, 4].map((x) => (
                    <div>
                      <div className="suggestion-product-skeleton"></div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {searchText && (
              <div className="search-result-container">
                <div className="suggestions-list">
                  <ul>
                    {suggestions &&
                      suggestions.length > 0 &&
                      suggestions.map((x) => (
                        <li
                          key={x.term}
                          onClick={() => {
                            this.props.setSearchInput(
                              x?._links?.self?.href?.split("query=")[1] ||
                                x?._links?.self?.href?.split(
                                  "collection="
                                )[1] ||
                                x.term ||
                                ""
                            );

                            this.props.setSideBarActiveItem(
                              MENU_ITEM_NAMES.search
                            );
                            this.props.setCurrentPageName(PAGE_NAMES.plp);
                          }}
                        >
                          {x.term}
                        </li>
                      ))}
                  </ul>

                  {suggestions?.length > 0 && (
                    <div
                      className="see-all-for-search-text"
                      onClick={() => {
                        this.props.setSearchInput(searchText);

                        this.props.setSideBarActiveItem(MENU_ITEM_NAMES.search);
                        this.props.setCurrentPageName(PAGE_NAMES.plp);
                      }}
                    >
                      <>
                        <span>{seeAllSearchText}</span>
                      </>
                    </div>
                  )}
                </div>
                <div className="product-suggestion-grid-wrapper">
                  {productSuggestions &&
                    productSuggestions.map((x) => (
                      <div
                        onClick={(e) => {
                          this.props.setExternalPDPTrigger(x.product_id);

                          this.setState({
                            suggestions: [],
                            productSuggestions: [],
                            isLoading: true,
                          });

                          if (handle_OpenPDPSetTimeout) {
                            clearInterval(handle_OpenPDPSetTimeout);
                          }
                          handle_OpenPDPSetTimeout = setTimeout(() => {
                            this.setState({ isLoading: false });
                            this.props.onClose();
                          }, 1000);
                        }}
                      >
                        <Product
                          key={x.product_id}
                          product={x}
                          selectedLocale={this.props.selectedLocale}
                        ></Product>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {/* <div>{"IS_LOADING:" + isLoading + ""}</div> */}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  activeHelpRequest,
  selectedLocale,
  appSettings,
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
  userSelectedSearchSuggestion,
}) {
  return {
    activeHelpRequest,
    selectedLocale,
    appSettings,
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
    userSelectedSearchSuggestion,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),

    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),

    setSearchInput: (val) => dispatch(setSearchInput(val)),

    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),

    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
