export class ProductDescriptionModel {
  constructor(
    product_id,
    description_heading,
    description_body,
    details_heading,
    details_items,
    highlights_heading,
    highlights_items
  ) {
    this.product_id = product_id;
    this.description = {
      heading: description_heading,
      body: description_body,
      image: null,
    };

    this.details = {
      heading: details_heading,
      items: details_items,
    };

    this.highlights =
      highlights_items?.length > 0
        ? {
            heading: highlights_heading,
            items: highlights_items,
          }
        : null;
  }
}
