import React, { Component } from "react";
// import { log, MSG_TYPE } from "../../../../utils/logger";
import "./ProductCardSkeleton.css";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

class ProductCardSkeleton extends Component {
  componentDidMount() {}
  componentDidUpdate() {}

  render() {
    return (
      <div className="product-card">
        <div className="product-card-skeleton">
          <div className="image_placeholder"></div>
        </div>
        <div className="category_placeholder"></div>
        <div className="name_placeholder"></div>
        <div className="price_placeholder"></div>
      </div>
    );
  }
}

export default ProductCardSkeleton;
