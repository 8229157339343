import APIBase from "../APIBase/APIBase";

class MembershipAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 5 * 60 * 1000, // set cache time to 5 minutes
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return response;
    });
  };

  #getMembershipUIdata = async (locale) => {
    await this.#getHeaders();

    const _url = `/user/membership/program?locale=${locale}`;

    try {
      let response = await this.#handleAPIGetRequest(_url);

      console.log(response);

      console.log("Membership Api data ", response?.data?.data);

      return response?.data?.data;
    } catch {
      return null;
    }
  };

  getAllFunctions = () => {
    return {
      getMembershipUIdata: this.#getMembershipUIdata,
    };
  };
}

export default MembershipAPI;
