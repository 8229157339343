export const SET_INPUT_WITH_KEYBOARD_ID = "SET_INPUT_WITH_KEYBOARD_ID";
export const SET_INPUT_WITH_KEYBOARD_VALUE = "SET_INPUT_WITH_KEYBOARD_VALUE";
export const CLEAR_INPUT_WITH_KEYBOARD_VALUE =
  "CLEAR_INPUT_WITH_KEYBOARD_VALUE";
export const SET_INITIAL_INPUT_WITH_KEYBOARD_VALUE =
  "SET_INITIAL_INPUT_WITH_KEYBOARD_VALUE";

export function setInputWithKeyboardId(id) {
  return { type: SET_INPUT_WITH_KEYBOARD_ID, id };
}

export function setInputWithKeyboardValue(id, value) {
  return { type: SET_INPUT_WITH_KEYBOARD_VALUE, id, value };
}

export function clearInputWithKeyboardValue(id, val) {
  return { type: CLEAR_INPUT_WITH_KEYBOARD_VALUE };
}

export function setInitialInputWithKeyboardValue(value) {
  return { type: SET_INITIAL_INPUT_WITH_KEYBOARD_VALUE, value };
}
