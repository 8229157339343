import React, { Component } from "react";

import { connect } from "react-redux";

import "./LanguageOptions.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import { setSideBarActiveItem } from "../../../../actions/sidebarMenuActions";
import { setLanguageLocale } from "../../../../actions/appSettingsActions";

import flagOverrides from "../Menu/flagoverrides";

let mounted;

class LanguageOptions extends Component {
  state = {};
  componentDidMount() {
    mounted = true;
  }

  componentWillUnmount() {
    if (mounted) mounted = false;
  }

  getTranslation(key) {
    return translateString(key);
  }

  getFlagJSXWhenMultiLang = (locale) => {
    const flagCountryCode = locale?.split("_")?.[1].toLowerCase();

    const imgPublicPath = `/adidas/assets/flags/${flagCountryCode}.svg`;

    const flagImageOverride = flagOverrides.filter(
      (x) => x.locale === locale
    )[0]?.flagPath;

    const flagPath = flagImageOverride ? flagImageOverride : imgPublicPath;

    return <img className="multi-lang-flag" src={flagPath} alt={locale} />;
  };

  render() {
    let languageOptions = this.props?.languageOptions;

    return (
      <>
        <div
          className="languague-options-container animate-right"
          onClick={(e) => this.props.onClose()}
        >
          <div
            className="languague-options"
            onClick={(e) => {
              this.props.resetTimoutTimer();
              e.stopPropagation();
            }}
          >
            <section className="header">
              <div>
                <h4>
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.change_language)}
                </h4>
              </div>
            </section>
            <div className="language-list">
              {languageOptions.map((x) => (
                <div
                  className={`language-list__row ${
                    this.props.selectedTranslationLocale === x?.locale
                      ? "highlighted"
                      : ""
                  }`}
                  onClick={(e) => {
                    setTimeout(() => {
                      this.props.setLanguageLocale(x?.locale);
                      this.props.setSideBarActiveItem(null);
                    }, 300);
                  }}
                >
                  <div class="language-list__row__flag">
                    {this.getFlagJSXWhenMultiLang(x?.locale)}
                  </div>
                  <div class="language-list__row__lang">{x?.language_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  appSettings,
  selectedTranslationLocale,
}) {
  return {
    selectedLocale,
    appSettings,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setLanguageLocale: (val) => dispatch(setLanguageLocale(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageOptions);
