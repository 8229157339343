import { setup } from "axios-cache-adapter";
import { log, MSG_TYPE } from "../../../utils/logger";
import { BFF_API } from "../constants";

// const _TEMP_LOCAL_BFF = "http://localhost:3001/ssk-service/sam";

// import * as config from "./config/config.js";

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const cacheAxiosAPI = setup({
  // `axios-cache-adapter` options
  cache: {
    maxAge: 15 * 60 * 1000,
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      methods: ["put", "patch", "delete"],
    },
  },
});

const cacheAxiosAPI_20SecCache = setup({
  // `axios-cache-adapter` options
  cache: {
    maxAge: 20 * 1000,
  },
});

const noCacheAxiosAPI = setup({
  cache: {
    maxAge: 0,
  },
});

export const getTime = () => {
  log("maps API getTime called", MSG_TYPE.READY);

  return cacheAxiosAPI({
    url: `${BFF_API}/epoch`,
    method: "get",
  });
};

export const getProductIdFromEAN = (ean) => {
  return cacheAxiosAPI({
    url: `${BFF_API}/sam/barcode/${ean}`,
    method: "get",
  });
};

export const getProductAvailabilityInStore = (productId, ssmid) => {
  return cacheAxiosAPI_20SecCache({
    url: `${BFF_API}/sam/products/${productId}/in-store-availability?ssmid=${ssmid}`,
    method: "get",
  });
};

export const createBITMTask = (
  productId,
  technicalSize,
  ssmid,
  countryCode,
  InStoreLocationIdentifier,
  bitmRequestType
) => {
  let _postData = {
    type: bitmRequestType,
    ssmId: ssmid,
    countryCode: countryCode,
    stockKeepingUnits: [
      {
        articleNumber: productId,
        technicalSize: technicalSize,
        quantity: 1,
      },
    ],
    location: InStoreLocationIdentifier,
    customerName: "ssk_user",
  };

  return cacheAxiosAPI_20SecCache({
    url: `${BFF_API}/sam/bitm/task/`,
    method: "post",
    data: _postData,
  });
};

export const createAssociateHelpRequestTask = (
  ssmid,
  countryCode,
  InStoreLocationIdentifier,
  requestType
) => {
  let _postData = {
    type: requestType,
    ssmId: ssmid,
    countryCode: countryCode,

    location: InStoreLocationIdentifier,
    note: "store associate request from ssk",
  };

  return cacheAxiosAPI_20SecCache({
    url: `${BFF_API}/sam/bitm/task/`,
    method: "post",
    data: _postData,
  });
};

export const getTask = (taskId) => {
  if (!taskId) {
    return Promise.reject("task id cannot be null or blank");
  } else {
    return noCacheAxiosAPI({
      url: `${BFF_API}/sam/bitm/tasks/${taskId}`,
      method: "get",
    });
  }
};

export const deleteTask = (taskId) => {
  if (!taskId) {
    return Promise.reject("task id cannot be null or blank");
  } else {
    return noCacheAxiosAPI({
      url: `${BFF_API}/sam/bitm/task?taskId=${taskId}`,
      method: "delete",
    });
  }
};
