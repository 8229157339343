import React, { Component } from "react";

import { connect } from "react-redux";

import "./RequestHelpItems.css";

import "./RequestHelpItemsStatus.css";

import * as helpers from "../../../../../../utils/helpers";

import { SAM_REQUEST_STATUS_TYPE } from "../../../../../../utils/constants";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

class RequestHelpItems extends Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  getTranslation(key) {
    return translateString(key);
  }

  getBITMStatusJSX = () => {
    let { activeHelpRequest } = this.props;

    const status =
      activeHelpRequest[0]?.task?.status || SAM_REQUEST_STATUS_TYPE.todo;

    let statusLocalized;

    if (status === SAM_REQUEST_STATUS_TYPE.todo) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_todo
      );
    } else if (status === SAM_REQUEST_STATUS_TYPE.inProgress) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_in_progress
      );
    } else if (status === SAM_REQUEST_STATUS_TYPE.complete) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_complete
      );
    }

    let updatedAt = activeHelpRequest[0]?.task?.updatedAt;
    let assigneeName = activeHelpRequest[0]?.task?.assignee?.username;

    let progressBarCode = 1;

    if (status === SAM_REQUEST_STATUS_TYPE.todo) {
      progressBarCode = 1;
    } else if (status === SAM_REQUEST_STATUS_TYPE.inProgress) {
      progressBarCode = 2;
    }
    if (status === SAM_REQUEST_STATUS_TYPE.complete) {
      progressBarCode = 3;
    }

    //
    return (
      <div className="bitm-status-container">
        <div className="bitm-status">
          <div className="header">
            <div className="title">
              <h4>{statusLocalized}</h4>
            </div>
            <div className="lastupdated-info">
              <div>
                <p>{assigneeName && assigneeName + ", "}</p>
              </div>
              <div>
                <h4>{helpers.getLocalTimeString(updatedAt)}</h4>
              </div>
            </div>
          </div>
          <div className="bitm-progress-bar">
            <div>
              <div
                class={`${progressBarCode >= 1 ? "black-circle" : "circle"} }`}
              >
                <div></div>
              </div>
            </div>
            <div
              className={`separator ${
                progressBarCode > 1 ? "highlighted" : ""
              } }`}
            ></div>
            <div>
              <div
                className={`${
                  progressBarCode >= 2 ? "black-circle" : "circle"
                } }`}
              >
                <div></div>
              </div>
            </div>
            <div
              className={`separator ${
                progressBarCode >= 3 ? "highlighted" : ""
              } }`}
            ></div>
            <div>
              <div
                className={`${
                  progressBarCode > 2 ? "black-circle" : "circle"
                } }`}
              >
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { activeHelpRequest } = this.props;

    const status =
      activeHelpRequest[0]?.task?.status || SAM_REQUEST_STATUS_TYPE.todo;

    return (
      <>
        <section className="bitm-container">
          {/* {this.getCartJSX()} */}
          <div>
            {this.getBITMStatusJSX()}
            <div className="debug-info hidden">
              <p>debug info - Task ID : {activeHelpRequest[0]?.task?.id}</p>
            </div>

            <div className="checkout-cta">
              {status === SAM_REQUEST_STATUS_TYPE.todo && (
                <div className="delete_bag_container">
                  <div
                    className="delete-btn"
                    onClick={() => this.props.onshowDeleteTaskConfirmation()}
                  >
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.do_you_need_assistance_delete_btn_label
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps({ activeHelpRequest, selectedLocale }) {
  return {
    activeHelpRequest,
    selectedLocale,
  };
}

export default connect(mapStateToProps, null)(RequestHelpItems);
