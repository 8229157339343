export const translations_sv_SE = {
  all_products: "Alla produkter",
  go_to_top: "Tillbaka till toppen",
  more_filters: "Fler filter",
  less_filters: "Färre filter",
  sort_by: "Sortera efter",
  clear_all: "Rensa alla",
  colors_available: "<count> tillgängliga färger",
  colors_available_plp: "<count> färger",

  details: "Information",
  how_to_style: "Stylingtips",
  reviews: "Recensioner",
  gallery: "Galleri",
  highlights: "Höjdpunkter",

  select_size: "Välj storlek",
  store_availability: "Kontrollera andra butiker",

  size_guidance: "Storleksguide",
  add_to_bag: "Lägg i varukorgen",
  scan_to_order_online: "Skanna för att beställa online",
  shopping_bag: "Varukorg",
  bag_empty: "Din varukorg är tom",

  max_cart_item_count_reached: "Max. antal produkter uppnått",

  browse_online: (
    <>
      Skaffa något du gillar! <br />
      Sök i denna terminal för att se produkterna i den här butiken eller för
      att beställa produkter online.
    </>
  ),

  order_online: "Beställ online",

  start_browsing: "Utforska butiken",
  total: "Summa ",
  including_tax: "(inkl. moms)",
  checkout: "Kassan",
  delete_bag: "Rensa varukorgen",

  continue_on_this_device: "Betala här",
  checkout_on_your_device: "Betala med telefonen",

  checkout_on_your_device_instruction:
    "Skanna QR-koden för att överföra din varukorg till adidas app eller webbsida på din telefon.",

  employee_checkout: "Medarbetarutcheckning",
  employee_checkout_title: "Utgång med en medarbetare",
  employee_checkout_instruction:
    "Skanna denna QR-kod för att överföra din väska och betala för dina varor.",
  employee_id_instruction: "Ange din medarbetar-ID och uppdatera QR-koden.",
  employee_id_update_qr_code: "Uppdatera QR-koden",
  employe_id_validation_error: "Ogiltigt anställningsnummer",
  change_employee_id: "Byt medarbetar-ID",

  only_cash_on_delivery_option:
    "Obs! Vi accepterar endast kontanter vid leverans som betalningsmetod för beställningar i den här kiosken.",

  yes_over_16_years_of_age: "Jag är över 16 år",

  choose_your_shipping_method: "Välj leveransmetod",
  order_summary: "Beställningsöversikt",
  promo_code: "Rabattkod",
  place_order: "Lägg beställning",
  order_now_pay_on_delivery: "Beställ nu, betala när den levererats",

  read_more_reviews: "Läs fler recensioner",

  edit_address: "Redigera adress",
  save_details: "Spara",
  edit_details: "Redigera information",
  select_pickup_point: "Välj den här platsen",
  select_store: "Välj den här butiken",
  back_to_list: "Tillbaka till produkter",

  sure_to_delete_cart: "Vill du rensa den här varukorgen?",
  cart_delete_message:
    "Alla produkter du har lagt till tas bort och din session avslutas.",
  cancel: "Avbryt",

  add_voucher_heading: "Lägg till en rabatt- eller kampanjkod",
  add_voucher_instruction: "Skiftlägeskänslig, ange koden som den står skriven",
  add_voucher_disclaimer:
    "Vissa produkter kan vara exkluderade från vissa rabattkoder. Läs våra Regler och villkor.",
  apply: "Använd",

  loading: "Laddar ...",
  your_order_in_progress: "Laddar ...",
  your_order_is_placed: "Beställningen klar!",
  session_ends_in: "Sessionen avslutas om:",
  estimated_delivery: "Beräknad leverans:",
  confirmation_email_sent_to: "Ett bekräftelsemejl har skickats till:",
  order_number: "Beställningsnummer:",
  start_new_session: "Starta ny session",
  extend_session: "Fortsätt",

  map_search_no_result:
    "Tyvärr kunde vi inte hitta några resultat. Försök igen.",

  enter_your_details: "Kontaktuppgifter",
  first_name: "Förnamn",
  last_name: "Efternamn",
  street: "Gata",
  house_number: "Gatunummer",
  zip_code: "Postnummer",
  town_city: "Ort",
  email_address: "Mejladress",
  phone_number: "Telefonnummer",

  change_store: "Byt butik",
  change_pickup_point: "Ändra upphämtningsställe",

  search_by_city_dictrict_or_address: "Sök efter stad, område eller adress",

  processing: "Bearbetar ...",

  error_occurred: "Något gick fel",

  retry_place_order: "Försök beställa igen",

  retry_start_checkout: "Prova att betala igen",

  please_select_your_size: "Välj storlek",
  bring_it_to_me: "Hämta den åt mig",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Kalla på personalen",
  do_you_need_assistance_title: "Behöver du hjälp? Vi kommer till dig",
  do_you_need_assistance_instruction:
    "Tryck på knappen så kommer någon från personalen till dig inom kort.",
  do_you_need_assistance_sure_to_delete:
    "Är du säker på att du vill ta bort den här begäran?", //verify
  do_you_need_assistance_delete_btn_label: "Ta bort begäran", //verify

  we_will_bring_it_here: "Eller få den hämtad",
  bring_it_to_me_instruction:
    "Tryck på knappen och stanna nära den här skärmen så hämtar vi dina efterfrågade varor inom 3–5 minuter.",

  bitm_none_active_title: "Hittade du en produkt du gillar?",
  bitm_none_active_instruction:
    "Låt oss hämta den åt dig! Välj de varor och de storlekar du vill ha och tryck på HÄMTA DEN ÅT MIG på den här skärmen eller i adidas app. ",

  bitm_sure_to_delete: "Är du säker på att du vill ta bort den här begäran?", //verify
  bitm_delete_btn_label: "Ta bort begäran",

  bitm_task_todo: "Förfrågan i kö",
  bitm_task_in_progress: "På väg till dig!",
  bitm_task_complete: "Det var allt!",

  bitm_qr_title: "Bläddra medan du väntar",
  bitm_qr_description:
    "Skanna QR-koden för att slutföra din förfrågan i appen. Gå gärna runt i butiken – vi kommer till dig när dina varor är redo.",
  restart_checkout: "Är något fel? Uppdatera sidan",

  try_again: "Försök igen",

  session_expiring_title: "Är du klar?",
  session_expiring_body: "Aktuell session slutar om",

  nearbystore_availability_overlay_title:
    "Kontrollera tillgänglighet i andra butiker",

  available_in_nearby_store: "Tillgängliga storlekar på den här platsen",

  nearby_store_no_sizes_found:
    "Produkten du söker finns för närvarande inte i lager på den här platsen.",

  please_try_selecting_another_store: "Försök med en annan butik.",

  in_stock_at_this_store: "I lager i den här butiken",

  employee_id: "Medarbetar-ID",

  add_employee_id_heading: "Lägg till medarbetar-ID",

  add_employee_id_instruction:
    "Be butikspersonalen om hjälp för att fortsätta.",

  verify_checkout: "Kontrollera dina uppgifter innan du sparar",

  search: "Sök",

  search_result_see_all: "Se alla <search-text>",

  search_no_result: "Tyvärr, inga resultat hittades",

  try_searching_for_something_else: "Prova att söka efter något annat.",

  start_new_search: "Starta ny sökning",

  showing_results_for: "Sökresultat för <search-text>",

  no_available_sizes_found:
    "Det finns inga tillgängliga storlekar för tillfället",

  online: "Online",
  in_store: "I butik",

  tap_to_get_started: "Tryck för att komma igång", //not used
  your_journey_starts_here: "Din resa börjar här",
  app_header_title: "Bläddra här och upptäck de senaste och bästa produkterna",
  landingpage_filter_heading: "Vem handlar du för",
  landingpage_signup_heading: "lås upp det bästa för adidas",
  landingpage_signup_cta:
    "Registrera dig för att handla vårt medlems-exklusiva utrustning",
  landingpage_events_cta: "utforska våra kommande släpp och evenemang",
  landingpage_instore_services_cta: "upptäck våra butikstjänster",
  eventspage_heading: "upptäck våra adidas-evenemang",
  eventspage_sub_heading:
    "Få ut det mesta av din shoppingupplevelse med våra exklusiva evenemang.",

  eventspage_store_event: "Butikshändelse",
  eventspage_event_details: "Evenemangsdetaljer",
  eventspage_raffle_timelines: "Loddtimmar",
  eventspage_raffle_results: "Lottresultat",
  eventspage_sign_up_before: "Registrera dig innan",
  eventspage_scan_and_sign_up: "Scanna och registrera dig",

  instoreservicepage_heading: "upptäck våra butikstjänster",
  instoreservicepage_sub_heading:
    "Få ut det mesta av din shoppingupplevelse med våra exklusiva tjänster.",

  membershippage_title: "GÅ MED I MEDLEMSKAPSPROGRAMMET GRATIS",
  membershippage_body:
    "Börja använda dina adiClub-poäng för att belönas med ännu mer av det du älskar.",
  membershippage_feature_membership_discount: "medlemsrabatter",
  membershippage_feature_freeshipping: "gratis frakt",
  membershippage_feature_birthdaygifts: "födelsedagspresenter",
  membershippage_feature_priorityshopping: "prioritetsshopping",
  membershippage_feature_specialevents: "specialhändelser",

  change_language: "ändra språk",
};
