export const translations_it_IT = {
  all_products: "Tutti i prodotti",
  go_to_top: "Torna all'inizio",
  more_filters: "Altri filtri",
  less_filters: "Meno filtri",
  sort_by: "Ordina per",
  clear_all: "Cancella tutto",
  colors_available: "<count> colori disponibili",
  colors_available_plp: "<count> colori",

  details: "Dettagli",
  how_to_style: "Mostra il tuo stile",
  reviews: "Recensioni",
  gallery: "Gallery",
  highlights: "Highlight",

  select_size: "Seleziona taglia",
  store_availability: "Vedi altri store",

  size_guidance: "Guida taglie",
  add_to_bag: "Aggiungi al carrello",
  scan_to_order_online: "Scansiona e ordina online",
  shopping_bag: "Carrello",
  bag_empty: "Il tuo carrello è vuoto",

  max_cart_item_count_reached: "Numero massimo di articoli raggiunto",

  browse_online: (
    <>
      Scegli ciò che preferisci! <br />
      Usa questa postazione per controllare i prodotti disponibili in negozio o
      per ordinare articoli online.
    </>
  ),

  order_online: "Ordina online",

  start_browsing: "Esplora il negozio",
  total: "Totale ",
  including_tax: "(Incl. Tax)",
  checkout: "Cassa",
  delete_bag: "Svuota carrello",

  continue_on_this_device: "Effettua il pagamento qui",
  checkout_on_your_device: "Effettua il pagamento con lo smartphone",

  checkout_on_your_device_instruction:
    "Inquadra il QR Code per trasferire il tuo carrello su adidas APP o su adidas.it sul tuo telefono.",

  employee_checkout: "Check-out dell'impiegato",
  employee_checkout_title: "Check-out con un impiegato",
  employee_checkout_instruction:
    "Scansiona questo codice QR per trasferire la tua borsa e pagare per i tuoi articoli.",
  employee_id_instruction:
    "Per favore, inserisci il tuo ID impiegato e aggiorna il codice QR.",
  employee_id_update_qr_code: "Aggiorna il codice QR",
  employe_id_validation_error: "ID dipendente non valido",
  change_employee_id: "Cambia ID impiegato",

  only_cash_on_delivery_option:
    "Per gli ordini effettuati tramite postazione, si accetta esclusivamente il pagamento in contanti alla consegna.",

  yes_over_16_years_of_age: "Ho più di 16 anni",

  choose_your_shipping_method: "Scegli il metodo di spedizione",
  order_summary: "Riepilogo ordine",
  promo_code: "Codice promozionale",
  place_order: "Inoltra l'ordine",
  order_now_pay_on_delivery: "Ordina ora, paga alla consegna",

  read_more_reviews: "Leggi altre recensioni",

  edit_address: "Modifica indirizzo",
  save_details: "Salva",
  edit_details: "Modifica dettagli",
  select_pickup_point: "Seleziona questa posizione",
  select_store: "Seleziona questo negozio",
  back_to_list: "Torna ai prodotti",

  sure_to_delete_cart: "Vuoi svuotare il carrello?",
  cart_delete_message:
    "Tutti gli elementi aggiunti verranno eliminati e la sessione terminerà.",
  cancel: "Annulla",

  add_voucher_heading: "Aggiungi un voucher o un codice promozionale",
  add_voucher_instruction: "Fai attenzione a maiuscole e minuscole",
  add_voucher_disclaimer:
    "Alcuni codici promozionali potrebbero non essere validi per determinati articoli. Controlla i nostri Termini e condizioni.",
  apply: "Applica",

  loading: "Caricamento...",
  your_order_in_progress: "Caricamento...",
  your_order_is_placed: "Ordine completato!",
  session_ends_in: "La sessione termina tra:",
  estimated_delivery: "Consegna prevista:",
  confirmation_email_sent_to: "Un'email di conferma è stata inviata a:",
  order_number: "Numero ordine:",
  start_new_session: "Iniziane un'altra",
  extend_session: "Continua",

  map_search_no_result:
    "Siamo spiacenti, non abbiamo trovato alcun risultato. Riprova.",

  enter_your_details: "Informazioni di contatto",
  first_name: "Nome",
  last_name: "Cognome",
  street: "Strada",
  house_number: "Numero",
  zip_code: "Codice postale",
  town_city: "Città",
  email_address: "Indirizzo email",
  phone_number: "Numero di telefono",

  change_store: "Cambia negozio",
  change_pickup_point: "Cambia punto di ritiro",

  search_by_city_dictrict_or_address:
    "Ricerca per città, codice postale o indirizzo",

  processing: "Elaborazione in corso...",

  error_occurred: "Siamo spiacenti, si è verificato un errore",

  retry_place_order: "Riprova a effettuare l'ordine",

  retry_start_checkout: "Riprova ad effettuare il pagamento",

  please_select_your_size: "Seleziona taglia",
  bring_it_to_me: "Bring it to me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Contatta membro staff",
  do_you_need_assistance_title: (
    <>"Hai bisogno di aiuto? Un membro dello staff ti raggiungerà"</>
  ),
  do_you_need_assistance_instruction:
    "Tocca il pulsante e un membro dello staff sarà presto da te.",
  do_you_need_assistance_sure_to_delete:
    "Vuoi davvero eliminare questa richiesta?", //verify
  do_you_need_assistance_delete_btn_label: "Elimina richiesta", //verify

  we_will_bring_it_here: "O aspetta qui",
  bring_it_to_me_instruction:
    "Tocca il pulsante, resta qui vicino e ti porteremo gli articoli richiesti in circa 3-5 minuti.",

  bitm_none_active_title: "Hai trovato qualcosa che ti piace?",
  bitm_none_active_instruction:
    "Te lo portiamo! Seleziona gli articoli e la taglia che desideri, e tocca BRING IT TO ME su questa postazione o su adidas APP. ",

  bitm_sure_to_delete: "Vuoi davvero eliminare questa richiesta?", //verify
  bitm_delete_btn_label: "Elimina richiesta",

  bitm_task_todo: "Richiesta in coda",
  bitm_task_in_progress: "In arrivo!",
  bitm_task_complete: "Fatto!",

  bitm_qr_title: "Continua lo shopping",
  bitm_qr_description:
    "Inquadra il QR code per completare la tua richiesta sull'app. Esplora il negozio, ti raggiungeremo non appena i tuoi articoli saranno pronti.",
  restart_checkout: "Qualcosa non va? Aggiorna la pagina",

  try_again: "Riprova",

  session_expiring_title: "Hai finito?",
  session_expiring_body: "La sessione attuale terminerà tra",

  nearbystore_availability_overlay_title:
    "Verifica la disponibilità in altri negozi",

  available_in_nearby_store: "Taglie disponibili in questa posizione",

  nearby_store_no_sizes_found:
    "L'articolo che stai cercando è attualmente esaurito in questa posizione.",

  please_try_selecting_another_store: "Prova in un altro negozio.",

  in_stock_at_this_store: "Disponibile in negozio",

  employee_id: "ID dipendente",

  add_employee_id_heading: "Aggiungi ID dipendente",

  add_employee_id_instruction:
    "Chiedi aiuto a un membro dello staff per continuare.",

  verify_checkout: "Verifica i tuoi dati prima di effettuare il salvataggio",

  search: "Cerca",

  search_result_see_all: "Vedere tutti <search-text>",

  search_no_result: "Siamo spiacenti, nessun risultato trovato",

  try_searching_for_something_else: "Prova a effettuare una nuova ricerca.",

  start_new_search: "Inizia nuova ricerca",

  showing_results_for: "Risultati ricerca per",

  no_available_sizes_found: "Nessuna taglia disponibile al momento",

  online: "Online",
  in_store: "In negozio",

  tap_to_get_started: "Tocca per iniziare", //not used
  your_journey_starts_here: "Il tuo viaggio inizia qui",
  app_header_title: "Naviga qui e scopri i prodotti più recenti e migliori",
  landingpage_filter_heading: "Per chi stai facendo acquisti",
  landingpage_signup_heading: "Sblocca il meglio di adidas",
  landingpage_signup_cta:
    "Iscriviti per acquistare la nostra attrezzatura esclusiva per i membri",
  landingpage_events_cta: "Esplora le nostre uscite e gli eventi in arrivo",
  landingpage_instore_services_cta: "Scopri i nostri servizi in negozio",
  eventspage_heading: "Scopri gli eventi adidas",
  eventspage_sub_heading:
    "Sfrutta al meglio la tua esperienza di shopping con i nostri eventi esclusivi.",

  eventspage_store_event: "Evento in negozio",
  eventspage_event_details: "Dettagli dell'evento",
  eventspage_raffle_timelines: "Cronologie delle estrazioni a sorte",
  eventspage_raffle_results: "Risultati delle estrazioni a sorte",
  eventspage_sign_up_before: "Iscriviti prima del",
  eventspage_scan_and_sign_up: "Scansiona e iscriviti",

  instoreservicepage_heading: "Scopri i nostri servizi in negozio",
  instoreservicepage_sub_heading:
    "Sfrutta al meglio la tua esperienza di shopping con i nostri servizi esclusivi.",

  membershippage_title: "DIVENTA MEMBRO GRATUITAMENTE",
  membershippage_body:
    "Inizia a spendere i tuoi punti adiClub per essere ricompensato ancora di più per ciò che ami.",
  membershippage_feature_membership_discount: "sconti per i membri",
  membershippage_feature_freeshipping: "spedizione gratuita",
  membershippage_feature_birthdaygifts: "regali di compleanno",
  membershippage_feature_priorityshopping: "acquisti prioritari",
  membershippage_feature_specialevents: "eventi speciali",

  change_language: "cambia lingua",
};
