import React, { Component } from "react";
import { connect } from "react-redux";

import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { GlParagraph, GL_PARAGRAPH_FONT_SIZE } from "@adl/foundation";

import { setUserActionTime } from "../../../../../../../../actions/pageActions";

import "./MapView.css";
import { MAP_ZOOM } from "../../SelectAddressView";

class MapView extends Component {
  state = {
    map: {},

    markerMap: {},
    isWindowOpen: {},
  };

  componentDidMount() {
    const { locations, handleMapCenterChange } = this.props;

    if (locations && locations.length > 0) {
      handleMapCenterChange(
        parseFloat(locations[0].coordinates.lat),
        parseFloat(locations[0].coordinates.long)
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedAddress, locations, handleMapCenterChange } = this.props;

    if (selectedAddress && prevProps.selectedAddress !== selectedAddress) {
      handleMapCenterChange(
        parseFloat(selectedAddress.coordinates.lat),
        parseFloat(selectedAddress.coordinates.long)
      );
    }

    if (
      locations &&
      prevProps.locations !== locations &&
      locations.length > 0
    ) {
      handleMapCenterChange(
        parseFloat(locations[0].coordinates.lat),
        parseFloat(locations[0].coordinates.long)
      );
    }
  }

  onLoad = (map) => {
    this.setState({ map });
  };

  onMarkerLoad = (marker, place) => {
    this.setState({
      markerMap: {
        [place.id]: marker,
      },
    });
  };

  onMarkerClick = (location) => {
    const { onLocationSelect, handleMapInfoWindowOpen, handleMapZoomChange } =
      this.props;
    const isSetAddress = false;

    handleMapInfoWindowOpen(location);
    handleMapZoomChange(MAP_ZOOM.selected);

    onLocationSelect(location, isSetAddress);
  };

  onMarkerCloseClick = (location) => {
    const { handleMapInfoWindowOpen, handleMapZoomChange } = this.props;

    handleMapInfoWindowOpen(location);
    handleMapZoomChange(MAP_ZOOM.defualt);
  };

  onDragStart = () => {
    this.props.setUserActionTime({
      epoch: Date.now(),
    });
  };

  onDragEnd = () => {
    const {
      getAdidasLocationsDataFromMap,
      mapZoom,
      handleMapZoomChange,
      handleMapCenterChange,
    } = this.props;
    const { map } = this.state;

    const _currentMapCenter = map.getCenter();
    const _latitude = _currentMapCenter.lat();
    const _longitude = _currentMapCenter.lng();

    getAdidasLocationsDataFromMap(_latitude, _longitude);

    if (map.zoom && map.zoom !== mapZoom) {
      handleMapZoomChange(map.zoom);
    }

    handleMapCenterChange(_latitude, _longitude);
  };

  onZoomChanged = () => {
    const { map } = this.state;
    const { handleMapZoomChange, mapZoom } = this.props;

    if (map.zoom && map.zoom !== mapZoom) {
      handleMapZoomChange(map.zoom);
    }
  };

  render() {
    let { locations, mapZoom, openedMapInfoWindow, mapCenter } = this.props;
    const { markerMap } = this.state;

    return (
      <>
        {locations && mapCenter && (
          <Map
            locations={locations}
            openedMapInfoWindow={openedMapInfoWindow}
            markerMap={markerMap}
            mapCenter={mapCenter}
            mapZoom={mapZoom}
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}
            onLoad={this.onLoad}
            onZoomChanged={this.onZoomChanged}
            onMarkerLoad={this.onMarkerLoad}
            onMarkerClick={this.onMarkerClick}
            onMarkerCloseClick={this.onMarkerCloseClick}
          />
        )}
      </>
    );
  }
}

export const Map = ({
  locations,
  openedMapInfoWindow,
  onDragStart,
  onDragEnd,
  onLoad,
  onZoomChanged,
  onMarkerLoad,
  onMarkerClick,
  onMarkerCloseClick,
  onBoundsChanged,
  markerMap,
  mapCenter,
  mapZoom,
}) => {
  const _mapOptions = {
    /*
      For available options please refer:
      https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
     */

    // scrollwheel: false,
    fullscreenControl: false,
    zoomControl: false,
    // gestureHandling: 'none',
    streetViewControl: false,
    mapTypeControl: false,
    zoom: mapZoom,
    center: {
      lat: mapCenter.latitude,
      lng: mapCenter.longitude,
    },
    clickableIcons: false,
    keyboardShortcuts: false,
    disableDefaultUI: true,
  };

  const _containerStyle = {
    width: "100%",
  };

  return (
    <>
      <GoogleMap
        mapContainerClassName="map"
        mapContainerStyle={_containerStyle}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onLoad={(map) => onLoad(map)}
        options={_mapOptions}
        onZoomChanged={onZoomChanged}
        onBoundsChanged={onBoundsChanged}
      >
        {locations.map((location) => {
          return (
            <Marker
              key={location?.id}
              onLoad={(marker) => onMarkerLoad(marker, location)}
              position={{
                lat: parseFloat(location.coordinates.lat),
                lng: parseFloat(location.coordinates.long),
              }}
              onClick={() => onMarkerClick(location)}
            >
              {openedMapInfoWindow[location.id] && (
                <InfoWindow
                  anchor={markerMap[location.id]}
                  onClick={() => onMarkerClick(location)}
                  onCloseClick={() => onMarkerCloseClick(location)}
                  options={{ padding: 0 }}
                  id="info-window"
                >
                  <div className="map-info-window">
                    <GlParagraph
                      className="store-name"
                      fontSize={GL_PARAGRAPH_FONT_SIZE.l}
                    >
                      {location.name}
                    </GlParagraph>
                    <GlParagraph
                      className="store-address"
                      fontSize={GL_PARAGRAPH_FONT_SIZE.m}
                    >
                      {location.street}
                    </GlParagraph>
                    <GlParagraph className="store-address">{`${location.postal_code} ${location.city}`}</GlParagraph>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setUserActionTime: (val) => dispatch(setUserActionTime(val)),
  };
}

export default connect(null, mapDispatchToProps)(MapView);
