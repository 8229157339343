import React, { Component } from "react";
import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlIcon,
  GlParagraph,
} from "@adl/foundation";

import "./OrderSummary.css";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../../../utils/translationCopies";

// import * as checkoutAPI from "../../../../API/checkoutAPI";

class OrderSummary extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  getOrderSummaryLines = () => {
    let { checkoutObject } = this.props;

    return (
      <>
        {checkoutObject?.order_summary?.lines?.map((item) => (
          <div key={item?.title + item?.display_value} className="row">
            <GlParagraph className="title">{item?.title}</GlParagraph>
            <GlParagraph>{item?.display_value}</GlParagraph>
          </div>
        ))}
      </>
    );
  };

  render() {
    let { checkoutObject, enablePlaceOrderButton } = this.props;

    const ENABLE_EMPLOYEE_ID = true;

    return (
      <>
        <section className="order-summary">
          <div className="header">
            <h4>
              {getTranslation(
                this.props.selectedLocale,
                TRANSLATIONS_KEY_LIST.order_summary
              )}
            </h4>
            {/* <Link to={`/`}>
              <GlIcon name="close" />
            </Link> */}
          </div>
          {checkoutObject && (
            <>
              <div className="content">{this.getOrderSummaryLines()}</div>

              <div className="footer">
                <div
                  className="promo-code"
                  onClick={() => {
                    this.props.onVoucherAddClick();
                  }}
                >
                  <div className="label">
                    <GlParagraph>
                      {getTranslation(
                        this.props.selectedLocale,
                        TRANSLATIONS_KEY_LIST.promo_code
                      )}
                    </GlParagraph>
                  </div>
                  <div>
                    <GlIcon name="plus" />
                  </div>
                </div>

                {ENABLE_EMPLOYEE_ID && (
                  <div
                    className="employee-id"
                    onClick={() => {
                      this.props.onEmployeeIdAdd();
                    }}
                  >
                    <div className="label">
                      <GlParagraph>
                        {getTranslation(
                          this.props.selectedLocale,
                          TRANSLATIONS_KEY_LIST.employee_id
                        )}
                      </GlParagraph>
                    </div>
                    <div>
                      <GlIcon name="plus" />
                    </div>
                  </div>
                )}

                <div className="row total-amount">
                  <div className="">
                    {/* <h4>{checkoutObject?.order_summary?.total_price?.title}</h4> */}
                    {checkoutObject?.order_summary?.total_price?.title}
                  </div>
                  <div>
                    {`${checkoutObject?.order_summary?.total_price?.display_value}`}
                  </div>
                </div>
                <div className="row">
                  <GlParagraph>
                    {checkoutObject?.order_summary?.membership_points?.title}
                  </GlParagraph>
                  <GlParagraph>
                    {
                      checkoutObject?.order_summary?.membership_points
                        ?.display_value
                    }
                  </GlParagraph>
                </div>

                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  disabled={!enablePlaceOrderButton}
                  onClick={() => {
                    this.props.endCheckout();
                    this.props.setShowOrderCreationModal(true);
                  }}
                >
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.place_order
                  )}
                </GlButton>

                <div className="row light">
                  <GlParagraph>
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.order_now_pay_on_delivery
                    )}
                  </GlParagraph>
                </div>
                {/* <div className="tertiary-btn">
                  <GlButton
                    aria-label="label"
                    variant={GL_BUTTON_VARIANT.tertiary}
                  >
                    What's cash on delivery ?
                  </GlButton>
                </div> */}
              </div>
            </>
          )}
        </section>
      </>
    );
  }
}

export default OrderSummary;
