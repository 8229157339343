import React, { Component } from "react";

import { GlStarRating, GlLoader } from "@adl/foundation";
import { InView } from "react-intersection-observer";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

import "./RatingsReviews.css";

let handleSetTimeoutResetTimer;

class RatingsReviews extends Component {
  state = {
    shouldShowLoadMore: false,
  };

  setShouldShowLoadMore = (val) => {
    this.setState({
      shouldShowLoadMore: val,
    });
  };

  componentDidMount() {
    this.setShouldShowLoadMore(true);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (
      JSON.stringify(prevProps.productReviews) !==
      JSON.stringify(this.props.productReviews)
    ) {
      this.setShouldShowLoadMore(true);
    }
  }

  initResetIdleTimeout = () => {
    if (handleSetTimeoutResetTimer) {
      clearTimeout(handleSetTimeoutResetTimer);
    }
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { productReviewStatistics, productReviews, productReviewsNextSet_URL } =
      this.props;

    let { shouldShowLoadMore } = this.state;

    return (
      <>
        <div className="rating-reviews-container">
          {(productReviewStatistics?.average_overall_rating ||
            productReviews.length > 0) && (
            <div className="heading">
              <h4>{this.getTranslation(TRANSLATIONS_KEY_LIST.reviews)}</h4>
            </div>
          )}

          <div className="row">
            {productReviewStatistics?.average_overall_rating > 0 && (
              <div className="summary">
                <div>
                  <p>
                    {productReviewStatistics?.total_review_count}{" "}
                    {this.getTranslation(TRANSLATIONS_KEY_LIST.reviews)}
                  </p>
                </div>
                <div className="star-ratings">
                  <div>
                    <GlStarRating
                      rating={productReviewStatistics?.average_overall_rating}
                    />
                  </div>

                  <div className="avg-rating-label">
                    {productReviewStatistics?.average_overall_rating?.toFixed(
                      1
                    )}
                  </div>
                </div>
                <div className="ratings_averages">
                  {productReviewStatistics?.ratings_averages.map((x) => (
                    <div key={x.id + x.average_rating}>
                      <div>
                        <GlStarRating rating={x.average_rating} />
                        {}
                      </div>
                      <div>{x.id}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div
              className={`reviews ${
                productReviewStatistics?.average_overall_rating
                  ? "review_offset"
                  : ""
              }`}
            >
              {productReviews?.map((x) => (
                <div key={x.title + x.text} className="review-item">
                  <InView
                    as="div"
                    onChange={(inView, entry) => {
                      if (inView) {
                        this.initResetIdleTimeout();
                      }
                    }}
                  ></InView>
                  <div className="reviews__title">
                    <p>{x.title}</p>
                  </div>
                  <div className="reviews__star-rating">
                    <div>
                      <GlStarRating rating={x.rating} />
                    </div>
                  </div>
                  <div className="reviews__notes">
                    <p>{x.text}</p>
                  </div>

                  <div className="reviews__more-details">
                    <p>{`${x.username} | ${new Date(
                      x.creation_date
                    ).toLocaleString()} ${
                      x.verified_buyer ? " | verified_buyer" : ""
                    }`}</p>
                  </div>
                </div>
              ))}
              <div
                onClick={() => {
                  this.setShouldShowLoadMore(false);
                  this.props.onFetchNextSetOfReviews();
                }}
              >
                {shouldShowLoadMore && productReviewsNextSet_URL?.href ? (
                  <p className="read-more">
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.read_more_reviews
                    )}
                  </p>
                ) : (
                  <>{productReviewsNextSet_URL && <GlLoader lang="en-GB" />}</>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RatingsReviews;
