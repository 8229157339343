import React, { Component } from "react";
import { connect } from "react-redux";

import ModalView from "../../../../../../../ModalView/ModalView";

import "./NearbyStoreAvailabilityModal.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../../../utils/translationCopies";
import { clearInputWithKeyboardValue } from "../../../../../../../../actions/inputWithKeyboardActions";

import AvailabilityInfo from "./components/AvailabilityInfo/AvailabilityInfo";
import StoresListMap from "./components/StoresListMap/StoresListMap";

import { API } from "../../../../../../../../API/index";

const ENABLE_DEBUG_COMPONENT = false;

class NearbyStoreAvailabilityModal extends Component {
  state = {
    SSMID: this.props.SSMID,
    productId: this.props.productId,
    availabilityinfoFetchInProgress: false,
    locationsList: null,
    loading: false,
  };

  componentDidMount() {
    this.getNearbyStoresBySSMID(this.props.SSMID, 10);
    this.props.setInputWithKeyboardValue(null, {});
  }

  getNearbyStoresBySSMID = (ssmid, distance) => {
    this.setState({
      loading: true,
    });

    const _distance = this.getDistance(distance);

    API.ssmAPI
      .getNearbyStoresForSSMId(ssmid, `${_distance}km`)
      .then(({ response }) => {
        this.setState({
          locationsList: response.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          locationsList: [],
          loading: false,
        });
      });
  };

  getNearbyStoresByGeoCoordinates = (lat, lng, distance) => {
    this.setState({
      loading: true,
    });

    const _distance = this.getDistance(distance);

    API.ssmAPI
      .getNearbyStoresForGeoCoordinates(lat, lng, `${_distance}km`)
      .then(({ response }) => {
        this.setState({
          locationsList: response.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          locationsList: [],
          loading: false,
        });
      });
  };

  getDistance = (distance) => {
    const defaultDistance =
      this.props?.appSettings?.value?.nearbyStoreSearchMinRangeInKm || 30;

    let _distance;

    if (distance < defaultDistance || !distance) {
      _distance = defaultDistance;
    } else {
      _distance = distance;
    }

    return _distance;
  };

  setSSMID = (val) => {
    this.setState({ SSMID: val });
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { show } = this.props;
    const { locationsList, loading } = this.state;
    const diplayAvailabilityInfo = locationsList?.content?.length > 0;

    return (
      <>
        <div>
          <ModalView
            showCloseBtn={true}
            show={show}
            onClose={() => {
              this.props.onClose();
            }}
            className="modal-centered"
          >
            <div className="store-availability_container">
              <>
                <div className="store-info-view">
                  <h4>
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.nearbystore_availability_overlay_title
                    )}
                  </h4>
                  {locationsList && (
                    <StoresListMap
                      defaultSSMID={this.props.SSMID}
                      storeList={locationsList}
                      handleSSMIDChange={this.setSSMID}
                      getNearbyStoresBySSMID={this.getNearbyStoresBySSMID}
                      getNearbyStoresByGeoCoordinates={
                        this.getNearbyStoresByGeoCoordinates
                      }
                      loading={loading}
                      ENABLE_DEBUG_COMPONENT={ENABLE_DEBUG_COMPONENT}
                      resetTimoutTimer={this.props.resetTimoutTimer}
                    />
                  )}
                </div>

                <div className="availability-info-view">
                  {diplayAvailabilityInfo && (
                    <div className="size-grid">
                      <p className="bold">
                        {this.getTranslation(
                          TRANSLATIONS_KEY_LIST.available_in_nearby_store
                        )}
                      </p>

                      <div>
                        <AvailabilityInfo
                          sizeCountryCode={this.props.sizeCountryCode}
                          selectedLocale={this.props.selectedLocale}
                          productId={this.props.productId}
                          SSMID={this.state.SSMID}
                          ENABLE_DEBUG_COMPONENT={ENABLE_DEBUG_COMPONENT}
                        ></AvailabilityInfo>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          </ModalView>
        </div>
      </>
    );
  }
}

function mapStateToProps({ appSettings }) {
  return {
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(clearInputWithKeyboardValue(id, val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NearbyStoreAvailabilityModal);
