import { setup } from "axios-cache-adapter";
import { REMOTE_BFF_API_URL } from "../constants";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

const appSettingsAPI = require("../AppSettingsAPI/appSettingsAPI");

const databaseName = "ssk-api-data-cache";

class APIBase {
  requestHeaders;
  axiosApi;
  localforageStore;
  initInProgress;
  static apiKey;

  constructor() {
    console.log("constructor - APIBase");

    this.localforageStore = localforage.createInstance({
      // List of drivers used
      driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        memoryDriver._driver,
      ],
      // Prefix all storage keys to prevent conflicts
      name: databaseName,
    });
  }

  deleteProductsDatabase = () => {
    console.log("clearing storage..");

    const DBDeleteRequest = window.indexedDB.deleteDatabase(databaseName);

    DBDeleteRequest.onerror = (event) => {
      console.error("Error deleting indexedDB database - " + databaseName);
    };

    DBDeleteRequest.onsuccess = (event) => {
      console.log("Database deleted indexedDB successfully - " + databaseName);
      console.log(event.result);
    };
  };

  initConfig = async () => {
    if (this.initInProgress === true) {
      return;
    }

    this.initInProgress = true;

    localforage.defineDriver(memoryDriver);

    let appSettingsResponse = await appSettingsAPI.getAppSettings();

    let appSettings;

    if (appSettingsResponse.response?.data) {
      appSettings = appSettingsResponse.response?.data;

      this.requestHeaders = {
        headers: {
          "Content-Type": "application/json",
          "x-store-id":
            appSettings &&
            appSettings.value &&
            appSettings.value.player &&
            appSettings.value.player._SSMID
              ? appSettings.value.player._SSMID
              : "",
          "x-device-info":
            appSettings &&
            appSettings.value &&
            appSettings.value.player &&
            appSettings.value.player.x_device_info
              ? appSettings.value.player.x_device_info
              : "",
        },
      };

      if (APIBase?.apiKey?.length > 0) {
        this.requestHeaders.headers["api-key"] = `${APIBase.apiKey}`;
      }

      let remoteBackendURL;

      switch (appSettings?.backend_hosting_environment) {
        case "local":
          remoteBackendURL = REMOTE_BFF_API_URL.local;
          break;
        case "staging":
          remoteBackendURL = REMOTE_BFF_API_URL.staging;
          break;
        case "production":
          remoteBackendURL = REMOTE_BFF_API_URL.production;
          break;
        default:
          remoteBackendURL = REMOTE_BFF_API_URL.development;
      }

      this.axiosApi = setup({
        baseURL: remoteBackendURL,
        cache: {
          maxAge: 0, // set cache time to 60 minutes
          exclude: { query: false }, // cache requests with query parameters
          store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
          clearOnStale: true,
        },
        headers: {
          ...this.requestHeaders.headers,
        },
      });

      this.initInProgress = false;
      return Promise.resolve(this.requestHeaders);
    } else {
      this.initInProgress = false;

      return Promise.reject("API Base initialization error");
    }
  };
}

export default APIBase;
