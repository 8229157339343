import React, { Component } from "react";
import { connect } from "react-redux";
import { GlButton, GL_BUTTON_THEME, GL_BUTTON_VARIANT } from "@adl/foundation";

import Timer from "react-compound-timer";
import ModalView from "../../ModalView/ModalView";

import { setInputWithKeyboardId } from "../../../actions/inputWithKeyboardActions";

import { MENU_ITEM_NAMES } from "../../../actions/sidebarMenuActions";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../utils/translationCopies";

import "./Timeout.css";

// import * as analytics from "../../../analytics";

class Timeout extends Component {
  constructor(props) {
    super(props);
    this.handle_timerStart = React.createRef();
    this.handle_timerReset = React.createRef();
  }

  state = {
    showTimeoutOverlay: false,
    timeoutDuration: null,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        timeoutDuration:
          parseInt(
            this.props?.appSettings?.value?.idle_timeout?.duration_in_sec || 60
          ) * 1000,
        displayTimer:
          this.props?.appSettings?.value?.idle_timeout?.display_timer_in_ui ||
          false,
      });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.UserLastActivityAtTime?.epoch !==
      this.props?.UserLastActivityAtTime?.epoch
    ) {
      this.onTimerReset();
    }
  }

  onTimerReset = (e) => {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ showTimeoutOverlay: false });

    this.handle_timerReset?.current?.click();
    setTimeout(() => {
      this.handle_timerStart?.current?.click();
    }, 500);
  };

  onTimeoutReached = () => {
    // this will close keyboard if open
    this.props.setInputWithKeyboardId("");

    // if its checkout page then reset the timer (do not refresh or reload app)
    if (this.props.sideMenu === MENU_ITEM_NAMES.checkout) {
      this.onTimerReset();
      return;
    } else {
      if (this.props?.UserLastActivityAtTime?.epoch) {
        this.setState({ showTimeoutOverlay: true });
      }
    }
  };

  refreshSessionByReloadingPage = () => {
    //just reload the page - it will automatically clear everything

    //TODO session time need enhancement
    // analytics.endSession({
    //   hostname: this.props.appSettings?.value?.player?.hostname,
    // });

    if (window) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        window.location.reload();
      }, 100);
      return;
    }
  };

  gotoHome = () => {
    this.refreshSessionByReloadingPage();
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { showTimeoutOverlay, timeoutDuration, displayTimer } = this.state;

    return (
      <>
        <div className={`timeout-counter ${displayTimer ? "" : "hidden"}`}>
          {timeoutDuration > 0 && (
            <Timer
              initialTime={timeoutDuration}
              direction="backward"
              checkpoints={[
                {
                  time: 0,
                  callback: () => {
                    this.onTimeoutReached();
                  },
                },
              ]}
            >
              {({ start, reset }) => (
                <>
                  Idle countdown timer : <Timer.Minutes />:<Timer.Seconds />
                  <br />
                  <div onClick={(e) => e.stopPropagation()}>
                    <button
                      onClick={reset}
                      ref={this.handle_timerReset}
                    ></button>
                    <button
                      onClick={start}
                      ref={this.handle_timerStart}
                    ></button>
                  </div>
                </>
              )}
            </Timer>
          )}
        </div>

        <div>
          <ModalView
            showCloseBtn={true}
            show={showTimeoutOverlay}
            onClose={(e) => this.onTimerReset(e)}
            className="modal-centered"
          >
            <div className="timeout-confirmation__content">
              <div className="upper">
                <div className="title">
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.session_expiring_title
                  )}
                </div>

                <div className="body">
                  <Timer
                    initialTime={10000}
                    direction="backward"
                    checkpoints={[
                      {
                        time: 0,
                        callback: () => {
                          this.refreshSessionByReloadingPage();
                        },
                      },
                    ]}
                  >
                    {({ start, reset }) => (
                      <>
                        <p>
                          {`${this.getTranslation(
                            TRANSLATIONS_KEY_LIST.session_expiring_body
                          )}`}{" "}
                          <Timer.Seconds />
                        </p>
                      </>
                    )}
                  </Timer>
                </div>
              </div>

              <div className="lower">
                <div></div>

                <div className="button-container">
                  <div>
                    <GlButton
                      aria-label="label"
                      theme={GL_BUTTON_THEME.light}
                      variant={GL_BUTTON_VARIANT.secondary}
                      onClick={() => this.onTimerReset()}
                      icon={null}
                    >
                      {this.getTranslation(
                        TRANSLATIONS_KEY_LIST.extend_session
                      )}
                    </GlButton>
                  </div>

                  <div>
                    <GlButton
                      aria-label="label"
                      theme={GL_BUTTON_THEME.dark}
                      variant={GL_BUTTON_VARIANT.secondary}
                      onClick={() => this.gotoHome()}
                      icon={null}
                    >
                      {this.getTranslation(
                        TRANSLATIONS_KEY_LIST.start_new_session
                      )}
                    </GlButton>
                  </div>
                </div>
              </div>
            </div>
          </ModalView>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  sideMenu,
  currentPage,
  UserLastActivityAtTime,
  appSettings,
}) {
  return {
    selectedLocale,
    sideMenu,
    currentPage,
    UserLastActivityAtTime,
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardId: (val) => dispatch(setInputWithKeyboardId(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeout);
