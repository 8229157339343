import React, { Component } from "react";

import { connect } from "react-redux";

import "./HomeView.css";

import ProductListView from "../ProductListView/ProductListView";
import LandingPage from "../LandingPage/LandingPage";
import Header from "../Header/Header";
import EventsListView from "../EventsListView/EventsListView";
import VideoView from "../VideoView/VideoView";
import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";
import ServicesListView from "../ServicesListView/ServicesListView";
import ServiceOverlayView from "../ServicesListView/components/ServiceOverlayView";
import MembershipBanner from "../MembershipBanner/MembershipBanner";

import { getMedia } from "../../utils/media";

import TapAnimation from "../TapAnimation/TapAnimation";
import { Events, animateScroll as scroll } from "react-scroll";
import { API } from "../../API/index";
// import membershipApiJsonData from "../../data/SAMPLE_MEMBERSHIP_DATA_EN.json";
import MembershipBannerModel from "../../models/MembershipBannerModel";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

class HomeView extends Component {
  state = {
    rootProductFilterSelection: null,
    showTapToGetStartedAnimation: false,
    animationData: null,
    animationJson: null,
  };

  initializeReactScroll = () => {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });
  };

  scrollToTop = () => {
    // scroll.scrollToTop();

    // because now plp is a part of bigger page i.e. landing page, therefore now we scroll the landing page container
    scroll.scrollTo(0, {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "home-view",
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedTranslationLocale !==
      this.props.selectedTranslationLocale
    ) {
      this.fetchMembershipUIdata();
    }
  }

  getTranslation(key) {
    return translateString(key);
  }

  componentDidMount() {
    this.initializeReactScroll();

    this.setState({
      showTapToGetStartedAnimation: true,
    });

    this.fetchMembershipUIdata();

    setTimeout(() => {
      const lottieAnimationUrl =
        this.props.appSettings?.value?.tap_to_get_started_animation
          ?.lottie_animation_url;
      const lottieAnimationEnabledInLandingPage =
        this.props.appSettings?.value?.tap_to_get_started_animation?.enabled;

      const interactionVideoScreenEnabled =
        this.props.appSettings?.value?.interaction_video_screen?.enabled ||
        false;

      if (
        lottieAnimationEnabledInLandingPage &&
        !interactionVideoScreenEnabled
      ) {
        this.setState({ showTapToGetStartedAnimation: true });
        this.downloadLottieAnimation(lottieAnimationUrl);
      } else {
        this.setState({ showTapToGetStartedAnimation: false });
      }
    }, 50);
  }

  fetchMembershipUIdata = () => {
    const locale =
      this.props.selectedTranslationLocale ||
      this.props.appSettings?.value?.default_locale ||
      "en_US";

    let qrCodeUrl =
      this.props.appSettings?.value?.landing_page?.membership_page?.qr_code_url;

    if (!qrCodeUrl || qrCodeUrl?.trim()?.length < 1) {
      qrCodeUrl = "https://www.adidas.com/adiclub";
    }

    const setMembershipData = (apiData) => {
      if (!apiData) {
        const title = this.getTranslation(
          TRANSLATIONS_KEY_LIST.membershippage_title
        );

        const description = this.getTranslation(
          TRANSLATIONS_KEY_LIST.membershippage_body
        );

        let features =
          this.props.appSettings?.value?.landing_page?.membership_page?.features_list?.filter(
            (x) => x?.visible === true
          );

        const headerBackgroundImage =
          this.props.appSettings?.value?.landing_page?.membership_page?.header
            ?.image_url;

        const footerBackgroundImage =
          this.props.appSettings?.value?.landing_page?.membership_page?.footer
            ?.image_url;

        console.log("#### features", features);

        const membershipBannerData = new MembershipBannerModel(
          title,
          qrCodeUrl,
          headerBackgroundImage,
          description,
          [
            {
              rewards: features,
            },
          ],
          footerBackgroundImage
        );

        this.setState({
          membershipUIdata: membershipBannerData,
        });
      } else {
        const membershipBannerData = new MembershipBannerModel(
          apiData.title,
          qrCodeUrl,
          apiData.background_image_asset_url,
          apiData.description,
          apiData.tiers,
          apiData.logged_out_background_image_asset_url
        );

        this.setState({
          membershipUIdata: membershipBannerData,
        });
      }
    };

    let useApiData =
      this.props.appSettings?.value?.landing_page?.membership_page
        ?.use_api_data;

    console.log("### Membership data useApiData", useApiData);

    if (locale && useApiData) {
      API.membershipAPI.getMembershipUIdata(locale).then((x) => {
        if (x) {
          console.log(
            "### Membership data, received response from membership API, response: ",
            x
          );
          setMembershipData(x);
        } else {
          console.log(
            "### Membership data not received from API hence using fallback from appsettings "
          );
          setMembershipData(null);
        }
      });
    } else if (locale) {
      setTimeout(() => {
        setMembershipData(null);
      }, 500);
    }
  };

  downloadLottieAnimation = (animationUrl) => {
    if (!animationUrl) return;

    console.log("----- FETCHING ANIMATION DATA START-----");

    getMedia(animationUrl).then(
      (result) => {
        let animationData = {
          url: animationUrl,
          blob: null,
        };

        var _blob = window.URL.createObjectURL(result.response);

        animationData.blob = _blob;

        this.setState({
          animationData: animationData,
        });
        console.log("----- FETCHING ANIMATION DATA END-----");
      },
      (Error) => {
        console.log(Error);
      }
    );
  };

  getPageView = (pageName) => {
    switch (pageName) {
      case PAGE_NAMES.plp:
        return (
          <ProductListView
            resetTimoutTimer={this.props.resetTimoutTimer}
            rootProductFilterSelection={this.state.rootProductFilterSelection}
          ></ProductListView>
        );
      case PAGE_NAMES.pdp:
        return (
          <ProductListView
            resetTimoutTimer={this.props.resetTimoutTimer}
          ></ProductListView>
        );
      case PAGE_NAMES.landingPage:
        this.scrollToTop();
        return (
          <LandingPage
            onRootProductFilterSelected={(filterObject) =>
              this.setState({ rootProductFilterSelection: filterObject })
            }
          ></LandingPage>
        );

      case PAGE_NAMES.eventsPage:
        this.scrollToTop();
        return <EventsListView></EventsListView>;

      case PAGE_NAMES.eventDetailsPage:
        return <EventsListView></EventsListView>;

      case PAGE_NAMES.servicesPage:
        this.scrollToTop();
        return <ServicesListView></ServicesListView>;

      case PAGE_NAMES.serviceDetailsPage:
        return <ServiceOverlayView></ServiceOverlayView>;

      case PAGE_NAMES.membershipPage:
        return (
          <MembershipBanner
            data={this.state?.membershipUIdata}
          ></MembershipBanner>
        );
      default:
        return <>"No route found"</>;
    }
  };

  render() {
    const interactionVideoScreenEnabled =
      this.props.appSettings?.value?.interaction_video_screen?.enabled || false;

    return (
      <div
        className="home-view"
        id="home-view"
        onScroll={() => {
          if (window._setUserActionTime) window._setUserActionTime();
        }}
      >
        <>
          {[
            PAGE_NAMES.eventsPage,
            PAGE_NAMES.landingPage,
            PAGE_NAMES.servicesPage,
            PAGE_NAMES.plp,
          ].includes(this.props.currentPage) && (
            <>
              <div className="plp-video-container">
                {window.screen.height > window.screen.width && (
                  <VideoView
                    media={this.props.appSettings?.value?.media}
                  ></VideoView>
                )}
              </div>
              <div>
                <Header></Header>
              </div>
            </>
          )}
          {this.getPageView(this.props.currentPage)}
        </>

        {this.state.showTapToGetStartedAnimation && (
          <div
            class={`fullscreen-overlay-container ${
              interactionVideoScreenEnabled ? "" : "dark-background-overlay"
            }`}
            onClick={() =>
              this.setState({ showTapToGetStartedAnimation: false })
            }
          >
            <TapAnimation></TapAnimation>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  selectedTranslationLocale,
  currentPage,
  appSettings,
  session,
}) {
  return {
    selectedLocale,
    selectedTranslationLocale,
    currentPage,

    appSettings,
    session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
