import React, { Component } from "react";

import { API } from "../../API/index";

import { connect } from "react-redux";

import { setExternalPDPTrigger } from "../../actions/productActions";

let source = "";
let epochSinceSourceError = -1;
let isDisconnected = false;

class BarCodeSSE extends Component {
  state = {
    barcodeData: null,
    articleId: null,
    ignoreInput: false,
  };

  componentDidMount() {
    this.initializeSSE();
  }

  initializeSSE = () => {
    this.connectBarcodeSSE();
    setInterval(this.reinitializeIfRequired, 2000);
  };

  reinitializeIfRequired = () => {
    // console.log("reinitializeIfRequired called !");
    let sourceErrorSince =
      Math.round(Date.now() / 1000) - epochSinceSourceError;
    //console.log(sourceErrorSince);
    if (isDisconnected && sourceErrorSince > 3) {
      console.log(
        "No more source error, and disconnected since over 10 seconds hence re-initializing now"
      );
      this.connectBarcodeSSE();
    }
  };

  connectBarcodeSSE = () => {
    source = API.eventSourceHardwareIntegration;

    source.onopen = function (e) {
      console.log("onopen");
      isDisconnected = false;
    };

    source.onerror = function (e) {
      console.log(
        "Event source connection error detected at " +
          Math.round(Date.now() / 1000)
      );

      //if(!isDisconnected)
      epochSinceSourceError = Math.round(Date.now() / 1000);
      isDisconnected = true;
    };

    source.onsuccess = (e) => {
      console.log("connected to SSE !");
    };

    // on msg receive from server
    source.onmessage = (event) => {
      console.log("Device message received !");

      let _ean = JSON.parse(event.data);

      console.log(_ean?.ean);

      if (_ean?.ean?.length > 1) {
        if (true) {
          this.setState({ barcodeData: _ean?.ean });
          this.setState({ articleId: null });

          if (this.state.ignoreInput === false) {
            this.setState({ ignoreInput: true });

            setTimeout(() => {
              this.setState({ ignoreInput: false });
            }, 3000);

            console.log("....will make API call here");
            API.samAPI.getProductIdFromEAN(_ean?.ean).then((x) => {
              const articleId = x?.data?.data?.articleNumber;

              console.log("Article ID found **", articleId);
              this.setState({ articleId: articleId });
              if (articleId) {
                this.props.setExternalPDPTrigger(articleId);
              }
            });
          } else {
            console.log("....will NOT make API call here");
          }
        }
      }
    };
  };

  render() {
    let { barcodeData, articleId, ignoreInput } = this.state;

    return (
      <>
        <p>{barcodeData}</p>
        <p>{articleId}</p>
        <p>{"Ignoring h/w input: " + ignoreInput + ""}</p>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),
  };
}

export default connect(null, mapDispatchToProps)(BarCodeSSE);
