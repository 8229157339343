import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  GlButton,
  GlIcon,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlCheckbox,
} from "@adl/foundation";

import ModalView from "../../../../../ModalView/ModalView";

import "./CheckoutConfirmationModal.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

import { getRestoreCartURL } from "../../../../../../utils/productUrl";
import { KEYBOARD_IDS } from "../../../../../../utils/constants";
import { clearInputWithKeyboardValue } from "../../../../../../actions/inputWithKeyboardActions";
import { getFormattedEmployeeId } from "../../../../../../utils/employeeIdHelper";

import * as analytics from "../../../../../../analytics";

import QRCode from "react-qr-code";
import EmployeeCheckout from "../EmployeeCheckout/EmployeeCheckout";

function CheckoutConfirmationModal(props) {
  const [isValid, setIsValid] = useState(false);
  const [viewQRUrl, setViewQRUrl] = useState(false);
  const [showEmployeeCheckout, setShowEmployeeCheckout] = useState(false);
  const [showEmployeeCheckoutQrCode, setShowEmployeeCheckoutQrCode] =
    useState(false);

  const modalRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom when keyboard appears
    if (props.selctedInputWithKeyboardId === KEYBOARD_IDS.employeeId) {
      const scrollHeight = modalRef.current.scrollHeight;
      const height = modalRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;

      modalRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [props.selctedInputWithKeyboardId]);

  const disableCheckoutButton =
    props?.appSettings?.value?.player?.disableCheckoutButton;
  let {
    country,
    _SSMID: ssmid,
    hostname,
    region,
    city,
  } = props?.appSettings?.value?.player;

  let restoreCartURL = getRestoreCartURL({
    country,
    ssmid,
    hostname,
    region,
    city,
    cartItems: props?.cartItems,
    employeeId:
      props.selctedInputWithKeyboardValue[KEYBOARD_IDS.employeeId]?.length > 0
        ? getFormattedEmployeeId(
            props.selctedInputWithKeyboardValue[KEYBOARD_IDS.employeeId],
            props.appSettings.value.default_locale
          )
        : "",
  });

  const _getTranslation = (key) => {
    return translateString(key);
  };

  const handleBack = () => {
    setShowEmployeeCheckout(false);
    setShowEmployeeCheckoutQrCode(false);
    props.setInputWithKeyboardValue(KEYBOARD_IDS.employeeId, "");
  };

  return (
    <div>
      <ModalView
        showCloseBtn={false}
        show={props.show}
        onClose={() => {
          props.onClose();
          props.setInputWithKeyboardValue(KEYBOARD_IDS.employeeId, "");
        }}
        className={`modal-centered checkout-confirmation ${
          props.selctedInputWithKeyboardId === KEYBOARD_IDS.employeeId
            ? "align-items-unset"
            : ""
        }`}
        innerRef={modalRef}
      >
        <div className="checkout-confirmation_container">
          <div>
            <div
              className={`checkout-confirmation__content_left ${
                disableCheckoutButton
                  ? "checkout-confirmation__content_left__wide"
                  : ""
              }`}
            >
              <div className="checkout-confirmation-title">
                {showEmployeeCheckout && (
                  <img
                    src="images/arrow-left.svg"
                    alt="left-arrow"
                    onClick={() => handleBack()}
                  />
                )}
                <h4>
                  {_getTranslation(
                    showEmployeeCheckout
                      ? TRANSLATIONS_KEY_LIST.employee_checkout_title
                      : TRANSLATIONS_KEY_LIST.checkout_on_your_device
                  )}
                </h4>
                <GlIcon
                  name="close"
                  onClick={() => {
                    props.onClose();
                    props.setInputWithKeyboardValue(
                      KEYBOARD_IDS.employeeId,
                      ""
                    );
                  }}
                />
              </div>
              <div className="qr_code">
                {/* <QRCode value={"https://www.adidas.de"} size={160} /> */}
                {showEmployeeCheckoutQrCode ? (
                  <div className="qrcode-background" />
                ) : (
                  <QRCode value={restoreCartURL} size={370} />
                )}
              </div>

              <div className="debug_qr_url hidden">
                <p
                  onClick={(e) => {
                    setViewQRUrl(!viewQRUrl);
                  }}
                >
                  {!viewQRUrl ? "Debug: view url ?" : "Debug: hide url"}
                </p>
                {viewQRUrl && <p>{restoreCartURL}</p>}
                <p></p>
              </div>

              <div className="instruction">
                <p>
                  {_getTranslation(
                    showEmployeeCheckout
                      ? TRANSLATIONS_KEY_LIST.employee_checkout_instruction
                      : TRANSLATIONS_KEY_LIST.checkout_on_your_device_instruction
                  )}
                </p>
              </div>
            </div>
            <div className="employee-checkout-container">
              {!showEmployeeCheckout && (
                <div
                  className="employee-checkout"
                  onClick={() => setShowEmployeeCheckout(true)}
                >
                  {_getTranslation(TRANSLATIONS_KEY_LIST.employee_checkout)}
                </div>
              )}
              {showEmployeeCheckout && (
                <EmployeeCheckout
                  setShowEmployeeCheckoutQrCode={(value) =>
                    setShowEmployeeCheckoutQrCode(value)
                  }
                />
              )}
            </div>
          </div>
          {!disableCheckoutButton && (
            <div>
              <div className="checkout-confirmation__content_right">
                <div className="upper">
                  <div className="title">
                    <h4>
                      {_getTranslation(
                        TRANSLATIONS_KEY_LIST.continue_on_this_device
                      )}
                    </h4>
                  </div>

                  <div className="body">
                    <p>
                      {_getTranslation(
                        TRANSLATIONS_KEY_LIST.only_cash_on_delivery_option
                      )}
                    </p>
                  </div>
                </div>

                <div className="lower">
                  <div>
                    <GlCheckbox
                      isChecked={isValid}
                      label={_getTranslation(
                        TRANSLATIONS_KEY_LIST.yes_over_16_years_of_age
                      )}
                      onChange={(event) => setIsValid(event.target.checked)}
                    />
                  </div>

                  <div>
                    <GlButton
                      aria-label="label"
                      theme={GL_BUTTON_THEME.light}
                      variant={GL_BUTTON_VARIANT.secondary}
                      onClick={() => {
                        console.log("555");
                        props.setInputWithKeyboardValue(null, {});
                        props.onConfirm();

                        //Log Continue on Kiosk analytics
                        analytics.continueOnKiosk({
                          hostname: props?.appSettings?.value?.player.hostname,
                        });
                      }}
                      disabled={!isValid}
                    >
                      {_getTranslation(TRANSLATIONS_KEY_LIST.checkout)}
                    </GlButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalView>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(clearInputWithKeyboardValue(id, val)),
  };
}

function mapStateToProps({
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
  appSettings,
}) {
  return {
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
    appSettings,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutConfirmationModal);
