export class ProductListItemModel {
  constructor(
    product_id,
    product_name,
    model_number,
    original_color,
    display_currency,
    subtitle,
    badge_text,
    category,
    size_category,
    image_url,
    gallery_media,
    color_variations,
    discount_price,
    original_price,
    orderable,
    share_url
  ) {
    this.product_id = product_id;
    this.product_name = product_name;
    this.model_number = model_number;
    this.original_color = original_color;
    this.display_currency = display_currency;
    this.subtitle = subtitle;
    this.badge_text = badge_text;
    this.category = category;
    this.size_category = size_category;
    this.image_url = image_url;
    this.gallery_media = gallery_media;
    this.color_variations = color_variations;
    this.discount_price = discount_price;
    this.original_price = original_price;
    this.orderable = orderable;
    this.share_url = share_url;
  }
}
