import React, { Component } from "react";

import "./CheckoutView.css";

import { connect } from "react-redux";

import {
  GlLoader,
  GlParagraph,
  GlButton,
  GL_BUTTON_VARIANT,
  GlInput,
  GlAccordion,
} from "@adl/foundation";

import HomeDeliveryDetails from "./components/HomeDeliveryDetails/HomeDeliveryDetails";
import PickupCollectDetails from "./components/PickupCollectDetails/PickupCollectDetails";
import DeliveryOptions from "./components/DeliveryOptions/DeliveryOptions";
import OrderSummary from "./components/OrderSummary/OrderSummary";

import OrderCreationView from "./components/OrderCreationView/OrderCreationView";

import ModalView from "../ModalView/ModalView";

import VoucherOverlay from "./components/VoucherOverlay/VoucherOverlay";
import EmployeeIdOverlay from "./components/EmployeeIdOverlay/EmployeeIdOverlay";

import CartGalleryView from "./components/CartGalleryView/CartGalleryView";

import { API } from "../../API/index";

import * as helpers from "../../utils/helpers";

import { setSideBarActiveItem } from "../../actions/sidebarMenuActions";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../utils/translationCopies";

import { LOCATION_TYPE } from "../../utils/constants";

import * as analytics from "../../analytics";

class CheckoutView extends Component {
  state = {
    __bkp_userDetails: {
      firstName: "_Charlie",
      lastName: "_Doe",
      address: "_Three Stripes Street",
      street: "_Some Street",
      zipCode: "_AM 100123",
      city: "_London",
      email: "_charlier.doe@gmail.com",
      phoneNumber: "0123456789",
      newsletterAccepted: false,
    },
    // userDetails: {
    //   firstName: "Charlie",
    //   lastName: "Doe",
    //   address: "Three Stripes Street",
    //   street: "Some Street",
    //   zipCode: "105064",
    //   city: "Moscow",
    //   email: "charlier.doe@gmail.com",
    //   phoneNumber: "0123456789",
    //   newsletterAccepted: false,
    // },
    userDetails: {
      firstName: "",
      lastName: "",
      address: "",
      street: "",
      zipCode: "",
      city: "",
      email: "",
      phoneNumber: "",
      newsletterAccepted: false,
    },
    deliveryOptionSelected: "home",
    storeAddressSelected: null,
    pickupPointAddressSelected: null,

    // currentOpenMenu: null,
    checkoutObject: null,
    deliveryOptions: null,
    deliveryOptionsDetailed: null,
    dev_helper_existingCartId: "",

    locationSearchResult: {
      stores: [],
      pick_up_points: [],
    },

    isFormInEditMode: true,

    showOrderCreationModal: false,
    isProcessing: false,
    isOrderable: false,
    finalOrderCreationSuccess: false, // can close overlay in case of error and re try from ui order placement, tertiary button
    finalOrderCreationInProcess: false, // time out? and then failure

    startCheckoutError: false,
    homeDetailsSaveError: false,
    storeLocationDetailsSaveError: false,
    pickupPointLocationDetailsSaveError: false,
    storePickupUserDetailsSaveError: false,

    storeUserDetailsSaveError: undefined,
    pickupUserDetailsSaveError: undefined,

    paymentOptions: null, // reset on type chnage

    orderCompletionDetails: null,

    showVoucherModal: false,
    voucherCodeError: false,

    isHomeEditView: false,

    showEmployeeIdModal: false,
    employeeIdError: false,
    employeeId: null,
    employeeIdSavedSuccessfully: false,
  };

  componentDidMount() {
    // this.setState({ showOrderCreationModal: true });

    if (
      this.props.appSettings?.value?.player?.showCheckoutDebugScreen === false
    ) {
      this.createNewCheckout();
    }
    let { userDetails } = this.props;

    if (!this.userDetailsAvailable(userDetails)) {
      this.setEditView(true);
    } else {
      this.setEditView(false);
    }
  }

  setEditView = (val) => {
    this.setState({ isHomeEditView: val, isFormInEditMode: val });

    // this.onFormInEditMode(val);
  };

  userDetailsAvailable = (_userDetails) => {
    return helpers.validateUserDetailsForHomeDelivery(_userDetails);
  };

  componentDidUpdate() {}

  onSessionEnd = () => {
    // TO DO
    analytics.endSession({
      hostname: this.props.appSettings.value.player.hostname,
    });
    window.location.reload();
  };

  setShowOrderCreationModal = (val) => {
    this.setState({ showOrderCreationModal: val });
  };

  onFormInEditMode = (value) => {
    this.setState({ isFormInEditMode: value });
  };

  useExistingCart = () => {
    const _cartId = this.state.dev_helper_existingCartId;

    if (_cartId) {
      API.checkoutAPI
        .getExistingCheckout(_cartId, this.props.selectedLocale)
        .then((_response) => {
          let _checkoutObject = _response?.response?.data.data;

          this.setState({ checkoutObject: _checkoutObject });

          if (_checkoutObject?.id) {
            API.checkoutAPI
              .getDeliveryOptions(
                _checkoutObject?.id,
                this.props.selectedLocale
              )
              .then((x, y) => {
                if (x) {
                  this.setState({
                    deliveryOptions: x.deliveryOptions,
                  });
                }
              })
              .catch((error) => {
                console.log("error detected");
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.log("error detected");
          console.error(error);
        });
    }
  };

  createNewCheckout = (resetState) => {
    //get cart infor from redux store
    const cartItems = this.props.cartItems;

    if (!cartItems || cartItems.length === 0) {
      console.warn(
        "No cart items found in redux store hence skipping checkout creation"
      );
      return;
    }

    this.setState({ startCheckoutError: false });

    API.checkoutAPI
      .startCheckout(cartItems, this.props.selectedLocale)
      .then((_response) => {
        // get delivery options

        let _checkoutObject = _response?.response?.data.data;

        this.setState({ checkoutObject: _checkoutObject });

        if (_checkoutObject?.id) {
          API.checkoutAPI
            .getDeliveryOptions(_checkoutObject?.id, this.props.selectedLocale)
            .then((x, y) => {
              if (x) {
                if (resetState) {
                  this.setState({
                    deliveryOptions: x.deliveryOptions,
                    startCheckoutError: false,
                    deliveryOptionSelected: "home",
                    storeAddressSelected: null,
                    pickupPointAddressSelected: null,
                    dev_helper_existingCartId: "",
                    locationSearchResult: {
                      stores: [],
                      pick_up_points: [],
                    },
                    isFormInEditMode: true,
                    isHomeEditView: true,
                    showOrderCreationModal: false,
                    isProcessing: false,
                    isOrderable: false,
                    finalOrderCreationSuccess: false,
                    finalOrderCreationInProcess: false,
                    homeDetailsSaveError: false,
                    storeLocationDetailsSaveError: false,
                    pickupPointLocationDetailsSaveError: false,
                    storePickupUserDetailsSaveError: false,
                    storeUserDetailsSaveError: undefined,
                    pickupUserDetailsSaveError: undefined,
                    paymentOptions: null,
                    voucherCodeError: false,
                    orderCompletionDetails: null,
                    showVoucherModal: false,
                  });
                } else {
                  this.setState({
                    deliveryOptions: x.deliveryOptions,
                    startCheckoutError: false,
                  });
                }
              }
            })
            .catch((error) => {
              console.log("error detected");
              console.error(error);
              this.setState({ startCheckoutError: true });
            });
        }
      })
      .catch((error) => {
        console.log("error detected");
        console.error(error);
        this.setState({ startCheckoutError: true });
      });
  };

  onDeliveryOptionClick = (e, deliveryOptionId) => {
    // TODO: 4-5 times switch, isFormEdit is true??? Check this scenario
    const {
      userDetails,
      isFormInEditMode,
      checkoutObject,
      paymentOptions,
      storeAddressSelected,
      pickupPointAddressSelected,
    } = this.state;
    let isOrderable = this.state.isOrderable;
    let isUserInfoValid = false;
    let dataUnchanged =
      deliveryOptionId === checkoutObject?.selected?.delivery?.id;
    let isAddressNull = null;

    switch (deliveryOptionId) {
      case LOCATION_TYPE.home:
        isUserInfoValid =
          helpers.validateUserDetailsForHomeDelivery(userDetails);
        // console.log(isUserInfoValid, !isFormInEditMode, !dataUnchanged, isUserInfoValid && !isFormInEditMode && !dataUnchanged)
        if (isUserInfoValid && !isFormInEditMode && !dataUnchanged)
          this.onHomeCheckoutInformationUpdated(deliveryOptionId);
        else if (paymentOptions && paymentOptions.length > 0)
          isOrderable = true;

        break;
      case LOCATION_TYPE.store:
      case LOCATION_TYPE.pick_up_point:
        isAddressNull =
          deliveryOptionId === LOCATION_TYPE.store
            ? storeAddressSelected
            : pickupPointAddressSelected;
        isUserInfoValid =
          helpers.validateUserDetailsForStorePickup(userDetails);
        // console.log(isUserInfoValid, !isFormInEditMode, !dataUnchanged, !(isAddressNull === null), isUserInfoValid && !isFormInEditMode && !dataUnchanged && !!isAddressNull)
        if (
          isUserInfoValid &&
          !isFormInEditMode &&
          !dataUnchanged &&
          !(isAddressNull === null)
        )
          this.onCheckoutLocationInformationUpdated(null, deliveryOptionId);
        else if (paymentOptions && paymentOptions.length > 0)
          isOrderable = true;

        break;
      default:
        break;
    }
    this.setState({ deliveryOptionSelected: deliveryOptionId, isOrderable });
  };

  onStoreAddressListItemSelected = (address) => {
    this.setState({ storeAddressSelected: address });
  };

  onPickupPointAddressListItemSelected = (address) => {
    this.setState({ pickupPointAddressSelected: address });
  };

  onUserDetailsChanged = (updatedUserDetails) => {
    this.setState({
      userDetails: updatedUserDetails,
    });
  };

  onLocationSearchResult = (result, type) => {
    let _locationSearchResult = this.state.locationSearchResult;

    if (type === "store") {
      _locationSearchResult.stores = result;
    } else {
      _locationSearchResult.pick_up_points = result;
    }

    this.setState({
      locationSearchResult: _locationSearchResult,
    });
  };

  captureExistingCart = (t, value) => {
    this.setState({
      dev_helper_existingCartId: value,
    });
  };

  // updateDeliveryOptions(newDeliveryOptions) {
  //   let deliveryOptions = this.state;
  //   deliveryOptions.forEach
  // }

  async onHomeCheckoutInformationUpdated(deliveryOptionId, locale) {
    const { userDetails, checkoutObject, deliveryOptionSelected } = this.state;

    //todo: temp
    const deliveryOption = deliveryOptionId || deliveryOptionSelected;

    const deliveryInfo = {
      deliveryOption: deliveryOption,
      userDetails: userDetails,
      countryCode: this.props.appSettings?.value?.player?.country_code,
    };

    const checkoutObjectId = checkoutObject.id;

    this.setState({ isProcessing: true, isOrderable: false });

    API.checkoutAPI
      .updateCheckoutDeliveryInformation(
        checkoutObjectId,
        locale || this.props.selectedLocale,
        deliveryInfo
      )
      .then((deliveryResponse) => {
        const { checkout, deliveryOptionsDetailed } = deliveryResponse;

        this.setState({ checkoutObject: checkout, deliveryOptionsDetailed });

        API.checkoutAPI
          .updateCheckoutShippingInformation(
            checkoutObjectId,
            this.props.selectedLocale,
            deliveryInfo,
            deliveryOptionsDetailed
          )
          .then((shippingResponse) => {
            const { checkout } = shippingResponse;

            this.setState({ checkoutObject: checkout });

            API.checkoutAPI
              .updateCheckoutCustomerInformation(
                checkoutObjectId,
                this.props.selectedLocale,
                deliveryInfo
              )
              .then((customerResponse) => {
                const { checkout } = customerResponse;

                this.setState({ checkoutObject: checkout });

                API.checkoutAPI
                  .getPaymentOptionsInformation(
                    checkoutObjectId,
                    this.props.selectedLocale,
                    deliveryOption
                  )
                  .then((paymentOptionsResponse) => {
                    const { paymentOptions } = paymentOptionsResponse;

                    this.setState({
                      paymentOptions,
                      isProcessing: false,
                      isOrderable: true,
                      homeDetailsSaveError: false,
                    });
                  })
                  .catch((error) => {
                    this.setState({
                      isProcessing: false,
                      homeDetailsSaveError: true,
                    });
                  });
              })
              .catch((error) => {
                this.setState({
                  isProcessing: false,
                  homeDetailsSaveError: true,
                });
              });
          })
          .catch((error) => {
            this.setState({ isProcessing: false, homeDetailsSaveError: true });
          });
      })
      .catch((error) => {
        this.setState({ isProcessing: false, homeDetailsSaveError: true });
      });
  }

  async onCheckoutLocationInformationUpdated(
    address,
    deliveryOptionId,
    locale
  ) {
    const {
      userDetails,
      checkoutObject,
      deliveryOptionSelected,
      storeAddressSelected,
      pickupPointAddressSelected,
    } = this.state;

    const deliveryOption = deliveryOptionId || deliveryOptionSelected;

    const deliveryInfo = {
      deliveryOption: deliveryOption,
      userDetails: userDetails,
      storeAddress: address || storeAddressSelected,
      pickupPointAddress:
        address !== null ? address : pickupPointAddressSelected,
      countryCode: this.props.appSettings?.value?.player?.country_code,
    };

    const checkoutObjectId = checkoutObject.id;

    this.setState({ isProcessing: true, isOrderable: false });

    const setErrorState = () => {
      switch (deliveryOption) {
        case LOCATION_TYPE.store:
          this.setState({
            isProcessing: false,
            storeLocationDetailsSaveError: true,
          });
          break;
        case LOCATION_TYPE.pick_up_point:
          this.setState({
            isProcessing: false,
            pickupPointLocationDetailsSaveError: true,
          });
          break;
        default:
          break;
      }
    };

    const setSuccessState = (paymentOptions) => {
      switch (deliveryOption) {
        case LOCATION_TYPE.store:
          this.setState({
            paymentOptions,
            isProcessing: false,
            isOrderable: true,
            storeLocationDetailsSaveError: false,
          });
          break;
        case LOCATION_TYPE.pick_up_point:
          this.setState({
            paymentOptions,
            isProcessing: false,
            isOrderable: true,
            pickupPointLocationDetailsSaveError: false,
          });
          break;
        default:
          break;
      }
    };

    API.checkoutAPI
      .updateCheckoutDeliveryInformation(
        checkoutObjectId,
        this.props.selectedLocale,
        deliveryInfo
      )
      .then((deliveryResponse) => {
        const { checkout, deliveryOptionsDetailed } = deliveryResponse;

        this.setState({ checkoutObject: checkout, deliveryOptionsDetailed });

        API.checkoutAPI
          .updateCheckoutShippingInformation(
            checkoutObjectId,
            locale || this.props.selectedLocale,
            deliveryInfo,
            deliveryOptionsDetailed
          )
          .then((shippingResponse) => {
            const { checkout } = shippingResponse;

            this.setState({ checkoutObject: checkout, isProcessing: false }); // TODO: setTimeout funntion for 60 sec and cancel this handle after flag is off cleartimeout

            API.checkoutAPI
              .getPaymentOptionsInformation(
                checkoutObjectId,
                this.props.selectedLocale,
                deliveryOption
              )
              .then((paymentOptionsResponse) => {
                const { paymentOptions } = paymentOptionsResponse;

                setSuccessState(paymentOptions);
              })
              .catch((error) => {
                setErrorState();
              });
          })
          .catch((error) => {
            setErrorState();
          });
      })
      .catch((error) => {
        setErrorState();
      });
  }

  async onCheckoutCustomerInformationUpdated(locale) {
    const {
      deliveryOptionSelected,
      userDetails,
      checkoutObject,
      pickupPointAddressSelected,
      storeAddressSelected,
    } = this.state;

    const deliveryInfo = {
      deliveryOption: deliveryOptionSelected,
      userDetails: userDetails,
      countryCode: this.props.appSettings?.value?.player?.country_code,
      addressInfo:
        deliveryOptionSelected === LOCATION_TYPE.pick_up_point
          ? pickupPointAddressSelected
          : storeAddressSelected,
    };

    const checkoutObjectId = checkoutObject.id;

    this.setState({ isProcessing: true });

    API.checkoutAPI
      .updateCheckoutCustomerInformation(
        checkoutObjectId,
        locale || this.props.selectedLocale,
        deliveryInfo
      )
      .then((customerResponse) => {
        const { checkout } = customerResponse;
        if (deliveryOptionSelected === LOCATION_TYPE.pick_up_point) {
          this.setState({
            checkoutObject: checkout,
            isProcessing: false,
            pickupUserDetailsSaveError: false,
          });
        } else {
          this.setState({
            checkoutObject: checkout,
            isProcessing: false,
            storeUserDetailsSaveError: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          isProcessing: false,
          storePickupUserDetailsSaveError: true,
        }); // TODO: how to handle error here
      });
  }

  async endCheckout() {
    const { paymentOptions, checkoutObject, deliveryOptionSelected } =
      this.state;

    this.setState({ finalOrderCreationInProcess: true });

    API.checkoutAPI
      .endCheckout(
        checkoutObject.id,
        this.props.selectedLocale,
        paymentOptions,
        deliveryOptionSelected
      )
      .then((endCheckoutResponse) => {
        const { orderCompletionDetails } = endCheckoutResponse;

        this.setState({
          orderCompletionDetails,
          finalOrderCreationSuccess: true,
          finalOrderCreationInProcess: false,
        });
      })
      .catch((error) => {
        this.setState({
          finalOrderCreationSuccess: false,
          finalOrderCreationInProcess: false,
        });
      });
  }

  async applyAndValidateVoucherCode(voucherCode) {
    let { checkoutObject } = this.state;

    this.setState({ finalOrderCreationInProcess: true });

    API.checkoutAPI
      .applyAndValidateVoucherCode(
        checkoutObject.id,
        this.props.selectedLocale,
        voucherCode
      )
      .then((applyAndValidateVoucherCodeResponse) => {
        const { voucherResponse } = applyAndValidateVoucherCodeResponse;

        this.setState({
          checkoutObject: voucherResponse,
          showVoucherModal: false,
          voucherCodeError: false,
        });
      })
      .catch((error) => {
        checkoutObject.selected = {};
        this.setState({ voucherCodeError: true, checkoutObject });
      });
  }

  async applyAndValidateEmployeeId(employeeId) {
    const { checkoutObject } = this.state;

    this.setState({ finalOrderCreationInProcess: true });

    API.checkoutAPI
      .applyAndValidateEmployeeId(
        checkoutObject.id,
        this.props.selectedLocale,
        employeeId
      )
      .then((applyAndValidateEmployeeIdResponse) => {
        const { employeeResponse } = applyAndValidateEmployeeIdResponse;
        // TODO: need to check what response looks like and update Response and overall employee id
        // checkoutObject: employeeResponse,

        this.setState({
          employeeId,
          showEmployeeIdModal: false,
          employeeIdError: false,
          employeeIdSavedSuccessfully: true,
          employeeResponse: employeeResponse,
        });
      })
      .catch((error) => {
        this.setState({
          employeeId: null,
          employeeIdError: true,
          employeeIdSavedSuccessfully: false,
        });
      });
  }

  // applyEmployeeId = (val) => {
  //   this.setState({
  //     employeeId: val,
  //   });

  //   // todo://
  //   // apply the employee id and make related checkout api calls
  //   // if success then close the employee id overlay, and proceed with next steps.
  //   this.setState({ showEmployeeIdModal: false });

  //   // if error occured then show error occured, do not close the emp id overlay automatically,
  //   // let users take next actions manually - re-entry/re-try or close the overlay
  //   // ==> this.setState({ employeeIdError: false });
  // };

  render() {
    let {
      deliveryOptionSelected,
      deliveryOptions,
      userDetails,
      checkoutObject,
      dev_helper_existingCartId,
      storeAddressSelected,
      pickupPointAddressSelected,
      locationSearchResult,
      isFormInEditMode,
      isProcessing,
      isOrderable,
      startCheckoutError,
      homeDetailsSaveError,
      storeLocationDetailsSaveError,
      pickupPointLocationDetailsSaveError,
      storePickupUserDetailsSaveError,
      storeUserDetailsSaveError,
      pickupUserDetailsSaveError,
      orderCompletionDetails,
      voucherCodeError,
      finalOrderCreationSuccess,
      finalOrderCreationInProcess,
      showVoucherModal,
      showEmployeeIdModal,
      employeeIdError,
      employeeId,
      employeeIdSavedSuccessfully,
    } = this.state;

    let isUserInfoValid = false;
    let deliveryOptionObject = null;

    if (deliveryOptionSelected === "home") {
      isUserInfoValid = helpers.validateUserDetailsForHomeDelivery(userDetails);
    }

    if (
      deliveryOptionSelected === "store" ||
      deliveryOptionSelected === "pick_up_point"
    ) {
      isUserInfoValid = helpers.validateUserDetailsForStorePickup(userDetails);
      deliveryOptionObject = deliveryOptions.find(
        (option) => option.id === deliveryOptionSelected
      );
    }

    const checkForErrorsInCurrentSelection = () => {
      switch (deliveryOptionSelected) {
        case LOCATION_TYPE.home:
          return homeDetailsSaveError;
        case LOCATION_TYPE.store:
          return (
            storeLocationDetailsSaveError ||
            storeUserDetailsSaveError ||
            storeUserDetailsSaveError === undefined
          );
        case LOCATION_TYPE.pick_up_point:
          return (
            pickupPointLocationDetailsSaveError ||
            pickupUserDetailsSaveError ||
            pickupUserDetailsSaveError === undefined
          );
        default:
          break;
      }
    };
    let enablePlaceOrderButton =
      isUserInfoValid &&
      !isFormInEditMode &&
      !isProcessing &&
      isOrderable &&
      !checkForErrorsInCurrentSelection();

    let { setSideBarActiveItem } = this.props;

    let _shouldShowCheckoutDebugScreen =
      this.props.appSettings?.value?.player?.showCheckoutDebugScreen;

    return (
      <>
        <ModalView
          show={isProcessing}
          onClose={() => {}}
          className="modal-centered"
        >
          <div className="checkout-loading">
            <div>
              <GlLoader />
            </div>
            <div>
              {getTranslation(
                this.props.selectedLocale,
                TRANSLATIONS_KEY_LIST.processing
              )}
            </div>
          </div>
        </ModalView>

        {showVoucherModal && (
          <VoucherOverlay
            show={this.state.showVoucherModal}
            onClose={() =>
              this.setState({ showVoucherModal: false, voucherCodeError: null })
            }
            voucherCode={checkoutObject?.selected?.voucher || ""}
            voucherCodeError={voucherCodeError}
            onSubmit={(voucherCode) =>
              this.applyAndValidateVoucherCode(voucherCode)
            }
            selectedLocale={this.props.selectedLocale}
          ></VoucherOverlay>
        )}

        {showEmployeeIdModal && (
          <EmployeeIdOverlay
            show={this.state.showEmployeeIdModal}
            onClose={() =>
              this.setState({
                showEmployeeIdModal: false,
                employeeIdError: null,
              })
            }
            employeeId={employeeId}
            employeeIdError={employeeIdError}
            onSubmit={(employeeId) =>
              this.applyAndValidateEmployeeId(employeeId)
            }
            selectedLocale={this.props.selectedLocale}
            employeeIdSavedSuccessfully={employeeIdSavedSuccessfully}
          ></EmployeeIdOverlay>
        )}

        <OrderCreationView
          showOrderCreationModal={this.state.showOrderCreationModal}
          setShowOrderCreationModal={this.setShowOrderCreationModal}
          onSessionEnd={this.onSessionEnd}
          orderCompletionDetails={orderCompletionDetails}
          selectedLocale={this.props.selectedLocale}
          finalOrderCreationInProcess={finalOrderCreationInProcess}
          finalOrderCreationSuccess={finalOrderCreationSuccess}
          endCheckout={() => this.endCheckout()}
        ></OrderCreationView>

        <div className="checkout">
          <div className="cart-image-gallery">
            {window.screen.height > window.screen.width && <CartGalleryView />}
          </div>
          <div
            className="navigation"
            onClick={() => setSideBarActiveItem(null)}
          >
            <div className="">
              <img src="images/arrow-left.svg" alt="left-arrow"></img>
            </div>
          </div>
          {_shouldShowCheckoutDebugScreen && !checkoutObject && (
            <div>
              <GlParagraph>
                Helper functions for development process
              </GlParagraph>
              <GlParagraph>
                for UI development purpose and seq. flow creation, so as to
                avoid creating actual checkout object from api
              </GlParagraph>
              <div className="helper-ctas">
                <GlInput
                  id="existing_cart"
                  placeholder="Existing cart id"
                  value={dev_helper_existingCartId}
                  onChange={(event) =>
                    this.captureExistingCart(
                      event.target.id,
                      event.target.value
                    )
                  }
                />
                <GlButton
                  aria-label="label"
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={(e) => this.useExistingCart()}
                >
                  Use existing Cart for development purpose
                </GlButton>
                <div style={{ padding: "1rem" }}></div>
                <GlButton
                  aria-label="label"
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={(e) => this.createNewCheckout()}
                >
                  Create new checkout from current Cart
                </GlButton>

                <div>
                  <br />
                  <p>{"Employee ID from overlay: " + this.state.employeeId}</p>
                </div>
              </div>
            </div>
          )}
          {startCheckoutError && (
            <div>
              <div className="start-checkout-error">
                <div>
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.error_occurred
                  )}
                </div>
                <GlButton
                  aria-label="label"
                  variant={GL_BUTTON_VARIANT.tertiary}
                  onClick={(e) => this.createNewCheckout()}
                >
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.retry_start_checkout
                  )}
                </GlButton>
              </div>
            </div>
          )}

          {checkoutObject && (
            <div className="checkout-form-container">
              <section>
                <h4>
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.choose_your_shipping_method
                  )}
                  <GlButton
                    aria-label="label"
                    variant={GL_BUTTON_VARIANT.tertiary}
                    onClick={(e) => this.createNewCheckout(true)}
                  >
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.restart_checkout
                    )}
                  </GlButton>
                </h4>
              </section>
              <div className="input-container">
                {_shouldShowCheckoutDebugScreen && (
                  <div className="debug-info hidden">
                    <h4>debug info:</h4>
                    <p>
                      {"delivery option selected: " + deliveryOptionSelected}
                    </p>
                    <p>{"user details:" + JSON.stringify(userDetails)}</p>
                    <p>{"user details is valid? : " + isUserInfoValid + ""}</p>
                    <p>{"form is in edit mode ?:" + isFormInEditMode}</p>
                    <p>
                      {"enable place order button? : " + enablePlaceOrderButton}
                    </p>
                  </div>
                )}
                {/* FOR DEBUGGING: */}
                {_shouldShowCheckoutDebugScreen && (
                  <GlAccordion
                    isOpen={false}
                    onToggle={(isOpen) => console.log("toggle", isOpen)}
                    selected
                    summary="values"
                    title="Debug"
                    className="debug-info"
                    style={{ marginBottom: "2rem" }}
                  >
                    <div>
                      <p>IS PROCESSING: {isProcessing ? "TRUE" : "FALSE"}</p>
                      <p>IS ORDERABLE: {isOrderable ? "TRUE" : "FALSE"}</p>
                      <p>
                        IS USER INFO VALID: {isUserInfoValid ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        IS FORM IN EDIT MODE:{" "}
                        {isFormInEditMode ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        HOME CHECKOUT ERROR:{" "}
                        {homeDetailsSaveError ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        STORE LOCATION CHECKOUT ERROR:{" "}
                        {storeLocationDetailsSaveError ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        PICKUPPOINT LOCATION CHECKOUT ERROR:{" "}
                        {pickupPointLocationDetailsSaveError ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        USER DETAILS ERROR:{" "}
                        {storePickupUserDetailsSaveError ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        FINAL ORDER CREATION SUCCESS:{" "}
                        {finalOrderCreationSuccess ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        FINAL ORDER CREATION IN PROCESS:{" "}
                        {finalOrderCreationInProcess ? "TRUE" : "FALSE"}
                      </p>
                      <p>
                        VOUCHER ERROR: {voucherCodeError ? "TRUE" : "FALSE"}
                      </p>

                      <p>EMPLOYEE ID: {employeeId}</p>

                      <p>
                        EMPLOYEE ID ERROR: {employeeIdError ? "TRUE" : "FALSE"}
                      </p>

                      <GlButton
                        aria-label="label"
                        variant={GL_BUTTON_VARIANT.tertiary}
                        onClick={(e) =>
                          this.onHomeCheckoutInformationUpdated(null, "de")
                        }
                      >
                        Home
                      </GlButton>
                      <GlButton
                        aria-label="label"
                        variant={GL_BUTTON_VARIANT.tertiary}
                        onClick={(e) =>
                          this.onCheckoutLocationInformationUpdated(
                            null,
                            null,
                            "de"
                          )
                        }
                      >
                        location
                      </GlButton>
                      <GlButton
                        aria-label="label"
                        variant={GL_BUTTON_VARIANT.tertiary}
                        onClick={(e) =>
                          this.onCheckoutCustomerInformationUpdated("de")
                        }
                      >
                        user details
                      </GlButton>
                    </div>
                  </GlAccordion>
                )}
                {
                  <DeliveryOptions
                    deliveryOptionSelected={deliveryOptionSelected}
                    deliveryOptions={deliveryOptions}
                    onDeliveryOptionClick={this.onDeliveryOptionClick}
                    selectedLocale={this.props.selectedLocale}
                  ></DeliveryOptions>
                }

                {deliveryOptionSelected === "home" && (
                  <HomeDeliveryDetails
                    userDetails={userDetails}
                    onUserDetailsChanged={this.onUserDetailsChanged}
                    onFormInEditMode={this.onFormInEditMode}
                    isHomeEditView={this.state.isHomeEditView}
                    setEditView={(value) => this.setEditView(value)}
                    onCheckoutInformationUpdated={() =>
                      this.onHomeCheckoutInformationUpdated()
                    }
                    selectedLocale={this.props.selectedLocale}
                    homeDetailsSaveError={homeDetailsSaveError}
                  ></HomeDeliveryDetails>
                )}

                {deliveryOptionSelected === "store" && (
                  <PickupCollectDetails
                    userDetails={userDetails}
                    deliveryOptionSelected={deliveryOptionSelected}
                    onUserDetailsChanged={this.onUserDetailsChanged}
                    checkoutObject={checkoutObject}
                    onAddressListItemSelected={
                      this.onStoreAddressListItemSelected
                    }
                    addressSelected={storeAddressSelected}
                    onLocationSearchResult={this.onLocationSearchResult}
                    previousLocationSearchResult={locationSearchResult}
                    currentAddress={
                      this.props.appSettings?.value?.player?.address
                    }
                    onFormInEditMode={this.onFormInEditMode}
                    onCheckoutLocationInformationUpdated={(address) =>
                      this.onCheckoutLocationInformationUpdated(address)
                    }
                    onCheckoutCustomerInformationUpdated={() =>
                      this.onCheckoutCustomerInformationUpdated()
                    }
                    deliveryOptionObject={deliveryOptionObject}
                    selectedLocale={this.props.selectedLocale}
                    locationDetailsSaveError={storeLocationDetailsSaveError}
                    storePickupUserDetailsSaveError={storeUserDetailsSaveError}
                  ></PickupCollectDetails>
                )}

                {deliveryOptionSelected === "pick_up_point" && (
                  <PickupCollectDetails
                    userDetails={userDetails}
                    deliveryOptionSelected={deliveryOptionSelected}
                    onUserDetailsChanged={this.onUserDetailsChanged}
                    checkoutObject={checkoutObject}
                    onAddressListItemSelected={
                      this.onPickupPointAddressListItemSelected
                    }
                    addressSelected={pickupPointAddressSelected}
                    onLocationSearchResult={this.onLocationSearchResult}
                    previousLocationSearchResult={locationSearchResult}
                    currentAddress={
                      this.props.appSettings?.value?.player?.address
                    }
                    onFormInEditMode={this.onFormInEditMode}
                    onCheckoutLocationInformationUpdated={(address) =>
                      this.onCheckoutLocationInformationUpdated(address)
                    }
                    onCheckoutCustomerInformationUpdated={() =>
                      this.onCheckoutCustomerInformationUpdated()
                    }
                    deliveryOptionObject={deliveryOptionObject}
                    selectedLocale={this.props.selectedLocale}
                    locationDetailsSaveError={
                      pickupPointLocationDetailsSaveError
                    }
                    storePickupUserDetailsSaveError={pickupUserDetailsSaveError}
                  ></PickupCollectDetails>
                )}
              </div>
              <div className="debug-info hidden">
                <h5>Order object (for debugging)</h5>
                <pre>
                  <code>
                    <GlParagraph>
                      {JSON.stringify(checkoutObject, null, 2)}
                    </GlParagraph>
                  </code>
                </pre>
              </div>
              <OrderSummary
                checkoutObject={checkoutObject}
                enablePlaceOrderButton={enablePlaceOrderButton}
                setShowOrderCreationModal={this.setShowOrderCreationModal}
                selectedLocale={this.props.selectedLocale}
                onVoucherAddClick={() => {
                  this.setState({ showVoucherModal: true });
                }}
                onEmployeeIdAdd={() => {
                  if (employeeIdSavedSuccessfully)
                    this.setState({ showEmployeeIdModal: true });
                  else
                    this.setState({
                      showEmployeeIdModal: true,
                      employeeId: null,
                    });
                }}
                endCheckout={() => this.endCheckout()}
              ></OrderSummary>
            </div>
          )}

          {!checkoutObject && !startCheckoutError && !deliveryOptions && (
            <div className="loading-animation-container">
              <GlLoader />
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps({ cartItems, selectedLocale, appSettings }) {
  return {
    cartItems,
    selectedLocale,
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutView);
