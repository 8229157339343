export const getMedia = (url) => {
  console.log("blob fetch for " + url);

  return new Promise((resolve, reject) => {
    var request = new XMLHttpRequest();
    request.open("GET", url);
    request.responseType = "blob";

    request.onload = () => {
      if (request.status === 200) {
        resolve(request);
      } else {
        reject(
          Error(
            "Media didn't load successfully; error code:" + request.statusText
          )
        );
      }
    };

    request.onerror = () => {
      reject(Error("There was a network error."));
    };

    try {
      request.send();
    } catch {
      console.log("blob fetch error");
    }
  });
};
