import React, { Component } from "react";
import { connect } from "react-redux";

import { GlButton, GL_BUTTON_THEME, GL_BUTTON_VARIANT } from "@adl/foundation";

import ModalView from "../../../ModalView/ModalView";
import InputWithKeyboard from "../../../InputWithKeyboard/InputWithKeyboard";
import { setInputWithKeyboardValue } from "../../../../actions/inputWithKeyboardActions";

import "./VoucherOverlay.css";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../../../utils/translationCopies";

import { KEYBOARD_IDS } from "../../../../utils/constants";

class VoucherOverlay extends Component {
  state = {
    voucherCode: this.props.voucherCode || "",
  };

  componentDidMount() {
    this.props.setInputWithKeyboardValue(
      KEYBOARD_IDS.voucherCode,
      this.props.voucherCode || ""
    );
  }

  componentDidUpdate(prevProps) {
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
    } = this.props;

    if (
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      this.setState({
        voucherCode: selctedInputWithKeyboardValue[selctedInputWithKeyboardId],
      });
    }
  }

  render() {
    let { voucherCode } = this.state;
    let { show, selectedLocale, voucherCodeError } = this.props;

    return (
      <div>
        <ModalView
          showCloseBtn={true}
          show={show}
          onClose={() => {
            this.props.onClose();
          }}
          className="modal-centered voucher"
        >
          <div className="voucher__content">
            <div className="upper">
              <div className="title">
                <h4>
                  {getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.add_voucher_heading
                  )}
                </h4>
              </div>

              <div className="body">
                <InputWithKeyboard
                  id={KEYBOARD_IDS.voucherCode}
                  placeholder={getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.promo_code
                  )}
                />
              </div>
            </div>

            <div className="lower">
              {voucherCodeError && (
                <div className="error-text">
                  {getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.error_occurred
                  )}
                </div>
              )}
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => {
                    this.props.onSubmit(voucherCode);
                  }}
                  disabled={
                    voucherCode === undefined ||
                    voucherCode?.length < 5 ||
                    voucherCode === this.props.voucherCode
                  }
                >
                  {getTranslation(selectedLocale, TRANSLATIONS_KEY_LIST.apply)}
                </GlButton>
              </div>
              <p className="disclaimer">
                {getTranslation(
                  selectedLocale,
                  TRANSLATIONS_KEY_LIST.add_voucher_disclaimer
                )}
              </p>
            </div>
          </div>
        </ModalView>
      </div>
    );
  }
}

function mapStateToProps({
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherOverlay);
