import React, { Component } from "react";
// import { log, MSG_TYPE } from "../../../../utils/logger";
import "./ProductCard.css";

import {
  GlProductCard,
  GlBadge,
  GlProductCardMediaHover,
} from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import * as helpers from "../../../../utils/helpers";

class ProductCard extends Component {
  componentDidMount() {}
  componentDidUpdate() {}

  onProductClicked = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { product } = this.props;

    return (
      <div className="product-card" id={product?.product_id}>
        <GlProductCard
          badge={
            product.badge_text ? (
              <GlBadge size="small" variant="urgent">
                {product.badge_text}
              </GlBadge>
            ) : (
              <></>
            )
          }
          category={product.category}
          media={
            <GlProductCardMediaHover
              base={
                <img
                  src={product.image_url}
                  alt={product.name}
                  title={product.name}
                />
              }
            />
          }
          priceFromLabel=""
          productVariationsLabel={
            parseInt(product?.color_variations?.length || 1) < 2
              ? null
              : this.getTranslation(
                  TRANSLATIONS_KEY_LIST.colors_available_plp
                )?.replace("<count>", product?.color_variations?.length + "")
          }
          // reviewCount={1856}
          salePrice={
            product.discount_price &&
            helpers.formatPrice(
              product.discount_price,
              this.props.selectedLocale
            )
          }
          standardPrice={helpers.formatPrice(
            product.original_price,
            this.props.selectedLocale
          )}
          title={product.product_name || ""}
        />
      </div>
    );
  }
}

export default ProductCard;
