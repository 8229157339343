import React, { Component } from "react";
import { log, MSG_TYPE } from "../../../../utils/logger";
import "./FilterMenu.css";

import {
  GlLabel,
  GL_LABEL_AS,
  GL_LABEL_FONT_SIZE,
  GlCheckbox,
  GlIcon,
} from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import FilterMenuSkeleton from "../FilterMenuSkeleton/FilterMenuSkeleton";

let closeFilterMenuHandle;

const LESS_FILTER_COUNT = 7;

class FilterMenu extends Component {
  state = {
    isFilterMenuOpen: null,
    selectedRefinements: this.props.selectedRefinements || [],
    isSortMenuOpen: false,
    selectedSortOption: null,
    isShowingAllFilters: false,
    refinements: [],
    lessRefinements: [],
  };

  componentDidMount() {
    this.initializeRefinementData();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props?.refinements) !==
      JSON.stringify(prevProps?.refinements)
    ) {
      this.initializeRefinementData();

      // let x = this.state.isFilterMenuOpen;
    }

    if (
      JSON.stringify(this.props?.selectedRefinements) !==
      JSON.stringify(prevProps?.selectedRefinements)
    ) {
      this.setState({
        selectedRefinements: this.props.selectedRefinements || [],
      });
    }

    if (
      JSON.stringify(this.props?.sortSelection) !==
      JSON.stringify(prevProps?.sortSelection)
    ) {
      this.setState({
        selectedSortOption: this.props?.sortSelection,
      });
    }
  }

  initializeRefinementData = () => {
    if (!this.props.refinements) return;

    let _refinements = this.props.refinements;
    let _lessRefinements = _refinements.slice(0, LESS_FILTER_COUNT);

    this.setState({
      refinements: _refinements,
      lessRefinements: _lessRefinements,
    });
  };

  onToggleMoreFilter = (event) => {
    if (event) {
      event.preventDefault();
    }

    let _isShowingAllFilters = this.state.isShowingAllFilters;

    this.setState({
      isShowingAllFilters: !_isShowingAllFilters,
    });
  };

  onFilterItemClicked = (isChecked) => {
    log(isChecked, MSG_TYPE.WARN);
  };

  onClearAllClick = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      selectedRefinements: [],
      // selectedSortOption: null,
      isSortMenuOpen: false,
      isFilterMenuOpen: false,
    });

    this.props.onFilterUpdated(
      [],
      this.state.selectedSortOption?.sort_option_id
    );
  };

  onFilterValueClicked = (event, isChecked, refinement, refinement_id) => {
    if (!refinement) return;

    if (event) {
      event.preventDefault();
    }

    refinement = { ...refinement, refinement_id };

    let { onFilterUpdated } = this.props;

    let _selectedRefinements = this.state.selectedRefinements;

    if (
      _selectedRefinements.filter(
        (z) => z.non_localized_value === refinement.non_localized_value
      ).length > 0
    ) {
      _selectedRefinements = _selectedRefinements.filter(
        (x) => x.non_localized_value !== refinement.non_localized_value
      );
    } else {
      _selectedRefinements.push(refinement);
    }

    this.setState({ selectedRefinements: _selectedRefinements });

    onFilterUpdated(
      _selectedRefinements,
      this.state.selectedSortOption?.sort_option_id || null
    );
  };

  onRefinementMenuClick = (e, item) => {
    e.preventDefault();

    let x = item === this.state.isFilterMenuOpen ? null : item;

    this.setState({
      isFilterMenuOpen: x,
    });

    if (false) {
      clearTimeout(closeFilterMenuHandle);

      closeFilterMenuHandle = setTimeout(() => {
        this.setState({ isFilterMenuOpen: null });
      }, 20000);
    }
  };

  onSortMenuClick = (e, item) => {
    e.preventDefault();

    let _isSortMenuOpen = this.state.isSortMenuOpen;

    this.setState({
      isSortMenuOpen: !_isSortMenuOpen,
    });
  };

  onSortValueSelected = (e, sortSelection) => {
    e.preventDefault();

    let _isSortMenuOpen = this.state.isSortMenuOpen;

    let _newSortSelection = null;

    if (
      sortSelection?.sort_option_id ===
      this.state.selectedSortOption?.sort_option_id
    ) {
      _newSortSelection = null;
    } else {
      _newSortSelection = sortSelection;
    }

    this.setState({
      selectedSortOption: _newSortSelection,
      isSortMenuOpen: !_isSortMenuOpen,
    });

    this.props.onSortUpdated(this.state.selectedRefinements, _newSortSelection);
  };

  isFilterHeadingHighlighted = (filterItem) => {
    let _selectedFilters = this.state.selectedRefinements;

    let _isMatchFound = false;

    // filterItem.values.every((item) => {
    //   let _match = _selectedFilters.filter(
    //     (x) => x.non_localized_value === item.non_localized_value
    //   );

    //   if (_match.length > 0) {
    //     _isMatchFound = true;
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    _selectedFilters.forEach((element) => {
      let _match = filterItem.values.filter(
        (x) => x.non_localized_value === element.non_localized_value
      );

      if (_match?.length > 0) {
        _isMatchFound = true;
        return;
      }
    });

    if (_isMatchFound) {
      return true;
    } else {
      return false;
    }
  };

  isFilterValueHighlighted = (filterValue) => {
    let _match = null;
    let _selectedRefinements = this.state.selectedRefinements;

    _match = _selectedRefinements.filter((x) => x.value === filterValue.value);

    if (_match.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { sorting_options } = this.props;
    let {
      isFilterMenuOpen,
      selectedRefinements,
      isSortMenuOpen,
      selectedSortOption,
      isShowingAllFilters,
      refinements,
      lessRefinements,
    } = this.state;

    let _refinements = isShowingAllFilters ? refinements : lessRefinements;

    return (
      <>
        {!this.props.dataReloading ? (
          <div className="">
            <div className="plp__filter-wrapper">
              <div className="filter-panel">
                <div className="filter-options">
                  {_refinements &&
                    _refinements.map((x) => (
                      <div
                        className={`option ${
                          isFilterMenuOpen === x.name ? "selected" : ""
                        } ${
                          x.values.length === 1 ? "single-value-filter" : ""
                        }`}
                        key={x.refinement_id}
                      >
                        <div
                          className="title"
                          onClick={(e) => this.onRefinementMenuClick(e, x.name)}
                        >
                          <div className="title_text">
                            <GlLabel
                              as={GL_LABEL_AS.label}
                              fontSize={GL_LABEL_FONT_SIZE.l}
                              onClick={(e) =>
                                this.onFilterValueClicked(
                                  e,
                                  null,
                                  x.values.length === 1 ? x.values[0] : null,
                                  x.refinement_id
                                )
                              }
                            >
                              <span
                                className={` ${
                                  this.isFilterHeadingHighlighted(x)
                                    ? "highlighted"
                                    : ""
                                }`}
                              >
                                {x.values.length === 1
                                  ? x.values[0].name
                                  : x.name}
                              </span>
                            </GlLabel>
                          </div>

                          {x.values.length > 1 && (
                            <div className="icon">
                              <GlIcon
                                name={`${
                                  isFilterMenuOpen === x.name
                                    ? "arrow-up"
                                    : "arrow-down"
                                }`}
                              />
                            </div>
                          )}
                        </div>

                        {x.values.length > 1 && (
                          <div className="options-container">
                            <div className="connector"></div>
                            <div className="option-list">
                              <ul>
                                {x.values &&
                                  x.values.length > 1 &&
                                  x.values.map((y) => (
                                    <li
                                      key={y.non_localized_value}
                                      onClick={(event) =>
                                        this.onFilterValueClicked(
                                          event,
                                          null,
                                          y,
                                          x.refinement_id
                                        )
                                      }
                                    >
                                      <div>
                                        <div>
                                          <div className={`filter-item-row `}>
                                            {x.type
                                              .toLowerCase()
                                              .indexOf("multi") !== -1 && (
                                              <span
                                                className={`${
                                                  x.refinement_id
                                                    ?.toLowerCase()
                                                    .indexOf("color") !== -1
                                                    ? `color-selection ${y.non_localized_value}`
                                                    : ""
                                                }`}
                                              >
                                                {y.refinement_id}
                                                <GlCheckbox
                                                  isChecked={this.isFilterValueHighlighted(
                                                    y
                                                  )}
                                                />
                                              </span>
                                            )}
                                            <span> {y.name} </span>

                                            <span className="counter">
                                              ({y.hit_count})
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                <div className="secondary-controller">
                  {sorting_options?.length > 0 &&
                    _refinements &&
                    _refinements.length > 0 && (
                      <div
                        className={`option ${isSortMenuOpen ? "selected" : ""}`}
                        onClick={(e) => this.onSortMenuClick(e)}
                      >
                        <div className="title">
                          <div className="title_text">
                            <GlLabel
                              as={GL_LABEL_AS.label}
                              fontSize={GL_LABEL_FONT_SIZE.l}
                            >
                              {this.state?.selectedSortOption?.name ||
                                this.getTranslation(
                                  TRANSLATIONS_KEY_LIST.sort_by
                                )}
                            </GlLabel>
                          </div>
                          <div className="icon arrow">
                            <div>
                              <GlIcon
                                name={`${
                                  isSortMenuOpen ? "arrow-up" : "arrow-down"
                                }`}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="options-container">
                          <div className="connector"></div>

                          <div className={`option-list option-list-right`}>
                            <ul>
                              {sorting_options &&
                                sorting_options.map((x) => (
                                  <li
                                    className={`${
                                      selectedSortOption?.sort_option_id ===
                                      x.sort_option_id
                                        ? "selected"
                                        : ""
                                    }`}
                                    key={x.sort_option_id}
                                    onClick={(e) =>
                                      this.onSortValueSelected(e, x)
                                    }
                                  >
                                    <div>{x.name}</div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}

                  {refinements && refinements.length > LESS_FILTER_COUNT && (
                    <>
                      <div className="option">
                        <div
                          className="title"
                          onClick={(e) => this.onToggleMoreFilter(e)}
                        >
                          <div className="title_text">
                            <GlLabel
                              as={GL_LABEL_AS.label}
                              fontSize={GL_LABEL_FONT_SIZE.l}
                            >
                              {isShowingAllFilters
                                ? this.getTranslation(
                                    TRANSLATIONS_KEY_LIST.less_filters
                                  )
                                : this.getTranslation(
                                    TRANSLATIONS_KEY_LIST.more_filters
                                  )}
                            </GlLabel>
                          </div>
                          <div className="icon">
                            <div>
                              <GlIcon
                                name={`${
                                  isShowingAllFilters ? "minus" : "plus"
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <!--    next section--> */}
            <div className="plp__filters-applied">
              {selectedRefinements.map((x) => (
                <div
                  key={x.name}
                  className="filter"
                  onClick={(e) => {
                    this.onFilterValueClicked(e, null, x, x.refinement_id);
                  }}
                >
                  <div>{x.name}</div>
                  <div className="icon">
                    <GlIcon name="cross-small" />
                  </div>
                </div>
              ))}

              {selectedRefinements.length > 0 && (
                <div
                  className="filter clear-all"
                  onClick={(e) => this.onClearAllClick(e)}
                >
                  <div>
                    {this.getTranslation(TRANSLATIONS_KEY_LIST.clear_all)}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <FilterMenuSkeleton></FilterMenuSkeleton>
          </>
        )}
      </>
    );
  }
}

export default FilterMenu;
