import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GlCheckbox,
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
} from "@adl/foundation";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../utils/translationCopies";

import "./EditUserDetails.css";

import * as helpers from "../../../../../../utils/helpers";

import InputWithKeyboard from "../../../../../InputWithKeyboard/InputWithKeyboard";

class EditUserDetails extends Component {
  state = {
    userDetails: null,
  };

  componentDidMount() {
    let _userDetails = this.props.userDetails;

    if (!_userDetails) {
      _userDetails = {
        firstName: "",
        lastName: "",
        street: "",
        houseNumber: "",
        zipCode: "",
        city: "",
        email: "",
        phoneNumber: "",
        newsletterAccepted: false,
      };
    }

    this.setState({
      userDetails: _userDetails,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
      onUserDetailsChanged,
    } = this.props;
    const { userDetails } = this.state;

    if (
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      let _userDetails = userDetails;

      if (selctedInputWithKeyboardValue[selctedInputWithKeyboardId]) {
        _userDetails[selctedInputWithKeyboardId] =
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId] || "";

        this.setState({
          userDetails: _userDetails,
        });

        onUserDetailsChanged(_userDetails);
      }
    }
  }

  setInputTextValue = (inputId, value) => {
    let _userDetails = this.state.userDetails;

    _userDetails[inputId] = value;

    this.setState({
      userDetails: _userDetails,
    });

    this.props.onUserDetailsChanged(_userDetails);
  };

  validateForm = () => {
    let _userDetails = this.state.userDetails;
    return helpers.validateUserDetailsForHomeDelivery(_userDetails);
  };

  updateCheckout = () => {
    this.props.onCheckoutInformationUpdated();
    this.props.setEditView(false);
  };

  getLocalTranslation = (key) => {
    return getTranslation(this.props.selectedLocale, key);
  };

  render() {
    let _saveDetails = getTranslation(
      this.props.selectedLocale,
      TRANSLATIONS_KEY_LIST.save_details
    );

    let { userDetails } = this.state;

    let { homeDetailsSaveError } = this.props;

    return (
      <>
        <section className="consumer-details-form">
          <div>
            <h4>
              {getTranslation(
                this.props.selectedLocale,
                TRANSLATIONS_KEY_LIST.enter_your_details
              )}
            </h4>
            <div className="form">
              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="firstName"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.first_name
                    )}
                  />
                </div>
                <div className="item">
                  <InputWithKeyboard
                    id="lastName"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.last_name
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="street"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.street
                    )}
                  />
                </div>

                <div className="item">
                  <InputWithKeyboard
                    id="houseNumber"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.house_number
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="zipCode"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.zip_code
                    )}
                  />
                </div>
                <div className="item">
                  <InputWithKeyboard
                    id="city"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.town_city
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="email"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.email_address
                    )}
                  />
                </div>
                <div className="item">
                  <InputWithKeyboard
                    id="phoneNumber"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.phone_number
                    )}
                  />
                </div>
              </div>
              <div className="col info-message">
                <div className="item">
                  <p>
                    *
                    {
                      (this,
                      this.getLocalTranslation(
                        TRANSLATIONS_KEY_LIST.verify_checkout
                      ))
                    }
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="item">
                  {false && (
                    <div>
                      <GlCheckbox
                        id="newsletterAccepted"
                        isChecked={userDetails?.newsletterAccepted}
                        label="Yes, I’d like to receive newsletters with exclusive sales
                and more."
                        onChange={(event) =>
                          this.setInputTextValue(
                            event.target.id,
                            event.target.checked
                          )
                        }
                      />
                    </div>
                  )}
                </div>
                <div
                  className="col item btn-primary-container"
                  onClick={(e) =>
                    this.validateForm() ? this.updateCheckout() : null
                  }
                >
                  {homeDetailsSaveError && (
                    <div className="error-text">
                      {this.getLocalTranslation(
                        TRANSLATIONS_KEY_LIST.error_occurred
                      )}
                    </div>
                  )}
                  <GlButton
                    aria-label="label"
                    theme={GL_BUTTON_THEME.light}
                    variant={GL_BUTTON_VARIANT.secondary}
                    disabled={!this.validateForm()}
                  >
                    {_saveDetails}
                  </GlButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps({
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

export default connect(mapStateToProps)(EditUserDetails);
