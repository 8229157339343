import {
  translations_nl_NL,
  translations_tr_TR,
  translations_en,
  translations_en_US,
  translations_en_CA,
  translations_fr_CA,
  translations_de_DE,
  translations_fr_FR,
  translations_ja_JP,
  translations_es_ES,
  translations_es_CL,
  translations_es_CO,
  translations_es_MX,
  translations_es_PE,
  translations_sv_SE,
  translations_it_IT,
  translations_pt_PT,
  translations_ru_RU,
  translations_pl_PL,
  translations_ko_KR,
} from "./translations/index.js";

export const TRANSLATIONS_KEY_LIST = {
  all_products: "all_products",
  go_to_top: "go_to_top",
  more_filters: "more_filters",
  less_filters: "less_filters",
  sort_by: "sort_by",
  clear_all: "clear_all",
  colors_available: "colors_available",
  colors_available_plp: "colors_available_plp",

  details: "details",
  how_to_style: "how_to_style",
  reviews: "reviews",
  gallery: "gallery",
  highlights: "highlights",

  select_size: "select_size",
  store_availability: "store_availability",

  size_guidance: "size_guidance",
  add_to_bag: "add_to_bag",
  scan_to_order_online: "scan_to_order_online",
  shopping_bag: "shopping_bag",
  max_cart_item_count_reached: "max_cart_item_count_reached",

  bag_empty: "bag_empty",
  browse_online: "browse_online",

  order_online: "order_online",

  start_browsing: "start_browsing",
  total: "total",
  including_tax: "including_tax",
  checkout: "checkout",
  delete_bag: "delete_bag",
  continue_on_this_device: "continue_on_this_device",
  checkout_on_your_device: "checkout_on_your_device",
  checkout_on_your_device_instruction: "checkout_on_your_device_instruction",
  employee_checkout: "employee_checkout",
  employee_checkout_title: "employee_checkout_title",
  employee_checkout_instruction: "employee_checkout_instruction",
  employee_id_instruction: "employee_id_instruction",
  employee_id_update_qr_code: "employee_id_update_qr_code",
  employe_id_validation_error: "employe_id_validation_error",
  change_employee_id: "change_employee_id",

  only_cash_on_delivery_option: "only_cash_on_delivery_option",
  yes_over_16_years_of_age: "yes_over_16_years_of_age",

  choose_your_shipping_method: "choose_your_shipping_method",
  order_summary: "order_summary",
  promo_code: "promo_code",
  place_order: "place_order",
  order_now_pay_on_delivery: "order_now_pay_on_delivery",

  read_more_reviews: "read_more_reviews",

  edit_address: "edit_address",
  save_details: "save_details",
  edit_details: "edit_details",
  select_store: "select_store",
  select_pickup_point: "select_pickup_point",
  back_to_list: "back_to_list",

  sure_to_delete_cart: "sure_to_delete_cart",
  cart_delete_message: "cart_delete_message",
  cancel: "cancel",

  add_voucher_heading: "add_voucher_heading",
  add_voucher_instruction: "add_voucher_instruction",
  add_voucher_disclaimer: "add_voucher_disclaimer",
  apply: "apply",

  your_order_in_progress: "your_order_in_progress",

  loading: "loading",
  your_order_is_placed: "your_order_is_placed",
  session_ends_in: "session_ends_in",
  estimated_delivery: "estimated_delivery",
  confirmation_email_sent_to: "confirmation_email_sent_to",
  order_number: "order_number",
  start_new_session: "start_new_session",
  extend_session: "extend_session",

  map_search_no_result: "map_search_no_result",

  enter_your_details: "enter_your_details",
  first_name: "first_name",
  last_name: "last_name",
  street: "street",
  house_number: "house_number",
  zip_code: "zip_code",
  town_city: "town_city",
  email_address: "email_address",
  phone_number: "phone_number",

  change_store: "change_store",
  change_pickup_point: "change_pickup_point",

  search_by_city_dictrict_or_address: "search_by_city_dictrict_or_address",

  processing: "processing",

  error_occurred: "error_occurred",

  retry_place_order: "retry_place_order",

  retry_start_checkout: "retry_start_checkout",

  bring_it_to_me: "bring_it_to_me",
  need_help: "need_help",

  call_store_associate: "call_store_associate",
  do_you_need_assistance_title: "do_you_need_assistance_title",
  do_you_need_assistance_instruction: "do_you_need_assistance_instruction",
  do_you_need_assistance_sure_to_delete:
    "do_you_need_assistance_sure_to_delete",
  do_you_need_assistance_delete_btn_label:
    "do_you_need_assistance_delete_btn_label",

  please_select_your_size: "please_select_your_size",
  we_will_bring_it_here: "we_will_bring_it_here",
  bring_it_to_me_instruction: "bring_it_to_me_instruction",

  bitm_none_active_title: "bitm_none_active_title",
  bitm_none_active_instruction: "bitm_none_active_instruction",

  bitm_sure_to_delete: "bitm_sure_to_delete",
  bitm_delete_btn_label: "bitm_delete_btn_label",

  bitm_task_todo: "bitm_task_todo",
  bitm_task_in_progress: "bitm_task_in_progress",
  bitm_task_complete: "bitm_task_complete",

  bitm_qr_title: "bitm_qr_title",
  bitm_qr_description: "bitm_qr_description",
  restart_checkout: "restart_checkout",

  try_again: "try_again",

  session_expiring_title: "session_expiring_title",
  session_expiring_body: "session_expiring_body",

  nearbystore_availability_overlay_title:
    "nearbystore_availability_overlay_title",

  available_in_nearby_store: "available_in_nearby_store",
  nearby_store_no_sizes_found: "nearby_store_no_sizes_found",

  please_try_selecting_another_store: "please_try_selecting_another_store",

  in_stock_at_this_store: "in_stock_at_this_store",

  employee_id: "employee_id",
  add_employee_id_heading: "add_employee_id_heading",
  add_employee_id_instruction: "add_employee_id_instruction",
  verify_checkout: "verify_checkout",

  search: "search",
  search_result_see_all: "search_result_see_all",

  search_no_result: "search_no_result",

  try_searching_for_something_else: "try_searching_for_something_else",

  start_new_search: "start_new_search",

  showing_results_for: "showing_results_for",

  no_available_sizes_found: "no_available_sizes_found",

  online: "online",
  in_store: "in_store",

  tap_to_get_started: "tap_to_get_started",
  your_journey_starts_here: "your_journey_starts_here",
  app_header_title: "app_header_title",
  landingpage_filter_heading: "landingpage_filter_heading",
  landingpage_signup_heading: "landingpage_signup_heading",
  landingpage_signup_cta: "landingpage_signup_cta",
  landingpage_events_cta: "landingpage_events_cta",
  landingpage_instore_services_cta: "landingpage_instore_services_cta",

  eventspage_heading: "eventspage_heading",
  eventspage_sub_heading: "eventspage_sub_heading",

  eventspage_store_event: "eventspage_store_event",
  eventspage_event_details: "eventspage_event_details",
  eventspage_raffle_timelines: "eventspage_raffle_timelines",
  eventspage_raffle_results: "eventspage_raffle_results",
  eventspage_sign_up_before: "eventspage_sign_up_before",
  eventspage_scan_and_sign_up: "eventspage_scan_and_sign_up",

  instoreservicepage_heading: "instoreservicepage_heading",
  instoreservicepage_sub_heading: "instoreservicepage_sub_heading",

  membershippage_title: "membershippage_title",
  membershippage_body: "membershippage_body",
  membershippage_feature_membership_discount:
    "membershippage_feature_membership_discount",
  membershippage_feature_freeshipping: "membershippage_feature_freeshipping",
  membershippage_feature_birthdaygifts: "membershippage_feature_birthdaygifts",
  membershippage_feature_priorityshopping:
    "membershippage_feature_priorityshopping",
  membershippage_feature_specialevents: "membershippage_feature_specialevents",

  change_language: "change_language",
};

const TRANSLATIONS = {
  ru: translations_ru_RU,
  de: translations_de_DE,
  en: translations_en,
  tr: translations_tr_TR,
  fr: translations_fr_FR,
  es_ES: translations_es_ES,
  es_CL: translations_es_CL,
  es_CO: translations_es_CO,
  es_MX: translations_es_MX,
  es_PE: translations_es_PE,
  ja_JP: translations_ja_JP,
  en_US: translations_en_US,
  en_CA: translations_en_CA,
  fr_CA: translations_fr_CA,
  sv_SE: translations_sv_SE,
  it_IT: translations_it_IT,
  nl_NL: translations_nl_NL,
  pt_PT: translations_pt_PT,
  pl_PL: translations_pl_PL,
  ko_KR: translations_ko_KR,
};

export const getTranslation = (locale, key) => {
  let _result;
  let _fallbackUsed = false;
  const _fallbackLanguage = "en";

  /* first check if translation for locale exists, and return that if found */
  if (TRANSLATIONS[locale] && TRANSLATIONS[locale][key]) {
    _result = TRANSLATIONS[locale][key];
  } else {
    /* otherwise return the first match based on language */
    try {
      _result = TRANSLATIONS[locale.split("_")[0]][key];
    } catch {
      _result = TRANSLATIONS[_fallbackLanguage][key];
      _fallbackUsed = true;
    }
  }

  if (_fallbackUsed) {
    console.log(
      `Translation missing for requested locale ${locale} for string ${key}, hence fallback language ${_fallbackLanguage} will be used`
    );
  }

  return _result;
};

let selectedLocale = null;

export const setTranslationLocale = (locale) => {
  selectedLocale = locale;
};

export const translateString = (key) => {
  if (!selectedLocale) {
    return key;
  }
  let _result;
  let _fallbackUsed = false;
  const _fallbackLanguage = "en";

  /* first check if translation for locale exists, and return that if found */
  if (TRANSLATIONS[selectedLocale] && TRANSLATIONS[selectedLocale][key]) {
    _result = TRANSLATIONS[selectedLocale][key];
  } else {
    /* otherwise return the first match based on language */
    try {
      _result = TRANSLATIONS[selectedLocale.split("_")[0]][key];
      if (!_result) {
        _result = TRANSLATIONS[_fallbackLanguage][key];
        _fallbackUsed = true;
      }
    } catch {
      _result = TRANSLATIONS[_fallbackLanguage][key];
      _fallbackUsed = true;
    }
  }

  if (_fallbackUsed) {
    console.log(
      `Translation missing for requested locale ${selectedLocale} for string ${key}, hence fallback language ${_fallbackLanguage} will be used`
    );
  }

  return _result;
};
