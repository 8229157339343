export const translations_fr_CA = {
  all_products: "Tous les produits",
  go_to_top: "Haut de la page",
  more_filters: "Plus de filtres",
  less_filters: "Moins de filtres",
  sort_by: "Trier par",
  clear_all: "Tout supprimer",
  colors_available: "<count> couleurs disponibles",
  colors_available_plp: "<count> couleurs",

  details: "Détails",
  how_to_style: "Trouve ton style",
  reviews: "Avis",
  gallery: "Galerie",
  highlights: "Caractéristiques",

  select_size: "Choisis une taille",
  store_availability: "Voir les autres magasins",

  size_guidance: "Guide des tailles",
  add_to_bag: "Ajouter au panier",
  scan_to_order_online: "Scanne pour commander en ligne",
  shopping_bag: "Panier",
  bag_empty: "Ton panier est vide",

  max_cart_item_count_reached: "Max. articles atteints",

  browse_online: (
    <>
      Fais-toi plaisir et trouve ton style ! <br />
      Explore ce kiosque pour vérifier le stock dans ce magasin ou commander des
      articles en ligne.
    </>
  ),

  order_online: "Commander en ligne",

  start_browsing: "Explorer le shop",
  total: "Total ",
  including_tax: "(Taxes comprises)",
  checkout: "Paiement",
  delete_bag: "Supprimer le panier",

  continue_on_this_device: "Paye ici",
  checkout_on_your_device: "Paye avec ton téléphone",

  checkout_on_your_device_instruction:
    "Scanne le QR Code pour transférer ton panier sur l'appli adidas ou sur le site via ton téléphone.",

  employee_checkout: "Sortie de l'employé",
  employee_checkout_title: "Check-out avec un employé",
  employee_checkout_instruction:
    "Scannez ce code QR pour transférer votre sac et payer vos articles.",
  employee_id_instruction:
    "Veuillez entrer votre identifiant d'employé et mettre à jour le code QR.",
  employee_id_update_qr_code: "Mettre à jour le code QR",
  employe_id_validation_error: "Identifiant d'employé invalide",
  change_employee_id: "Changer l'identifiant de l'employé",

  only_cash_on_delivery_option:
    "Remarque : nous n'acceptons le paiment en liquide que pour les commandes passées via le kiosque.",

  yes_over_16_years_of_age: "J'ai plus de 16 ans",

  choose_your_shipping_method: "Choisis un mode de livraison",
  order_summary: "Récapitulatif de la commande",
  promo_code: "Code promo",
  place_order: "Commander",
  order_now_pay_on_delivery: "Commande maintenant, paye à la livraison",

  read_more_reviews: "Lire plus de commentaires",

  edit_address: "Modifier l'adresse",
  save_details: "Enregistrer",
  edit_details: "Modifier les détails",
  select_pickup_point: "Sélectionner ce lieu",
  select_store: "Sélectionner ce magasin",
  back_to_list: "Retour aux produits",

  sure_to_delete_cart: "Tu veux supprimer ce panier ?",
  cart_delete_message:
    "Tous les articles que tu as ajoutés seront supprimés et ta session se terminera.",
  cancel: "Annuler",

  add_voucher_heading: "Ajouter un code promo",
  add_voucher_instruction:
    "Merci de respecter les lettres majuscules et minuscules.",
  add_voucher_disclaimer:
    "Certains articles peuvent être exclus de certains codes promo. Merci de consulter les Conditions Générales.",
  apply: "Appliquer",

  loading: "Chargement...",
  your_order_in_progress: "Chargement...",
  your_order_is_placed: "Commande terminée !",
  session_ends_in: "La session se termine dans",
  estimated_delivery: "Livraison estimée",
  confirmation_email_sent_to: "Un e-mail de confirmation a été envoyé à",
  order_number: "Numéro de commande :",
  start_new_session: "Démarrer une nouvelle session",
  extend_session: "Continuer",

  map_search_no_result:
    "Désolé, nous n'avons trouvé aucun résultat. Merci de réessayer.",

  enter_your_details: "Informations de contact",
  first_name: "Prénom",
  last_name: "Nom",
  street: "Rue",
  house_number: "Numéro",
  zip_code: "Code postal",
  town_city: "Ville",
  email_address: "Adresse e-mail",
  phone_number: "Numéro de téléphone",

  change_store: "Changer de magasin",
  change_pickup_point: "Changer de point de retrait",

  search_by_city_dictrict_or_address:
    "Rechercher par ville, arrondissement ou adresse",

  processing: "Traitement en cours...",

  error_occurred: "Désolé, une erreur est survenue.",

  retry_place_order: "Essaye de repasser ta commande.",

  retry_start_checkout: "Essaye de recommence le paiement.",

  please_select_your_size: "Choisis une taille",
  bring_it_to_me: "Me l'apporter",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Appeler un vendeur",
  do_you_need_assistance_title: "Besoin d'aide ? Nous viendrons vers toi",
  do_you_need_assistance_instruction:
    "Clique sur le bouton et un de nos vendeurs viendra t'aider.",
  do_you_need_assistance_sure_to_delete:
    "Es-tu sûr(e) de vouloir supprimer cette demande ?",
  do_you_need_assistance_delete_btn_label: "Supprimer la demande",

  we_will_bring_it_here: "Tu préfères qu'on t'apporte tes articles ?",
  bring_it_to_me_instruction:
    "Clique sur le bouton, reste connecté(e) à cet écran et nous t'apporterons les articles demandés dans 3-5 minutes environ.",

  bitm_none_active_title: "Tu as trouvé un modèle qui te plaît ?",
  bitm_none_active_instruction:
    "Laisse-nous te l'apporter ! Il te suffit de sélectionner les articles et la taille souhaités, et de cliquer sur ME L'APPORTER sur ce kiosque ou sur l'appli adidas. ",

  bitm_sure_to_delete: "Es-tu sûr(e) de vouloir supprimer cette demande ?",
  bitm_delete_btn_label: "Supprimer la demande",

  bitm_task_todo: "Demande sur la liste d'attente",
  bitm_task_in_progress: "Je suis en chemin !",
  bitm_task_complete: "Terminé !",

  bitm_qr_title: "Explore en attendant",
  bitm_qr_description:
    "Scanne le QR code pour finaliser ta demande sur l'appli. Tu peux te promener dans le magasin, nous viendrons vers toi quand tes articles seront prêts.",
  restart_checkout: "Un problème ? Actualiser la page",

  try_again: "Réessayer",

  session_expiring_title: "Tu as fini ?",
  session_expiring_body: "Session en cours terminée dans",

  nearbystore_availability_overlay_title:
    "Vérifier la disponibilité dans les autres magasins",

  available_in_nearby_store: "Tailles disponibles sur ce site",

  nearby_store_no_sizes_found:
    "L'article que tu recherches n'est plus en stock dans ce magasin.",

  please_try_selecting_another_store: "Merci de choisir un autre magasin.",

  in_stock_at_this_store: "En stock dans ce magasin",

  employee_id: "Matricule employé",

  add_employee_id_heading: "Ajouter un matricule employé",

  add_employee_id_instruction:
    "Demande à un vendeur de t'aider pour continuer.",

  verify_checkout: "Merci de revérifier les informations avant d'enregistrer.",

  search: "Rechercher",

  search_result_see_all: "Tout afficher pour <search-text>",

  search_no_result: "Désolé, aucun résultat trouvé",

  try_searching_for_something_else: "Essaye de chercher autre chose.  ",

  start_new_search: "Commencer une nouvelle recherche",

  showing_results_for: "Résultats de la recherche pour",

  no_available_sizes_found: "Aucune taille n'est disponible pour le moment.",

  online: "Online",
  in_store: "In Store",

  tap_to_get_started: "Appuyez pour commencer", // not used
  your_journey_starts_here: "Votre voyage commence ici",
  app_header_title:
    "Parcourez ici et découvrez les produits les plus récents et les meilleurs",
  landingpage_filter_heading: "Pour qui magasinez-vous",
  landingpage_signup_heading: "Déverrouillez le meilleur d'adidas",
  landingpage_signup_cta:
    "Inscrivez-vous pour acheter notre équipement exclusif aux membres",
  landingpage_events_cta: "Explorez nos prochaines sorties et événements",
  landingpage_instore_services_cta: "Découvrez nos services en magasin",
  eventspage_heading: "Découvrez les événements adidas",
  eventspage_sub_heading:
    "Obtenez le meilleur de votre expérience de magasinage avec nos événements exclusifs.",

  eventspage_store_event: "Événement en magasin",
  eventspage_event_details: "Détails de l'événement",
  eventspage_raffle_timelines: "Calendriers de tirages au sort",
  eventspage_raffle_results: "Résultats des tirages au sort",
  eventspage_sign_up_before: "Inscrivez-vous avant",
  eventspage_scan_and_sign_up: "Scannez et inscrivez-vous",

  instoreservicepage_heading: "Découvrez nos services en magasin",
  instoreservicepage_sub_heading:
    "Obtenez le meilleur de votre expérience de magasinage avec nos services exclusifs.",

  membershippage_title: "DEVENIR MEMBRE GRATUITEMENT",
  membershippage_body:
    "Commencez à dépenser vos points adiClub pour être récompensé encore plus pour ce que vous aimez.",
  membershippage_feature_membership_discount: "rabais pour les membres",
  membershippage_feature_freeshipping: "livraison gratuite",
  membershippage_feature_birthdaygifts: "cadeaux d'anniversaire",
  membershippage_feature_priorityshopping: "achats prioritaires",
  membershippage_feature_specialevents: "événements spéciaux",

  change_language: "changer de langue",
};
