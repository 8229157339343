import React, { Component } from "react";
import { connect } from "react-redux";

import SidebarMenu from "../SidebarMenu/SidebarMenu";
import InteractionVideoView from "../InteractionVideoView/InteractionVideoView";

import BarcodeSSE from "../BarCodeSSE/BarCodeSSE";

import Timeout from "./components/Timeout";

import { fetchAppSettings } from "../../actions/appSettingsActions";
import { ScreenBurnLine } from "react-screen-burn";
import { API } from "../../API/index";

import {
  GlButton,
  GL_BUTTON_VARIANT,
  GlCallout,
  GlHeading,
  GlParagraph,
} from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  setTranslationLocale,
  translateString,
} from "../../utils/translationCopies";

import HomeView from "../HomeView/HomeView";
import {
  setCurrentPageName,
  PAGE_NAMES,
  setAvailableBrowserStorage,
} from "../../actions/pageActions";

class ViewRouter extends Component {
  componentDidMount() {
    this.props.fetchAppSettings();

    if ("storage" in navigator && "estimate" in navigator.storage) {
      navigator.storage.estimate().then(({ usage, quota }) => {
        let usagePercentage = (usage / quota) * 100;
        let usageThreshold = 90;

        if (usagePercentage > usageThreshold) {
          console.warn(
            `browser storage is reaching its quota, current usage = ${usagePercentage}`
          );

          this.clearBrowserAppDataCache();
        }

        this.props.setAvailableBrowserStorage({
          usage: usage,
          quota: quota,
          usage_percentage: usagePercentage,
        });

        console.info("browser storage quota usage details", {
          usage: usage,
          quota: quota,
          usage_percentage: usagePercentage,
        });
      });
    }

    // blocking the background/ body scroll when ther e is an overlay or any other page open
    if (this.props?.sideMenu !== "plp" || this.props?.currentPage !== "plp") {
      let element = document.getElementById("body");
      if (element) {
        element.classList.remove("no-scroll");
        element.classList.add("no-scroll");
      }
    } else {
      let element = document.getElementById("body");
      if (element) {
        element.classList.remove("no-scroll");
      }
    }
  }

  clearBrowserAppDataCache = () => {
    const cachesToKeep = [
      "workbox-precache-v2-https://dev-ssk.digital-signage.adidas.com/",
      "workbox-precache-v2-https://stg-ssk.digital-signage.adidas.com/",
      "workbox-precache-v2-https://ssk.digital-signage.adidas.com/",
    ];

    console.log("delete media caches process -->started..");

    caches
      .keys()
      .then((keyList) =>
        Promise.all(
          keyList.map((key) => {
            if (!cachesToKeep.includes(key)) {
              return caches.delete(key);
            }

            return null;
          })
        )
      )
      .then(console.log("delete media caches process -->completed.."));

    console.log("clearing Product List data cache -> started...");
    API.productsAPI.deleteProductsDatabase();
    console.log("clearing Product List data cache -> completed...");
  };

  componentDidUpdate(prevProps) {
    // blocking the background/ body scroll when ther e is an overlay or any other page open
    if (
      prevProps?.sideMenu !== this.props?.sideMenu ||
      prevProps?.currentPage !== this.props?.currentPage
    ) {
      if (
        this.props?.sideMenu !== "plp" ||
        this.props?.currentPage !== "plp" ||
        this.props?.currentPage !== PAGE_NAMES.landingPage ||
        this.props?.currentPage !== PAGE_NAMES.eventsPage ||
        this.props?.currentPage !== PAGE_NAMES.membershipPage
      ) {
        let element = document.getElementById("body");
        if (element) {
          element.classList.remove("no-scroll");
          element.classList.add("no-scroll");
        }
      } else {
        let element = document.getElementById("body");
        if (element) {
          element.classList.remove("no-scroll");
        }
      }
    }

    if (
      Object.keys(this.props.appSettings).length === 0 &&
      this.props.appSettings.constructor === Object
    ) {
      const retryAfterSec = 30;

      console.log(
        `App settings data empty {}, will auto-retry after ${retryAfterSec} seconds again...`
      );
      setTimeout(() => {
        if (
          Object.keys(this.props.appSettings).length === 0 &&
          this.props.appSettings.constructor === Object
        ) {
          this.props.fetchAppSettings();
        }
      }, retryAfterSec * 1000);
    }

    if (
      prevProps?.selectedTranslationLocale !==
      this.props?.selectedTranslationLocale
    ) {
      let _translationLocale = this.props.selectedTranslationLocale;
      let _defaultLocale = this.props.selectedLocale;

      setTranslationLocale(_translationLocale || _defaultLocale);
    }

    if (prevProps?.appSettings !== this.props?.appSettings) {
      let interactionVideoisEnabled =
        this.props.appSettings?.value?.interaction_video_screen?.enabled ||
        false;

      const enabledLandingPage =
        (this.props.appSettings?.value?.landing_page?.enabled &&
          (this.props.appSettings?.value?.landing_page?.filter_section
            ?.enabled ||
            this.props.appSettings?.value?.landing_page?.membership_section
              ?.enabled ||
            this.props.appSettings?.value?.landing_page?.events_section
              ?.enabled ||
            this.props.appSettings?.value?.landing_page
              ?.instore_services_section?.enabled)) ||
        false;

      if (interactionVideoisEnabled) {
        this.props.setCurrentPageName(PAGE_NAMES.interactionVideo);
      } else if (enabledLandingPage) {
        this.props.setCurrentPageName(PAGE_NAMES.landingPage);
      } else {
        this.props.setCurrentPageName(PAGE_NAMES.plp);
      }
    }
  }

  _resetTimoutTimer = () => {
    if (window._setUserActionTime) window._setUserActionTime();
  };

  getTranslation(key) {
    return translateString(key);
  }

  onVideoTap = () => {
    this.props.setCurrentPageName(PAGE_NAMES.landingPage);
  };

  render() {
    const enabledNearbyStoreAvailability =
      this.props?.appSettings?.value?.enableScannerDebugInfo || false;

    let shouldShowSideBar = ![
      PAGE_NAMES.interactionVideo,
      PAGE_NAMES.membershipPage,
    ].includes(this.props.currentPage);

    let shouldActivateIdleTimer = ![PAGE_NAMES.interactionVideo].includes(
      this.props.currentPage
    );

    return (
      <>
        <ScreenBurnLine
          colors={["#000"]}
          retriggerTime={1800000}
          size={100}
          triggerTime={1800000}
        />

        {/* todo check these 2 below once checkout is enabled */}

        {shouldActivateIdleTimer && (
          <>
            <Timeout></Timeout>
          </>
        )}

        {shouldShowSideBar && (
          <>
            <SidebarMenu
              resetTimoutTimer={this._resetTimoutTimer}
            ></SidebarMenu>
          </>
        )}

        <div
          className={`sse-debug ${
            enabledNearbyStoreAvailability ? "" : "hidden"
          }`}
        >
          {this.props.appSettings?.value?.player?.receive_hardware_scan_input &&
          this.props.sideMenu !== "checkout" ? (
            <BarcodeSSE></BarcodeSSE>
          ) : (
            <></>
          )}
        </div>

        {JSON.stringify(this.props.appSettings) === "{}" ? (
          <div className="plp-error-message-container">
            <GlCallout type="urgent">
              <GlHeading as="h4" fontStyle="regular">
                {this.getTranslation(TRANSLATIONS_KEY_LIST.error_occurred)}
              </GlHeading>
              <GlParagraph>
                {
                  <GlButton
                    aria-label="label"
                    variant={GL_BUTTON_VARIANT.tertiary}
                    onClick={() => window.location.reload()}
                  >
                    {this.getTranslation(TRANSLATIONS_KEY_LIST.try_again)}
                  </GlButton>
                }
              </GlParagraph>
            </GlCallout>
          </div>
        ) : (
          <>
            {this.getApplicationView(
              this.props.currentPage === PAGE_NAMES.interactionVideo
            )}
          </>
        )}
      </>
    );
  }

  getApplicationView = (isInteractionVideo) => {
    if (isInteractionVideo) {
      return (
        <InteractionVideoView
          onVideoTap={this.onVideoTap}
        ></InteractionVideoView>
      );
    } else {
      return <HomeView resetTimoutTimer={this._resetTimoutTimer}></HomeView>;
      // return <EventsOverlayView />;
    }
  };
}

function mapStateToProps({
  appSettings,
  checkout,
  sideMenu,
  currentPage,
  selectedLocale,
  selectedTranslationLocale,
}) {
  return {
    appSettings,
    checkout,
    sideMenu,
    currentPage,
    selectedLocale,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAppSettings: () => dispatch(fetchAppSettings()),
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
    setAvailableBrowserStorage: (val) =>
      dispatch(setAvailableBrowserStorage(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRouter);
