import React, { Component } from "react";

import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";

import "./EventsListView.css";

import { connect } from "react-redux";

import { GlHeading } from "@adl/foundation";
import EventsOverlayView from "./components/EventsOverlayView";
import NewBackButton from "../NewBackButton/NewBackButton";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

class EventsListView extends Component {
  state = {
    genderList: [],
    isProductListDataLoadError: null,
    eventSelected: null,
  };

  componentDidMount() {
    if (PAGE_NAMES.eventsPage !== this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.eventsPage);
    }
  }

  componentDidUpdate(prevProps) {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    return (
      <>
        {PAGE_NAMES.eventsPage === this.props.currentPage && (
          <>
            <div className="events events-page-wrapper">
              <GlHeading as="h2" fontStyle="regular" className="heading">
                {this.getTranslation(TRANSLATIONS_KEY_LIST.eventspage_heading)}
              </GlHeading>
              <p className="sub-heading">
                {this.getTranslation(
                  TRANSLATIONS_KEY_LIST.eventspage_sub_heading
                )}
              </p>
              <div className="section tiles-container">
                {this.props.eventsList.map((x) => (
                  <div
                    className="tile"
                    onClick={() => {
                      this.props.setCurrentPageName(
                        PAGE_NAMES.eventDetailsPage
                      );

                      this.setState({ eventSelected: x });
                    }}
                  >
                    <div>
                      <img alt="event" src={x?.imageUrl} />
                    </div>
                    <div className="secondary-masthead">
                      <div className="container">
                        <div className="event-name-block">
                          <div className="sub-heading ">
                            <span>
                              <span>
                                {x?.title?.length > 28
                                  ? x?.title?.substring(0, 28) + "..."
                                  : x?.title}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <NewBackButton
              onClick={() => {
                this.props.setCurrentPageName(PAGE_NAMES.landingPage);
              }}
            ></NewBackButton>
          </>
        )}

        {PAGE_NAMES.eventDetailsPage === this.props.currentPage && (
          <EventsOverlayView
            eventDetails={this.state.eventSelected}
          ></EventsOverlayView>
        )}
      </>
    );
  }
}

function mapStateToProps({
  appSettings,
  currentPage,
  eventsList,
  selectedTranslationLocale,
}) {
  return {
    appSettings,
    currentPage,
    eventsList,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsListView);
