import React, { useState } from "react";

import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlLoader,
} from "@adl/foundation";

import ModalView from "../../../../../../../ModalView/ModalView";

import "./BITMConfirmationModal.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../../../utils/translationCopies";

import * as analytics from "../../../../../../../../analytics";

import QRCode from "react-qr-code";

function BITMConfirmationModal(props) {
  // const [isValid, setIsValid] = useState(false);

  const [viewQRUrl, setViewQRUrl] = useState(false);

  const _getTranslation = (key) => {
    return translateString(key);
  };

  return (
    <div>
      <ModalView
        showCloseBtn={true}
        show={props.show}
        onClose={() => {
          props.onClose();
        }}
        className="modal-centered bitm-confirmation"
      >
        <div className="bitm-confirmation_container">
          <div>
            <div
              className={`bitm-confirmation__content_left ${
                false ? "bitm-confirmation__content_left__wide" : ""
              }`}
            >
              <div className="title">
                <h4>{_getTranslation(TRANSLATIONS_KEY_LIST.bitm_qr_title)}</h4>
              </div>

              <div className="qr_code">
                {/* <QRCode value={"https://www.adidas.de"} size={160} /> */}
                <QRCode value={props.qrUrl} size={370} />
              </div>

              <div className="debug_qr_url hidden">
                <p
                  onClick={(e) => {
                    setViewQRUrl(!viewQRUrl);
                  }}
                >
                  {!viewQRUrl ? "Debug: view url ?" : "Debug: hide url"}
                </p>
                {viewQRUrl && <p>{props.qrUrl}</p>}
                <p></p>
              </div>

              <div className="instruction">
                <p>
                  {_getTranslation(TRANSLATIONS_KEY_LIST.bitm_qr_description)}
                </p>
              </div>
            </div>
          </div>
          {!false && (
            <div>
              <div className="bitm-confirmation__content_right">
                <div className="upper">
                  <div className="title">
                    <h4>
                      {_getTranslation(
                        TRANSLATIONS_KEY_LIST.we_will_bring_it_here
                      )}
                    </h4>
                  </div>

                  <div className="body">
                    <p>
                      {_getTranslation(
                        TRANSLATIONS_KEY_LIST.bring_it_to_me_instruction
                      )}
                    </p>
                  </div>
                </div>

                <div className="lower">
                  <div>
                    {props?.isBITMCreationInProgress && (
                      <div className="loader-wrapper">
                        <GlLoader></GlLoader>
                      </div>
                    )}

                    {props?.isBITMCreationError && (
                      <div>
                        <p className="error-text">
                          {" "}
                          {_getTranslation(
                            TRANSLATIONS_KEY_LIST.error_occurred
                          )}
                        </p>
                      </div>
                    )}

                    {!props?.isBITMCreationInProgress && (
                      <GlButton
                        aria-label="label"
                        theme={GL_BUTTON_THEME.light}
                        variant={GL_BUTTON_VARIANT.secondary}
                        onClick={() => {
                          props.onConfirm();

                          //Log BITM analytics
                          analytics.bitm({
                            storeId: props.appData.ssmId,
                            hostname: props.appData.hostname,
                            country: props.appData.country,
                            ssmId: props.appData.ssmId,
                            sku: props.appData.sku,
                            productId: props.appData.productId,
                          });
                        }}
                      >
                        {_getTranslation(TRANSLATIONS_KEY_LIST.bring_it_to_me)}
                      </GlButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalView>
    </div>
  );
}

export default BITMConfirmationModal;
