export const translations_de_DE = {
  all_products: "Alle Produkte",
  go_to_top: "Nach oben",
  more_filters: "Weitere Filter",
  less_filters: "Weniger Filter",
  sort_by: "Sortieren nach",
  clear_all: "Alle löschen",
  colors_available: "<count> Farben verfügbar",
  colors_available_plp: "<count> Farben",

  details: "Details",
  how_to_style: "Style-Inspiration",
  reviews: "Bewertungen",
  gallery: "Galerie",
  highlights: "Das Wichtigste",

  select_size: "Wähle eine Größe",
  store_availability: "In anderen Stores",

  size_guidance: "Größentabelle",
  add_to_bag: "In den Warenkorb",
  scan_to_order_online: "Scannen & online bestellen",
  shopping_bag: "Warenkorb",

  max_cart_item_count_reached: "Max. Bestellmenge erreicht",

  bag_empty: "Gönn dir was Schönes! ",
  browse_online:
    "An diesem Terminal siehst du die Produktverfügbarkeit in diesem Store oder kannst online bestellen.",
  order_online: "online bestellen",

  start_browsing: "weiter", // not used in UI anymore (was requested removal from UI during DE launch)
  total: "Gesamt ",
  including_tax: "(inkl. Steuern)",
  checkout: "Zur Kasse",
  delete_bag: "Warenkorb leeren",

  continue_on_this_device: "Hier abschließen",
  checkout_on_your_device: "Einkauf auf dem Smartphone abschließen",

  checkout_on_your_device_instruction:
    "Scanne den QR-Code, um den Warenkorb in die adidas App oder auf die adidas Website auf deinem Smartphone zu übertragen.",

  employee_checkout: "Mitarbeiter-Checkout",
  employee_checkout_title: "Mit einem Mitarbeiter auschecken",
  employee_checkout_instruction:
    "Scannen Sie diesen QR-Code, um Ihre Tasche zu übertragen und für Ihre Artikel zu bezahlen.",
  employee_id_instruction:
    "Bitte geben Sie Ihre Mitarbeiter-ID ein und aktualisieren Sie den QR-Code.",
  employee_id_update_qr_code: "QR-Code aktualisieren",
  employe_id_validation_error: "Ungültige Mitarbeiter-ID",
  change_employee_id: "Mitarbeiter-ID ändern",

  only_cash_on_delivery_option:
    "Hinweis: Für Bestellungen über dieses Terminal akzeptieren wir nur die Zahlungsart Nachnahme.",
  yes_over_16_years_of_age: "Ich bin über 16 Jahre alt",

  choose_your_shipping_method: "Versandart wählen",
  order_summary: "Bestellübersicht",
  promo_code: "Promocode",
  place_order: "Bestellung abschicken",
  order_now_pay_on_delivery: "Jetzt bestellen und bei Lieferung bezahlen",

  read_more_reviews: "Weitere Bewertungen lesen",

  edit_address: "Adresse bearbeiten",
  save_details: "Speichern",
  edit_details: "Daten bearbeiten",
  select_pickup_point: "Diesen Abholort auswählen",
  select_store: "Diesen Store auswählen",
  back_to_list: "Zurück zu den Artikeln",

  sure_to_delete_cart: "Möchtest du den Warenkorb leeren?",
  cart_delete_message:
    "Alle Artikel, die du hinzugefügt hast, werden gelöscht und deine Sitzung wird beendet.",
  cancel: "Abbrechen",

  add_voucher_heading: "Gutschein oder Promocode hinzufügen",
  add_voucher_instruction:
    "Groß- und Kleinschreibung beachten, wie abgebildet eingeben",
  add_voucher_disclaimer:
    "Einige Artikel können von bestimmten Promocodes ausgeschlossen sein. Bitte beachte die Nutzungsbedingungen.",
  apply: "Anwenden",

  loading: "Wird verarbeitet ...",
  your_order_in_progress: "Lädt …",
  your_order_is_placed: "Bestellung abgeschickt!",
  session_ends_in: "Sitzung endet in",
  estimated_delivery: "Voraussichtliche Lieferung",
  confirmation_email_sent_to: "Du erhältst eine Bestätigung an:",
  order_number: "Bestellnummer:",
  start_new_session: "Neue Sitzung",
  extend_session: "Sitzung fortsetzen",

  map_search_no_result:
    "Sorry, wir konnten keine Ergebnisse finden. Bitte versuch es noch einmal.",

  enter_your_details: "Kontaktdaten",
  first_name: "Vorname",
  last_name: "Nachname",
  street: "Straße",
  house_number: "Hausnummer",
  zip_code: "Postleitzahl",
  town_city: "Stadt/Gemeinde",
  email_address: "E-Mail-Adresse",
  phone_number: "Telefonnummer",

  change_store: "Store ändern",
  change_pickup_point: "Abholort ändern",

  search_by_city_dictrict_or_address: "Such nach einer Stadt oder Adresse",

  processing: "Wird verarbeitet ...",

  error_occurred: "Leider ist ein Fehler aufgetreten",

  retry_place_order: "Nochmal versuchen",

  retry_start_checkout: "Nochmal versuchen",

  bring_it_to_me: "Bring It To Me",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Store-Mitarbeiter_in rufen",
  do_you_need_assistance_title: "Brauchst du Hilfe? Wir kommen zu dir!",
  do_you_need_assistance_instruction: "Brauchst du Hilfe?",
  do_you_need_assistance_sure_to_delete:
    "Möchtest du diese Anfrage wirklich löschen?",
  do_you_need_assistance_delete_btn_label: "Anfrage löschen",

  please_select_your_size: "Wähle eine Größe",

  we_will_bring_it_here: "Oder warte hier",
  bring_it_to_me_instruction:
    "Tippe auf den Button und bleib in der Nähe dieses Bildschirms. Wir bringen deine Artikel in ca. 3–5 Minuten hierher.",

  bitm_none_active_title: "Hast du was gefunden?",
  bitm_none_active_instruction:
    "Wir bringen es dir! Wähl einfach die Artikel in der Größe, die du anprobieren möchtest, und nutz unseren Bring It To Me Service an diesem Terminal oder in der adidas App.",

  bitm_sure_to_delete: "Möchtest du diese Anfrage wirklich löschen?", // missing in trans rcved, taking value from do_you_need_assistance_sure_to_delete
  bitm_delete_btn_label: "Anfrage löschen",

  bitm_task_todo: "Anfrage in der Warteschleife",
  bitm_task_in_progress: "Unterwegs zu dir!",
  bitm_task_complete: "Erledigt!",

  bitm_qr_title: "Schau dich weiter um",
  bitm_qr_description:
    "Scanne den QR-Code, um deine Anfrage in der App abzuschließen. Schau dich einfach weiter im Store um – wir kommen mit den Artikeln zu dir.",
  restart_checkout: "Passt was nicht? Seite aktualisieren",

  try_again: "Nochmal versuchen",

  session_expiring_title: "Bist du fertig?",
  session_expiring_body: "Aktuelle Sitzung endet in",

  nearbystore_availability_overlay_title:
    "Verfügbarkeit in anderen Stores prüfen",

  available_in_nearby_store: "Verfügbare Größen in diesem Store",

  nearby_store_no_sizes_found:
    "Der gesuchte Artikel ist in diesem Store momentan nicht auf Lager.",

  please_try_selecting_another_store:
    "Bitte versuch es mit einem anderen Store.",

  in_stock_at_this_store: "In diesem Store auf Lager",

  employee_id: "Mitarbeiter-ID",

  add_employee_id_heading: "Mitarbeiter-ID eingeben",

  add_employee_id_instruction:
    "Um fortzufahren, bitte unser Store-Team um Hilfe.",

  verify_checkout: "Bitte überprüfe vor dem Speichern deine Daten",

  search: "Suche",

  search_result_see_all: "Alle Ergebnisse für <search-text> anzeigen",

  search_no_result: "Sorry, keine Ergebnisse",

  try_searching_for_something_else: "Probier es mit einem anderen Suchbegriff.",

  start_new_search: "Erneut suchen", // not used

  showing_results_for: "Suchergebnisse für",

  no_available_sizes_found: "Momentan sind alle Größen ausverkauft",

  online: "Online",
  in_store: "Im Store",

  tap_to_get_started: "Tippen und los geht’s", // not used
  your_journey_starts_here: "Deine Reise beginnt hier",
  app_header_title: "Entdecke die neuesten & besten Produkte",
  landingpage_filter_heading: "Für wen soll’s sein?",
  landingpage_signup_heading: "Hol dir das Beste von adidas",
  landingpage_signup_cta:
    "Meld dich an und shoppe exklusive Produkte nur für Members",
  landingpage_events_cta: "Schau nach, welche Releases & Events anstehen",
  landingpage_instore_services_cta: "Entdecke unsere Services im Store",

  eventspage_heading: "Entdecke unsere adidas Events",
  eventspage_sub_heading:
    "Mach mit unseren exklusiven Events mehr aus deinem Shopping-Erlebnis.",

  eventspage_store_event: "Ladenevent",
  eventspage_event_details: "Veranstaltungsdetails",
  eventspage_raffle_timelines: "Verlosungszeitpläne",
  eventspage_raffle_results: "Verlosungsergebnisse",
  eventspage_sign_up_before: "Anmeldung vor",
  eventspage_scan_and_sign_up: "Scannen und anmelden",

  instoreservicepage_heading: "Entdecke unsere Services im Store",
  instoreservicepage_sub_heading:
    "Mach mit unseren exklusiven Services mehr aus deinem Shopping-Erlebnis.",

  membershippage_title: "Werde kostenlos Member in unserem Vorteilsprogramm",
  membershippage_body:
    "Lös deine adiClub Punkte ein und bekomm dafür noch mehr von dem, was du liebst.",
  membershippage_feature_membership_discount: "Mitglieder-Rabatte",
  membershippage_feature_freeshipping: "kostenloser Versand",
  membershippage_feature_birthdaygifts: "Geburtstagsgeschenke",
  membershippage_feature_priorityshopping: "priorisierte Einkäufe",
  membershippage_feature_specialevents: "spezielle Veranstaltungen",

  change_language: "Sprache ändern",
};
