import React, { Component } from "react";
import "./ProductDetails.css";

import ProductSidePanel from "./components/ProductSidePanel/ProductSidePanel";

import ProductDetailsWrapper from "./components/ProductDetailsWrapper/ProductDetailsWrapper";

import { Events, scroller } from "react-scroll";

import { translateString } from "../../../../utils/translationCopies";

class ProductDetails extends Component {
  state = {
    productDescriptionReStructured: [],
    navigationTabs: [
      { id: 0, label: "gallery", available: true },
      { id: 1, label: "details", available: false },
      { id: 2, label: "highlights", available: false },
      { id: 3, label: "how_to_style", available: false },
      { id: 4, label: "reviews", available: false },
      // { id: 5, label: "complete the look", available: true },
    ],
    stickyNav: false,
    activeTabIndex: 0,
  };

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  componentDidMount() {
    this.updateTabsTranslation();

    let { productDescription } = this.props;

    this.setState({
      productDescription: productDescription,
    });

    this.reStructureDescriptionData(this.props.productDescription);

    Events.scrollEvent.register("begin", function () {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      // console.log("end", arguments);
    });
  }

  updateTabsTranslation = () => {
    let { navigationTabs } = this.state;

    navigationTabs.forEach((element) => {
      element.localizedLabel = this.getTranslation(element.label);
    });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.productDescription) !==
      JSON.stringify(this.props.productDescription)
    ) {
      this.setState({
        productDescription: this.props.productDescription,
      });

      this.reStructureDescriptionData(this.props.productDescription);
    }
  }

  reStructureDescriptionData = (data) => {
    let updatedArray = [];

    let index = 0;

    for (var i = 0; i < data?.embedded?.story?.elements.length; i++) {
      let newItem = data?._embedded?.story.elements[i];
      newItem.index = index++;
      updatedArray.push(newItem);
    }

    this.setState({ productDescriptionReStructured: updatedArray });
  };

  setActiveTabIndex = (index) => {
    let { navigationTabs } = this.state;

    setTimeout(() => {
      this.scrollToWithContainer("section_" + index);
    }, 100);

    navigationTabs.forEach((element) => {
      element.active = false;
      if (element.id === index) {
        element.active = true;
      }
    });

    this.setState({ activeTabIndex: index, navigationTabs: navigationTabs });
  };

  scrollToWithContainer(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "pdp__wrapper",
      offset: -140, //offset due to sticky header
    });
  }

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    console.log("##### rendering product details !!!");
    let {
      product,
      productDescription,
      productReviews,
      productReviewStatistics,
      productReviewsNextSet_URL,
      isDataFetchError,
      selectedLocale,
      productUGC,
      resetTimoutTimer,
      setExternalPDPTrigger,
      onFetchNextSetOfReviews,
      onRequestReload,
    } = this.props;

    return (
      <>
        {
          <>
            <ProductDetailsWrapper
              product={product}
              productDescription={productDescription}
              productUGC={productUGC}
              productReviews={productReviews}
              productReviewStatistics={productReviewStatistics}
              productReviewsNextSet_URL={productReviewsNextSet_URL}
              isDataFetchError={isDataFetchError}
              selectedLocale={selectedLocale}
              resetTimoutTimer={resetTimoutTimer}
              setExternalPDPTrigger={setExternalPDPTrigger}
              onFetchNextSetOfReviews={onFetchNextSetOfReviews}
              onRequestReload={onRequestReload}
              selectedTranslationLocale={this.props.selectedTranslationLocale}
            ></ProductDetailsWrapper>
          </>
        }
        <div className="product-side-panel-container">
          <div
            className={`content ${
              this.props.sideMenu?.visible ? "" : "xhidden"
            }`}
          >
            <ProductSidePanel
              product={product}
              productSizeGuideHTML={this.props.productSizeGuideHTML}
              productReviewStatistics={this.props.productReviewStatistics}
              scrollToReviews={() => {
                this.setActiveTabIndex(4);
              }}
              resetTimoutTimer={this.props.resetTimoutTimer}
            ></ProductSidePanel>
          </div>
        </div>
      </>
    );
  }
}

export default ProductDetails;
