import React, { Component } from "react";
import { connect } from "react-redux";

import Keyboard from "react-simple-keyboard";
import Draggable from "react-draggable";

import {
  setInputWithKeyboardId,
  setInputWithKeyboardValue,
  setInitialInputWithKeyboardValue,
} from "../../actions/inputWithKeyboardActions";
import { KEYBOARD_LAYOUTS } from "../../utils/keyboardLayouts";

import "react-simple-keyboard/build/css/index.css";
import "./AppKeyboard.css";

import gbFlag from "../../assets/icons/gb_flag.svg";
import ruFlag from "../../assets/icons/ru_flag.svg";
import deFlag from "../../assets/icons/de_flag.svg";
import esFlag from "../../assets/icons/es_flag.svg";
import frFlag from "../../assets/icons/fr_flag.svg";
import jaFlag from "../../assets/icons/jp_flag.svg";
import trFlag from "../../assets/icons/tr_flag.svg";
import itFlag from "../../assets/icons/it_flag.svg";
import plFlag from "../../assets/icons/pl_flag.svg";
import ptFlag from "../../assets/icons/pt_flag.svg";
import svFlag from "../../assets/icons/sv_flag.svg";
import nlFlag from "../../assets/icons/nl_flag.svg";
import krFlag from "../../assets/icons/kr_flag.svg";

import { ReactComponent as DragIcon } from "../../assets/icons/drag.svg";

const mappedLanguages = {
  ru: KEYBOARD_LAYOUTS.ru.layout,
  en: KEYBOARD_LAYOUTS.en.layout,
  de: KEYBOARD_LAYOUTS.de.layout,
  es: KEYBOARD_LAYOUTS.es.layout,
  fr: KEYBOARD_LAYOUTS.fr.layout,
  ja: KEYBOARD_LAYOUTS.ja.layout,
  tr: KEYBOARD_LAYOUTS.tr.layout,
  it: KEYBOARD_LAYOUTS.it.layout,
  pl: KEYBOARD_LAYOUTS.pl.layout,
  pt: KEYBOARD_LAYOUTS.pt.layout,
  sv: KEYBOARD_LAYOUTS.sv.layout,
  nl: KEYBOARD_LAYOUTS.en.layout, //todo correct keyboard for nl
  ko: KEYBOARD_LAYOUTS.ko.layout,
};

const mappedFlags = {
  ru: ruFlag,
  en: gbFlag,
  de: deFlag,
  es: esFlag,
  fr: frFlag,
  ja: jaFlag,
  tr: trFlag,
  it: itFlag,
  pl: plFlag,
  pt: ptFlag,
  sv: svFlag,
  nl: nlFlag,
  ko: krFlag,
};

const isKeyboardPlacedBelowInput = false;
let timer;
class AppKeyboard extends Component {
  state = {
    layoutName: "default",
    isKeyboradHidden: false,
    keyboardLanguage: mappedLanguages.en,
    currentMappedLanguages: {},
    selectedLanguage: "en",
    draggedPosition: {
      x: 0,
      y: 0,
    },
    keyboardPosition: {
      top: 0,
      left: 0,
    },
    isLandscape: false,
  };

  constructor(props) {
    super(props);
    this.keyboardRef = React.createRef();
  }

  componentDidMount() {
    if (this.props?.selectedLocale) {
      const selectedLangFromLocale = this.props.selectedLocale.split("_")[0];

      let currentMappedLanguages = {
        en: KEYBOARD_LAYOUTS.en.layout,
      };
      let _keyboardLanguage = currentMappedLanguages["en"];

      if (mappedLanguages[selectedLangFromLocale]) {
        currentMappedLanguages = {
          ...currentMappedLanguages,
          [selectedLangFromLocale]: mappedLanguages[selectedLangFromLocale],
        };
        _keyboardLanguage = currentMappedLanguages[selectedLangFromLocale];
      }

      this.setState({
        keyboardLanguage: _keyboardLanguage,
        currentMappedLanguages: currentMappedLanguages,
        selectedLanguage: "en",
      });
    }

    this.props.setInitialInputWithKeyboardValue(
      this.props.selctedInputWithKeyboardValue
    );

    // Keyboard positioning for specific inputs
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const inputDOMElement = this.getDOMElement(
      `#${this.props.selctedInputWithKeyboardId}`
    );

    if (inputDOMElement) {
      const inputDOMElementPosition = inputDOMElement.getBoundingClientRect();
      const keyboard = this.keyboardRef.current;
      const screen = document.body;

      if (!isLandscape && !isKeyboardPlacedBelowInput) {
        if (this.props.selctedInputWithKeyboardId === "search_input_text") {
          this.setState({
            keyboardPosition: {
              top: "45%",
              left: "calc(20% + 550px)",
            },
          });
        }

        const modalDOMElement = document.querySelectorAll(".modal");
        const checkoutContainerDOMElement = this.getDOMElement(
          ".checkout-form-container"
        );

        if (
          !modalDOMElement[modalDOMElement.length - 1]?.classList.contains(
            "modal-full-width"
          )
        ) {
          if (modalDOMElement[modalDOMElement.length - 1]) {
            let modalContentElement = modalDOMElement[
              modalDOMElement.length - 1
            ]
              .getElementsByClassName("modal-content")[0]
              .getBoundingClientRect();

            this.setState({
              keyboardPosition: {
                top: modalContentElement.top + modalContentElement.height,
                left: "calc(20% + 50px)",
              },
            });
          }
        } else {
          this.setState({
            keyboardPosition: {
              top:
                checkoutContainerDOMElement.getBoundingClientRect().top +
                checkoutContainerDOMElement.getBoundingClientRect().height,
              left: "calc(20% + 50px)",
            },
          });
        }
      }

      if (!isLandscape && isKeyboardPlacedBelowInput) {
        let left = 0;
        let top = inputDOMElementPosition.top + 60;

        // Make sure that keybaord will be always fully visible on screen
        // screen width - keyboard width with additional marging
        if (left < screen.clientWidth - keyboard.clientWidth) {
          left = keyboard.clientWidth / 2;
        }

        if (top > screen.clientHeight - keyboard.clientHeight) {
          top = screen.clientHeight - keyboard.clientHeight;
        }

        this.setState({
          keyboardPosition: {
            top: top,
            left: left,
          },
        });
      }
    }

    if (isLandscape) {
      this.setState({
        isLandscape: true,
        keyboardPosition: {
          top: "unset",
          left: "unset",
        },
      });
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (!this.props.selctedInputWithKeyboardId) {
        this.setState({
          draggedPosition: {
            x: 0,
            y: 0,
          },
        });
      }
      if (this.props.selctedInputWithKeyboardId && this.keyboard) {
        this.keyboard.setInput(
          this.props.selctedInputWithKeyboardValue[
            this.props.selctedInputWithKeyboardId
          ]
        );
      }
    }
  }

  getDOMElement = (selector) => {
    const _element = document.querySelector(selector);

    return _element;
  };

  handleClickOutside = (event) => {
    if (this.keyboardRef && !this.keyboardRef.current.contains(event.target)) {
      this.props.setInputWithKeyboardId(event.target.id);
    }
  };

  onChange = (input) => {
    this.props.setInputWithKeyboardValue(
      this.props.selctedInputWithKeyboardId,
      input
    );
  };

  onKeyPress = (button) => {
    const { currentMappedLanguages, selectedLanguage } = this.state;

    if (button === "{shift}" || button === "{lock}") this.handleShift();

    if (button === "{lang}") {
      const currentMappedLanguagesKeys = Object.keys(currentMappedLanguages);
      const index = currentMappedLanguagesKeys.indexOf(selectedLanguage);

      if (index === 0) {
        this.setState({
          keyboardLanguage:
            currentMappedLanguages[currentMappedLanguagesKeys[0]],
          selectedLanguage: currentMappedLanguagesKeys[1],
        });
      } else {
        this.setState({
          keyboardLanguage:
            currentMappedLanguages[currentMappedLanguagesKeys[1]],
          selectedLanguage: currentMappedLanguagesKeys[0],
        });
      }
    }
  };

  onKeyReleased = (button) => {
    if (button === "{close}") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.props.setInputWithKeyboardId("");
      }, 100);
    }
  };

  handleShift = () => {
    const layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default",
    });
  };

  handleDragging = (e, position) => {
    const { deltaX, deltaY } = position;

    const _x = this.state.draggedPosition.x + deltaX;
    const _y = this.state.draggedPosition.y + deltaY;

    this.setState({
      draggedPosition: { x: _x, y: _y },
    });
  };

  getFLag = () => {};

  render() {
    const {
      draggedPosition,
      keyboardPosition,
      keyboardLanguage,
      selectedLanguage,
      currentMappedLanguages,
      layoutName,
      isLandscape,
    } = this.state;

    const flagIcon = mappedFlags[selectedLanguage];
    const flagClassName =
      Object.keys(currentMappedLanguages).length === 1
        ? "hg-lang disabled-flag"
        : "hg-lang";

    return (
      <Draggable
        // disabled={isLandscape}
        bounds="body"
        position={draggedPosition}
        onStart={this.handleDragging}
        onDrag={this.handleDragging}
        onStop={this.handleDragging}
        cancel=".react-simple-keyboard"
      >
        <div
          id="app-keyboard"
          ref={this.keyboardRef}
          style={{
            top: keyboardPosition.top,
            left: keyboardPosition.left,
            bottom: isLandscape ? 0 : "unset",
          }}
        >
          <div className="drag-icon">
            <DragIcon />
          </div>
          <Keyboard
            // autoUseTouchEvents  do not use this when device is touch screen-> causes issues
            keyboardRef={(r) => (this.keyboard = r)}
            layoutName={layoutName}
            layout={keyboardLanguage}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            onKeyReleased={this.onKeyReleased}
            // theme="app-keyboard-theme"
            buttonTheme={[
              {
                class: flagClassName,
                buttons: "{lang}",
              },
              {
                class: "hg-bksp-close",
                buttons: "{close}",
              },
            ]}
            display={{
              "{lang}": `<img src="${flagIcon}" alt="flag" title="flag" aria-label="flag" class="gl-flag">`,
              "{close}": `<svg class="gl-icon"><use xlink:href="#close"></use></svg>`,
            }}
            mergeDisplay
          />
        </div>
      </Draggable>
    );
  }
}

function mapStateToProps({
  appSettings,
  selectedLocale,
  selctedInputWithKeyboardId,
  selctedInputWithKeyboardValue,
}) {
  return {
    appSettings,
    selectedLocale,
    selctedInputWithKeyboardId,
    selctedInputWithKeyboardValue,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
    setInputWithKeyboardId: (val) => dispatch(setInputWithKeyboardId(val)),
    setInitialInputWithKeyboardValue: (val) =>
      dispatch(setInitialInputWithKeyboardValue(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppKeyboard);
