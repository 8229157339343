import React, { Component } from "react";

import "./EmptyBag.css";

// import { GlButton, GL_BUTTON_VARIANT } from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

class EmptyBag extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    return (
      <>
        <section className="empty-bag">
          <div className="wrapper">
            <div>
              <img
                src={"images/cart-empty.svg"}
                alt="cart"
                className="cart-icon"
              />
            </div>
            <h4>{this.getTranslation(TRANSLATIONS_KEY_LIST.bag_empty)}</h4>
            <p>{this.getTranslation(TRANSLATIONS_KEY_LIST.browse_online)}</p>
          </div>
        </section>
        {/* <section className="empty-bag__btn-container">
          <GlButton
            aria-label="label"
            variant={GL_BUTTON_VARIANT.primary}
            icon={"arrow-right-long"}
            disabled={false}
            onClick={(e) => this.props.onClose()}
          >
            {getTranslation(
              this.props.selectedLocale,
              TRANSLATIONS_KEY_LIST.start_browsing
            )}
          </GlButton>
        </section> */}
      </>
    );
  }
}

export default EmptyBag;
