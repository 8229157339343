export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const SET_AVAILABLE_BROWSER_STORAGE = "SET_AVAILABLE_BROWSER_STORAGE";

export const PAGE_NAMES = {
  home: "home",
  interactionVideo: "interactionVideo",
  landingPage: "landingPage",
  plp: "plp",
  pdp: "pdp",
  checkout: "checkout",
  eventsPage: "eventsPage",
  eventDetailsPage: "eventDetailsPage",
  servicesPage: "servicesPage",
  serviceDetailsPage: "serviceDetailsPage",
  membershipPage: "membershipPage",
};

export const SET_USER_ACTION_TIME = "SET_USER_ACTION_TIME";

export function setCurrentPageName(value) {
  return { type: SET_CURRENT_PAGE, value };
}

export function setUserActionTime(value) {
  return { type: SET_USER_ACTION_TIME, value };
}

export function setAvailableBrowserStorage(value) {
  return { type: SET_AVAILABLE_BROWSER_STORAGE, value };
}
