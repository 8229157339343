import React from "react";

import "./MembershipBanner.css";
import adiclubLogo from "../../assets/images/adiclub.png";
import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";

import frameBottomRightImage from "../../assets/images/frame-bottom-right.png";
import frameTopLeftImage from "../../assets/images/frame-top-left.png";
import NewBackButton from "../NewBackButton/NewBackButton";
import { connect } from "react-redux";

import QRCode from "react-qr-code";

import MembershipBannerModel from "../../models/MembershipBannerModel";

const MembershipBanner = (props) => {
  let membershipBannerData = new MembershipBannerModel();
  membershipBannerData = props.data;
  const featuresList = [];

  membershipBannerData?.features?.map((x) => {
    console.log(x?.rewards);

    x?.rewards?.map((y) => {
      return featuresList.push({ id: y?.id, name: y?.name });
    });

    return null;
  });

  featuresList.sort((a, b) => a.id - b.id);

  return (
    <>
      <div className="membership-banner">
        <div className="adiculb-logo">
          <img src={adiclubLogo} alt="logo" />
        </div>
        <div className="hero-image">
          <img
            alt="membership"
            src={membershipBannerData?.backgroundImageAssetUrl}
          ></img>
        </div>

        <div className="description-section">
          <div className="title">{membershipBannerData?.title}</div>
          <div className="qr-container">
            <div className="qr-img">
              <QRCode value={membershipBannerData?.qrCodeUrl} size={360} />
              <p style={{ display: "none" }}>
                {membershipBannerData?.qrCodeUrl}
              </p>
              <div className="top-left">
                <img src={frameTopLeftImage} alt="corner-left"></img>
              </div>
              <div className="bottom-right">
                <img src={frameBottomRightImage} alt="corner-right"></img>
              </div>
            </div>
          </div>
          <div className="body">{membershipBannerData?.description}</div>

          <div className="feature-list">
            {featuresList.map((x) => (
              <div key={x?.id + x?.name}>{x?.name}</div>
            ))}
          </div>
        </div>

        <div className="footer-image">
          <img
            alt="membership"
            src={membershipBannerData?.footerBackgroundImageAssetUrl}
          ></img>
        </div>

        <NewBackButton
          onClick={() => {
            props.setCurrentPageName(PAGE_NAMES.landingPage);
          }}
        ></NewBackButton>
      </div>
    </>
  );
};

function mapStateToProps({ appSettings, selectedTranslationLocale }) {
  return {
    appSettings,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipBanner);
