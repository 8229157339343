import React, { Component } from "react";
import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlPrice,
  GlPriceItem,
  GL_PRICE_ITEM_THEME,
  GlCallout,
  GL_CALLOUT_TYPE,
} from "@adl/foundation";

import { connect } from "react-redux";

import "./CartItems.css";

// import SizeSelector from "./components/SizeSelector/SizeSelector";

import { setSideBarActiveItem } from "../../../../../../actions/sidebarMenuActions";

import { setExternalPDPTrigger } from "../../../../../../actions/productActions";

import {
  addToCart,
  updateCartSku,
  removeFromCart,
  removeAllFromCart,
} from "../../../../../../actions/cartActions";

import trashIcon from "../../../../../../assets/icons/trash.svg";

import * as helpers from "../../../../../../utils/helpers";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

import { MAX_CART_ITEM_LIMIT } from "../../../../../../utils/constants";

import * as analytics from "../../../../../../analytics";

const COUNT = {
  Increase: "increase",
  Decrease: "decrease",
};

class CartItems extends Component {
  state = {
    items: [],
    totalAmount: null,
    stockQuantityAutoAdjusted: false,
    stockQuantityAutoAdjusted_SKU: null,
  };

  componentDidMount() {
    if (this.props.cartItems) {
      let _items = this.props.cartItems;
      this.setState({
        items: _items,
      });

      this.updateTotalAmount(_items);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.cartItems) !==
      JSON.stringify(this.props.cartItems)
    ) {
      let _items = this.props.cartItems;
      this.setState({
        items: _items,
      });

      this.updateTotalAmount(_items);
    }
  }

  getTranslation(key) {
    return translateString(key);
  }

  updateTotalAmount = (items) => {
    if (!items || items.length === 0) {
      this.setState({
        totalAmount: null,
      });
      return;
    }

    const sum =
      items &&
      items.length > 0 &&
      items.reduce((sum, item) => {
        if (item.product?.discount_price && item.product?.discount_price > 0) {
          sum += item.product?.discount_price * item.quantity;
        } else {
          sum += item.product?.original_price * item.quantity;
        }

        sum = Math.round(sum * 100) / 100;
        return sum;
      }, 0);

    this.setState({
      totalAmount: sum,
    });

    //Log Cart View analytics
    analytics.cartView({
      hostname: this.props.appSettings.value.player.hostname,
      quantity: helpers.getCartItemsCount(this.props.cartItems),
      products:
        this.props.cartItems
          .map((cartItem) => cartItem.product.product_id)
          .toString() || "",
      value: sum,
      currency: helpers
        .formatPrice(sum, this.props.selectedLocale)
        .split(" ")[0],
    });
  };

  onCountDecreaseClick = (skuItem) => {
    const productPrice = skuItem.product.discount_price
      ? helpers.formatPrice(
          skuItem.product.discount_price,
          this.props.selectedLocale
        )
      : helpers.formatPrice(
          skuItem.product.original_price,
          this.props.selectedLocale
        );

    //Log Remove From Cart analytics
    analytics.removeProductFromCart({
      hostname: this.props.appSettings?.value?.player?.hostname,
      productId: skuItem?.product?.product_id,
      currency: skuItem?.product?.display_currency,
      modelId: skuItem?.product?.model_number,
      productName: skuItem?.product?.product_name,
      orginalPrice: skuItem?.product?.original_price,
      price: productPrice?.split(" ")[1],
      size: skuItem?.size?.size,
      accessType: "",
      sizeId: skuItem?.size?.variation_product_id,
      color: skuItem?.product?.original_color,
      earlyAccess: "",
      quantity: skuItem?.quantity,
      rating: skuItem?.productReviewStatistics?.average_overall_rating,
      reviewCount: skuItem?.productReviewStatistics?.total_review_count,
      category: skuItem?.product?.category,
      stockStatus: skuItem?.product?.orderable,
    });

    this.onSkuCountUpdate(skuItem, COUNT.Decrease);
  };

  onCountIncreaseClick = (skuItem) => {
    this.onSkuCountUpdate(skuItem, COUNT.Increase);
  };

  onSkuCountUpdate = (skuItem, countActionType) => {
    let _skuList = this.state.items;

    if (countActionType === COUNT.Increase) {
      this.props.addToCart(skuItem);
    } else if (countActionType === COUNT.Decrease) {
      skuItem.quantity -= 1;

      if (skuItem.quantity > 0) this.props.updateCartSku(skuItem);
      else {
        this.props.removeFromCart(skuItem);
      }
    }

    this.updateTotalAmount(_skuList);

    return;
  };

  onSkuSizeUpdate = (originalSize, updatedSize) => {
    let _cartItems = this.props.cartItems;

    let _originalSizeMatchArray = _cartItems.filter(
      (x) => x.sku === originalSize.variation_product_id
    );

    let _destinationSizeMatchArray = _cartItems.filter(
      (x) => x.sku === updatedSize.variation_product_id
    );

    // console.log(_originalSizeMatchArray);
    // console.log(_destinationSizeMatchArray);

    let _originalSizeMatch;

    if (_originalSizeMatchArray[0]) {
      _originalSizeMatch = _originalSizeMatchArray[0];
    }

    let _destinationSizeMatch = _destinationSizeMatchArray[0];

    if (_destinationSizeMatchArray?.length > 0) {
      const _toAddQuantity = _originalSizeMatch.quantity;
      _destinationSizeMatch.quantity += _toAddQuantity;

      if (
        _destinationSizeMatch.quantity > _destinationSizeMatch.size?.stock_level
      ) {
        _destinationSizeMatch.quantity =
          _destinationSizeMatch.size?.stock_level;
        console.log(
          "Warning:",
          "Sku Qty adjusted while changing size, so as to not exceed the stock availability"
        );

        this.setState({
          stockQuantityAutoAdjusted: true,
          stockQuantityAutoAdjusted_SKU: _destinationSizeMatch.sku,
        });

        setTimeout(() => {
          this.setState({
            stockQuantityAutoAdjusted: false,
            stockQuantityAutoAdjusted_SKU: _destinationSizeMatch.sku,
          });
        }, 5000);
      }
      this.props.updateCartSku(_destinationSizeMatch);
      this.props.removeFromCart(_originalSizeMatch);
    } else {
      _originalSizeMatch.sku = updatedSize.variation_product_id;
      _originalSizeMatch.size = updatedSize;
      this.props.updateCartSku(_originalSizeMatch);
    }

    return;
  };

  getAvailableStock = (x) => {
    let _currentStockLevel = x?.stock_level;
    let _remainingStock = _currentStockLevel;

    let currentSkuCountInBasket_List = this.props.cartItems.filter(
      (y) => y.sku === x.variation_product_id
    );

    if (currentSkuCountInBasket_List?.length > 0) {
      _remainingStock =
        _currentStockLevel - currentSkuCountInBasket_List[0].quantity;
      // console.log(_remainingStock);

      if (_remainingStock < 1) {
        if (
          this.state.sizeSelected?.variation_product_id ===
          x.variation_product_id
        ) {
          this.setState({ sizeSelected: null });
          this.props.onSizeClicked(null, null);
        }
      }
    }

    return _remainingStock;
  };

  shouldDisableCountIncrease = (count, size) => {
    const _CART_TOTAL_COUNT = helpers.getCartItemsCount(this.props.cartItems);

    if (_CART_TOTAL_COUNT >= MAX_CART_ITEM_LIMIT) return true;

    let _currentStockLevel = size.stock_level;
    let _remainingStock = _currentStockLevel;

    let currentSkuCountInBasket_List = this.props.cartItems.filter(
      (y) => y.sku === size.variation_product_id
    );

    if (currentSkuCountInBasket_List?.length > 0) {
      _remainingStock =
        _currentStockLevel - currentSkuCountInBasket_List[0].quantity;
      // console.log(_remainingStock);
    }

    return _remainingStock < 1 ? true : false;
  };

  getCartJSX = () => {
    let { items, stockQuantityAutoAdjusted, stockQuantityAutoAdjusted_SKU } =
      this.state;

    const _CART_TOTAL_COUNT = helpers.getCartItemsCount(this.props.cartItems);

    // const _INCREASE_COUNT_DISABLED = _CART_TOTAL_COUNT >= MAX_CART_ITEM_LIMIT;

    return (
      <section>
        <section className="cart-wrapper">
          {items.map((x) => (
            <div key={x.sku}>
              <div className="cart">
                <div className="image-container">
                  <img
                    src={x.product?.image_url}
                    alt={x.product?.product_name}
                    onClick={() => {
                      this.props.setExternalPDPTrigger(x?.product?.product_id);
                    }}
                  />
                </div>
                <div className="product-details">
                  <div className="wrapper">
                    <div className="title">
                      <h4>{x?.product?.product_name}</h4>
                    </div>

                    <div className="price">
                      {x?.product?.is_sale === true && (
                        <div className="sale">
                          {x?.product?.discount_percentage_text ||
                            x?.product?.badge_text}
                        </div>
                      )}

                      {this.getPriceJSX(
                        x?.product?.discount_price,
                        x?.product?.original_price,
                        x?.product?.display_currency
                      )}
                    </div>
                    <div className="action-panel">
                      <div className="left">
                        <div onClick={(e) => this.onCountDecreaseClick(x)}>
                          {x.quantity === 1 ? (
                            <div className="trash-icon">
                              <img src={trashIcon} alt="trash-icon"></img>
                            </div>
                          ) : (
                            <GlButton
                              variant={GL_BUTTON_VARIANT.secondary}
                              icon={"minus"}
                            ></GlButton>
                          )}
                        </div>

                        <div className="quantity">{x.quantity}</div>
                        <div>
                          <GlButton
                            variant={GL_BUTTON_VARIANT.secondary}
                            icon={"plus"}
                            disabled={this.shouldDisableCountIncrease(
                              x.quantity,
                              x.size
                            )}
                            onClick={() => this.onCountIncreaseClick(x)}
                          ></GlButton>
                        </div>
                      </div>
                      <div className="right">
                        <div>
                          <span>{x?.product?.product_id}</span>
                        </div>
                        <div>
                          <div
                            style={{
                              paddingLeft: "0.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            {x?.size?.size}
                          </div>
                          {/* <SizeSelector
                            orderableSizes={x?.orderableSizes}
                            selectedSize={x?.size}
                            currentCartItem={x}
                            onSkuSizeUpdate={this.onSkuSizeUpdate}
                          ></SizeSelector> */}
                        </div>
                      </div>
                    </div>
                    {stockQuantityAutoAdjusted &&
                      stockQuantityAutoAdjusted_SKU === x?.sku && (
                        <div className="info-quantity-auto-adjusted">
                          <GlCallout type={GL_CALLOUT_TYPE["urgent"]}>
                            <p>
                              Quantity auto-adjusted on size change, based on
                              current stock availability of size {x?.size?.size}
                            </p>
                          </GlCallout>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {_CART_TOTAL_COUNT > MAX_CART_ITEM_LIMIT - 1 && (
            <GlCallout type={GL_CALLOUT_TYPE.urgent}>
              <p>
                {this.getTranslation(
                  TRANSLATIONS_KEY_LIST.max_cart_item_count_reached
                )}
              </p>
            </GlCallout>
          )}
        </section>
      </section>
    );
  };

  getPriceJSX = (discountPrice, originalPrice, displayCurrency) => {
    return (
      <GlPrice>
        {discountPrice ? (
          <>
            <GlPriceItem theme={GL_PRICE_ITEM_THEME.sale}>
              {helpers.formatPrice(discountPrice, this.props.selectedLocale)}
            </GlPriceItem>

            <GlPriceItem theme={GL_PRICE_ITEM_THEME.crossed}>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        ) : (
          <>
            <GlPriceItem>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        )}
      </GlPrice>
    );
  };

  render() {
    let { totalAmount } = this.state;

    return (
      <>
        <section className="cart-container">
          {this.getCartJSX()}
          <div>
            <div className="cta-items">
              <div className="left">
                <div>
                  <h4 className="bold">
                    {this.getTranslation(TRANSLATIONS_KEY_LIST.total)}
                  </h4>
                </div>
                <div className="tax-label">
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.including_tax)}
                </div>
              </div>

              <div className="right">
                <div className="bold">{`${helpers.formatPrice(
                  totalAmount,
                  this.props.selectedLocale
                )}`}</div>
              </div>
            </div>
            <div className="checkout-cta">
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() =>
                    this.props.onProceedToCheckout(this.state.totalAmount)
                  }
                >
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.order_online)}
                </GlButton>
              </div>

              <div
                className="delete_bag_container"
                // onClick={(e) => this.onDeleteBag(e)}
              >
                <div
                  className="delete-btn"
                  onClick={() => this.props.onShowDeleteCartConfirmation()}
                >
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.delete_bag)}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps({ appSettings, cartItems, selectedLocale }) {
  return {
    appSettings,
    cartItems,
    selectedLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToCart: (val) => dispatch(addToCart(val)),
    updateCartSku: (sku) => dispatch(updateCartSku(sku)),
    removeFromCart: (sku) => dispatch(removeFromCart(sku)),
    removeAllFromCart: () => dispatch(removeAllFromCart()),
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItems);
