import React, { Component } from "react";
import FilterMenu from "./components/FilterMenu/FilterMenu";

import ProductView from "../ProductView/ProductView";
import TopNavigationMenu from "./components/TopNavigationMenu/TopNavigationMenu";

import { InView } from "react-intersection-observer";

import { connect } from "react-redux";

import { Events, animateScroll as scroll } from "react-scroll";

import {
  GlButton,
  GL_BUTTON_VARIANT,
  GlParagraph,
  GlTab,
  GlTabItem,
} from "@adl/foundation";

import { log, MSG_TYPE } from "../../utils/logger";

import "./ProductListView.css";

import { API } from "../../API/index";

import upArrow from "../../assets/icons/up-arrow.svg";

import { setCurrentPageName, PAGE_NAMES } from "../../actions/pageActions";

import { setExternalPDPTrigger } from "../../actions/productActions";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../actions/sidebarMenuActions";

import { setSearchInput } from "../../actions/searchActions";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../utils/translationCopies";

import ProductsGrid from "./components/ProductsGrid/ProductsGrid";

import * as analytics from "../../analytics";

let _api = null;
// let _apiImages = null;

const PLP_NAVIGATION_STACK_TYPE = {
  PLP: "PLP",
  PDP: "PDP",
};

const DATA_SOURCE_TYPE = {
  ONLINE: "ONLINE",
  RETAIL: "RETAIL",
};

class ProductListView extends Component {
  state = {
    productList: null,
    refinements: null,
    selectedRefinements: null,
    imageBlob: null,
    sorting_options: null,
    lastFetchedResultPage: 1,
    totalResultProductsCount: null,
    totalResultPages: null,
    pdpOverlayStackData: [],
    title: null,
    showBackToTopButton: false,
    isProductListDataLoadError: false,
    dataSourceType: DATA_SOURCE_TYPE.ONLINE,
    enableRetailCatalogOption: false,
  };

  componentDidMount() {
    if (this.props.currentPage !== PAGE_NAMES.plp) {
      this.props.setCurrentPageName(PAGE_NAMES.plp);
    }

    // this.fetchResult(null, null, null, null);

    let enableRetailCatalogOption =
      this.props.appSettings?.value?.enableRetailCatalog || false;

    let defaultCatalogShouldBeOnline =
      this.props.appSettings?.value?.defaultCatalogShouldBeOnline || false;

    this.setState({
      enableRetailCatalogOption: enableRetailCatalogOption,
    });

    if (enableRetailCatalogOption) {
      this.setState({
        dataSourceType: defaultCatalogShouldBeOnline
          ? DATA_SOURCE_TYPE.ONLINE
          : DATA_SOURCE_TYPE.RETAIL,
      });
    }

    if (this.props.pdpRequest?.length > 0) this.handlePDPdisplayRequest();

    if (this.props?.rootProductFilterSelection?.hit_count > 0) {
      this.setState({
        selectedRefinements: [
          {
            ...this.props?.rootProductFilterSelection,
          },
        ],
      });
      setTimeout(() => {
        this.onLoadRefreshRequested();
      }, 200);
    } else {
      setTimeout(() => {
        this.fetchResult(null, null, null, null);
      }, 200);
      this.setState({ pdpOverlayStackData: [], selectedRefinements: [] });
    }
  }

  scrollToTop = () => {
    // scroll.scrollToTop();

    // because now plp is a part of bigger page i.e. landing page, therefore now we scroll the landing page container
    scroll?.scrollTo(0, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "home-view",
    });
  };

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  getTranslation(key) {
    return translateString(key);
  }

  handlePDPdisplayRequest() {
    if (this.props.pdpRequest) {
      let pdpOverlayStackData = this.state.pdpOverlayStackData;

      const _match = pdpOverlayStackData.filter(
        (x) => x?.id === this.props.pdpRequest
      );

      if (_match?.length > 0) {
        let _updated = pdpOverlayStackData.filter(
          (x) => x?.id !== this.props.pdpRequest
        );
        _updated.push(_match[0]);

        this.setState({
          pdpOverlayStackData: [..._updated],
        });
      } else {
        API.productsAPI
          .getProduct(_api, this.props.pdpRequest, this.props.selectedLocale)
          .then((_product) => {
            if (_product) {
              pdpOverlayStackData.push({
                id: _product.product_id,
                type: PLP_NAVIGATION_STACK_TYPE.PDP,
                product: _product,
              });

              this.setState({
                pdpOverlayStackData: [...pdpOverlayStackData],
              });
            }
          });
      }
      this.props.setExternalPDPTrigger(null);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pdpRequest !== this.props.pdpRequest) {
      this.handlePDPdisplayRequest();
    }

    if (
      prevProps.userSelectedSearchSuggestion !==
      this.props.userSelectedSearchSuggestion
    ) {
      this.fetchResult(null, null, null, null);
      this.setState({ pdpOverlayStackData: [], selectedRefinements: [] });
    }

    if (
      prevProps.currentPage === PAGE_NAMES.pdp &&
      this.props.currentPage === PAGE_NAMES.plp
    ) {
      this.setState({
        pdpOverlayStackData: [],
      });

      this.props.setCurrentPageName(PAGE_NAMES.plp);
    } else if (
      prevProps.currentPage === PAGE_NAMES.pdp &&
      this.props.currentPage === PAGE_NAMES.home
    ) {
      this.setState({
        pdpOverlayStackData: [],
      });

      this.props.setCurrentPageName(PAGE_NAMES.plp);

      setTimeout(() => {
        this.scrollToTop();
      }, 500);
    } else if (
      prevProps.currentPage === PAGE_NAMES.plp &&
      this.props.currentPage === PAGE_NAMES.home
    ) {
      this.props.setCurrentPageName(PAGE_NAMES.plp);

      setTimeout(() => {
        this.scrollToTop();
      }, 500);
    }

    if (prevProps.selectedLocale !== this.props.selectedLocale) {
      this.fetchResult(null, null, null, null);
    }

    if (
      JSON.stringify(prevProps.appSettings) !==
      JSON.stringify(this.props.appSettings)
    ) {
      this.fetchResult(null, null, null, null);
      let enableRetailCatalogOption =
        this.props.appSettings?.value?.enableRetailCatalog || false;

      this.setState({
        enableRetailCatalogOption: enableRetailCatalogOption,
      });
    }

    if (
      JSON.stringify(prevProps.selectedTranslationLocale) !==
      JSON.stringify(this.props.selectedTranslationLocale)
    ) {
      this.setState({
        productList: null,
        refinements: null,
        selectedRefinements: null,
        sorting_options: null,
        sortSelection: null,
        lastFetchedResultPage: 1,
        totalResultProductsCount: null,
      });

      this.fetchResult(null, null, null, null);
    }
  }

  fetchResult = (query, refinementsFlattened, sortId, isFetchMore) => {
    if (this.state.dataReloading) {
      return;
    }

    this.setState({
      dataReloading: true,
    });

    if (this.state.dataSourceType === DATA_SOURCE_TYPE.ONLINE) {
      this.fetchResult_online(query, refinementsFlattened, sortId, isFetchMore);
    } else if (this.state.dataSourceType === DATA_SOURCE_TYPE.RETAIL) {
      this.setState({
        shouldActivateLoadingMore: false,
      });
      this.fetchResult_retailCatalog(
        query,
        refinementsFlattened,
        sortId,
        isFetchMore
      );
    }
  };

  fetchResult_retailCatalog = async (
    query,
    refinementsFlattened,
    sortId,
    isFetchMore
  ) => {
    this.setState({
      isProductListDataLoadError: false,
    });

    // check also for search from input text, and preset filter

    let _lastFetchedResultPage = this.state.lastFetchedResultPage;
    let _pageToFetch = _lastFetchedResultPage;

    if (isFetchMore) {
      _pageToFetch = _lastFetchedResultPage ? _lastFetchedResultPage + 1 : 1;
    } else {
      _pageToFetch = 1;

      this.setState({
        productList: [],
        totalResultPages: null,
        totalResultProductsCount: null,
        lastFetchedResultPage: 1,
      });
    }

    /* API call to get the product list for Retails catalog */
    let retailProductList = await API.productsAPI.getRetailProducts(
      _api,
      "",
      refinementsFlattened, //filters or refinements
      "",
      _pageToFetch,
      this.props.appSettings?.value?.player?._SSMID
    );

    if (retailProductList && retailProductList?.total) {
      let _totalResultPages = retailProductList?.total; // / x?.limit
      let _pageFetched = _pageToFetch;
      let _products = retailProductList?.products;

      let _newResultSet = [];

      if (isFetchMore) {
        let _existingResultSet = this.state.productList;
        Array.prototype.push.apply(_newResultSet, _existingResultSet);
        Array.prototype.push.apply(_newResultSet, _products);
      } else {
        Array.prototype.push.apply(_newResultSet, _products);
      }

      const filterSelection =
        this.state.selectedRefinements?.map((refinement) => refinement.name) ||
        [];

      if (this.props.appSettings?.value?.preset_filters?.value) {
        filterSelection.push(
          this.props.appSettings?.value?.preset_filters?.value
        );
      }

      if (this.state.selectedRefinements?.length > 0) {
        //Log Filter Apply analytics
        analytics.filterApply({
          hostname: this.props.appSettings.value.player.hostname,
          filterSelection: filterSelection?.toString() || "",
        });
      }

      this.setState({
        shouldActivateLoadingMore: _pageFetched < Math.ceil(_totalResultPages),
      });

      const concatenatedFilterNamesArray =
        this.state.selectedRefinements?.reduce((acc, item) => {
          const x = [item.name];
          acc.push(x);
          return acc;
        }, []);

      /*computing plp page title based on filters as its missing from retail catalog api response*/
      let title =
        this.state.selectedRefinements?.length > 0
          ? concatenatedFilterNamesArray.join("•")
          : "";

      const _uniqueProductList = [
        ...new Map(
          _newResultSet.map((item) => [item["product_id"], item])
        ).values(),
      ];

      setTimeout(() => {
        this.setState({
          productList: _uniqueProductList,
          refinements: retailProductList.refinements,
          sorting_options: retailProductList.sorting_options,
          totalResultPages: Math.ceil(_totalResultPages),
          lastFetchedResultPage: _pageFetched,
          dataReloading: false,
          totalResultProductsCount: retailProductList.total || null,
          title: title || null,
          isProductListDataLoadError: false,
        });
      }, 100);
    } else {
      const retryAfterSec = 30;

      log(
        "**** =========== !!! Retail catalog - PLP data fetch error ",
        MSG_TYPE.ERROR
      );

      this.setState({
        isProductListDataLoadError: true,
        dataReloading: false,
      });

      console.log(
        `..auto re-trying after ${retryAfterSec} if by then still in error screen`
      );

      setTimeout(() => {
        console.log(`..auto re-loading plp screen`);
        if (this.state.isProductListDataLoadError) {
          if (this.state.dataReloading === true) return;

          API.productsAPI.deleteProductsDatabase(); // sometimes there is no error but data returned is null hence that is cached - so clearing it.

          this.setState({
            dataReloading: true,
          });

          setTimeout(() => {
            this.setState({
              dataReloading: false,
            });

            this.onLoadRefreshRequested();
          }, 2000);
        }
      }, retryAfterSec * 1000);
    }
  };

  fetchResult_online = (query, refinementsFlattened, sortId, isFetchMore) => {
    this.setState({
      isProductListDataLoadError: false,
    });

    const presetFilter =
      this.props.appSettings?.value?.preset_filters?.value || null;

    let updatedQuery;

    if (presetFilter) {
      // if default filter set in app settings
      if (query) {
        // if search query text is not null
        updatedQuery = `${presetFilter}|${query}`;
      } else {
        // when default filter NOT set
        updatedQuery = `${presetFilter}`;
      }
    } else {
      updatedQuery = query;
    }

    if (this.props.userSelectedSearchSuggestion?.length > 0) {
      updatedQuery = this.props.userSelectedSearchSuggestion;
    }

    let _lastFetchedResultPage = this.state.lastFetchedResultPage;
    let _pageToFetch = _lastFetchedResultPage;

    if (isFetchMore) {
      _pageToFetch = _lastFetchedResultPage ? _lastFetchedResultPage + 1 : 1;

      // let { lastFetchedResultPage } = this.state;
      // lastFetchedResultPage = lastFetchedResultPage + 1;
    } else {
      _pageToFetch = 1;
      this.setState({
        productList: [],
        totalResultPages: null,
        totalResultProductsCount: null,
      });
    }

    API.productsAPI
      .getProducts(
        _api,
        updatedQuery,
        refinementsFlattened,
        sortId,
        _pageToFetch,
        this.props.selectedLocale ||
          this.props.appSettings?.value?.default_locale,
        this.props.selectedTranslationLocale // workaround - for translating display text.
      )
      .then((x) => {
        let _totalResultPages = x?.total / x?.limit;
        let _pageFetched = _pageToFetch;

        let _products = x?.products;

        let _newResultSet = [];

        if (isFetchMore) {
          let _existingResultSet = this.state.productList;
          Array.prototype.push.apply(_newResultSet, _existingResultSet);
          Array.prototype.push.apply(_newResultSet, _products);
        } else {
          Array.prototype.push.apply(_newResultSet, _products);
        }

        const filterSelection =
          this.state.selectedRefinements?.map(
            (refinement) => refinement.name
          ) || [];

        if (this.props.appSettings?.value?.preset_filters?.value) {
          filterSelection.push(
            this.props.appSettings?.value?.preset_filters?.value
          );
        }

        if (this.state.selectedRefinements?.length > 0) {
          //Log Filter Apply analytics
          analytics.filterApply({
            hostname: this.props.appSettings.value.player.hostname,
            filterSelection: filterSelection?.toString() || "",
          });
        }

        //Log PLP View analytics if user session is not null
        if (this.props.session?.session_id) {
          console.log("### sending plpView event to analytics");
          analytics.plpView({
            hostname: this.props.appSettings?.value?.player?.hostname,
            filterSelection: filterSelection?.toString() || "",
          });
        }

        const _uniqueProductList = [
          ...new Map(
            _newResultSet.map((item) => [item["product_id"], item])
          ).values(),
        ];

        this.setState({
          productList: _uniqueProductList,
          refinements: x.refinements,
          sorting_options: x.sorting_options,
          totalResultPages: Math.ceil(_totalResultPages),
          lastFetchedResultPage: _pageFetched,
          dataReloading: false,
          totalResultProductsCount: x.total || null,
          title: x.title || null,
          isProductListDataLoadError: false,
        });
      })
      .catch(() => {
        const retryAfterSec = 30;

        log(
          "**** =========== !!! Online catalog - PLP data fetch error",
          MSG_TYPE.ERROR
        );

        this.setState({
          isProductListDataLoadError: true,
          dataReloading: false,
        });

        console.log(
          `..auto re-trying after ${retryAfterSec} if by then still in error screen`
        );

        setTimeout(() => {
          console.log(`..auto re-loading plp screen`);
          if (this.state.isProductListDataLoadError) {
            if (this.state.dataReloading === true) return;

            API.productsAPI.deleteProductsDatabase(); // sometimes there is no error but data returned is null hence that is cached - so clearing it.

            this.setState({
              dataReloading: true,
            });

            setTimeout(() => {
              this.setState({
                dataReloading: false,
              });

              this.onLoadRefreshRequested();
            }, 2000);
          }
        }, retryAfterSec * 1000);
      });
  };

  fetchMoreResult = () => {
    let _sortSelection = this.state.sortSelection;
    let _selectedRefinements = this.state.selectedRefinements;

    return this.prepareAndFetch(
      _selectedRefinements,
      _sortSelection?.sort_option_id,
      true
    );
  };

  onFilterUpdated = (_selectedRefinements, sortId) => {
    this.setState({
      selectedRefinements: _selectedRefinements,
    });

    this.prepareAndFetch(_selectedRefinements, sortId, false);
  };

  prepareAndFetch = (_selectedRefinements, sortId, isFetchMore) => {
    let computedRefinementList = [];
    let multipleValueSeparator = "|";

    if (this.state.dataSourceType === DATA_SOURCE_TYPE.RETAIL) {
      multipleValueSeparator = ",";
    }

    _selectedRefinements &&
      _selectedRefinements.forEach((item) => {
        const refinementId = item.refinement_id;
        const allMatch = _selectedRefinements.filter(
          (x) => x.refinement_id === refinementId
        );

        const concatenatedValues = allMatch.reduce((acc, item) => {
          const x = [item.value];
          acc.push(x);
          return acc;
        }, []);

        if (
          computedRefinementList.filter((x) => x.refinement_id === refinementId)
            .length === 0
        ) {
          computedRefinementList.push({
            refinement_id:
              this.state.dataSourceType === DATA_SOURCE_TYPE.ONLINE
                ? refinementId
                : item?.retail?.filter_id
                ? item?.retail?.filter_id
                : refinementId,
            values:
              this.state.dataSourceType === DATA_SOURCE_TYPE.ONLINE
                ? concatenatedValues.join(multipleValueSeparator)
                : item?.retail?.filter_value
                ? item?.retail?.filter_value
                : concatenatedValues.join(multipleValueSeparator),
          });
        }
      });

    const _refinementsFlattened = computedRefinementList.reduce((acc, item) => {
      const x = [`${item.refinement_id}=${item.values}`];
      acc.push(x);
      return acc;
    }, []);

    this.fetchResult(
      null,
      _refinementsFlattened.join("&"),
      sortId,
      isFetchMore
    );
  };

  onSortUpdated = (filters, sortSelection) => {
    this.prepareAndFetch(filters, sortSelection?.sort_option_id, false);
    this.setState({
      sortSelection: sortSelection,
    });
  };

  onProductCardClicked = (e, product) => {
    if (e) {
      e.preventDefault();
    }

    log(product, MSG_TYPE.SUCCESS);

    let _pdpOverlayStackData = this.state.pdpOverlayStackData;

    _pdpOverlayStackData.push({
      id: product.product_id,
      type: PLP_NAVIGATION_STACK_TYPE.PDP,
      product: product,
    });

    if (_pdpOverlayStackData !== [])
      this.setState({
        pdpOverlayStackData: _pdpOverlayStackData,
      });
  };

  onBackButtonClicked = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.currentPage === PAGE_NAMES.pdp) {
      let _pdpOverlayStackData = this.state.pdpOverlayStackData;
      _pdpOverlayStackData.pop();

      this.setState({
        pdpOverlayStackData: _pdpOverlayStackData,
      });

      if (_pdpOverlayStackData?.length === 0) {
        if (this.props.userSelectedSearchSuggestion?.length > 0) {
          this.props.setCurrentPageName(PAGE_NAMES.plp);
        } else {
          this.props.setSideBarActiveItem(MENU_ITEM_NAMES.home);
          this.props.setCurrentPageName(PAGE_NAMES.plp);
        }
      }
    } else {
      this.props.setSearchInput(null);
      this.props.setCurrentPageName(PAGE_NAMES.plp);
      this.props.setSideBarActiveItem(MENU_ITEM_NAMES.home);
      this.setState({ selectedRefinements: [] });
    }
  };

  onLoadRefreshRequested = () => {
    let { selectedRefinements } = this.state;

    this.prepareAndFetch(selectedRefinements, null, false);
  };

  onDataSourceTypeChanged = (val) => {
    this.setState({ selectedRefinements: [] });

    if (this.state.dataSourceType !== val)
      this.setState({
        dataSourceType: val,
      });
    return false;
  };

  render() {
    let {
      productList,
      refinements,
      selectedRefinements,
      sorting_options,
      lastFetchedResultPage,
      totalResultPages,
      totalResultProductsCount,
      dataReloading,
      pdpOverlayStackData,
      title,
      showBackToTopButton,
      dataSourceType,
    } = this.state;

    let shouldActivateLoadingMore =
      lastFetchedResultPage && lastFetchedResultPage < totalResultPages;

    /* to handle an exception while integrating data from sam api*/
    if (productList?.length === 1) {
      shouldActivateLoadingMore = false;
    }

    let _goToTop = "";

    if (this.props.selectedLocale) {
      _goToTop = this.getTranslation(TRANSLATIONS_KEY_LIST.go_to_top);
    }

    const presetFilterDisplayName =
      this.props.appSettings?.value?.preset_filters?.display_name;

    //if no section is enabled in landing page; lets directly show the PLP

    return (
      <div>
        <InView
          as="div"
          onChange={(inView, entry) => {
            if (!inView && productList) {
              this.setState({ showBackToTopButton: true });
            } else {
              this.setState({ showBackToTopButton: false });
            }
          }}
        ></InView>
        {/* {
          <h4>
            {this.props.pdpRequest +
              ", stack length:" +
              pdpOverlayStackData.length}
          </h4>
        } */}

        {/* <div className="plp-video-container">
          {window.screen.height > window.screen.width && (
            <VideoView media={this.props.appSettings?.value?.media}></VideoView>
          )}
        </div> */}
        {/* <div>{"Loading error-" + isProductListDataLoadError}</div> */}

        {
          <>
            <div className="plp-navigation">
              {
                <TopNavigationMenu
                  onBackButtonClicked={this.onBackButtonClicked}
                  selectedRefinements={selectedRefinements}
                  resultCount={totalResultProductsCount}
                  title={title}
                  locale={this.props.selectedLocale}
                  presetFilters={presetFilterDisplayName || ""}
                  userSelectedSearchSuggestion={
                    this.props.userSelectedSearchSuggestion
                  }
                ></TopNavigationMenu>
              }
            </div>
            {this.state.enableRetailCatalogOption && (
              <div className="plp-data-sourcetype-selector">
                <GlTab>
                  <GlTabItem
                    active={dataSourceType === DATA_SOURCE_TYPE.ONLINE}
                    id="tab-online"
                    label={this.getTranslation(TRANSLATIONS_KEY_LIST.online)}
                    onClick={(e) => {
                      if (dataReloading) return;

                      this.onDataSourceTypeChanged(DATA_SOURCE_TYPE.ONLINE);
                      setTimeout(() => {
                        this.prepareAndFetch([], null, false);
                      }, 200);
                    }}
                  />
                  <GlTabItem
                    active={dataSourceType === DATA_SOURCE_TYPE.RETAIL}
                    id="tab-retail"
                    label={this.getTranslation(TRANSLATIONS_KEY_LIST.in_store)}
                    onClick={(e) => {
                      if (dataReloading) return;
                      this.onDataSourceTypeChanged(DATA_SOURCE_TYPE.RETAIL);
                      setTimeout(() => {
                        this.prepareAndFetch([], null, false);
                      }, 200);
                    }}
                  />
                </GlTab>
              </div>
            )}
            <div className="plp-container">
              <FilterMenu
                refinements={refinements}
                sorting_options={sorting_options}
                sortSelection={this.state?.sortSelection}
                onFilterUpdated={this.onFilterUpdated}
                onSortUpdated={this.onSortUpdated}
                locale={this.props.selectedLocale}
                dataReloading={dataReloading}
                selectedRefinements={selectedRefinements}
              ></FilterMenu>
              {this.props.pdpRequest ||
                (pdpOverlayStackData.length > 0 && (
                  <ProductView
                    onBackButtonClicked={this.onBackButtonClicked}
                    pdpOverlayStackData={[...pdpOverlayStackData]}
                    _api={_api}
                    setCurrentPageAsPDP={() => {
                      this.props.setCurrentPageName(PAGE_NAMES.pdp);
                    }}
                    resetTimoutTimer={this.props.resetTimoutTimer}
                  ></ProductView>
                ))}

              {showBackToTopButton && pdpOverlayStackData.length === 0 && (
                <div className="go-to-top-container" onClick={this.scrollToTop}>
                  <GlButton
                    aria-label="label"
                    variant={GL_BUTTON_VARIANT.secondary}
                    icon={null}
                    disabled={false}
                  >
                    <div className="go-to-top-content">
                      <div> {_goToTop}</div>
                      <div>
                        <img src={upArrow} alt="up-arrow"></img>
                      </div>
                    </div>
                  </GlButton>
                </div>
              )}

              {!dataReloading && productList?.length === 0 && (
                <div>
                  <div className="no-result-info-container">
                    <div>
                      <h4>
                        {this.getTranslation(
                          TRANSLATIONS_KEY_LIST.search_no_result
                        )}

                        <GlParagraph>
                          {
                            <GlButton
                              aria-label="label"
                              variant={GL_BUTTON_VARIANT.tertiary}
                              onClick={() => {
                                if (this.state.dataReloading === true) return;

                                API.productsAPI.deleteProductsDatabase(); // sometimes there is no error but data returned is null hence that is cached - so clearing it.

                                this.setState({
                                  dataReloading: true,
                                });

                                setTimeout(() => {
                                  this.setState({
                                    dataReloading: false,
                                  });

                                  this.onLoadRefreshRequested();
                                }, 2000);
                              }}
                            >
                              {this.getTranslation(
                                TRANSLATIONS_KEY_LIST.try_again
                              )}
                            </GlButton>
                          }
                        </GlParagraph>
                      </h4>
                    </div>
                  </div>
                </div>
              )}

              <ProductsGrid
                productList={productList}
                selectedLocale={this.props.selectedLocale}
                shouldActivateLoadingMore={shouldActivateLoadingMore}
                dataReloading={dataReloading}
                fetchMoreResult={this.fetchMoreResult}
                onProductCardClicked={this.onProductCardClicked}
              ></ProductsGrid>
            </div>
          </>
        }
      </div>
    );
  }
}

function mapStateToProps({
  selectedLocale,
  selectedTranslationLocale,
  currentPage,
  pdpRequest,
  appSettings,
  userSelectedSearchSuggestion,
  session,
}) {
  return {
    selectedLocale,
    selectedTranslationLocale,
    currentPage,
    pdpRequest,
    appSettings,
    userSelectedSearchSuggestion,
    session,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
    setExternalPDPTrigger: (val) => dispatch(setExternalPDPTrigger(val)),
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    setSearchInput: (val) => dispatch(setSearchInput(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListView);
