import { setup } from "axios-cache-adapter";
import { log, MSG_TYPE } from "../../../utils/logger";
import { BFF_API } from "../constants";

// Create `axios` instance with pre-configured `axios-cache-adapter` attached to it
const cacheAxiosAPI = setup({
  // `axios-cache-adapter` options
  cache: {
    maxAge: 15 * 60 * 1000,
    exclude: {
      // Only exclude PUT, PATCH and DELETE methods from cache
      methods: ["put", "patch", "delete"],
    },
  },
});

export const getTime = () => {
  log("maps API getTime called", MSG_TYPE.READY);

  return cacheAxiosAPI({
    url: `${BFF_API}/epoch`,
    method: "get",
  });
};

export const getGooglePlace = (searchText) => {
  return cacheAxiosAPI({
    url: `${BFF_API}/google/place/findplacefromtext`,
    method: "get",
    params: {
      search_text: searchText,
    },
  });
};

export const getAdidasNearestStoresList = (
  checkoutId,
  latitude,
  longitude,
  locale
) => {
  return cacheAxiosAPI({
    url: `${BFF_API}/checkouts/${checkoutId}/nearest_stores?locale=${locale}&latitude=${latitude}&longitude=${longitude}`,
    method: "get",
  });
};

export const getAdidasNearestPickupPointsList = (
  checkoutId,
  latitude,
  longitude,
  locale
) => {
  return cacheAxiosAPI({
    url: `${BFF_API}/checkouts/${checkoutId}/nearest_pick_up_points?locale=${locale}&latitude=${latitude}&longitude=${longitude}`,
    method: "get",
  });
};
