import React, { Component } from "react";
import { connect } from "react-redux";

import { GlButton, GL_BUTTON_THEME, GL_BUTTON_VARIANT } from "@adl/foundation";

import ModalView from "../../../ModalView/ModalView";
import InputWithKeyboard from "../../../InputWithKeyboard/InputWithKeyboard";
import { setInputWithKeyboardValue } from "../../../../actions/inputWithKeyboardActions";

import "./EmployeeIdOverlay.css";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../../../utils/translationCopies";

import { KEYBOARD_IDS } from "../../../../utils/constants";

class EmployeeIdOverlay extends Component {
  state = {
    employeeId: this.props.employeeId || "",
  };

  componentDidMount() {
    this.props.setInputWithKeyboardValue(
      KEYBOARD_IDS.employeeId,
      this.props.employeeId || ""
    );
  }

  componentDidUpdate(prevProps) {
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
    } = this.props;

    if (
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      this.setState({
        employeeId: selctedInputWithKeyboardValue[selctedInputWithKeyboardId],
      });
    }
  }

  render() {
    let { employeeId } = this.state;
    let { show, selectedLocale, employeeIdError } = this.props;

    return (
      <div>
        <ModalView
          showCloseBtn={true}
          show={show}
          onClose={() => {
            this.props.onClose();
          }}
          className="modal-centered employee-id"
        >
          <div className="employee-id__content">
            <div className="upper">
              <div className="title">
                <h4>
                  {getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.add_employee_id_heading
                  )}
                </h4>
              </div>

              <div className="body">
                <InputWithKeyboard
                  id={KEYBOARD_IDS.employeeId}
                  placeholder={getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.employee_id
                  )}
                />
              </div>

              <p className="disclaimer">
                {getTranslation(
                  selectedLocale,
                  TRANSLATIONS_KEY_LIST.add_employee_id_instruction
                )}
              </p>
            </div>

            <div className="lower">
              {employeeIdError && (
                <div className="error-text">
                  {getTranslation(
                    selectedLocale,
                    TRANSLATIONS_KEY_LIST.error_occurred
                  )}
                </div>
              )}
              <div>
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => {
                    this.props.onSubmit(employeeId);
                  }}
                  disabled={
                    employeeId === undefined ||
                    employeeId?.length < 5 ||
                    employeeId === this.props.employeeId
                  }
                >
                  {getTranslation(selectedLocale, TRANSLATIONS_KEY_LIST.apply)}
                </GlButton>
              </div>
            </div>
          </div>
        </ModalView>
      </div>
    );
  }
}

function mapStateToProps({
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputWithKeyboardValue: (id, val) =>
      dispatch(setInputWithKeyboardValue(id, val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeIdOverlay);
