import React, { Component } from "react";
import { GlButton, GL_BUTTON_VARIANT } from "@adl/foundation";
import { connect } from "react-redux";

import InputWithKeyboard from "../../../../../InputWithKeyboard/InputWithKeyboard";
import { KEYBOARD_IDS } from "../../../../../../utils/constants";
import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";
import { getTranslation } from "../../../../../../utils/translationCopies";

import "./EmployeeCheckout.css";
import { validateEmployeeId } from "../../../../../../utils/employeeIdHelper";

class EmployeeCheckout extends Component {
  state = {
    updateButtonDisabled: true,
    enableEdit: true,
    isEmployeeIdValid: true,
  };

  componentDidMount() {
    if (this.props) {
      this.props.setShowEmployeeCheckoutQrCode(true);
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.selctedInputWithKeyboardValue !==
      this.props.selctedInputWithKeyboardValue
    ) {
      const {
        appSettings,
        selctedInputWithKeyboardValue,
        selctedInputWithKeyboardId,
        setShowEmployeeCheckoutQrCode,
      } = this.props;
      const employeeIdd =
        selctedInputWithKeyboardValue[KEYBOARD_IDS.employeeId];

      const isValid = validateEmployeeId(
        employeeIdd,
        appSettings.value.default_locale
      );

      if (selctedInputWithKeyboardId === KEYBOARD_IDS.employeeId) {
        setShowEmployeeCheckoutQrCode(true);
      }

      if (!employeeIdd) {
        this.setState({
          updateButtonDisabled: true,
          isEmployeeIdValid: true,
        });
      }

      if (employeeIdd && employeeIdd.length > 0) {
        this.setState({
          updateButtonDisabled: isValid ? false : true,
          isEmployeeIdValid: isValid,
        });
      }
    }
  }

  handleUpdateQrCode() {
    this.props.setShowEmployeeCheckoutQrCode(false);
    this.setState({
      enableEdit: false,
    });
  }

  handleEnableEdit() {
    this.props.setShowEmployeeCheckoutQrCode(true);
    this.setState({ enableEdit: true });
  }

  render() {
    const { updateButtonDisabled, enableEdit, isEmployeeIdValid } = this.state;
    const { selctedInputWithKeyboardId, selctedInputWithKeyboardValue } =
      this.props;

    return (
      <>
        <div
          className={`employeid-input-container ${
            selctedInputWithKeyboardId === KEYBOARD_IDS.employeeId
              ? "keyoboard-margin"
              : ""
          }`}
        >
          <div className="employeid-header">
            {translateString(TRANSLATIONS_KEY_LIST.employee_id)}
          </div>
          {!enableEdit && (
            <div className="employeid-wrapper">
              {[...selctedInputWithKeyboardValue[KEYBOARD_IDS.employeeId]].map(
                (val) => (
                  <div className="employeid-char">{val}</div>
                )
              )}
            </div>
          )}
          {enableEdit && (
            <>
              <InputWithKeyboard
                id={KEYBOARD_IDS.employeeId}
                placeholder={getTranslation(TRANSLATIONS_KEY_LIST.employee_id)}
              />
              {!isEmployeeIdValid && (
                <div className="employeeid-error">
                  {translateString(
                    TRANSLATIONS_KEY_LIST.employe_id_validation_error
                  )}
                </div>
              )}
            </>
          )}
          <div className="employeid-description">
            {translateString(TRANSLATIONS_KEY_LIST.employee_id_instruction)}
          </div>
          <div className="employeid-button-wrpapepr">
            {enableEdit && (
              <GlButton
                aria-label="label"
                variant={GL_BUTTON_VARIANT.secondary}
                onClick={() => this.handleUpdateQrCode()}
                disabled={updateButtonDisabled}
              >
                {translateString(
                  TRANSLATIONS_KEY_LIST.employee_id_update_qr_code
                )}
              </GlButton>
            )}
            {!enableEdit && (
              <div
                className="enable-employeeid-change"
                onClick={() => this.handleEnableEdit()}
              >
                {translateString(TRANSLATIONS_KEY_LIST.change_employee_id)}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({
  appSettings,
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    appSettings,
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

export default connect(mapStateToProps)(EmployeeCheckout);

