import React, { Component } from "react";
import { connect } from "react-redux";
import Timer from "react-compound-timer";

import {
  GlParagraph,
  GL_PARAGRAPH_FONT_SIZE,
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
} from "@adl/foundation";

import { removeAllFromCart } from "../../../../actions/cartActions";

import ModalView from "../../../ModalView/ModalView";

import "./OrderCreationView.css";

import calendar from "../../../../assets/icons/calendar.svg";

import checkmarkConfirm from "../../../../assets/icons/checkmark-confirm.svg";

import {
  TRANSLATIONS_KEY_LIST,
  getTranslation,
} from "../../../../utils/translationCopies";

class OrderCreationView extends Component {
  state = {
    orderCreationInProgress: null,
    orderCreationSuccess: null,
    showOrderSuccessSummary: null,
  };

  // onOrderCreationComplete = () => {
  //   this.setState({
  //     orderCreationInProgress: false,
  //     orderCreationSuccess: true,
  //     showOrderSuccessSummary: true,
  //   });
  // };

  // onShowOrderSuccessSummary = () => {
  //   this.setState({ showOrderSuccessSummary: true });
  // };

  onStartNewSession = () => {
    this.props.removeAllFromCart();
    this.props.onSessionEnd();
  };

  componentDidMount() {} // Start order process

  componentDidUpdate() {}

  render() {
    // let { orderCreationSuccess, showOrderSuccessSummary } = this.state;
    let {
      orderCompletionDetails,
      finalOrderCreationInProcess,
      finalOrderCreationSuccess
     } = this.props;

    const _ESTIMATED_DELIVERY_DATE =
      finalOrderCreationSuccess && orderCompletionDetails?.product_groups[0]?.expected_delivery;
    const _EMAIL_SENT_TO =
      finalOrderCreationSuccess && orderCompletionDetails?.customer_info?.customer_email;
    const _ORDER_NUMBER = finalOrderCreationSuccess && orderCompletionDetails?.order_number;

    return (
      <>
        <ModalView
          show={this.props.showOrderCreationModal}
          onClose={() => {}}
          className="modal-centered"
        >
          {finalOrderCreationInProcess && (
            <div className="creating-order">
              <div className="in-progress xhidden">
                <div className="loading">
                  <GlParagraph>
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.loading
                    )}
                  </GlParagraph>
                  <div className="static-progress-bar"></div>
                </div>
                <div>
                  <GlParagraph
                    fontSize={GL_PARAGRAPH_FONT_SIZE.l}
                    className="info"
                  >
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.your_order_in_progress
                    )}
                  </GlParagraph>
                  {/* <div className="btn-next-container">
                    {!orderCreationSuccess && (
                      <div onClick={(e) => this.onOrderCreationComplete()}>
                        {"fake success"}
                      </div>
                    )}

                    {orderCreationSuccess && (
                      <div onClick={(e) => this.onShowOrderSuccessSummary()}>
                        {"proceed to success screen"}
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          )}

          {!finalOrderCreationInProcess && !finalOrderCreationSuccess && (
            <div className="creating-order">
              <div className="in-progress xhidden overlay-error-container">
                
                <div className="creating-order__error">
                  <GlParagraph
                    fontSize={GL_PARAGRAPH_FONT_SIZE.l}
                    className=""
                  >
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.error_occurred
                    )}
                  </GlParagraph>
                 </div>
                 <div>
                  <GlButton
                      aria-label="label"
                      variant={GL_BUTTON_VARIANT.tertiary}
                      onClick={(e) => this.props.endCheckout()}
                    >
                      {getTranslation(
                        this.props.selectedLocale,
                        TRANSLATIONS_KEY_LIST.retry_place_order
                      )}
                  </GlButton>
                 </div>
                 <div>
                  <GlButton
                      aria-label="label"
                      variant={GL_BUTTON_VARIANT.tertiary}
                      onClick={(e) => this.props.setShowOrderCreationModal(false)}
                    >
                      {getTranslation(
                        this.props.selectedLocale,
                        TRANSLATIONS_KEY_LIST.cancel
                      )}
                  </GlButton>
                 </div>
              </div>
            </div>
          )}


          {!finalOrderCreationInProcess && finalOrderCreationSuccess && (
            <div className="creating-order__success">
              <div className="checkmark-confirm">
                <img src={checkmarkConfirm} alt="checkmark-confirm"></img>
              </div>
              <div>
                <h4>
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.your_order_is_placed
                  )}
                </h4>
              </div>
              <div className="session-end-info-container">
                {`${getTranslation(
                  this.props.selectedLocale,
                  TRANSLATIONS_KEY_LIST.session_ends_in
                )}`}{" "}
                <Timer
                  initialTime={30000}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => {
                        console.log(
                          "Reached timeout exiting order summary view "
                        );

                        this.onStartNewSession();
                      },
                    },
                  ]}
                >
                  {({ reset }) => (
                    <>
                      <Timer.Minutes />:<Timer.Seconds />
                    </>
                  )}
                </Timer>
                {/* {"session ends in 03:00"} */}
              </div>

              <div>
                <img src={calendar} alt="calendar"></img>
              </div>

              <div className="order-details">
                <div>
                  <div className="label">{`${getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.estimated_delivery
                  )} :`}</div>

                  <div>{`${new Date(_ESTIMATED_DELIVERY_DATE)
                    .toLocaleString()
                    .replace(":00 ", " ")}`}</div>
                </div>
                <div className="label">
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.confirmation_email_sent_to
                  )}
                </div>
                <div>{_EMAIL_SENT_TO}</div>

                <div className="label">{`${getTranslation(
                  this.props.selectedLocale,
                  TRANSLATIONS_KEY_LIST.order_number
                )}:${_ORDER_NUMBER}`}</div>
              </div>

              <div className="cta-container">
                <GlButton
                  aria-label="label"
                  theme={GL_BUTTON_THEME.light}
                  variant={GL_BUTTON_VARIANT.secondary}
                  onClick={() => this.onStartNewSession()}
                >
                  {getTranslation(
                    this.props.selectedLocale,
                    TRANSLATIONS_KEY_LIST.start_new_session
                  )}
                </GlButton>
              </div>
            </div>
          )}
        </ModalView>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    removeAllFromCart: () => dispatch(removeAllFromCart()),
  };
}

export default connect(null, mapDispatchToProps)(OrderCreationView);
