import FilterHelper from "./helpers/filterOptions";
import SortHelper from "./helpers/sortOptions";

export const getRefRefinementsAndSortOptions = function () {
  return {
    referenceRefinements: FilterHelper.getInstance().referenceRefinements,
    referenceSortOptions: SortHelper.getInstance().refMarketSortOptions,
  };
};
/**
 * Summary. Translates display values of
 * - filter name sand its options name
 * - sort options
 *
 * Description. It updates the filter and its options, with translation from other ref locale.
 *
 * @access     public
 * @param {string}  selectedRefinementsCommaSep   User selected refinements in comma sep string.
 * @param {array}   refMarketRefinements          Refinement data from other ref market
 * @param {string}  refMarketLocale               reference market locale, for e.g. if en translation is needed then you may provide en_GB or even en_US and likewise
 * @param {array}   refinements                   The refinement data that needs to be translated.
 * @param {string}  locale                        Current locale value
 *
 * @return {null} Returns null
 */
export const translateFilters = function (
  refMarketRefinements,
  refMarketLocale,
  refinements,
  locale
) {
  const { referenceRefinements } = getRefRefinementsAndSortOptions();

  let filterHelper = FilterHelper.getInstance();

  if (referenceRefinements?.length > 0) {
    filterHelper.translateUsingReferenceRefinements(refinements, locale);
  } else {
    filterHelper.init(refMarketRefinements, refMarketLocale);
    filterHelper.translateUsingReferenceRefinements(refinements, locale);
  }
};

/**
 * Summary. Translates the applied-filter title *
 * @access     public
 * @param {string}  title   title string
 * @param {array}   refinements   applied filters array
 *
 * @return {null} Returns translated title
 */
export const translateFiltersAppliedTitle = function (title, refinements) {
  let filterHelper = FilterHelper.getInstance();

  const referenceRefinementsTranslationMap =
    filterHelper.referenceRefinementsTranslationMap;

  let resultArray = [];

  let updatedTitle = title;

  try {
    refinements.forEach((x) => {
      const nonLocValue = x?.non_localized_value;
      const displayName = x?.name;

      const matchingTranslation = referenceRefinementsTranslationMap.filter(
        (y) => y.key === nonLocValue
      )?.[0];

      if (matchingTranslation?.value) {
        updatedTitle = updatedTitle.replace(
          displayName,
          matchingTranslation?.value
        );
      }
    });

    return updatedTitle;
  } catch (_) {
    console.log(
      "error occured while translating the title in other ref market locale"
    );
  }

  return resultArray.join(" ");
};

/**
 * Summary. Translates display values of
 * - sort options
 *
 * Description. it updates the display value of sort options, with its translation from other ref locale
 *
 * @access     public
 * @param {array}   refMarketSortOptions        sort option from ref market.
 * @param {string}  refMarketLocale             reference market locale, for e.g. if en translation is needed then you may provide en_GB or even en_US and likewise
 * @param {array}   sortOptions                 The sortOptions data that needs to be translated.
 * @param {string}  locale                      Current locale value
 *
 * @return {null} Returns null
 */
export const translateSortOptions = function (
  refMarketSortOptions,
  refMarketLocale,
  sortOptions,
  locale
) {
  let sortHelper = SortHelper.getInstance();
  const referenceSortOptions = sortHelper.referenceSortOptions;

  if (referenceSortOptions?.length > 0) {
    sortHelper.translateUsingReferenceSortOptions(sortOptions, locale);
  } else {
    sortHelper.init(refMarketSortOptions, refMarketLocale);

    sortHelper.translateUsingReferenceSortOptions(sortOptions, locale);
  }
};
