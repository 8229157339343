import flag_en_Fr from "../../../../assets/flag-overrides/en_FR.png";
import flag_fr_Fr from "../../../../assets/flag-overrides/fr_FR.png";

const flagOverrides = [
  {
    locale: "fr_CA",
    flagPath: flag_fr_Fr,
  },
  {
    locale: "en_CA",
    flagPath: flag_en_Fr,
  },
];

export default flagOverrides;
