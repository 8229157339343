import React, { Component } from "react";

import "./SizeGrid.css";

import { connect } from "react-redux";

import { SIZE_AVAILABILITY_TYPE } from "../../constants";

class SizeGrid extends Component {
  state = {
    sizeSelected: this.props.sizeSelected || null,
    showStockLevelForAllSizes: false,
    orderableSizesCombined: null,
  };

  componentDidMount() {
    this.updateSizeInformation();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.orderableSizes_online) !==
      JSON.stringify(prevProps.orderableSizes_online)
    ) {
      this.setState({
        orderableSizes_online: this.props.orderableSizes_online,
      });

      this.updateSizeInformation();
    }

    if (
      JSON.stringify(this.props.orderableSizes_instore) !==
      JSON.stringify(prevProps.orderableSizes_instore)
    ) {
      this.updateSizeInformation();
    }

    if (
      JSON.stringify(this.props.sizeSelected) !==
      JSON.stringify(prevProps.sizeSelected)
    ) {
      this.setState({ sizeSelected: this.props.sizeSelected });
    }
  }

  updateSizeInformation = () => {
    this.setState({ orderableSizesCombined: [] });

    let { orderableSizes_instore, orderableSizes_online } = this.props;

    let orderableSizesCombined = [];

    orderableSizes_online?.forEach((item) => {
      item.available_online = true;
      orderableSizesCombined.push(item);
    });

    orderableSizes_instore?.forEach((item) => {
      let match = orderableSizesCombined.filter(
        (x) =>
          x.variation_product_id === item.variation_product_id ||
          x.size === item.size
      );

      if (match.length > 0) {
        //present in array so just update that it is also available in-store flag
        match[0].available_in_store = true;
        // setting the technical size when its found from sam api
        if (!match[0].technical_size) {
          match[0].technical_size = `${item.technical_size}`;
        }
      } else {
        item.available_in_store = true;
        item.available_online = false;
        orderableSizesCombined.push(item);
      }
    });

    this.setState({ orderableSizesCombined });
  };

  onSizeClicked = (e, size) => {
    let _remainingStock = this.getAvailableStock(size);

    if (_remainingStock < 1) return;

    let _sizeSelected = this.state.sizeSelected;

    if (JSON.stringify(_sizeSelected) === JSON.stringify(size)) {
      size = null;
    }

    this.setState({ sizeSelected: size });

    this.props.onSizeClicked(e, size);
  };

  getAvailableStock = (x) => {
    let _currentStockLevel = x?.stock_level;

    let _remainingStock = _currentStockLevel;

    let currentSkuCountInBasket_List = this.props.cartItems.filter(
      (y) => y.sku === x.variation_product_id
    );

    if (currentSkuCountInBasket_List?.length > 0) {
      _remainingStock =
        _currentStockLevel - currentSkuCountInBasket_List[0].quantity;
      // console.log(_remainingStock);

      if (_remainingStock < 1) {
        if (
          this.state.sizeSelected?.variation_product_id ===
          x.variation_product_id
        ) {
          this.setState({ sizeSelected: null });
          this.props.onSizeClicked(null, null);
        }
      }
    }

    return _remainingStock;
  };

  render() {
    let { showStockLevelForAllSizes, orderableSizesCombined } = this.state;

    // if (!showStockLevelForAllSizes) {
    //   orderableSizes = orderableSizes?.filter((x) => x.stock_level > 0);
    // }

    let { sizeSelected, sizeAvailabilityType } = this.props;

    if (sizeAvailabilityType === SIZE_AVAILABILITY_TYPE.online) {
      orderableSizesCombined = orderableSizesCombined?.filter(
        (x) => x.available_online === true
      );
    } else {
      orderableSizesCombined = orderableSizesCombined?.filter(
        (x) => x.available_in_store === true
      );
    }

    return (
      <>
        {/* <h2>{sizeSelected?.size}</h2> */}
        {!orderableSizesCombined && (
          <div className={"sizegrid-container border_top"}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
              <div key={x} className="skeleton-size-item">
                <div></div>
              </div>
            ))}
          </div>
        )}
        <div
          className={`sizegrid-container ${
            orderableSizesCombined?.length > 4 ? "border_top" : ""
          }`}
        >
          {orderableSizesCombined &&
            orderableSizesCombined.map((x) => (
              <div
                key={x?.technical_size}
                onClick={(e) => this.onSizeClicked(e, x)}
                className={`
                
                ${
                  sizeSelected?.variation_product_id ===
                    x?.variation_product_id && this.getAvailableStock(x) > 0
                    ? "size-selected"
                    : ""
                }${orderableSizesCombined?.length < 5 ? " border_top" : ""}
                ${this.getAvailableStock(x) < 1 ? " unavailable-sku " : " "}
                `}
              >
                {x.size}

                {/* {"(" + sizeSelected?.variation_product_id + ")"} */}

                <span className="available-instore">
                  {showStockLevelForAllSizes && (
                    <div
                      className={`debug-size-stock-level ${
                        this.getAvailableStock(x) < 1 ? "unavailable-sku" : ""
                      }`}
                    >
                      {this.getAvailableStock(x)}
                      {" of "}
                      {x?.stock_level}
                    </div>
                  )}
                </span>
              </div>
            ))}
        </div>
        <div
          className="debug-msg hidden"
          onClick={() => {
            this.setState({
              showStockLevelForAllSizes: !showStockLevelForAllSizes,
            });
          }}
        >
          <p style={{ textDecoration: "underline" }}>
            {"debug: Toggle stock level info"}
          </p>
        </div>
      </>
    );
  }
}

function mapStateToProps({ cartItems }) {
  return {
    cartItems,
  };
}

export default connect(mapStateToProps, null)(SizeGrid);
