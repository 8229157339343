import React, { Component } from "react";

import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";

import { receiveEventsList } from "../../actions/eventActions";

import "./LandingPage.css";

import { API } from "../../API/index";

import { connect } from "react-redux";

import imageCTAMembership from "../../assets/images/ssk_cta_membership.png";

import adiclubLogo from "../../assets/images/adiclub.png";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

const SECTIONIMAGES = {
  membershipBanner: "membershipBanner",
  membershipCta: "membershipCta",
  releasesEventsBanner: "releasesEventsBanner",
  instoreServices: "instoreServices",
};

class LandingPage extends Component {
  state = {
    isProductListDataLoadError: null,
    rootProductFilterOptions: null,
  };

  componentDidMount() {
    if (!this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.landingPage);
    }

    //delay to init api constructors and configs
    setTimeout(() => {
      this.fetchProductList();
    }, 1000);
    this.fetchEvents();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedTranslationLocale !==
      this.props.selectedTranslationLocale
    ) {
      this.fetchProductList();
    }
  }

  fetchEvents() {
    const ssmID = this.props.appSettings?.value?.player?._SSMID;
    const locale =
      this.props.selectedTranslationLocale ||
      this.props.appSettings?.value?.default_locale ||
      "en_US";

    if (ssmID) {
      API.eventsAPI.getEvents(ssmID, locale).then((x) => {
        this.props.receiveEventsList(x);
        console.log("received response from events API, response: ", x);
      });
    }
  }

  fetchProductList() {
    API.productsAPI
      .getProducts(
        null,
        "",
        "",
        "",
        1,

        this.props?.selectedTranslationLocale ||
          this.props?.selectedLocale ||
          this.props?.appSettings?.value?.default_locale // workaround - for translating display text.
      )
      .then((x) => {
        let filterOptionsFromConfig =
          this.props.appSettings?.value?.landing_page?.filter_section
            ?.filter_options || [];

        let index = 0;
        const rootProductFilterList = filterOptionsFromConfig.map((x) => {
          x.value = x?.value?.toLowerCase();
          x.index = index++;
          return x;
        });

        console.log(rootProductFilterList);

        const rootProductFilterOptions = [];

        x.refinements?.forEach((element) => {
          element.values?.forEach((x) => {
            if (
              rootProductFilterList?.filter(
                (z) => x?.non_localized_value === z.value
              )?.length > 0
            ) {
              if (x?.hit_count > 0) {
                let existsInArrayAlready =
                  rootProductFilterOptions.filter(
                    (match) =>
                      match.non_localized_value === x?.non_localized_value
                  )?.length > 0;

                if (!existsInArrayAlready) {
                  rootProductFilterOptions.push({
                    index: rootProductFilterList?.filter(
                      (z) => x?.non_localized_value === z.value
                    )[0]?.index,
                    name: x?.name,
                    non_localized_value: x?.non_localized_value,
                    value: x?.value,
                    hit_count: x?.hit_count,
                    refinement_id: element?.refinement_id,
                    image_url: filterOptionsFromConfig.filter(
                      (y) => y.value === x?.non_localized_value
                    )[0]?.image_url,
                    retail: {
                      filter_id: filterOptionsFromConfig.filter(
                        (y) => y.value === x?.non_localized_value
                      )[0]?.retail?.filter_id,
                      filter_value: filterOptionsFromConfig.filter(
                        (y) => y.value === x?.non_localized_value
                      )[0]?.retail?.filter_value,
                    },
                  });
                }
              }
            }
          });
        });

        // sort based on index, i.e. way in which they appear in config
        rootProductFilterOptions.sort((a, b) => {
          return a.index - b.index;
        });

        this.setState({
          rootProductFilterOptions: rootProductFilterOptions,
        });
      });
  }

  getSectionImage(type) {
    const landingPageConfig = this.props.appSettings?.value?.landing_page;

    let returnImage;

    switch (type) {
      case SECTIONIMAGES.membershipBanner:
        returnImage = landingPageConfig?.membership_section?.image_url;
        break;
      case SECTIONIMAGES.membershipCta:
        returnImage = imageCTAMembership;
        break;
      case SECTIONIMAGES.releasesEventsBanner:
        returnImage = landingPageConfig?.events_section?.image_url;
        break;
      case SECTIONIMAGES.instoreServices:
        returnImage = landingPageConfig?.instore_services_section?.image_url;
        break;
      default:
        break;
    }

    return returnImage;
  }

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { rootProductFilterOptions } = this.state;

    const enabledLandingPage =
      this.props.appSettings?.value?.landing_page?.enabled || false;

    const enableFilterSection =
      this.props.appSettings?.value?.landing_page?.filter_section?.enabled ||
      false;

    const enableMembershipSection =
      this.props.appSettings?.value?.landing_page?.membership_section
        ?.enabled || false;

    const enableEventsSection =
      this.props.appSettings?.value?.landing_page?.events_section?.enabled ||
      false;

    const enableInstoreServicesSection =
      this.props.appSettings?.value?.landing_page?.instore_services_section
        ?.enabled || false;

    //if no section is enabled in landing page; lets directly show the PLP
    if (
      !enabledLandingPage ||
      (!enableFilterSection &&
        !enableEventsSection &&
        !enableInstoreServicesSection &&
        !enableMembershipSection)
    ) {
      setTimeout(() => {
        this.props.setCurrentPageName(PAGE_NAMES.plp);
      }, 800);
    }

    return (
      <div className="landing-page-wrapper">
        {enableFilterSection && (
          <div className="section">
            {rootProductFilterOptions?.length > 0 && (
              <>
                <h2 className="heading">
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.landingpage_filter_heading
                  )}
                </h2>
                <div className="content content-no-wrap">
                  {rootProductFilterOptions?.map((x) => (
                    <div
                      key={x.non_localized_value}
                      className="item"
                      style={{
                        backgroundImage: `url(${x?.image_url})`,
                      }}
                      onClick={() => {
                        this.props.onRootProductFilterSelected(x);
                        this.props.setCurrentPageName(PAGE_NAMES.plp);
                      }}
                    >
                      <div className="label">{x?.name}</div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        )}

        {(!enableFilterSection || rootProductFilterOptions?.length === 0) && (
          <>
            <div className="content content-no-wrap">
              <div
                className="item"
                style={{
                  backgroundImage: ``,
                }}
                onClick={() => {
                  this.props.setCurrentPageName(PAGE_NAMES.plp);
                }}
              >
                <div className="label">
                  {/* to show all products as a fallback when no filter is present */}
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.all_products)}
                </div>
              </div>
            </div>
            <br />
          </>
        )}

        {enableMembershipSection && (
          <div
            className="section"
            onClick={(e) => {
              this.props.setCurrentPageName(PAGE_NAMES.membershipPage);
            }}
          >
            <h2 className="heading">
              {this.getTranslation(
                TRANSLATIONS_KEY_LIST.landingpage_signup_heading
              )}
            </h2>
            <div className="content">
              <div
                className="item wide"
                style={{
                  backgroundImage: `url(${this.getSectionImage(
                    SECTIONIMAGES.membershipBanner
                  )})`,
                }}
              ></div>
              <div
                className="item black-text "
                style={{
                  backgroundImage: `url(${this.getSectionImage(
                    SECTIONIMAGES.membershipCta
                  )})`,
                }}
              >
                <div className="label top-left-aligned">
                  <div>
                    {" "}
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.landingpage_signup_cta
                    )}
                  </div>
                  <div className="cta-logo">
                    <img src={adiclubLogo} alt={"adiclub"}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* showing events section only if enabled from appsettings and also when events data is present for ssmid */}

        {enableEventsSection && this.props.eventsList.length > 0 && (
          <div
            className="section"
            onClick={(e) => {
              this.props.setCurrentPageName(PAGE_NAMES.eventsPage);
            }}
          >
            <div className="content">
              <div className="item">
                <div className="label top-left-aligned">
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.landingpage_events_cta
                  )}
                </div>
              </div>
              <div className="item wide">
                <div
                  className="label"
                  style={{
                    backgroundImage: `url(${this.getSectionImage(
                      SECTIONIMAGES.releasesEventsBanner
                    )})`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}

        {enableInstoreServicesSection && (
          <div
            className="section"
            onClick={(e) => {
              this.props.setCurrentPageName(PAGE_NAMES.servicesPage);
            }}
          >
            <div className="content">
              <div className="item wide">
                <div
                  className="label"
                  style={{
                    backgroundImage: `url(${this.getSectionImage(
                      SECTIONIMAGES.instoreServices
                    )})`,
                  }}
                ></div>
              </div>
              <div className="item">
                <div className="label top-left-aligned">
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.landingpage_instore_services_cta
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* todo : what's in store arrival coming soon etc to be done later when data source is known; paged section */}
        <div className="section hidden">
          <div className="heading">
            What's in store/ New Arrivals/ Coming SOON/ SALE
          </div>
          <div className="content left-aligned content-no-wrap">
            <div className="item item-narrow">
              <div className="label">Men</div>
            </div>
            <div className="item item-narrow">
              <div className="label">Men</div>
            </div>
            <div className="item item-narrow">
              <div className="label">Kids</div>
            </div>
            <div className="item item-narrow">
              <div className="label">Kids</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default InteractionVideoView;
function mapStateToProps({
  appSettings,
  eventsList,
  pdpRequest,
  selectedTranslationLocale,
}) {
  return {
    appSettings,
    eventsList,
    pdpRequest,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
    receiveEventsList: (data) => dispatch(receiveEventsList(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
