import React, { Component } from "react";

import "./Header.css";

import { connect } from "react-redux";

import adidasLogo from "../../assets/images/adidas_logo.png";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

class Header extends Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    return (
      <div className="site-header">
        <div className="logo">
          <img width="60" src={adidasLogo} alt="brand-logo"></img>
        </div>
        <div className="label">
          {this.getTranslation(TRANSLATIONS_KEY_LIST.app_header_title)}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ appSettings, selectedTranslationLocale }) {
  return {
    appSettings,
    selectedTranslationLocale,
  };
}

export default connect(mapStateToProps)(Header);
