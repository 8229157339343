import APIBase from "../APIBase/APIBase";

class ProductsAvailabilityAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 20 * 1000, // set cache time to 20 sec
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return { response };
    });
  };

  #getProductAvailability = async (productId, locale) => {
    await this.#getHeaders();

    let _url = `/products/${productId}/availability`;

    if (locale) {
      _url = `${_url}?locale=${locale}`;
    }

    return this.#handleAPIGetRequest(_url);
  };

  getAllFunctions = () => {
    return {
      getProductAvailability: this.#getProductAvailability,
    };
  };
}

export default ProductsAvailabilityAPI;
