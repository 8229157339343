import React, { Component } from "react";

import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlParagraph,
} from "@adl/foundation";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../utils/translationCopies";

import "./CurrentAddressSelectionView.css";

class CurrentAddressSelectionView extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  getPickupAvailabilityInfo = (availability) => {
    return (
      <GlParagraph className="estimated-delivery opening-hours">
        {`${new Date(availability?.from)
          .toLocaleString()
          .replace(":00 ", " ")}`}

        {" - "}
        <br />
        {`${new Date(availability?.to).toLocaleString().replace(":00 ", " ")}`}
      </GlParagraph>
    );
  };

  render() {
    let {
      deliveryOptionSelected,
      setAddressSelectionMode,
      selectedAddress,
      deliveryOptionObject,
    } = this.props;

    // let _locDetailsLabel = "Location Details";
    let _changeAddressLocation = "en_Change Pick-up Point";

    if (deliveryOptionSelected === "store") {
      // _locDetailsLabel = "Store Details";
      _changeAddressLocation = getTranslation(
        this.props.selectedLocale,
        TRANSLATIONS_KEY_LIST.change_store
      );
    } else {
      // _locDetailsLabel = "Store Details";
      _changeAddressLocation = getTranslation(
        this.props.selectedLocale,
        TRANSLATIONS_KEY_LIST.change_pickup_point
      );
    }

    return (
      <>
        <section className="selected-location-info">
          <div>
            <div className="bold">
              <GlParagraph>{selectedAddress?.name}</GlParagraph>
            </div>
            <div className="address">
              <GlParagraph>
                <span>
                  {selectedAddress?.street} <br />
                  {`${selectedAddress?.city} ${
                    selectedAddress?.postalCode || ""
                  }`}{" "}
                  <br />
                </span>
              </GlParagraph>

              {/* <div className="tertiary-btn">
                <GlButton
                  aria-label="label"
                  variant={GL_BUTTON_VARIANT.tertiary}
                >
                  {_locDetailsLabel}
                </GlButton>
              </div> */}
            </div>
          </div>
          <div>
            <GlParagraph className="delivery-date">
              {deliveryOptionObject?.details?.delivery_time?.availability || (
                <span>
                  {selectedAddress?.estimated_delivery?.availability_text}

                  {selectedAddress?.work_time &&
                    this.getPickupAvailabilityInfo(
                      selectedAddress?.availability
                    )}
                </span>
              )}
            </GlParagraph>
          </div>

          <div onClick={(e) => setAddressSelectionMode(true)}>
            <GlButton
              aria-label="label"
              theme={GL_BUTTON_THEME.dark}
              variant={GL_BUTTON_VARIANT.secondary}
            >
              {_changeAddressLocation}
            </GlButton>
          </div>
        </section>
      </>
    );
  }
}

export default CurrentAddressSelectionView;
