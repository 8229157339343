import APIBase from "../APIBase/APIBase";

import { transformEventsList } from "../../../utils/dataTransformations";

class EventsAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 5 * 60 * 1000, // set cache time to 5 minutes
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return response;
    });
  };

  #getEvents = async (ssmId, locale) => {
    await this.#getHeaders();

    const _url = `/events/locations/${ssmId}/digital-signage/`;

    try {
      let response = await this.#handleAPIGetRequest(_url);
      console.log(response);

      console.log("Events Api data ", response?.data?.data);

      const eventsList = transformEventsList(
        response?.data?.data?._embedded?.events,
        locale
      );

      return eventsList;
    } catch {
      return null;
    }
  };

  getAllFunctions = () => {
    return {
      getEvents: this.#getEvents,
    };
  };
}

export default EventsAPI;
