import APIBase from "../APIBase/APIBase";

class SamAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi.get(_url).then((response) => {
      return response;
    });
  };

  #handleAPIPostRequest = (_url, _postData) => {
    return this.axiosApi({
      url: _url,
      method: "post",
      data: _postData,
    }).then((response) => {
      return response;
    });
  };

  #handleAPIDeleteRequest = (_url) => {
    return this.axiosApi({ url: _url, method: "delete" }).then((response) => {
      return response;
    });
  };

  #getProductIdFromEAN = async (ean) => {
    await this.#getHeaders();

    let _url = `/sam/barcode/${ean}`;

    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 8 * 60 * 60 * 1000, // set cache time to 8 hour
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return response;
    });
  };

  #getProductAvailabilityInStore = async (productId, ssmid) => {
    await this.#getHeaders();
    let _url = `/sam/products/${productId}/in-store-availability?ssmid=${ssmid}`;

    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 20 * 1000, // set cache time to 20 sec
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return response;
    });
  };

  #createBITMTask = async (
    productId,
    technicalSize,
    ssmid,
    countryCode,
    InStoreLocationIdentifier,
    bitmRequestType
  ) => {
    let _postData = {
      type: bitmRequestType,
      ssmId: ssmid,
      countryCode: countryCode,
      stockKeepingUnits: [
        {
          articleNumber: productId,
          technicalSize: technicalSize,
          quantity: 1,
        },
      ],
      location: InStoreLocationIdentifier,
      customerName: "ssk_user",
    };

    let _url = `/sam/bitm/task/`;

    return this.#handleAPIPostRequest(_url, _postData);
  };

  #createAssociateHelpRequestTask = (
    ssmid,
    countryCode,
    InStoreLocationIdentifier,
    requestType
  ) => {
    let _postData = {
      type: requestType,
      ssmId: ssmid,
      countryCode: countryCode,

      location: InStoreLocationIdentifier,
      note: "store associate request from ssk",
    };

    let _url = `/sam/bitm/task/`;

    return this.#handleAPIPostRequest(_url, _postData);
  };

  #getTask = async (taskId) => {
    if (!taskId) {
      return Promise.reject("task id cannot be null or blank");
    } else {
      let _url = `/sam/bitm/tasks/${taskId}`;
      return this.#handleAPIGetRequest(_url);
    }
  };

  #deleteTask = async (taskId) => {
    if (!taskId) {
      return Promise.reject("task id cannot be null or blank");
    } else {
      let _url = `/sam/bitm/task?taskId=${taskId}`;

      return this.#handleAPIDeleteRequest(_url);
    }
  };

  getAllFunctions = () => {
    return {
      getProductIdFromEAN: this.#getProductIdFromEAN,
      getProductAvailabilityInStore: this.#getProductAvailabilityInStore,
      createBITMTask: this.#createBITMTask,
      createAssociateHelpRequestTask: this.#createAssociateHelpRequestTask,
      getTask: this.#getTask,
      deleteTask: this.#deleteTask,
    };
  };
}

export default SamAPI;
