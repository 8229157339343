import { amplitude, getPriceType, getStockStatus } from ".";
import { EVENTS, EVENT_PROPERTIES } from "./eventsMappings";

/*
  Log event for amplitude. Require API_KEY to be included, for the reference please go to the analytics/config.js file.
*/

const logEvent = (eventName, eventProperties) => {
  amplitude.getInstance().logEvent(EVENTS[eventName], eventProperties);
};

// TO DO
export const start = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Home",
    [EVENT_PROPERTIES.screen_type]: "Home",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
  };

  logEvent("home_start", eventProperties);
};

export const endSession = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Home",
    [EVENT_PROPERTIES.screen_type]: "Home",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
  };

  logEvent("end_session", eventProperties);
};

export const filterApply = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PLP|Filter",
    [EVENT_PROPERTIES.screen_type]: "PLP",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.filter_selection]: data.filterSelection,
  };

  logEvent("filter_apply", eventProperties);
};

export const plpView = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PLP",
    [EVENT_PROPERTIES.screen_type]: "PLP",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.filter_selection]: data.filterSelection,
  };

  logEvent("plp_view", eventProperties);
};

export const pdpView = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PDP",
    [EVENT_PROPERTIES.screen_type]: "PDP",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
    [EVENT_PROPERTIES.product_color]: data.color,
    [EVENT_PROPERTIES.product_currency]: data.currency,
    [EVENT_PROPERTIES.product_name]: data.productName,
    [EVENT_PROPERTIES.product_originalprice]: data.orginalPrice,
    [EVENT_PROPERTIES.product_price]: data.price,
    [EVENT_PROPERTIES.product_pricetype]: getPriceType(
      data.orginalPrice,
      data.price
    ),
    [EVENT_PROPERTIES.product_rating]: data.rating,
    [EVENT_PROPERTIES.product_reviewcount]: data.reviewCount,
    [EVENT_PROPERTIES.product_sizecategory]: data.sizeCategory,
    [EVENT_PROPERTIES.product_stockstatus]: getStockStatus(data.stockStatus),
    [EVENT_PROPERTIES.product_quantity]: data.quantity,
  };

  logEvent("pdp_view", eventProperties);
};

export const addToCart = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PDP",
    [EVENT_PROPERTIES.screen_type]: "PDP",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
    [EVENT_PROPERTIES.product_color]: data.color,
    [EVENT_PROPERTIES.product_currency]: data.currency,
    [EVENT_PROPERTIES.product_name]: data.productName,
    [EVENT_PROPERTIES.product_originalprice]: data.orginalPrice,
    [EVENT_PROPERTIES.product_price]: data.price,
    [EVENT_PROPERTIES.product_pricetype]: getPriceType(
      data.orginalPrice,
      data.price
    ),
    [EVENT_PROPERTIES.product_rating]: data.rating,
    [EVENT_PROPERTIES.product_reviewcount]: data.reviewCount,
    [EVENT_PROPERTIES.product_sizecategory]: data.sizeCategory,
    [EVENT_PROPERTIES.product_stockstatus]: getStockStatus(data.stockStatus),
    [EVENT_PROPERTIES.product_quantity]: data.quantity,
    [EVENT_PROPERTIES.product_size]: data.productSize,
  };

  logEvent("pdp_add_to_cart", eventProperties);
};

export const callStoreAssociate = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PDP",
    [EVENT_PROPERTIES.screen_type]: "PDP",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
  };

  logEvent("pdp_call_store_associate", eventProperties);
};

export const bitm = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Try it on",
    [EVENT_PROPERTIES.screen_type]: "Retail",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.store_id]: data.storeId,
    [EVENT_PROPERTIES.store_name]: data.hostname,
    [EVENT_PROPERTIES.store_country]: data.country,
    [EVENT_PROPERTIES.store_ssmid]: data.ssmId,
    [EVENT_PROPERTIES.product_articleid]: data.sku,
    [EVENT_PROPERTIES.product_type]: data.productId,
  };

  logEvent("retail_bitm", eventProperties);
};

export const viewBitm = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Bring it to me",
    [EVENT_PROPERTIES.screen_type]: "Retail",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.store_id]: data.sotreId,
    [EVENT_PROPERTIES.store_name]: data.storeName,
    [EVENT_PROPERTIES.store_city]: data.city,
    [EVENT_PROPERTIES.store_country]: data.country,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
    [EVENT_PROPERTIES.store_statusmessage]: data.statusMessage,
    [EVENT_PROPERTIES.store_distance]: data.distance,
    [EVENT_PROPERTIES.store_ssmid]: data.ssmId,
    [EVENT_PROPERTIES.product_name]: data.productName,
  };

  logEvent("retail_view_bitm", eventProperties);
};

export const cancelBitm = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Bring it to me",
    [EVENT_PROPERTIES.screen_type]: "Retail",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.store_id]: data.sotreId,
    [EVENT_PROPERTIES.store_name]: data.storeName,
    [EVENT_PROPERTIES.store_city]: data.city,
    [EVENT_PROPERTIES.store_country]: data.country,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
    [EVENT_PROPERTIES.store_ssmid]: data.ssmId,
    [EVENT_PROPERTIES.product_type]: data.type,
  };

  logEvent("retail_cancel_bitm", eventProperties);
};

export const searchView = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "(results screen)",
    [EVENT_PROPERTIES.screen_type]: "Search",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
  };

  logEvent("search_view", eventProperties);
};

export const searchTerm = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "PLP, No Search Results",
    [EVENT_PROPERTIES.screen_type]: "Search",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.search_term]: data.enteredText,
    [EVENT_PROPERTIES.search_results]: data.searchResults,
  };

  logEvent("search_search_term", eventProperties);
};

export const startCheckout = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Cart|Shopping Cart",
    [EVENT_PROPERTIES.screen_type]: "Cart",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.cart_itemquantity]: data.quantity,
    [EVENT_PROPERTIES.cart_products]: data.products,
    [EVENT_PROPERTIES.cart_value]: data.value,
    [EVENT_PROPERTIES.product_currency]: data.currency,
  };

  logEvent("cart_start_checkout", eventProperties);
};

export const cartView = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]:
      data.quantity.length === 0 ? "Cart|Empty Cart" : "Cart|Shopping Cart",
    [EVENT_PROPERTIES.screen_type]: "Cart",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.cart_itemquantity]: data.quantity,
    [EVENT_PROPERTIES.cart_products]: data.products,
    [EVENT_PROPERTIES.cart_value]: data.value,
    [EVENT_PROPERTIES.product_currency]: data.currency,
  };

  logEvent("cart_view", eventProperties);
};

export const removeProductFromCart = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "Cart|Shopping Cart",
    [EVENT_PROPERTIES.screen_type]: "Cart",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
    [EVENT_PROPERTIES.product_articleid]: data.productId,
    [EVENT_PROPERTIES.product_currency]: data.currency,
    [EVENT_PROPERTIES.product_modelid]: data.modelId,
    [EVENT_PROPERTIES.product_name]: data.productName,
    [EVENT_PROPERTIES.product_originalprice]: data.orginalPrice,
    [EVENT_PROPERTIES.product_price]: data.price,
    [EVENT_PROPERTIES.product_pricetype]: getPriceType(
      data.orginalPrice,
      data.price
    ),
    [EVENT_PROPERTIES.product_size]: data.size,
    [EVENT_PROPERTIES.access_type]: data.accessType,
    [EVENT_PROPERTIES.product_articleidsize]: data.sizeId,
    [EVENT_PROPERTIES.product_color]: data.color,
    [EVENT_PROPERTIES.product_earlyaccess]: data.earlyAccess,
    [EVENT_PROPERTIES.product_quantity]: data.quantity,
    [EVENT_PROPERTIES.product_rating]: data.rating,
    [EVENT_PROPERTIES.product_reviewcount]: data.reviewCount,
    [EVENT_PROPERTIES.product_sizecategory]: data.category,
    [EVENT_PROPERTIES.product_stockstatus]: getStockStatus(data.stockStatus),
  };

  logEvent("cart_remove_product_from_cart", eventProperties);
};

export const continueOnKiosk = (data) => {
  const eventProperties = {
    [EVENT_PROPERTIES.screen_name]: "",
    [EVENT_PROPERTIES.screen_type]: "",
    [EVENT_PROPERTIES.event_source]: "SSK",
    [EVENT_PROPERTIES.store_hostname]: data.hostname,
  };

  logEvent("cart_continue_on_kiosk", eventProperties);
};
