import React, { Component } from "react";

import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";

// import "./ServicesListView.css";

import { connect } from "react-redux";

import { GlHeading } from "@adl/foundation";
import ServiceOverlayView from "./components/ServiceOverlayView";
import NewBackButton from "../NewBackButton/NewBackButton";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

class ServicesListView extends Component {
  state = {
    genderList: [],
    isProductListDataLoadError: null,
    eventSelected: null,
  };

  componentDidMount() {
    if (PAGE_NAMES.servicesPage !== this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.servicesPage);
    }
  }

  componentDidUpdate(prevProps) {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    return (
      <>
        {PAGE_NAMES.servicesPage === this.props.currentPage && (
          <>
            <div className="events events-page-wrapper">
              <GlHeading as="h2" fontStyle="regular" className="heading">
                {this.getTranslation(
                  TRANSLATIONS_KEY_LIST.instoreservicepage_heading
                )}
              </GlHeading>
              <p className="sub-heading">
                {this.getTranslation(
                  TRANSLATIONS_KEY_LIST.instoreservicepage_sub_heading
                )}
              </p>
              <div className="section tiles-container">
                <div
                  className="tile"
                  onClick={() => {
                    this.props.setCurrentPageName(
                      PAGE_NAMES.serviceDetailsPage
                    );
                  }}
                >
                  <div>
                    <img alt="event" src={""} />
                  </div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Maker LAB</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile">
                  <div>
                    <img alt="event" src={""} />
                  </div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Service name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile">
                  <div>
                    <img alt="event" src={""} />
                  </div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>bra fittingk</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tile">
                  <div>
                    <img alt="event" src={""} />
                  </div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Service name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile">
                  <div></div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Service name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile">
                  <div></div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Service name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile">
                  <div></div>
                  <div className="secondary-masthead">
                    <div className="container">
                      <div className="event-name-block">
                        <div className="sub-heading ">
                          <span>Service name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewBackButton
              onClick={() => {
                console.log(111);
                this.props.setCurrentPageName(PAGE_NAMES.landingPage);
              }}
            ></NewBackButton>
          </>
        )}

        {PAGE_NAMES.serviceDetailsPage === this.props.currentPage && (
          <ServiceOverlayView></ServiceOverlayView>
        )}
      </>
    );
  }
}

// export default InteractionVideoView;
function mapStateToProps({
  appSettings,
  currentPage,
  selectedTranslationLocale,
}) {
  return {
    appSettings,
    currentPage,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesListView);
