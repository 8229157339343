const consola = require("consola");

const MSG_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  ERROR: "error",
  SILENT: "silent",
  FATAL: "fatal",
  WARN: "warn",
  LOG: "log",
  READY: "ready",
  START: "start",
};

const log = (msg, type = MSG_TYPE.INFO) => {
  consola[type](msg);
};

module.exports = {
  log,
  MSG_TYPE,
};
