import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import { setup } from "axios-cache-adapter";

import { BFF_API } from "../constants";

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "ssk-product-availability",
});

const api = setup({
  baseURL: BFF_API,
  cache: {
    maxAge: 2 * 60 * 1000, // set cache time to 2 minutes
    exclude: { query: false }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

export function getProductAvailability(productId, locale) {
  let _url = `${BFF_API}/products/${productId}/availability`;

  if (locale) {
    _url = `${BFF_API}/products/${productId}/availability?locale=${locale}`;
  }
  return api.get(_url).then((response) => {
    return { response };
  });
}
