import React, { Component } from "react";

import ProductCard from "../../components/ProductCard/ProductCard";

import { InView } from "react-intersection-observer";

import ProductCardSkeleton from "../../components/ProductCardSkeleton/ProductCardSkeleton";

let isDataFetchRequested = false;

class ProductsGrid extends Component {
  state = {
    isDataFetchRequested: false,
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (prevProps.productList?.length !== this.props.productList?.length) {
      isDataFetchRequested = false;
    }
  }

  requestMoreData = () => {
    if (isDataFetchRequested) return;

    isDataFetchRequested = true;
    setTimeout(() => {
      this.props.fetchMoreResult();
    }, 500);
    return;
  };

  render() {
    let { productList, shouldActivateLoadingMore, dataReloading } = this.props;

    return (
      <>
        <div className={`product-list`}>
          {productList &&
            productList.map((product) => (
              <div
                key={product?.product_id}
                onClick={(e) => this.props.onProductCardClicked(e, product)}
              >
                <ProductCard
                  product={product}
                  selectedLocale={this.props.selectedLocale}
                ></ProductCard>
              </div>
            ))}

          {shouldActivateLoadingMore && !dataReloading && (
            <InView>
              {({ inView, ref }) => {
                if (inView) {
                  this.requestMoreData();
                  return <></>;
                } else {
                  return <div ref={ref}></div>;
                }
              }}
            </InView>
          )}

          {dataReloading &&
            [1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
              <ProductCardSkeleton key={x}></ProductCardSkeleton>
            ))}
        </div>
      </>
    );
  }
}

export default ProductsGrid;
