import React from "react";

import "./ModalView.css";

import { GlIcon } from "@adl/foundation";

const ModalView = (props) => {
  if (!props.show) {
    return null;
  }

  let additionalClass;
  if (props.className) {
    additionalClass = props.className;
  }

  return (
    <div
      className={`modal ${additionalClass}`}
      onClick={() => props.onClose()}
      ref={props.innerRef}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">
          {props.showCloseBtn && (
            <div className="close-btn" onClick={() => props.onClose()}>
              <GlIcon name="close" />
            </div>
          )}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default ModalView;
