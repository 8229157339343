import React, { Component } from "react";

import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlParagraph,
} from "@adl/foundation";

import "./UserDetailsSummary.css";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../utils/translationCopies";

class UserDetailsSummary extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let _editAddressLabel = getTranslation(
      this.props.selectedLocale,
      TRANSLATIONS_KEY_LIST.edit_address
    );

    let { userDetails } = this.props;

    return (
      <>
        <section className="selected-location-info">
          <div>
            <div className="bold">
              <GlParagraph>{`${userDetails?.firstName}${" "}${
                userDetails?.lastName
              }`}</GlParagraph>
            </div>
            <div className="address">
              <GlParagraph>
                <span>
                  {`${userDetails?.street}`}{" "}
                  {`${userDetails?.houseNumber || ""}`}
                  <br />
                  {`${userDetails?.zipCode}`}
                  <br />
                  {`${userDetails?.city}`}
                  <br />
                  {`${userDetails?.email}`}
                  <br />
                  {`${userDetails?.phoneNumber}`}
                  <br />
                </span>
              </GlParagraph>
            </div>
          </div>

          <div onClick={(e) => this.props.setEditView(true)}>
            <GlButton
              aria-label="label"
              theme={GL_BUTTON_THEME.dark}
              variant={GL_BUTTON_VARIANT.secondary}
            >
              {_editAddressLabel}
            </GlButton>
          </div>
        </section>
      </>
    );
  }
}

export default UserDetailsSummary;
