import React, { Component } from "react";

import { GlCallout, GL_CALLOUT_TYPE } from "@adl/foundation";

import "./AvailabilityInfo.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../../../../../utils/translationCopies";

import { API } from "../../../../../../../../../../API/index";

let mounted = false;

class AvailabilityInfo extends Component {
  state = {
    sizes: null,
    isLoading: false,
  };

  componentDidMount() {
    mounted = true;
    this.checkInStoreAvailability();
  }

  componentDidUnMount() {
    mounted = false;
  }

  checkInStoreAvailability = () => {
    let { productId, SSMID } = this.props;

    if (SSMID && productId && mounted) {
      this.setState({ isLoading: true });
      API.samAPI
        .getProductAvailabilityInStore(productId, SSMID)
        .then((x) => {
          if (mounted) {
            this.setState({
              sizes: x?.data?.data,
              isLoading: false,
            });
          }
        })
        .catch((e) => {
          if (mounted) {
            this.setState({
              sizes: null,
              isLoading: false,
            });
          }
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.SSMID !== this.props.SSMID) {
      this.checkInStoreAvailability();
    }
  }

  getDisplaySizeName = (size) => {
    // special formatting required only for JP market. API returns in mm but we need to show in cm.
    if (this.props?.selectedLocale?.split("_")[1] === "JP") {
      size = parseFloat(size) / 10 + "cm";
    }
    return size;
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { sizeCountryCode, SSMID, productId } = this.props;

    let { sizes, isLoading } = this.state;

    sizes = sizes?.filter((x) => x.sizes[sizeCountryCode]?.length > 0) || [];

    let availableSizes = sizes?.filter((x) => x.quantity > 0) || [];

    return (
      <>
        {productId && (
          <div className="nearbystore-availability-container">
            {this.props.ENABLE_DEBUG_COMPONENT && (
              <div className="debug-info">
                <p>SSMID: {SSMID}</p>

                {<p>Product ID: {productId}</p>}
              </div>
            )}

            {isLoading && (
              <div className={"sizegrid-container border_top"}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((x) => (
                  <div key={x} className="skeleton-size-item">
                    <div></div>
                  </div>
                ))}
              </div>
            )}

            {!isLoading && availableSizes?.length > 0 && (
              <div className={`sizegrid-container`}>
                {availableSizes &&
                  availableSizes.map((x) => (
                    <div
                      className={`sizegrid-container border_top ${
                        x.quantity > 0 ? "" : "unavailable"
                      }`}
                      key={x?.technical_size || x?.technicalSize}
                    >
                      {this.getDisplaySizeName(x.sizes[sizeCountryCode])}
                    </div>
                  ))}
              </div>
            )}

            {!isLoading && (availableSizes?.length === 0 || !sizes) && (
              <div className="no-availability-info-container">
                <div>
                  <GlCallout>
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.nearby_store_no_sizes_found
                    )}
                  </GlCallout>
                </div>
                <div>
                  <GlCallout type={GL_CALLOUT_TYPE.alternative}>
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.please_try_selecting_another_store
                    )}
                  </GlCallout>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default AvailabilityInfo;
