import React, { Component } from "react";
import { connect } from "react-redux";

import { GlButton, GL_BUTTON_THEME, GL_BUTTON_VARIANT } from "@adl/foundation";

import "./EditUserDetailsCompact.css";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../utils/translationCopies";

import * as helpers from "../../../../../../utils/helpers";

import InputWithKeyboard from "../../../../../InputWithKeyboard/InputWithKeyboard";

class EditUserDetailsCompact extends Component {
  state = {
    userDetails: null,
  };

  componentDidMount() {
    let _userDetails = this.props.userDetails;

    if (!_userDetails) {
      _userDetails = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      };
    }

    this.setState({
      userDetails: _userDetails,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      selctedInputWithKeyboardId,
      selctedInputWithKeyboardValue,
      onUserDetailsChanged,
    } = this.props;
    const { userDetails } = this.state;

    if (
      prevProps.selctedInputWithKeyboardValue !== selctedInputWithKeyboardValue
    ) {
      let _userDetails = userDetails;
      if (selctedInputWithKeyboardValue[selctedInputWithKeyboardId]) {
        _userDetails[selctedInputWithKeyboardId] =
          selctedInputWithKeyboardValue[selctedInputWithKeyboardId];

        this.setState({
          userDetails: _userDetails,
        });

        onUserDetailsChanged(_userDetails);
      }
    }
  }

  validateForm = () => {
    let _userDetails = this.state.userDetails;

    return helpers.validateUserDetailsForStorePickup(_userDetails);
  };

  saveForm = () => {
    const isValid = this.validateForm();
    return isValid;
  };

  updateCheckout() {
    this.props.setUserDetailInEditView(false);
    this.props.onCheckoutCustomerInformationUpdated();
  }

  getLocalTranslation = (key) => {
    return getTranslation(this.props.selectedLocale, key);
  };

  render() {
    return (
      <>
        <section className="consumer-details-form">
          <div>
            <h4>
              {this.getLocalTranslation(
                TRANSLATIONS_KEY_LIST.enter_your_details
              )}
            </h4>
            <div className="form">
              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="firstName"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.first_name
                    )}
                  />
                </div>
                <div className="item">
                  <InputWithKeyboard
                    id="lastName"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.last_name
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="item">
                  <InputWithKeyboard
                    id="email"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.email_address
                    )}
                  />
                </div>
                <div className="item">
                  <InputWithKeyboard
                    id="phoneNumber"
                    placeholder={this.getLocalTranslation(
                      TRANSLATIONS_KEY_LIST.phone_number
                    )}
                  />
                </div>
              </div>

              <div className="row">
                <div className="item"></div>
                <div
                  className="item btn-primary-container"
                  onClick={(e) =>
                    this.saveForm() ? this.updateCheckout() : null
                  }
                >
                  {this.props.storePickupUserDetailsSaveError && (
                    <div className="error-text">
                      {this.getLocalTranslation(
                        TRANSLATIONS_KEY_LIST.error_occurred
                      )}
                    </div>
                  )}
                  <GlButton
                    aria-label="label"
                    theme={GL_BUTTON_THEME.light}
                    variant={GL_BUTTON_VARIANT.secondary}
                    disabled={
                      !this.validateForm() ||
                      this.props.selectedAddress === null ||
                      this.props.addressSelectionMode
                    }
                  >
                    {getTranslation(
                      this.props.selectedLocale,
                      TRANSLATIONS_KEY_LIST.save_details
                    )}
                  </GlButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps({
  selctedInputWithKeyboardValue,
  selctedInputWithKeyboardId,
}) {
  return {
    selctedInputWithKeyboardValue,
    selctedInputWithKeyboardId,
  };
}

export default connect(mapStateToProps)(EditUserDetailsCompact);
