import React, { Component } from "react";

import Navigation from "../Navigation/Navigation";
import ProductSpecifications from "../ProductSpecifications/ProductSpecifications";
import ProductHighlights from "../ProductHighlights/ProductHighlights";
import UGC from "../UGC/Ugc";
import RatingsReviews from "../RatingsReviews/RatingsReviews";
import ProductGallery from "../ProductGallery/ProductGallery";

import { InView } from "react-intersection-observer";
import { scroller } from "react-scroll";

import {
  GlButton,
  GL_BUTTON_VARIANT,
  GlCallout,
  GlHeading,
  GlParagraph,
} from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  setTranslationLocale,
  translateString,
} from "../../../../../../utils/translationCopies";

const navIds = {
  GALLERY: 0,
  DETAILS: 1,
  HIGHLIGHTS: 2,
  HOW_TO_STYLE: 3,
  REVIEWS: 4,
};

class ProductDetailsWrapper extends Component {
  state = {
    navigationTabs: [
      { id: navIds.GALLERY, label: "gallery", available: true },
      { id: navIds.DETAILS, label: "details", available: false },
      { id: navIds.HIGHLIGHTS, label: "highlights", available: false },
      { id: navIds.HOW_TO_STYLE, label: "how_to_style", available: false },
      { id: navIds.REVIEWS, label: "reviews", available: false },
      // { id: 5, label: "complete the look", available: true },
    ],
    activeTabIndex: 0,
    productDescription: this.props.productDescription,
  };

  componentDidMount() {
    this.updateTabsTranslation();
    this.props.onRequestReload();
  }

  updateTabsTranslation = () => {
    let { navigationTabs } = this.state;

    navigationTabs.forEach((element) => {
      element.localizedLabel = this.getTranslation(element.label);
    });
  };

  componentWillUnmount() {}

  componentDidUpdate(prevProps, nextProps) {
    let { productDescription, productUGC, productReviews } = this.props;
    let { navigationTabs } = this.state;

    if (
      JSON.stringify(prevProps.productDescription) !==
      JSON.stringify(productDescription)
    ) {
      const existsDescBody = productDescription?.description?.body
        ? true
        : false;

      navigationTabs.filter((x) => x.id === navIds.DETAILS)[0].available =
        existsDescBody ? true : false;

      this.setState({
        navigationTabs: navigationTabs,
      });
    }

    if (
      JSON.stringify(prevProps.productDescription?.highlights) !==
      JSON.stringify(productDescription?.highlights)
    ) {
      const existsHighlights =
        productDescription?.highlights?.items?.length > 0;

      navigationTabs.filter((x) => x.id === navIds.HIGHLIGHTS)[0].available =
        existsHighlights;

      this.setState({
        navigationTabs: navigationTabs,
      });
    }

    if (JSON.stringify(prevProps.productUGC) !== JSON.stringify(productUGC)) {
      const existsUGC = productUGC?.gallery?.length > 0;

      navigationTabs.filter((x) => x.id === navIds.HOW_TO_STYLE)[0].available =
        existsUGC;

      this.setState({
        navigationTabs: navigationTabs,
      });
    }

    if (
      JSON.stringify(prevProps.productReviews) !==
      JSON.stringify(productReviews)
    ) {
      const hasReviews = productReviews?.length > 0;

      navigationTabs.filter((x) => x.id === navIds.REVIEWS)[0].available =
        hasReviews;

      this.setState({
        navigationTabs: navigationTabs,
      });
    }

    if (
      JSON.stringify(prevProps.selectedTranslationLocale) !==
      JSON.stringify(this.props.selectedTranslationLocale)
    ) {
      if (this.props.selectedTranslationLocale)
        setTranslationLocale(this.props.selectedTranslationLocale);

      this.updateTabsTranslation();
    }
  }

  setTabsVisibility = (id, isActive) => {
    let navigationTabs = this.state.navigationTabs;
    navigationTabs.filter((x) => x.id === id)[0].active = isActive;

    /* first tab that is active from list of tabs */
    let computedActiveTabIndex = navigationTabs.filter(
      (x) => x.active === true
    )[0]?.id;

    this.setState({
      activeTabIndex: computedActiveTabIndex,
    });
  };

  setActiveTabIndex = (index) => {
    let { navigationTabs } = this.state;

    setTimeout(() => {
      this.scrollToWithContainer("section_" + index);
    }, 100);

    navigationTabs.forEach((element) => {
      element.active = false;
      if (element.id === index) {
        element.active = true;
      }
    });

    this.setState({ activeTabIndex: index, navigationTabs: navigationTabs });
  };

  scrollToWithContainer(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "pdp__wrapper",
      offset: -140, //offset due to sticky header
    });
  }

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let {
      product,
      productDescription,
      productUGC,
      productReviews,
      productReviewStatistics,
      productReviewsNextSet_URL,
      isDataFetchError,
    } = this.props;

    let { navigationTabs, stickyNav, activeTabIndex } = this.state;

    return (
      <>
        <div>
          {/* <!--  GALLERY START--> */}
          <InView
            className="scrollto-marker"
            as="div"
            id={"section_0"}
            name="section_0"
            onChange={(inView, entry) => {
              if (!inView) {
                this.setTabsVisibility(0, false);
              } else {
                this.setTabsVisibility(0, true);
              }
            }}
          >
            <ProductGallery
              media={product.gallery_media}
              resetTimoutTimer={() => {
                this.props.resetTimoutTimer();
              }}
            ></ProductGallery>
          </InView>

          {/* <!--  GALLERY START--> */}

          {/* <!--  COLOR START--> */}
          {product?.color_variations?.length > 1 && (
            <div className="pdp-color-menu-container">
              <div className="title">
                <div>
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.colors_available
                  )?.replace("<count>", product?.color_variations?.length + "")}
                </div>
              </div>
              <div className="content">
                {product?.color_variations?.map((x) => (
                  <div
                    key={x._links.image_small.href}
                    className={`image ${
                      x?.product_id === product.product_id ? "selected" : ""
                    }`}
                    onClick={(e) =>
                      this.props.setExternalPDPTrigger(x?.product_id)
                    }
                  >
                    <img src={x._links.image_small.href} alt="variation" />
                    <img
                      className={`circle-tick ${
                        x?.product_id === product.product_id ? "" : "hidden"
                      }`}
                      src="images/circle-tick.svg"
                      alt="selected-variation"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* <!--   COLOR END --> */}
        </div>
        <div className="navigation-wrapper">
          {/* <!--   NAVIGATION START --> */}
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (!inView) {
                this.setState({ stickyNav: true });
              } else {
                this.setState({ stickyNav: false });
              }
            }}
          ></InView>
          <Navigation
            navigationTabs={navigationTabs}
            sticky={stickyNav}
            setActiveTabIndex={this.setActiveTabIndex}
            activeTabIndex={activeTabIndex}
          ></Navigation>
          {/* <!--   NAVIGATION END --> */}
        </div>

        <div className="product-details" id="product-details">
          {/* removing this load error info for timebeing */}
          {false && isDataFetchError && (
            <div>
              <GlCallout type="urgent">
                <GlHeading as="h4" fontStyle="regular">
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.error_occurred)}
                </GlHeading>
                <GlParagraph>
                  {
                    <GlButton
                      aria-label="label"
                      variant={GL_BUTTON_VARIANT.tertiary}
                      onClick={() => this.props.onRequestReload()}
                    >
                      {this.getTranslation(TRANSLATIONS_KEY_LIST.try_again)}
                    </GlButton>
                  }
                </GlParagraph>
              </GlCallout>
            </div>
          )}
          <section className="full-width">
            <InView
              className="scrollto-marker"
              as="div"
              id={"section_1"}
              name="section_1"
              onChange={(inView, entry) => {
                if (!inView) {
                  this.setTabsVisibility(navIds.DETAILS, false);
                } else {
                  this.setTabsVisibility(1, true);
                }
              }}
            >
              <>
                {
                  <div className="description">
                    <div className="text-content">
                      <h5 className="heading___IO3QX">
                        {product && product.product_name}
                      </h5>
                      <h5 className="gl-heading--italic gl-heading--s heading___IO3QX">
                        {productDescription?.description?.heading || ""}
                      </h5>
                      <p>{productDescription?.description?.body || ""}</p>
                    </div>
                    {(productDescription?.description?.image ||
                      product?.image_url) && (
                      <div className="media">
                        <img
                          src={
                            productDescription?.description?.image ||
                            product?.image_url
                          }
                          alt="prod-description"
                        />
                      </div>
                    )}
                  </div>
                }
              </>
            </InView>
          </section>
          <section className="full-width">
            <ProductSpecifications
              details={productDescription?.details}
            ></ProductSpecifications>
          </section>
          <section className="full-width">
            {productDescription?.highlights?.items?.length > 0 && (
              <InView
                as="div"
                className="scrollto-marker"
                name="section_2"
                id={"section_2"}
                onChange={(inView, entry) => {
                  if (!inView) {
                    this.setTabsVisibility(navIds.HIGHLIGHTS, false);
                  } else {
                    this.setTabsVisibility(navIds.HIGHLIGHTS, true);
                  }
                }}
              >
                <ProductHighlights
                  highlights={productDescription?.highlights}
                  headingOverride={this.getTranslation(
                    TRANSLATIONS_KEY_LIST.highlights
                  )}
                ></ProductHighlights>
              </InView>
            )}
          </section>
          <section>
            {productUGC && (
              <InView
                as="div"
                className="scrollto-marker"
                name="section_3"
                id={"section_3"}
                onChange={(inView, entry) => {
                  if (!inView) {
                    this.setTabsVisibility(navIds.HOW_TO_STYLE, false);
                  } else {
                    this.setTabsVisibility(navIds.HOW_TO_STYLE, true);
                  }
                }}
              >
                {JSON.stringify(productUGC) !== "{}" && (
                  <UGC productUGC={productUGC}></UGC>
                )}
              </InView>
            )}
          </section>

          <section className="review-section">
            {productReviews && (
              <InView
                as="div"
                className="scrollto-marker"
                name="section_4"
                id={"section_4"}
                onChange={(inView, entry) => {
                  if (!inView) {
                    this.setTabsVisibility(navIds.REVIEWS, false);
                  } else {
                    this.setTabsVisibility(navIds.REVIEWS, true);
                  }
                }}
              >
                <RatingsReviews
                  productReviewStatistics={productReviewStatistics}
                  selectedLocale={this.props.selectedLocale}
                  productReviews={productReviews}
                  productReviewsNextSet_URL={productReviewsNextSet_URL}
                  onFetchNextSetOfReviews={() =>
                    this.props.onFetchNextSetOfReviews()
                  }
                ></RatingsReviews>
              </InView>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default ProductDetailsWrapper;
