import { ProductListItemModel } from "../../models/ProductListItemModel";

import { ProductDescriptionModel } from "../../models/ProductDescriptionModel";

import { EventsListModel } from "../../models/EventsListModel";

export const transformMGWProductList = (mgw_productList) => {
  const _updatedList = mgw_productList?.reduce((acc, item) => {
    acc.push(transformMGWProduct(item));
    return acc;
  }, []);

  return _updatedList;
};

export const transformMGWProduct = (product) => {
  const transformedProduct = new ProductListItemModel(
    product?.product_id,
    product?.product_name,
    product?.model_number,
    product?.original_color,
    product?.display_currency,
    product?.subtitle,
    product?.badge_text,
    product?.category,
    product?.size_category,
    product?._links?.image_small?.href,
    product?._embedded?.gallery_media,
    product?._embedded?.color_variations,
    product?.discount_price || null,
    product?.original_price,
    product?.orderable,
    product?.share_url
  );

  return transformedProduct;
};

export const transformMGWFilters = (inputFilters) => {
  let uniqueFilters = [];

  for (var i = 0; i < 100; i++) {
    if (!inputFilters[i]) {
      break;
    }
    uniqueFilters.push(inputFilters[i]);
  }
  const transformedFilters = uniqueFilters?.reduce((acc, item) => {
    const _valuesArray = [];

    item?.values.forEach((option) => {
      _valuesArray.push({
        name: option?.name,
        non_localized_value:
          option?.non_localized_value ||
          option?.value?.toLowerCase() ||
          option?.name?.toLowerCase(),
        value: option?.value,
        hit_count: option?.hit_count,
      });
    });

    acc.push({
      refinement_id: item?.refinement_id || item?.name,
      name: item?.name,
      type: item?.type,
      values: _valuesArray,
    });
    return acc;
  }, []);

  return transformedFilters;
};

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const transformRetailProductList = (retail_productList) => {
  const _updatedList = retail_productList?.reduce((acc, item) => {
    let smallImages = item?.media?.images?.filter(
      (x) => x.format === "JPEG" && x.size === "540x540"
    );
    let largeImages = item?.media?.images?.filter(
      (x) => x.format === "JPEG" && x.size === "800x800"
    );

    let galleryMedia = [];

    /* transforming /formatting data as per what's needed for the UI bindings*/
    for (var i = 0; i < smallImages[0]?.urls?.length; i++) {
      const smallImage = smallImages[0]?.urls[i];
      const largeImage = largeImages[0]?.urls[i];
      galleryMedia.push({
        small: {
          href: smallImage?.href,
        },
        large: {
          href: largeImage?.href ? largeImage.href : smallImage.href,
        },
        type: "image",
      });
    }

    acc.push(
      new ProductListItemModel(
        item?.articleNumber,
        item?.name,
        item?.modelNumber,
        capitalize(item?.colorway),
        item?.prices?.[0].currency,
        capitalize(item?.subtitle || item?.sportsCategory),
        item?.badge_text, //no badge text found
        item?.category,
        item?.division === "footwear" ? "shoes" : item?.division, // if its shoes/footware then this info will be used for BITM
        item?.media?.images.filter(
          (x) => x.size === "540x540"
        )[0]?.urls[0]?.href,
        galleryMedia ? galleryMedia : [], //gallery
        [],
        (item?.prices[0]?.crossOutPrice &&
          item?.prices[0]?.crossOutPrice > 0) ||
          0,
        item?.prices[0]?.salePrice || 0,
        true,
        null // share url is not available for retail only products
      )
    );
    return acc;
  }, []);

  return _updatedList;
};

export const transformSAMProductDescription = (product) => {
  let bullets = product?.description?.bullets?.filter((x) => x !== "null");

  if (bullets?.length > 0) {
    bullets.push(`Article number ${product?.articleNumber}`);
  }
  const description = new ProductDescriptionModel(
    product?.articleNumber,
    product?.description?.shortDescription,
    product?.description?.longDescription,
    "details", //unlike MGW this does not come from MGW API
    bullets,
    null,
    null
  );

  return description;
};

export const transformRetailCatalogFilters = (retailFilters) => {
  const retailFiltersConfig = {
    scaledSize: {
      id: "size",
      name: "Size",
      visible: true,
    },
    technicalSize: {
      id: "technicalSize",
      name: "Technical Size",
      visible: false,
    },
    brand: {
      id: "brand",
      name: "brand",
      visible: true,
    },
    division: {
      id: "division",
      name: "division",
      visible: true,
    },
    subbrand: {
      id: "subbrand",
      name: "sub-brand",
      visible: true,
    },
    gender: {
      id: "gender",
      name: "gender",
      visible: true,
    },
    ageGroup: {
      id: "ageGroup",
      name: "Age Group",
      visible: true,
    },
    category: {
      id: "category",
      name: "category",
      visible: false,
    },
    sportsCategory: {
      id: "sportsCategory",
      name: "Sports Category",
      visible: true,
    },
  };
  // let retailFilters = filterDataResponse?.data;

  let retailFiltersArray = [];

  for (var i = 0; i < 100; i++) {
    if (!retailFilters[i]) {
      break;
    }
    retailFiltersArray.push(retailFilters[i]);
  }
  const transformedRetailFilters = retailFiltersArray?.reduce((acc, item) => {
    const _valuesArray = [];

    item?.values.forEach((valueItem) => {
      _valuesArray.push({
        name: valueItem.value,
        value: valueItem.value,
        hit_count: valueItem.count,
        non_localized_value: valueItem.value,
      });
    });

    if (retailFiltersConfig[item?.name]?.visible && _valuesArray?.length > 1) {
      acc.push({
        refinement_id: item?.name,
        name: retailFiltersConfig[item?.name]?.name,
        type: "MultiSelectType",
        values: _valuesArray,
      });
    }

    return acc;
  }, []);

  return transformedRetailFilters;
};

export const transformMGWProductDescriptionData = (productId, response) => {
  /* Description does not contain data in like key value kind.
    For using it in our application we need restructure the data with key and values in json
    Doing this we will extract data into - details heading, body, bullets and also highlights */

  console.log(
    "getProductDescription(...), raw api response is => ",
    response?.data
  );

  console.log("productId:", productId);

  /* step 1 : we refactor the description data and keep only what we need in an array */
  let descriptionDataRefactored = [];
  let descriptionData = response?.data?.data;

  for (var i = 0; i < descriptionData?._embedded?.story?.elements.length; i++) {
    let item = descriptionData?._embedded?.story.elements[i];
    item.index = i;
    descriptionDataRefactored.push(item);
  }

  /* step 2 : we now extract the description headline and the body
       - For the headline we extract that is in font size 24 (could not find other reliable ways)
       - And for the body we take one that has font size 14
    */
  let descriptionheading = descriptionDataRefactored.filter(
    (x) => x?.text?.font?.size === 24
  )[0]?.text?.text;

  let descriptionBody = descriptionDataRefactored.filter(
    (x) => x?.text?.font?.size === 14
  )[0]?.text?.text;

  /* step 3:  Extracting the product specification / bullets */
  let nodeWithListBullets = descriptionDataRefactored.filter(
    (x) =>
      x.text?.action?.detail?.elements.length > 0 &&
      x.text?.action?.detail?.elements?.text?.text.indexOf("•") !== -1
  );

  let productDetailsBullets;
  let productDetailsHeading;

  if (nodeWithListBullets.length !== 0) {
    nodeWithListBullets =
      nodeWithListBullets[0]?.text?.action?.detail?.elements;

    productDetailsHeading =
      nodeWithListBullets?.filter((x) => x?.text?.text?.indexOf("•") === -1)[0]
        ?.text?.text || "";

    productDetailsBullets = nodeWithListBullets
      .filter((y) => y?.text?.text?.length > 0)
      .filter((x) => x?.text?.text.indexOf("•") !== -1);
  }

  productDetailsBullets = productDetailsBullets?.reduce((acc, item) => {
    acc.push({ ...item.text }.text);
    return acc;
  }, []);

  /* step 4 : extracting the product highlights (text with or without images) */

  let highlightsData = descriptionDataRefactored
    .filter((x) => !x.text?.action || x.image)
    ?.filter((x) => x.index > 1);

  let highlightsHeading = highlightsData[0]?.text?.text || "_highlights";
  let highlightsItems = highlightsData.splice(1, highlightsData.length - 1);

  let productDescription = new ProductDescriptionModel(
    productId,
    descriptionheading,
    descriptionBody,
    productDetailsHeading,
    productDetailsBullets,
    highlightsHeading,
    highlightsItems
  );

  return productDescription;
};

export const transformEventsList = (mgw_eventsList, locale = "en_US") => {
  const _updatedList = mgw_eventsList?.reduce((acc, item) => {
    const eventAllocations = item?._embedded?.allocations;

    const _tempEventIdArray = [];

    eventAllocations.forEach((allocation) => {
      const eventListItemModel = new EventsListModel(
        item.id,
        item.title,
        item.description,
        localizeDateTimeString(allocation?.signUpStartDate, locale),
        localizeDateTimeString(allocation?.signUpDeadlineDate, locale),
        localizeDateTimeString(allocation?.raffleDate, locale),
        localizeDateTimeString(allocation?.eventStartDate, locale),
        localizeDateTimeString(allocation?.eventEndDate, locale),
        item?._links?.share?.href,
        item?._links?.gallery?.[0]?.href
      );

      if (!_tempEventIdArray.includes(item.id)) {
        acc.push(eventListItemModel);
      }

      if (item?.id) {
        _tempEventIdArray.push(item?.id);
      }
    });

    return acc;
  }, []);

  return _updatedList;
};

function localizeDateTimeString(UTCDateTimeString, locale) {
  if (!UTCDateTimeString) return null;
  //locale here should be in dash -, like en-US so we format it likewise

  locale = locale.replace("_", "-");
  if (UTCDateTimeString?.indexOf("Z") !== -1) {
    return `${new Intl.DateTimeFormat(locale, {
      dateStyle: "long",
    }).format(
      new Date(Date.parse(UTCDateTimeString))
    )},  ${new Intl.DateTimeFormat(locale, {
      timeStyle: "short",
    }).format(new Date(Date.parse(UTCDateTimeString)))}`;
  } else {
    return UTCDateTimeString;
  }
}
