// reference : https://github.com/simple-keyboard/simple-keyboard-layouts/tree/master/src/lib/layouts

const KEYBOARD_LAYOUTS = {
  en: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {close}",
        "{tab} q w e r t y u i o p [ ] \\",
        "{lock} a s d f g h j k l ; ' {enter}",
        "{shift} z x c v b n m , . / {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp} {close}",
        "{tab} Q W E R T Y U I O P { } |",
        '{lock} A S D F G H J K L : " {enter}',
        "{shift} Z X C V B N M < > ? {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  de: {
    layout: {
      default: [
        "^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp} {close}",
        "{tab} q w e r t z u i o p \u00FC +",
        "{lock} a s d f g h j k l \u00F6 \u00E4 # {enter}",
        "{shift} < y x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp} {close}',
        "{tab} Q W E R T Z U I O P \u00DC *",
        "{lock} A S D F G H J K L \u00D6 \u00C4 ' {enter}",
        "{shift} > Y X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  ru: {
    layout: {
      default: [
        "\u0451 1 2 3 4 5 6 7 8 9 0 - = {bksp} {close}",
        "{tab} \u0439 \u0446 \u0443 \u043a \u0435 \u043d \u0433 \u0448 \u0449 \u0437 \u0445 \u044a \\",
        "{lock} \u0444 \u044b \u0432 \u0430 \u043f \u0440 \u043e \u043b \u0434 \u0436 \u044d {enter}",
        "{shift} / \u044f \u0447 \u0441 \u043c \u0438 \u0442 \u044c \u0431 \u044e . {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u0401 ! " \u2116 ; % : ? * ( ) _ + {bksp} {close}',
        "{tab} \u0419 \u0426 \u0423 \u041a \u0415 \u041d \u0413 \u0428 \u0429 \u0417 \u0425 \u042a /",
        "{lock} \u0424 \u042b \u0412 \u0410 \u041f \u0420 \u041e \u041b \u0414 \u0416 \u042d {enter}",
        "{shift} | \u042f \u0427 \u0421 \u041c \u0418 \u0422 \u042c \u0411 \u042e , {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  es: {
    layout: {
      default: [
        "\u007c 1 2 3 4 5 6 7 8 9 0 ' \u00bf {bksp} {close}",
        "{tab} q w e r t y u i o p \u0301 +",
        "{lock} a s d f g h j k l \u00f1 \u007b \u007d {enter}",
        "{shift} < z x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u00b0 ! " # $ % & / ( ) = ? \u00a1 {bksp} {close}',
        "{tab} Q W E R T Y U I O P \u0308 *",
        "{lock} A S D F G H J K L \u00d1 \u005b \u005d {enter}",
        "{shift} > Z X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  fr: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 \u00B0 + {bksp} {close}",
        "{tab} a z e r t y u i o p ^ $",
        "{lock} q s d f g h j k l m \u00F9 * {enter}",
        "{shift} < w x c v b n , ; : ! {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        "\u00B2 & \u00E9 \" ' ( - \u00E8 _ \u00E7 \u00E0 ) = {bksp} {close}",
        "{tab} A Z E R T Y U I O P \u00A8 \u00A3",
        "{lock} Q S D F G H J K L M % \u00B5 {enter}",
        "{shift} > W X C V B N ? . / \u00A7 {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  ja: {
    layout: {
      default: [
        "1 2 3 4 5 6 7 8 9 0 - ^ \u00a5 {bksp} {close}",
        "{tab} \u305f \u3066 \u3044 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u309b \u309c \u3080",
        "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
        "{shift} \u3064 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u306d \u308b \u3081 {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        "! \" # $ % & ' ( ) \u0301 = ~ | {bksp} {close}",
        "{tab} \u305f \u3066 \u3043 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u300c \u300d \u3080",
        "{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 {enter}",
        "{shift} \u3063 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u3001 \u3002 \u30fb {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  tr: {
    layout: {
      default: [
        '" 1 2 3 4 5 6 7 8 9 0 * - # {bksp} {close}',
        "{tab} q w e r t y u ı o p ğ ü [ ]",
        "{lock} a s d f g h j k l ş i , {enter}",
        "{shift} < z x c v b n m ö ç . | $ € {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        "é ! ' ^ + % & / ( ) = ? _ ~ {bksp} {close}",
        "{tab} Q W E R T Y U I O P Ğ Ü { }",
        "{lock} A S D F G H J K L Ş İ ; {enter}",
        "{shift} > Z X C V B N M Ö Ç : \\ ` ´ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  it: {
    layout: {
      default: [
        "\\ 1 2 3 4 5 6 7 8 9 0 ' \u00EC {bksp} {close}",
        "{tab} q w e r t y u i o p \u00E8 +",
        "{lock} a s d f g h j k l \u00F2 \u00E0 \u00F9 {enter}",
        "{shift} < z x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '| ! " \u00A3 $ % & / ( ) = ? ^ {bksp} {close}',
        "{tab} Q W E R T Y U I O P \u00E9 *",
        "{lock} A S D F G H J K L \u00E7 \u00B0 \u00A7 {enter}",
        "{shift} > Z X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  sv: {
    layout: {
      default: [
        "\u00A7 1 2 3 4 5 6 7 8 9 0 + \u00B4 {bksp} {close}",
        "{tab} q w e r t y u i o p \u00E5 ¨",
        "{lock} a s d f g h j k l \u00F6 \u00E4 ' {enter}",
        "{shift} < z x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u00B0 ! " # $ % & / ( ) = ? ` {bksp} {close}',
        "{tab} Q W E R T Y U I O P \u00C5 ^",
        "{lock} A S D F G H J K L \u00D6 \u00C4 * {enter}",
        "{shift} > Z X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },

  //todo: pt keyboard verification
  pt: {
    layout: {
      default: [
        "\u007c 1 2 3 4 5 6 7 8 9 0 ' \u00bf {bksp} {close}",
        "{tab} q w e r t y u i o p \u0301 +",
        "{lock} a s d f g h j k l \u00e7 \u007b \u007d {enter}",
        "{shift} < z x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u00b0 ! " # $ % & / ( ) = ? \u00a1 {bksp} {close}',
        "{tab} Q W E R T Y U I O P \u0308 *",
        "{lock} A S D F G H J K L \u00c7 \u005b \u005d {enter}",
        "{shift} > Z X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
  pl: {
    layout: {
      default: [
        "\u02DB 1 2 3 4 5 6 7 8 9 0 + ' {bksp} {close}",
        "{tab} q w e r t z u i o p \u017C \u015B",
        "{lock} a s d f g h j k l \u0142 \u0105 \u00F3 {enter}",
        "{shift} < y x c v b n m , . - {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        '\u00B7 ! " # \u00A4 % & / ( ) = ? * {bksp} {close}',
        "{tab} Q W E R T Z U I O P \u0144 \u0107",
        "{lock} A S D F G H J K L \u0141 \u0119 \u017A {enter}",
        "{shift} > Y X C V B N M ; : _ {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },

  ko: {
    layout: {
      default: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp} {close}",
        "{tab} \u1107 \u110c \u1103 \u1100 \u1109 \u116d \u1167 \u1163 \u1162 \u1166 [ ] \\",
        "{lock} \u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 ; ' {enter}",
        "{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 , . / {shift}",
        "{lang} .com @ {space}",
      ],
      shift: [
        "~ ! @ # $ % ^ & * ( ) _ + {bksp} {close}",
        "{tab} \u1108 \u110d \u1104 \u1101 \u110a \u116d \u1167 \u1163 \u1164 \u1168 { } |",
        '{lock} \u1106 \u1102 \u110b \u1105 \u1112 \u1169 \u1165 \u1161 \u1175 : " {enter}',
        "{shift} \u110f \u1110 \u110e \u1111 \u1172 \u116e \u1173 < > ? {shift}",
        "{lang} .com @ {space}",
      ],
    },
  },
};

export { KEYBOARD_LAYOUTS };
