import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import { setup } from "axios-cache-adapter";

import { BFF_API } from "../constants";

// Register the custom `memoryDriver` to `localforage`
localforage.defineDriver(memoryDriver);

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "ssm-api",
});

const api = setup({
  baseURL: BFF_API,
  cache: {
    maxAge: 8 * 60 * 60 * 1000, // set cache time to 8 hours
    exclude: { query: false, methods: ["post", "put", "patch", "delete"] }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

//excluded from cache for now; we will enable caching later after development complete
export function getNearbyStoresForSSMId(ssmId, distance) {
  let _postData = {
    nearStoreRequest: {
      distance: distance, // example "10km"
      ssmId: ssmId, //example eg. "9990094660"
    },
  };

  let _url = `${BFF_API}/ssm/stores/?businessModel=Own Retail&lifecycleStatus=Open&ssmFields=LOCATION`;

  _url = encodeURI(_url);

  console.log(_url);

  return api({
    url: _url,
    method: "post",
    data: _postData,
  }).then((response) => {
    return { response };
  });
}

//excluded from cache for now; we will enable caching later after development complete
export function getNearbyStoresForGeoCoordinates(lat, long, distance) {
  let _postData = {
    nearGeoLocationRequest: {
      distance: distance, //example "10km"
      latitude: lat, //example 49.56798
      longitude: long, //example 10.88565
    },
  };

  let _url = `${BFF_API}/ssm/stores/?businessModel=Own Retail&lifecycleStatus=Open&ssmFields=LOCATION`;

  _url = encodeURI(_url);

  console.log(_url);

  return api({
    url: _url,
    method: "post",
    data: _postData,
  }).then((response) => {
    return { response };
  });
}
