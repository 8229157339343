export default class SortOptions {
  instance;

  referenceSortOptions = [];
  referenceLocale = null;
  referenceSortOptionsTranslationMap = [];

  static getInstance() {
    if (!this.instance) {
      this.instance = new SortOptions();
    }

    return this.instance;
  }

  translateUsingReferenceSortOptions = (sortOptions, locale) => {
    let resultRefinement;

    try {
      resultRefinement = this.#translateSortOptionsUsingMap(
        sortOptions,
        locale
      );
    } catch (e) {
      console.log(e);
    }
    return resultRefinement;
  };

  init = (referenceSortOptions = [], locale = null) => {
    this.referenceLocale = locale;
    this.referenceSortOptions = referenceSortOptions;

    this.#initReferenceTranslationMap();
  };

  /* use the reference SortOptions data to create a lookup map */
  #initReferenceTranslationMap = () => {
    try {
      let referenceSortOptions = this.referenceSortOptions;
      let referenceLocale = this.referenceLocale;

      if (!referenceLocale) return;
      referenceLocale = "_" + referenceLocale.toLowerCase();

      let result = [];

      console.log(referenceSortOptions);
      // return;

      referenceSortOptions.forEach((_option) => {
        result.push({
          key: _option?.sort_option_id?.replace(referenceLocale, ""),
          value: _option?.name,
        });
      });

      console.table("Sort Options translation map", result);

      this.referenceSortOptionsTranslationMap = result;
    } catch (e) {
      console.log("Sort Options translation map error", e);
    }
  };

  /* Fn to update the display name of SortOptions using reference filter and options data (of other locale) */
  #translateSortOptionsUsingMap = (sortOptions = [], locale = null) => {
    if (!locale) return;

    locale = "_" + locale.toLowerCase();

    sortOptions.forEach((_option) => {
      let c = this.referenceSortOptionsTranslationMap?.filter(
        (x) => x.key === _option?.sort_option_id?.replace(locale, "")
      )?.[0]?.value;

      _option.name = c || _option?.name;
    });

    return sortOptions;
  };
}
