export default class FilterOptions {
  instance;

  referenceRefinements = [];
  referenceLocale = null;
  referenceRefinementsTranslationMap = [];

  static getInstance() {
    if (!this.instance) {
      this.instance = new FilterOptions();
    }

    return this.instance;
  }

  translateUsingReferenceRefinements = (refinements, locale) => {
    let resultRefinement;

    try {
      resultRefinement = this.#translateRefinementsUsingMap(
        refinements,
        locale
      );
    } catch (e) {
      console.log(e);
    }
    return resultRefinement;
  };

  init = (referenceRefinements = [], locale = null) => {
    this.referenceLocale = locale;
    this.referenceRefinements = referenceRefinements;

    this.#initReferenceTranslationMap();
  };

  getTranslationForKey = (key) => {
    if (!this.referenceRefinementsTranslationMap) return key;

    const x = this.referenceRefinementsTranslationMap?.filter(
      (x) => x.key === key
    )?.[0];
    return x?.value || key;
  };

  /* use the reference refinements data to create a lookup map */
  #initReferenceTranslationMap = () => {
    try {
      let referenceRefinements = this.referenceRefinements;
      let referenceLocale = this.referenceLocale;

      if (!referenceLocale) return;
      referenceLocale = "_" + referenceLocale.toLowerCase();

      let result = [];

      referenceRefinements.forEach((_refinment) => {
        result.push({
          key: _refinment?.refinement_id?.replace(referenceLocale, ""),
          value: _refinment?.name,
        });

        _refinment?.values.forEach((option) => {
          result.push({
            key: option?.non_localized_value?.replace(referenceLocale, ""),
            value: option?.name,
          });
        });
      });

      this.referenceRefinementsTranslationMap = result;
    } catch (e) {
      console.log("filter trans init refe map error", e);
    }
  };

  /* Fn to update the display name of refinements using reference filter and options data (of other locale) */
  #translateRefinementsUsingMap = (refinements = [], locale = null) => {
    if (!locale) return;

    locale = "_" + locale.toLowerCase();

    refinements.forEach((_refinment) => {
      let c = this.referenceRefinementsTranslationMap?.filter(
        (x) => x.key === _refinment?.refinement_id?.replace(locale, "")
      )?.[0]?.value;

      _refinment.name = c || _refinment.name;

      _refinment?.values.forEach((option) => {
        const y = this.referenceRefinementsTranslationMap?.filter(
          (x) => x.key === option?.non_localized_value
        )?.[0]?.value;
        option.name = y || option?.name;
      });
    });

    return refinements;
  };
}
