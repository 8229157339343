import amplitude from "amplitude-js";

const API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || "";

/*
  This library requires API_KEY to be included in the .env file. Fo the reference please visit amplitude documentation page: 
  https://www.docs.developers.amplitude.com/data/sdks/javascript/#__tabbed_1_1
*/

amplitude.getInstance().init(API_KEY, null, {
  includeUtm: true,
  includeGclid: true,
  includeReferrer: true,
  serverZone: "EU",
  serverZoneBasedApi: true,
});

export { amplitude };
