import _SAMPLE_CHECKOUT from "./SAMPLE_CHECKOUT.json";
import _SAMPLE_CHECKOUT_DELIVERY_OPTIONS from "./SAMPLE_CHECKOUT_DELIVERY_OPTIONS.json";
import _SAMPLE_CHECKOUT_NEAREST_PICKUP_POINTS from "./SAMPLE_CHECKOUT_NEAREST_PICKUP_POINTS.json";
import _SAMPLE_CHECKOUT_NEAREST_STORES from "./SAMPLE_CHECKOUT_NEAREST_STORES.json";
import _SAMPLE_PRODUCT_AVAILABILITY from "./SAMPLE_PRODUCT_AVAILABILITY.json";
import _SAMPLE_PRODUCT_DETAILS from "./SAMPLE_PRODUCT_DETAILS.json";
import _SAMPLE_PRODUCT_SEARCH_RESULTS from "./SAMPLE_PRODUCT_SEARCH_RESULTS.json";
import _SAMPLE_NEARBY_STORES from "./SAMPLE_NEARBY_STORES.json";

export const SAMPLE_CHECKOUT = _SAMPLE_CHECKOUT;
export const SAMPLE_CHECKOUT_DELIVERY_OPTIONS =
  _SAMPLE_CHECKOUT_DELIVERY_OPTIONS;
export const SAMPLE_CHECKOUT_NEAREST_PICKUP_POINTS =
  _SAMPLE_CHECKOUT_NEAREST_PICKUP_POINTS;
export const SAMPLE_CHECKOUT_NEAREST_STORES = _SAMPLE_CHECKOUT_NEAREST_STORES;
export const SAMPLE_PRODUCT_AVAILABILITY = _SAMPLE_PRODUCT_AVAILABILITY;
export const SAMPLE_PRODUCT_DETAILS = _SAMPLE_PRODUCT_DETAILS;
export const SAMPLE_PRODUCT_SEARCH_RESULTS = _SAMPLE_PRODUCT_SEARCH_RESULTS;
export const SAMPLE_NEARBY_STORES = _SAMPLE_NEARBY_STORES;
