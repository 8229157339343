import React from "react";

function getHighlights(props) {
  let highlightsItems = props?.highlights?.items;

  /* highlights is a set of 2 or 3 items, 
  namely either heading and text body, or image, heading and text body
  we extract data and keep in 3 data structure separately iterating through the list
  */

  let subHeadingsList = [],
    bodyTextList = [],
    mediaList = [];

  highlightsItems.forEach((element) => {
    if (element?.text) {
      if (element?.text?.font?.type?.toLowerCase().indexOf("bold") !== -1) {
        subHeadingsList.push(element?.text?.text);
      } else {
        bodyTextList.push(element?.text?.text);
      }
    } else if (element?.image) {
      mediaList.push({ type: "image", url: element?.image.url });
    } else if (element?.video) {
      mediaList.push({ type: "video", url: element?.video.url });
    }
  });

  let highlights = [];

  for (var i = 0; i < subHeadingsList.length; i++) {
    highlights.push({
      subHeading: subHeadingsList[i] || null,
      bodyText: bodyTextList[i] || null,
      media: mediaList[i] || null,
      _id: i,
    });
  }
  return {
    highlightsRestuctured: highlights,
  };
}

function ProductHighlights(props) {
  let { highlightsRestuctured } = getHighlights(props);

  return (
    <>
      <div className="heading">
        <h5>{props?.headingOverride || props?.highlights?.heading}</h5>
      </div>
      <div className="highlights">
        {highlightsRestuctured?.map((x) => (
          <div key={x?.subHeading} className="highlights__POIs">
            <div className="item">
              {x?.media?.type === "image" && (
                <img key={x?.media?.url} src={x?.media?.url} alt="highlight" />
              )}

              {x?.subHeading && (
                <h5 key={x?.subHeading}>{<p>{x?.subHeading}</p>}</h5>
              )}
              {x?.bodyText && (
                <p key={x?.subHeading + "_body"}>{x?.bodyText}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProductHighlights;
