const use_case = "SSK";

const encodeComponents = (data) => {
  Object.keys(data).forEach((property) => {
    data[property] = encodeURIComponent(data[property]);
  });
  return data;
};
const generateQrUrl = (urlData) => {
  if (urlData?.region?.toLowerCase() === "europe") {
    urlData.region = "EMEA";
  }
  const encodedUrlData = encodeComponents(urlData);

  return (
    `https://adidas.onelink.me/ihha` +
    `?pid=SSK&c=${encodedUrlData.screen}_Basket_Transfer` +
    `&af_channel=Digital_Retail` +
    `&af_adset=${encodedUrlData.country}` +
    `&af_ad=${encodedUrlData.ssmid}_${encodedUrlData.hostname}` +
    `&is_retargeting=true` +
    `&af_dp=${encodedUrlData.mobileAppDeepLink}` +
    `&af_r=${encodedUrlData.websiteUrl}`
  );
};
const generateWebUrlBasedOnShareUrl = (url, productId) => {
  // transforming share url received
  let splitUrlByParam = url.split("&af_web_dp=");
  let webUrl = splitUrlByParam[1];

  // url is already encoded - so should first decode it
  webUrl = decodeURIComponent(webUrl);

  // handling different format
  webUrl = webUrl?.replace("https://https:", "https:") || "";
  let result = webUrl?.split(productId)[0] || "";

  /* handling issue for Turkey*/
  if (result.indexOf(".com.tr") !== -1 && result.indexOf(".com.tr/tr") === -1) {
    result = result.replace(".com.tr", ".com.tr/tr");
  }

  return result;
};

const getWebUrl = (url, sku, productId, ssmid) => {
  let webUrl = generateWebUrlBasedOnShareUrl(url, productId);

  // restoring cart if product size is selected
  let finalWebUrl = `${webUrl}cart/restore?pid_1=${sku}&qty_1=1&eaStoreID=${ssmid}&orderSource=skava`;

  // NOTE: might need later on for qr code without size select
  //let finalWebUrl = sku ? `${webUrl}cart/restore?pid_1=${sku}&qty_1=1&eaStoreID=${ssmid}&orderSource=skava` : `${webUrl}product/${productId}.html?eaStoreID=${ssmid}&orderSource=skava`;

  return finalWebUrl;
};

const getScanToOrderUrl = ({
  country,
  ssmid,
  hostname,
  region,
  city,
  category,
  productId,
  shareUrl,
  sku,
}) => {
  let mobileAppDeepLink;
  let productInfoParams;
  if (sku) {
    productInfoParams = `pid_1=${sku}&qty_1=1`;
    mobileAppDeepLink = getMobileAppDeepLink_RestoreCart({
      ssmid,
      city,
      hostname,
      country,
      productInfoParams,
    });
  } else mobileAppDeepLink = `adidas://product/${productId}`;
  let websiteUrl =
    shareUrl &&
    productId &&
    getWebsiteURL_RestoreCart({
      shareUrl,
      productId,
      ssmid,
      city,
      hostname,
      country,
      productInfoParams,
    });

  let qrcode_url = generateQrUrl({
    screen: "PDP",
    country,
    ssmid,
    hostname,
    region,
    city,
    productId,
    mobileAppDeepLink,
    websiteUrl,
  });
  return qrcode_url;
};

const getBITMUrl = ({
  country,
  ssmid,
  hostname,
  region,
  city,
  category,
  productId,
  shareUrl,
  sku,
}) => {
  // TODO: can reuse common functions later, also category doesnt seem to be used
  let product_web_url =
    shareUrl && productId && getWebUrl(shareUrl, sku, productId, ssmid);

  if (region?.toLowerCase() === "europe") {
    region = "EMEA";
  }

  ssmid = encodeURIComponent(ssmid);
  hostname = encodeURIComponent(hostname);
  productId = encodeURIComponent(productId);
  product_web_url = encodeURIComponent(product_web_url);
  region = encodeURIComponent(region);
  category = encodeURIComponent(category);
  country = encodeURIComponent(country);
  city = encodeURIComponent(city);

  // for BITM QR code => sample is as below.
  // adidas://retail/store/9990066659/bitm/product/BB5476

  let appDeeplinkAddtoCart = `adidas://retail/store/${ssmid}/bitm/product/${productId}`;
  let mobileAppDeeplink_encoded = encodeURIComponent(appDeeplinkAddtoCart);

  let qrcode_url =
    `https://adidas.onelink.me/ihha` +
    `?pid=QR&c=GLOBAL-Retail-Digital_Retail-QRCode-Global-${use_case}` +
    `&af_channel=Digital_Retail` +
    `&af_adset=${country}` +
    `&af_ad=${ssmid}_${hostname}` +
    `&is_retargeting=true` +
    `&af_dp=${mobileAppDeeplink_encoded}` +
    `&af_r=${product_web_url}` +
    `&af_sub1=${region}` +
    `&af_sub2=${country}` +
    `&af_sub3=${city}` +
    `&af_sub4=${ssmid}` +
    `&af_sub5=${hostname}` +
    `&product_id=${productId}` +
    `&referral_code=${use_case}_${ssmid}_${hostname} `;

  return qrcode_url;
};

const getMobileAppDeepLink_RestoreCart = (data) => {
  let { ssmid, productInfoParams } = data;

  /* if basketOrigin is present in the url 
  then eaEmployeeID must also be present. 
  And basketOrigin no more supports value 'ssk', its only 'retail' being accepted by mobile app now
  */

  let appDeeplinkAddtoCart =
    `adidas://shopping-cart/restore?` +
    `&${productInfoParams}` +
    `&eaStoreID=${ssmid}` +
    `&basketOrigin=retail`; //no more ssk

  if (data.employeeId && data.employeeId.length > 0) {
    appDeeplinkAddtoCart =
      appDeeplinkAddtoCart + `&eaEmployeeID=${data.employeeId}`;
  } else {
    // even if emp id is null the param must be included with value null to avoid cart restore error on mobile app side
    appDeeplinkAddtoCart = appDeeplinkAddtoCart + `&eaEmployeeID=null`;
  }

  return appDeeplinkAddtoCart;
};

const getWebsiteURL_RestoreCart = (data) => {
  let websiteAddtoCart;
  let {
    shareUrl,
    productId,
    ssmid,
    city,
    hostname,
    country,
    productInfoParams,
  } = data;
  let webUrl = generateWebUrlBasedOnShareUrl(shareUrl, productId);

  country = country.replace(" ", "");
  city = city.replace(" ", "");

  if (webUrl.indexOf("https://shop.adidas.ae") !== -1) {
    websiteAddtoCart =
      `https://www.adidas.ae/on/demandware.store/Sites-adidas-AE-Site/en-AE/Cart-UpdateItems?${productInfoParams}` +
      `&eaStoreID=${ssmid}` +
      `&orderSource=skava` +
      `&cm_mmc=AdiRetail_${use_case}-_-GLOBAL-_-${country}_${city}-_-${ssmid}-_-QR-_-dv:Retail-_-cn:${hostname}-_-pc:MULTIPLE` +
      `&cm_mmc1=${country}` +
      `&cm_mmc2=adidas-GLOBAL-Retail-Retail-MULTIPLE-Multiple-${country}-${hostname}-`;
  } else {
    websiteAddtoCart =
      `${webUrl}cart/restore?` +
      `${productInfoParams}` +
      `&eaStoreID=${ssmid}` +
      `&orderSource=skava` +
      `&cm_mmc=AdiRetail_${use_case}-_-GLOBAL-_-${country}_${city}-_-${ssmid}-_-QR-_-dv:Retail-_-cn:${hostname}-_-pc:MULTIPLE` +
      `&cm_mmc1=${country}` +
      `&cm_mmc2=adidas-GLOBAL-Retail-Retail-MULTIPLE-Multiple-${country}-${hostname}-`;
  }

  return websiteAddtoCart;
};

// this is for Shopping Cart
const getRestoreCartURL = ({
  country,
  ssmid,
  hostname,
  region,
  city,
  cartItems,
  employeeId,
}) => {
  // restoring cart if product size is selected
  let productInfoParams = "";
  for (var i = 0; i < cartItems?.length; i++) {
    if (productInfoParams !== "") {
      productInfoParams += "&";
    }
    productInfoParams += `pid_${i + 1}=${cartItems[i]?.sku}&qty_${i + 1}=${
      cartItems[i]?.quantity
    }`;
  }
  let mobileAppDeepLink = "";
  let websiteUrl = "";
  if (cartItems?.length > 0) {
    let shareUrl = cartItems[0]?.product?.share_url; // assuming this format is same for all products
    let productId = cartItems[0]?.product?.product_id; // product ID is just used to extract url
    mobileAppDeepLink = getMobileAppDeepLink_RestoreCart({
      ssmid,
      productInfoParams,
      employeeId,
    });
    websiteUrl = getWebsiteURL_RestoreCart({
      shareUrl,
      productId,
      ssmid,
      city,
      hostname,
      country,
      productInfoParams,
    });
  }

  let qrcode_url = generateQrUrl({
    screen: "Cart",
    country,
    ssmid,
    hostname,
    region,
    city,
    productId: cartItems[0]?.product?.product_id,
    mobileAppDeepLink,
    websiteUrl,
  });
  return qrcode_url;
};

module.exports = {
  getScanToOrderUrl,
  getRestoreCartURL,
  getBITMUrl,
};
