import React, { Component } from "react";

import {
  GlButton,
  GL_BUTTON_THEME,
  GL_BUTTON_VARIANT,
  GlParagraph,
} from "@adl/foundation";

import "./PickupCollectUserDetailsSummary.css";

import {
  getTranslation,
  TRANSLATIONS_KEY_LIST,
} from "../../../../../../utils/translationCopies";

// const locale = "en_DE";

class PickupCollect_UserDetailsSummary extends Component {
  state = {
    isCartOpen: false,
  };

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let { userDetails } = this.props;

    return (
      <>
        <section className="selected-location-info">
          <div>
            <div className="bold">
              <GlParagraph>{`${userDetails?.firstName}${" "}${
                userDetails?.lastName
              }`}</GlParagraph>
            </div>
            <div className="address">
              <GlParagraph>
                <span>
                  {userDetails?.email}
                  <br />
                  {userDetails?.phoneNumber}
                  <br />
                </span>
              </GlParagraph>
            </div>
          </div>

          <div onClick={(e) => this.props?.setUserDetailInEditView(true)}>
            <GlButton
              aria-label="label"
              theme={GL_BUTTON_THEME.dark}
              variant={GL_BUTTON_VARIANT.secondary}
            >
              {getTranslation(
                this.props.selectedLocale,
                TRANSLATIONS_KEY_LIST.edit_details
              )}
            </GlButton>
          </div>
        </section>
      </>
    );
  }
}

export default PickupCollect_UserDetailsSummary;
