import V2_API from "./v2/API";
export let API;

/* logic to determine if older localnode js version is to be used
 * this is based on query string passed on UI ?api=v2
 */
let useLocalServerV2 = false;

let pageQueryString = window.location?.search?.replace("?", "").split("&");

pageQueryString.forEach((param) => {
  if (param.split("=")[0] === "api_key_enc") {
    useLocalServerV2 = true;
  }
});

if (!useLocalServerV2) {
  // if old local node js(v1) is to be used

  const appSettingsAPI = require("./v1/AppSettingsAPI/appSettingsAPI");
  const checkoutAPI = require("./v1/CheckoutAPI/checkoutAPI");
  const mapsAPI = require("./v1/MapsAPI/mapsAPI");
  const productsAPI = require("./v1/ProductsAPI/productsAPI");
  const eventsAPI = require("./v1/EventsAPI/eventsAPI");
  const membershipAPI = require("./v1/MembershipAPI/membershipAPI.js");
  const productsAvailabilityAPI = require("./v1/ProductsAvailabilityAPI/productsAvailabilityAPI");
  const samAPI = require("./v1/SamAPI/samAPI");
  const ssmAPI = require("./v1/SsmAPI/ssmAPI");
  const { getLocalSSEEventSource } = require("./v1/SseAPI/SseAPI");

  API = {
    appSettingsAPI: appSettingsAPI,
    checkoutAPI: checkoutAPI,
    mapsAPI: mapsAPI,
    productsAPI: productsAPI,
    eventsAPI: eventsAPI,
    membershipAPI: membershipAPI,
    productsAvailabilityAPI: productsAvailabilityAPI,
    samAPI: samAPI,
    ssmAPI: ssmAPI,
    eventSourceHardwareIntegration: getLocalSSEEventSource(),
  };
}
// NEW - data logic if new local node js (v2) is to be used
else {
  let apiKeyEnc;
  let apiKey;
  let appSettingsConfigId;
  let appSettingsConfigName;

  pageQueryString.forEach((param) => {
    if (param.split("=")[0] === "api_key_enc") {
      apiKeyEnc = param.split("=")[1];
    }

    if (param.split("=")[0] === "config_id") {
      appSettingsConfigId = param.split("=")[1];
    }

    if (param.split("=")[0] === "config_name") {
      appSettingsConfigName = param.split("=")[1];
    }
  });

  if (apiKeyEnc) {
    apiKeyEnc = decodeURIComponent(apiKeyEnc);
  }

  if (apiKeyEnc) apiKey = window.atob(apiKeyEnc);

  let appSettingsApiBaseUrl = process.env.REACT_APP_APPSETTINGS_API_URL_BASE;

  let objV2_API;

  objV2_API = new V2_API(apiKey);
  objV2_API.appSettingsAPI().setRemoteAppSettingsRef({
    baseUrl: appSettingsApiBaseUrl, //get from env variable
    apiKey: apiKey, // get from query string
    configId: appSettingsConfigId, //get from query string any one value for configId and
    configName: appSettingsConfigName, //get from query string
  });

  API = {
    apiKey: objV2_API.apiKey,
    appSettingsAPI: objV2_API.appSettingsAPI(),
    productsAPI: objV2_API.productsAPI(),
    eventsAPI: objV2_API.eventsAPI(),
    membershipAPI: objV2_API.membershipAPI(),
    ssmAPI: objV2_API.ssmAPI(),
    samAPI: objV2_API.samAPI(),
    productsAvailabilityAPI: objV2_API.productsAvailabilityAPI(),
    mapsAPI: objV2_API.mapsAPI(),
    eventSourceHardwareIntegration: objV2_API.getLocalSSEAPI(),
  };
}
