import React, { Component } from "react";
import ReactPlayer from "react-player";

import "./InteractionVideoView.css";

import { getMedia } from "../../utils/media";

import { connect } from "react-redux";

import { PAGE_NAMES, setCurrentPageName } from "../../actions/pageActions";

import TapAnimation from "../TapAnimation/TapAnimation";

const VIDEO = "video";

class InteractionVideoView extends Component {
  state = {
    currentPlayingIndex: 0,
    videos: [],
  };

  componentDidMount() {
    const videos =
      this.props.appSettings?.value?.interaction_video_screen?.videos || [];
    this.setState({
      videos: videos,
    });
    if (!this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.interactionVideo);
    }
    if (videos) this.downloadMedia(videos);
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.appSettings) !==
      JSON.stringify(this.props.appSettings)
    ) {
      const videos =
        this.props.appSettings?.value?.interaction_video_screen?.videos || [];

      this.setState({
        videos: videos,
      });

      if (videos) this.downloadMedia(this.props.media);
    }

    if (prevProps.pdpRequest !== this.props.pdpRequest) {
      if (this.props.pdpRequest?.length > 0) {
        if (this.props.currentPage !== PAGE_NAMES.plp) {
          this.props.setCurrentPageName(PAGE_NAMES.plp);
        }
      }
    }
  }

  downloadMedia = (contentArray) => {
    console.log(1111111, contentArray);
    if (!contentArray) return;

    let _contentArray = contentArray;

    for (let i = 0; i < _contentArray?.length; i++) {
      console.log(i);
      console.log(_contentArray.length);
      if (_contentArray[i].type === VIDEO) {
        console.log("----- FETCHING BLOB-----");
        console.log(_contentArray[i].url);

        if (!_contentArray[i].blob) {
          getMedia(_contentArray[i].url).then(
            (result) => {
              console.log(i);
              console.log(result.responseURL);
              console.log(result.response);
              var _mediaBlob = window.URL.createObjectURL(result.response);

              console.log(_mediaBlob);

              let _contentArray = this.state.videos;
              let _match = _contentArray.filter(
                (x) => x.url === result.responseURL
              );
              if (_match && _match.length > 0) {
                _match[0].blob = _mediaBlob;
              }

              console.log(_match);

              this.setState({
                videos: _contentArray,
              });
              console.log("----- FETCHING BLOB END-----");
            },
            (Error) => {
              console.log(Error);
            }
          );
        }
      }
    }
  };

  onVideoEnded = () => {
    console.log("ended video");

    let { videos } = this.state || [];

    if (this.state.currentPlayingIndex === videos?.length - 1) {
      this.setState({
        currentPlayingIndex: 0,
      });
    } else {
      this.setState({
        currentPlayingIndex: this.state.currentPlayingIndex + 1,
      });
    }
  };

  render() {
    let { videos } = this.state;

    let { onVideoTap } = this.props;

    const videosWithBlob = videos.filter((x) => x.blob?.length > 0);

    return (
      <div
        className="interaction-video-component-wrapper"
        onClick={() => onVideoTap()}
      >
        {<TapAnimation></TapAnimation>}
        <div className="container-video">
          {
            <ReactPlayer
              url={videosWithBlob[this.state.currentPlayingIndex]?.blob || null}
              loop={videosWithBlob.length === 1}
              onEnded={(e) => {
                this.onVideoEnded();
              }}
              muted={true}
              volume={1}
              playing={true}
            />
          }
        </div>

        <div className="video-debug-info hidden">
          <div>
            <p>Debug Info</p>
          </div>
          <div>
            <p>
              Now playing media from index : {this.state?.currentPlayingIndex}
            </p>
          </div>
          <div>
            <p>
              Original source:{" "}
              {this.state.videos[this.state?.currentPlayingIndex]?.url}
            </p>
          </div>

          <div>
            <p>
              Playing from local cache?:{" "}
              {this.state.videos[this.state?.currentPlayingIndex]?.blob
                ? "yes"
                : "no, its downloading now !"}
            </p>
          </div>

          <div>
            <p>
              Playing from local path:{" "}
              {this.state.videos[this.state?.currentPlayingIndex]?.blob}
            </p>
          </div>

          <div>
            <p>
              CAUTION: please note cache is active only after you build and
              serve from normal server (not from yarn start)
            </p>
          </div>

          <div>
            {/* <p>{JSON.stringify(this.state.videos)}</p> */}

            {this.state?.videos?.map((x) => (
              <p key={x?.url}>{x.blob}</p>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

// export default InteractionVideoView;

function mapStateToProps({ appSettings, pdpRequest }) {
  return {
    appSettings,
    pdpRequest,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractionVideoView);
