import React, { Component } from "react";
// import { log, MSG_TYPE } from "../../../../utils/logger";
import "./TopNavigationMenu.css";

import {
  GlHeading,
  GL_HEADING_AS,
  GL_HEADING_FONT_SIZE,
} from "@adl/foundation";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../../../utils/translationCopies";

class TopNavigationMenu extends Component {
  state = {
    title: this.props.title,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.setState({ title: this.props.title });
    }
  }

  onProductClicked = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let {
      onBackButtonClicked,
      resultCount,
      title,
      presetFilters,
      userSelectedSearchSuggestion,
    } = this.props;

    let _headingText;

    let customFilter;

    if (userSelectedSearchSuggestion?.length > 0) {
      customFilter = decodeURI(userSelectedSearchSuggestion);
    } else {
      customFilter = presetFilters;
    }

    if (!customFilter) {
      if (title && title.length > 0) {
        if (title === "root") {
          _headingText = this.getTranslation(
            TRANSLATIONS_KEY_LIST.all_products
          );
        } else {
          _headingText = title;
        }
      } else if (!title && resultCount > 0) {
        _headingText = this.getTranslation(TRANSLATIONS_KEY_LIST.all_products);
      } else {
        _headingText = <span className={"title-loading"}></span>;
      }
    } else {
      if (title && title.length > 0) {
        _headingText = title;
      } else if (!title && resultCount > 0) {
        _headingText = customFilter;
      } else {
        _headingText = <span className={"title-loading"}></span>;
      }
    }

    let _heading = _headingText.length > 0 ? _headingText : "";

    return (
      <div className="plp-heading">
        {userSelectedSearchSuggestion?.length > 0 && (
          <div className="item icon" onClick={(e) => onBackButtonClicked(e)}>
            <img src="images/arrow-left.svg" alt="arrow-left"></img>
          </div>
        )}

        <div className="item heading-text">
          {userSelectedSearchSuggestion?.length > 0 ? (
            <>
              <div className="prefix">
                {this.props.locale &&
                  this.getTranslation(
                    TRANSLATIONS_KEY_LIST.showing_results_for
                  )}
              </div>
              <div>
                <GlHeading
                  as={GL_HEADING_AS.h6}
                  fontSize={GL_HEADING_FONT_SIZE.m}
                >
                  {`"${
                    _headingText.length > 0
                      ? _headingText
                      : decodeURI(userSelectedSearchSuggestion)
                  }"`}
                </GlHeading>
              </div>
            </>
          ) : (
            <>
              <GlHeading
                as={GL_HEADING_AS.h6}
                fontSize={GL_HEADING_FONT_SIZE.m}
              >
                {_heading}
              </GlHeading>
            </>
          )}
        </div>
        {resultCount && (
          <div className="item result-count">
            <div>[{resultCount}]</div>
          </div>
        )}
      </div>
    );
  }
}

export default TopNavigationMenu;
