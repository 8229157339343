import React, { Component } from "react";

import "./RequestHelpEmpty.css";

import { GlButton, GL_BUTTON_VARIANT, GlLoader } from "@adl/foundation";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

import helpIcon from "../../../../../../assets/icons/help.svg";

class RequestHelpEmpty extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    return (
      <>
        <section className="empty-help">
          <div className="wrapper">
            <div>
              <img src={helpIcon} alt="cart" className="help-icon" />
            </div>
            <h4>
              {this.getTranslation(
                TRANSLATIONS_KEY_LIST.do_you_need_assistance_title
              )}
            </h4>
            <p>
              {this.getTranslation(
                TRANSLATIONS_KEY_LIST.do_you_need_assistance_instruction
              )}
            </p>

            <div className="cta-create-help-request">
              {this.props?.isTaskCreateError && (
                <p className="error-text">
                  {" "}
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.error_occurred)}
                </p>
              )}
              {/* todo call here the create task for help request */}
              {this.props?.isTaskCreation_inProgress ? (
                <>
                  <div className="loader-wrapper">
                    <GlLoader></GlLoader>
                  </div>
                </>
              ) : (
                <GlButton
                  aria-label="label"
                  variant={GL_BUTTON_VARIANT.primary}
                  icon={"arrow-right-long"}
                  disabled={false}
                  onClick={(e) => this.props.onRequestHelp()}
                >
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.call_store_associate
                  )}
                </GlButton>
              )}
            </div>
          </div>
        </section>

        <section className="empty-help__btn-container"></section>
      </>
    );
  }
}

export default RequestHelpEmpty;
