const localeConfig = [
  {
    locale: "en_GB",
    currencySymbol: "£",
    code: "GBP",
    priceFormat: "symbol value",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "UK", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_DE",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "de_DE",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "ru_RU",
    currencySymbol: "р.",
    code: "RUB",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: " ",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "RU", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_US",
    currencySymbol: "$",
    code: "USD",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_SG",
    currencySymbol: "$",
    code: "SGD",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    productSizeCountryCode: "UK", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_MY",
    currencySymbol: "MYR",
    code: "SGD",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    productSizeCountryCode: "UK", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_AE",
    currencySymbol: "AED",
    code: "AED",
    priceFormat: "symbol value",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "es_ES",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "fr_FR",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "es_CL",
    currencySymbol: "$",
    code: "CLP",
    priceFormat: "symbolvalue",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "es_AR",
    currencySymbol: "$",
    code: "ARS",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },

  {
    locale: "es_CO",
    currencySymbol: "$",
    code: "COP",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "es_MX",
    currencySymbol: "$",
    code: "MXN",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    productSizeCountryCode: "MX", //used for displaying sizes coming from SAM API
  },
  {
    locale: "es_PE",
    currencySymbol: "S/",
    code: "PEN",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "ja_JP",
    currencySymbol: "¥",
    code: "JPY",
    priceFormat: "symbol value",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "JP", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_IN",
    currencySymbol: "₹",
    code: "INR",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "UK", //used for displaying sizes coming from SAM API
  },
  {
    locale: "tr_TR",
    currencySymbol: "₺",
    code: "TRY",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "nl_NL",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "symbol value",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },

  {
    locale: "it_IT",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },

  {
    locale: "pt_PT",
    currencySymbol: "€",
    code: "EUR",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },

  {
    locale: "pl_PL",
    currencySymbol: "zł",
    code: "PLN",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "sv_SE",
    currencySymbol: "KR",
    code: "SEK",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "en_CA",
    currencySymbol: "$",
    code: "CAD",
    priceFormat: "symbolvalue",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "fr_CA",
    currencySymbol: "$",
    code: "CAD",
    priceFormat: "value symbol",
    decimalSeparator: ",",
    thousandSeparator: ".",
    ignoreZeroZeroFractionalPart: false,
    productSizeCountryCode: "US", //used for displaying sizes coming from SAM API
  },
  {
    locale: "ko_KR",
    currencySymbol: "₩",
    code: "KRW",
    priceFormat: "symbol value",
    decimalSeparator: ".",
    thousandSeparator: ",",
    ignoreZeroZeroFractionalPart: true,
    productSizeCountryCode: "KR", //used for displaying sizes coming from SAM API
  },
  {
    locale: "fallback",
    currencySymbol: "CURRENCY",
    code: "_",
    priceFormat: "symbol value",
    decimalSeparator: ".",
    thousandSeparator: ",",
    productSizeCountryCode: "FR", //used for displaying sizes coming from SAM API
  },
];

const formatPrice = (value, locale) => {
  value = value + "";

  let _integralValue;
  let _fractionalValue;
  let _localeObject;

  let _match = localeConfig.filter((x) => x.locale === locale);

  if (_match?.length > 0) {
    _localeObject = _match[0];
  } else {
    _match = localeConfig.filter((x) => x.locale === "fallback");
    _localeObject = _match[0];
    console.warn(
      `Currency and price Format not found for provided locale ${locale}, hence  using fallback values`
    );
  }

  if (value.includes(".")) {
    _integralValue = value.split(".")[0];
    _fractionalValue = value.split(".")[1];
    if (parseInt(_fractionalValue) < 10) {
      _fractionalValue += "0";
    }
  } else {
    if (!_localeObject?.ignoreZeroZeroFractionalPart) {
      _fractionalValue = "00";
    } else {
      _fractionalValue = "";
    }
    _integralValue = value;
  }

  let _integralValueThousandSeparated = _integralValue;

  let _integralPartsArrayReversed = [];

  if (parseInt(_integralValue) > 999) {
    let _thousandCounter = 0;

    for (let i = _integralValue.length - 1; i >= 0; i--) {
      if (_thousandCounter > 0 && _thousandCounter % 3 === 0) {
        _integralPartsArrayReversed.push("thousand_separator");
      }

      _thousandCounter++;
      _integralPartsArrayReversed.push(_integralValue[i]);
    }

    _integralValueThousandSeparated = _integralPartsArrayReversed
      .reverse()
      .join("");

    _integralValueThousandSeparated =
      _integralValueThousandSeparated.replaceAll(
        "thousand_separator",
        _localeObject.thousandSeparator
      );
  }
  let _formattedPrice = _localeObject.priceFormat;
  // inserting in price string the value part
  _formattedPrice = _formattedPrice.replace(
    "value",
    `${_integralValueThousandSeparated}${
      _fractionalValue.length > 0 ? _localeObject.decimalSeparator : ""
    }${_fractionalValue}`
  );

  // inserting in price string the currency part
  _formattedPrice = _formattedPrice.replace(
    "symbol",
    _localeObject.currencySymbol
  );

  return _formattedPrice;
};

const getLocaleConfig = (locale) => {
  return (
    localeConfig.filter((x) => x.locale === locale) ||
    localeConfig.filter((x) => x.locale === "fallback")
  );
};

const validateEmail = (emailId) => {
  // eslint-disable-next-line
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)) {
    return true;
  }

  return false;
};

function validatePhoneNumber(phoneNumber) {
  // eslint-disable-next-line
  var phoneno = /^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]{0,5}$/g;

  if (phoneNumber.match(phoneno)) {
    return true;
  } else {
    return false;
  }
}

function validateUserDetailsForHomeDelivery(_userDetails) {
  if (
    _userDetails?.firstName?.length === 0 ||
    _userDetails?.firstName === undefined ||
    _userDetails?.lastName?.length === 0 ||
    _userDetails?.lastName === undefined ||
    _userDetails?.street?.length === 0 ||
    _userDetails?.street === undefined ||
    _userDetails?.zipCode?.length === 0 ||
    _userDetails?.zipCode === undefined ||
    _userDetails?.city?.length === 0 ||
    _userDetails?.city === undefined ||
    _userDetails?.email?.length === 0 ||
    validateEmail(_userDetails?.email) === false ||
    _userDetails?.email === undefined ||
    _userDetails?.phoneNumber?.length === 0 ||
    _userDetails?.phoneNumber === undefined ||
    validatePhoneNumber(_userDetails?.phoneNumber) === false
  ) {
    return false;
  } else {
    return true;
  }
}

function validateUserDetailsForStorePickup(_userDetails) {
  if (
    _userDetails?.firstName.length === 0 ||
    _userDetails?.lastName.length === 0 ||
    _userDetails?.email.length === 0 ||
    validateEmail(_userDetails?.email) === false ||
    _userDetails?.phoneNumber.length === 0 ||
    validatePhoneNumber(_userDetails?.phoneNumber) === false
  ) {
    return false;
  } else {
    return true;
  }
}

function getCartItemsCount(items) {
  const totalQuantity =
    items &&
    items.length > 0 &&
    items.reduce((quantity, item) => {
      if (item?.quantity) {
        quantity += item.quantity;
      }
      return quantity;
    }, 0);

  return totalQuantity;
}

function getLocalDateTimeString(UTCTimeString) {
  let _parsedDateTime = new Date(Date.parse(UTCTimeString));
  let _datePart = _parsedDateTime.toLocaleDateString();
  let _timePart = _parsedDateTime.toLocaleTimeString().split(" ")[0];
  let _amPmPart = _parsedDateTime.toLocaleTimeString().split(" ")[1];
  let _timePartShortened = _timePart.substring(0, _timePart.length - 3);
  return `${_datePart} ${_timePartShortened} ${_amPmPart}`;
}

function getLocalTimeString(UTCTimeString) {
  let _parsedDateTime = new Date(Date.parse(UTCTimeString));
  let _timePart = _parsedDateTime.toLocaleTimeString().split(" ")[0];
  let _amPmPart = _parsedDateTime.toLocaleTimeString().split(" ")[1];
  let _timePartShortened = _timePart.substring(0, _timePart.length - 3);
  return `${_timePartShortened} ${_amPmPart ? _amPmPart : ""}`; // AM PM part is null at times when local pc has setting of time in 24h
}

module.exports = {
  validateEmail,
  validatePhoneNumber,
  validateUserDetailsForHomeDelivery,
  validateUserDetailsForStorePickup,
  formatPrice,
  getCartItemsCount,
  getLocalDateTimeString,
  getLocalTimeString,
  getLocaleConfig,
};
