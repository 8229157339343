import React from "react";

import "./NewBackButton.css";

import newBackArrow from "../../assets/icons/new-back-arrow.png";

const NewBackButton = (props) => {
  return (
    <>
      <div
        className="new-back-button"
        onClick={() => {
          props.onClick();
        }}
      >
        <img alt="back" src={newBackArrow}></img>
      </div>
    </>
  );
};

export default NewBackButton;
