import React, { Component } from "react";

import { PAGE_NAMES, setCurrentPageName } from "../../../actions/pageActions";

import "./ServiceOverlayView.css";

import { connect } from "react-redux";

import { GlHeading } from "@adl/foundation";
import NewBackButton from "../../NewBackButton/NewBackButton";

class ServiceOverlayView extends Component {
  state = { genderList: [], isProductListDataLoadError: null };

  componentDidMount() {
    if (PAGE_NAMES.serviceDetailsPage !== this.props.currentPage) {
      this.props.setCurrentPageName(PAGE_NAMES.serviceDetailsPage);
    }
  }

  componentDidUpdate(prevProps) {}

  render() {
    return (
      <div className="event-container">
        <div className="image-placeholder">
          <div className="event-details-container">
            <img alt="event" src={""}></img>

            <div className="event-name-block">
              <div className="sub-heading ">
                <span>maker lab</span>
              </div>
              <div className="sub-heading ">
                <span style={{ color: "red" }}>[work in progress]</span>
              </div>
            </div>
          </div>
        </div>

        <div className="details-container">
          <div className="container-left">
            <div className="subline">Store Service</div>
            <div className="heading">
              {" "}
              <GlHeading as="h2" fontStyle="regular" className="heading">
                maker lab
              </GlHeading>{" "}
            </div>
            <div className="description">
              <p>
                Unleash your creativity in our Lab, a creative playground for
                innovation. Create unique adidas items through customization,
                e.g. football jersey flocking, laser engraving, patches, direct
                garment printing, and embroidery. You can also sign up for
                upcoming workshops and in-store events.
              </p>
            </div>
            <div className="sub-heading">how it works</div>
            <div className="timeline">
              <div className="row">
                <div className="progress-container">
                  <div className="progress-container-left with-right-border">
                    &nbsp;
                  </div>
                  <div className="circle-container">
                    <div className="icon">
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">01</div>
                  <div className="sub-heading">pick your items</div>
                  <div className="description">
                    Find your new favorite products in the adidas store.
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="progress-container">
                  <div className="progress-container-left with-right-border">
                    &nbsp;
                  </div>
                  <div className="circle-container">
                    <div className="icon">
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">02</div>
                  <div className="sub-heading">get creative</div>
                  <div className="description">
                    Head to the Lab and express yourself! If you can dream, you
                    can make it.
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="progress-container">
                  <div className="progress-container-left  with-right-border">
                    &nbsp;
                  </div>
                  <div className="circle-container">
                    <div className="icon">
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">03</div>
                  <div className="sub-heading">check out</div>
                  <div className="description">
                    Pay at the Lab register. Please do this before customizing.
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="progress-container">
                  <div className="progress-container-left">&nbsp;</div>
                  <div className="circle-container">
                    <div className="icon">
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>

                <div className="item">
                  <div className="heading">04</div>
                  <div className="sub-heading">get it made for you</div>
                  <div className="description">
                    Browse the store or relax while we create your design for
                    you.
                  </div>
                </div>
              </div>
            </div>
            <br />
            <p style={{ color: "red" }}>
              please note this screen is not actual - just uses dummy data
            </p>
          </div>
        </div>
        <NewBackButton
          onClick={() => {
            this.props.setCurrentPageName(PAGE_NAMES.servicesPage);
          }}
        ></NewBackButton>
      </div>
    );
  }
}

function mapStateToProps({ appSettings, selectedTranslationLocale }) {
  return {
    appSettings,
    selectedTranslationLocale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentPageName: (val) => dispatch(setCurrentPageName(val)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOverlayView);
