import React, { Component } from "react";
import axios from "axios";

import { getMedia } from "../../utils/media";

import { connect } from "react-redux";

import { Player } from "@lottiefiles/react-lottie-player";

import {
  translateString,
  TRANSLATIONS_KEY_LIST,
} from "../../utils/translationCopies";

import "./TapAnimation.css";

class TapAnimation extends Component {
  state = {
    animationData: null,
    animationJson: null,
  };

  componentDidMount() {
    const lottieAnimationUrl =
      this.props.appSettings?.value?.tap_to_get_started_animation
        ?.lottie_animation_url;

    const lottieAnimationEnabled =
      this.props.appSettings?.value?.tap_to_get_started_animation?.enabled;

    if (lottieAnimationEnabled) {
      this.downloadLottieAnimation(lottieAnimationUrl);
    }
  }

  componentDidUpdate(prevProps) {}

  downloadLottieAnimation = (animationUrl) => {
    if (!animationUrl) return;

    console.log("----- FETCHING ANIMATION DATA START-----");

    getMedia(animationUrl).then(
      (result) => {
        let animationData = {
          url: animationUrl,
          blob: null,
        };

        var _blob = window.URL.createObjectURL(result.response);

        animationData.blob = _blob;

        this.setState({
          animationData: animationData,
        });
        console.log("----- FETCHING ANIMATION DATA END-----");

        axios
          .get(animationData.blob)
          .then((response) => {
            this.setState({
              animationJson: response?.data,
            });
          })
          .catch(function (error) {
            console.log(error);
            console.log("interaction video - unable to fetch lottie animation");
          });
      },
      (Error) => {
        console.log(Error);
      }
    );
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { animationJson } = this.state;

    let stringAnimationJson = JSON.stringify(animationJson).replace(
      "YOUR JOURNEY\\rSTARTS HERE",
      ""
    );

    animationJson = JSON.parse(stringAnimationJson);

    return (
      <div>
        {/* todo:// translations and styling */}

        {animationJson && (
          <>
            <div className="fullscreen-overlay-container"></div>

            <div className="tap-to-get-started-animation">
              <Player
                autoplay
                loop
                // src="https://assets3.lottiefiles.com/packages/lf20_UJNc2t.json"
                src={animationJson}
              ></Player>
            </div>

            <div className="tap-to-get-started-title">
              <center>
                <h1>
                  {this.getTranslation(
                    TRANSLATIONS_KEY_LIST.your_journey_starts_here
                  )}
                </h1>
              </center>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps({ appSettings, pdpRequest }) {
  return {
    appSettings,
    pdpRequest,
  };
}

export default connect(mapStateToProps, null)(TapAnimation);
