import React, { Component } from "react";
import { GlPrice, GlPriceItem, GL_PRICE_ITEM_THEME } from "@adl/foundation";

import { connect } from "react-redux";

import "./BITMItems.css";

import "./BITMStatus.css";

import * as helpers from "../../../../../../utils/helpers";

import { SAM_REQUEST_STATUS_TYPE } from "../../../../../../utils/constants";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../../../utils/translationCopies";

class BITMItems extends Component {
  state = {};

  componentDidMount() {}

  componentDidUpdate() {}

  getTranslation(key) {
    return translateString(key);
  }

  getCartJSX = () => {
    let { activeBITM } = this.props;

    return (
      <section>
        <section className="bitm-wrapper">
          {activeBITM.map((x) => (
            <div key={x.sku}>
              <div className="cart">
                <div className="image-container">
                  <img
                    src={x.product?.image_url}
                    alt={x.product?.product_name}
                    onClick={() => {
                      this.props.setExternalPDPTrigger(x?.product?.product_id);
                    }}
                  />
                </div>
                <div className="product-details">
                  <div className="wrapper">
                    <div className="title">
                      <h4>{x?.product?.product_name}</h4>
                    </div>

                    <div className="price">
                      {x?.product?.is_sale === true && (
                        <div className="sale">
                          {x?.product?.discount_percentage_text}
                        </div>
                      )}

                      {this.getPriceJSX(
                        x?.product?.discount_price,
                        x?.product?.original_price,
                        x?.product?.display_currency
                      )}
                    </div>
                    <div className="action-panel">
                      <div className="left"></div>
                      <div className="right">
                        <div>
                          <span>{x?.product?.product_id}</span>
                        </div>
                        <div>
                          <div
                            style={{
                              paddingLeft: "0.5rem",
                              fontWeight: "bold",
                            }}
                          >
                            {x?.size?.size}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </section>
    );
  };

  getPriceJSX = (discountPrice, originalPrice) => {
    return (
      <GlPrice>
        {discountPrice ? (
          <>
            <GlPriceItem theme={GL_PRICE_ITEM_THEME.sale}>
              {helpers.formatPrice(discountPrice, this.props.selectedLocale)}
            </GlPriceItem>

            <GlPriceItem theme={GL_PRICE_ITEM_THEME.crossed}>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        ) : (
          <>
            <GlPriceItem>
              {helpers.formatPrice(originalPrice, this.props.selectedLocale)}
            </GlPriceItem>
          </>
        )}
      </GlPrice>
    );
  };

  getBITMStatusJSX = () => {
    let { activeBITM } = this.props;

    const status = activeBITM[0]?.task?.status || SAM_REQUEST_STATUS_TYPE.todo;

    let statusLocalized;

    if (status === SAM_REQUEST_STATUS_TYPE.todo) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_todo
      );
    } else if (status === SAM_REQUEST_STATUS_TYPE.inProgress) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_in_progress
      );
    } else if (status === SAM_REQUEST_STATUS_TYPE.complete) {
      statusLocalized = this.getTranslation(
        TRANSLATIONS_KEY_LIST.bitm_task_complete
      );
    }

    let updatedAt = activeBITM[0]?.task?.updatedAt;
    let assigneeName = activeBITM[0]?.task?.assignee?.username;

    let progressBarCode = 1;

    if (status === SAM_REQUEST_STATUS_TYPE.todo) {
      progressBarCode = 1;
    } else if (status === SAM_REQUEST_STATUS_TYPE.inProgress) {
      progressBarCode = 2;
    }
    if (status === SAM_REQUEST_STATUS_TYPE.complete) {
      progressBarCode = 3;
    }

    return (
      <div className="bitm-status-container">
        <div className="bitm-status">
          <div className="header">
            <div className="title">
              <h4>{statusLocalized}</h4>
            </div>
            <div className="lastupdated-info">
              <div>
                <p>{assigneeName && assigneeName + ", "}</p>
              </div>
              <div>
                <h4>{helpers.getLocalTimeString(updatedAt)}</h4>
              </div>
            </div>
          </div>
          <div className="bitm-progress-bar">
            <div>
              <div
                className={`${
                  progressBarCode >= 1 ? "black-circle" : "circle"
                } }`}
              >
                <div></div>
              </div>
            </div>
            <div
              className={`separator ${
                progressBarCode > 1 ? "highlighted" : ""
              } }`}
            ></div>
            <div>
              <div
                className={`${
                  progressBarCode >= 2 ? "black-circle" : "circle"
                } }`}
              >
                <div></div>
              </div>
            </div>
            <div
              className={`separator ${
                progressBarCode >= 3 ? "highlighted" : ""
              } }`}
            ></div>
            <div>
              <div
                className={`${
                  progressBarCode > 2 ? "black-circle" : "circle"
                } }`}
              >
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    let { activeBITM } = this.props;

    const status = activeBITM[0]?.task?.status || SAM_REQUEST_STATUS_TYPE.todo;

    return (
      <>
        <section className="bitm-container">
          {this.getCartJSX()}
          <div>
            {this.getBITMStatusJSX()}
            <div className="debug-info hidden">
              <p>debug info - Task ID : {activeBITM[0]?.task?.id}</p>
            </div>

            <div className="checkout-cta">
              {status === SAM_REQUEST_STATUS_TYPE.todo && (
                <div className="delete_bag_container">
                  <div
                    className="delete-btn"
                    onClick={() => this.props.onShowDeleteCartConfirmation()}
                  >
                    {this.getTranslation(
                      TRANSLATIONS_KEY_LIST.bitm_delete_btn_label
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps({ activeBITM, selectedLocale }) {
  return {
    activeBITM,
    selectedLocale,
  };
}

export default connect(mapStateToProps, null)(BITMItems);
