import React, { Component } from "react";
import "./FilterMenuSkeleton.css";

class FilterMenuSkeleton extends Component {
  render() {
    return (
      <>
        <div className="filter-skeleton ">
          <div className="filter-panel">
            <div className="filter-options">
              <div className={`option }`}>
                <div className="name_placeholder"></div>
              </div>
              <div className={`option }`}>
                <div className="name_placeholder"></div>
              </div>
              <div className={`option }`}>
                <div className="name_placeholder"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="plp__filters-applied"></div>
      </>
    );
  }
}

export default FilterMenuSkeleton;
