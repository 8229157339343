import React, { Component } from "react";
import { GlIcon, GlParagraph } from "@adl/foundation";

import "./DeliveryOptions.css";

class DeliveryOptions extends Component {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    let { deliveryOptionSelected, deliveryOptions, onDeliveryOptionClick } =
      this.props;

    return (
      <>
        <section className="delivery-options">
          {deliveryOptions &&
            deliveryOptions.map((x) => (
              <div
                key={x?.id}
                className={`item cursor-pointer ${
                  deliveryOptionSelected === x?.id ? "selected" : ""
                }`}
                onClick={(e) => onDeliveryOptionClick(e, x?.id)}
              >
                <div className="highlight-vertical-bar"></div>
                <div className="left">
                  <div>
                    {x?.id === "home" && <GlIcon name="usp-delivery" />}
                    {x?.id === "pick_up_point" && (
                      <img src="images/location-pin.svg" alt="store" />
                    )}
                    {x?.id === "store" && <GlIcon name="logo" />}
                  </div>
                </div>
                <div className="center">
                  <div>
                    <div className="heading">
                      <GlParagraph>{x.name}</GlParagraph>
                    </div>
                    <div>
                      <GlParagraph>{x.details?.description}</GlParagraph>
                    </div>
                    {x?.id === "home" && 
                      <>
                        { x.details?.location?.city && x.details?.location?.address1 &&
                          <div>
                            <GlParagraph>{`${x.details?.location?.city}, ${x.details?.location?.address1}`}</GlParagraph>
                          </div>
                        }
                        { x.details?.delivery_time?.date && 
                          <div className="bold">
                            <GlParagraph>{`FROM ${x.details?.delivery_time?.date?.from} TO ${x.details?.delivery_time?.date?.to}`}</GlParagraph>
                          </div>
                        }
                      </>
                    }
                    {(x?.id === "store" || x?.id === "pick_up_point") &&
                      <>
                        <div>
                          <GlParagraph>{x.details?.location?.name}</GlParagraph>
                        </div>
                        <div className="bold">
                          <GlParagraph>{x.details?.delivery_time?.availability}</GlParagraph>
                        </div>
                      </>
                    }
                  </div>
                  <div className="bold">
                    <GlParagraph>{x.details?.cost?.display_value}</GlParagraph>
                  </div>
                </div>
                <div className="right">
                  <div>
                    <GlIcon name="arrow-right" />
                  </div>
                </div>
              </div>
            ))}
        </section>
      </>
    );
  }
}

export default DeliveryOptions;
