export const translations_tr_TR = {
  all_products: "Tüm ürünler",
  go_to_top: "Başa dön",
  more_filters: "Daha fazla filtre",
  less_filters: "Daha az filtre",
  sort_by: "Sıralama",
  clear_all: "Tümünü sil",
  colors_available: "<count> renk seçeneği",
  colors_available_plp: "<count> renk",

  details: "Detaylar",
  how_to_style: "Nasıl giyilir",
  reviews: "Yorumlar",
  gallery: "Galeri",
  highlights: "Öne Çıkanlar",

  select_size: "Beden seç",
  store_availability: "Mağazalara bak",

  size_guidance: "Ölçü rehberi",
  add_to_bag: "Sepete ekle",
  scan_to_order_online: "Online sipariş için tara",
  shopping_bag: "Alışveriş sepeti",
  bag_empty: "Sepetin boş",

  max_cart_item_count_reached: "Maksimum ürün sayısına ulaşıldı",

  browse_online: (
    <>
      Devam et, kendin için güzel parçalar bul! <br />
      Bu mağazadaki stokları kontrol etmek veya ürünleri online olarak sipariş
      etmek için bu kioska göz at.
    </>
  ),

  order_online: "Online sipariş için tara",

  start_browsing: "Mağazaya göz at",
  total: "Toplam ",
  including_tax: "(KDV dahil)",
  checkout: "Ödeme",
  delete_bag: "Sepeti sil",

  continue_on_this_device:
    "Evet, özel fırsatlar ve daha fazlasıyla ilgili bülten almak istiyorum.",
  checkout_on_your_device: "Telefonunla ödeme yap",

  checkout_on_your_device_instruction:
    "Sepetini telefonundaki adidas uygulamasına ya da web sitesine aktarmak için QR kodunu tara.",

  employee_checkout: "Personel Çıkışı",
  employee_checkout_title: "Bir çalışanla çıkış yapın",
  employee_checkout_instruction:
    "Çantanızı aktarmak ve ürünlerinizi ödemek için bu QR kodunu tarayın.",
  employee_id_instruction:
    "Lütfen çalışan kimliğinizi girin ve QR kodunu güncelleyin.",
  employee_id_update_qr_code: "QR Kodunu Güncelle",
  employe_id_validation_error: "Geçersiz çalışan kimliği",
  change_employee_id: "Çalışan Kimliği Değiştir",

  only_cash_on_delivery_option:
    "Not: Kiosktan verilen siparişler için yalnızca Teslimatta Nakit ödeme yöntemini kabul ediyoruz.",

  yes_over_16_years_of_age: "16 yaşın üzerindeyim",

  choose_your_shipping_method: "Teslimat yöntemi seç",
  order_summary: "Sipariş özeti",
  promo_code: "Promosyon kodu",
  place_order: "Sipariş ver",
  order_now_pay_on_delivery: "Şimdi sipariş ver, teslim edildiğinde öde",

  read_more_reviews: "Daha fazla yorum oku",

  edit_address: "Adresi düzenle",
  save_details: "Kaydet",
  edit_details: "Bilgileri düzenle",
  select_pickup_point: "Bu konumu seç",
  select_store: "Bu mağazayı seç",
  back_to_list: "Ürünlere geri dön",

  sure_to_delete_cart: "Bu sepeti silmek ister misin?",
  cart_delete_message:
    "Eklediğin tüm ürünler silinecek ve oturumun sona erecek.",
  cancel: "İptal",

  add_voucher_heading: "Kupon veya promosyon kodu ekle",
  add_voucher_instruction: "Büyük/küçük harfe duyarlıdır; aynı şekilde gir",
  add_voucher_disclaimer:
    "Belirli promosyon kodları bazı ürünlerde geçersiz olabilir. Lütfen, Hüküm ve Koşulları kontrol et.",
  apply: "_tr apply",

  loading: "İşleniyor...",
  your_order_in_progress: "Yükleniyor...",
  your_order_is_placed: "Sipariş tamamlandı!",
  session_ends_in: "Oturumun kapanması için kalan süre",
  estimated_delivery: "Tahmini teslimat",
  confirmation_email_sent_to: "Onay e-postasının gönderildiği adres",
  order_number: "Sipariş numarası",
  start_new_session: "Yeni oturum aç",
  extend_session: "Devam et",

  map_search_no_result: "Üzgünüz, sonuç bulunamadı. Lütfen tekrar dene.",

  enter_your_details: "İletişim bilgileri",
  first_name: "Ad",
  last_name: "Soyadı",
  street: "Sokak",
  house_number: "Numara",
  zip_code: "Posta kodu",
  town_city: "Şehir",
  email_address: "E-posta adresi",
  phone_number: "Telefon numarası",

  change_store: "Mağaza değiştir",
  change_pickup_point: "Teslimat noktasını değiştir",

  search_by_city_dictrict_or_address: "Şehir, ilçe veya adrese göre ara",

  processing: "İşleniyor...",

  error_occurred: "Üzgünüz, bir sorun oluştu",

  retry_place_order: "Tekrar sipariş vermeyi dene",

  retry_start_checkout: "Tekrar ödeme yapmayı dene",

  please_select_your_size: "Beden seç", //from key "select_size"
  bring_it_to_me: "Bana getir",
  need_help: <br />, // translation not received. This text was to appears above call for help in the pdp side panel.

  call_store_associate: "Personeli çağır",
  do_you_need_assistance_title: "Mağaza personelini çağır",
  do_you_need_assistance_instruction:
    "Butona dokun ve mağaza temsilcilerimizden biri en kısa sürede sana yardıma gelsin.",
  do_you_need_assistance_sure_to_delete:
    "Bu talebi silmek istediğinden emin misin?",
  do_you_need_assistance_delete_btn_label: "Talebi sil",

  we_will_bring_it_here: "Ya da ürünü buraya iste",
  bring_it_to_me_instruction:
    "Butona dokun ve ekranın yakınında kal. İstediğin ürünleri 3-5 dakika içerisinde buraya getireceğiz.",

  bitm_none_active_title: "Güzel bir şey mi buldun?",
  bitm_none_active_instruction:
    "Sana getirelim! Sadece istediğin ürünleri ve bedeni seç, bu kioskta ya da adidas uygulamasında BANA GETİR butonuna dokun. ",

  bitm_sure_to_delete: "Bu talebi silmek istediğinden emin misin?",
  bitm_delete_btn_label: "Talebi sil",

  bitm_task_todo: "Talebin sırada",
  bitm_task_in_progress: "Yolda!",
  bitm_task_complete: "İşlem tamam!",

  bitm_qr_title: "Beklerken mağazaya göz at",
  bitm_qr_description:
    "QR kodunu taratarak uygulama üzerinden talebini tamamla. Beklerken mağazada dolaşmaya devam edebilirsin; ürünlerin hazır olduğunda yanında olacağız.",
  restart_checkout: "Bir sorun mu var? Sayfayı yenile",

  try_again: "Tekrar dene",

  session_expiring_title: "Bitti mi?",
  session_expiring_body: "Oturumun kapanması için kalan süre",

  nearbystore_availability_overlay_title:
    "Diğer mağazalardaki stok durumuna göz at",

  available_in_nearby_store: "Bu konumda stokta bulunan bedenler",

  nearby_store_no_sizes_found: "Aradığın ürün bu konumda stokta yok.",

  please_try_selecting_another_store: "Lütfen farklı bir mağaza dene.",

  in_stock_at_this_store: "Bu mağazada stokta",

  employee_id: "Personel Kimlik Numarası",

  add_employee_id_heading: "Personel Kimlik Numaranı Ekle",

  add_employee_id_instruction:
    "Devam etmek için lütfen mağaza personelinden yardım iste.",

  verify_checkout: "Lütfen kaydetmeden önce bilgilerini tekrar kontrol et",

  search: "Ara",

  search_result_see_all: "Tümünü gör",

  search_no_result: "Üzgünüz, sonuç bulunamadı",

  try_searching_for_something_else: "Başka bir ürün aramayı dene.  ",

  start_new_search: "Yeni arama başlat",

  showing_results_for: "için arama sonuçları", // placement of search text will appear in the last, if required elsewhere then code change is required in UI.

  no_available_sizes_found: "Şu anda hiçbir beden stokta yok",

  online: "Online",
  in_store: "Mağazada",

  tap_to_get_started: "Başlamak için dokun", //not used
  your_journey_starts_here: "Yolculuğunuz burada başlıyor",
  app_header_title: "Burada gezin ve en yeni ve en harika ürünleri keşfedin",
  landingpage_filter_heading: "Kim için alışveriş yapıyorsun",
  landingpage_signup_heading: "Adidas'ta en iyisini keşfetmek için kaydolun",
  landingpage_signup_cta:
    "Üye özel ekipmanlarımızı alışveriş yapmak için kaydolun",
  landingpage_events_cta:
    "Yaklaşan lansmanlarımızı ve etkinliklerimizi keşfedin",
  landingpage_instore_services_cta: "Mağaza içi hizmetlerimizi keşfedin",
  eventspage_heading: "Adidas etkinliklerimizi keşfedin",
  eventspage_sub_heading:
    "Özel etkinliklerimizle alışveriş deneyiminizin en iyisini elde edin.",

  eventspage_store_event: "Mağaza etkinliği",
  eventspage_event_details: "Etkinlik detayları",
  eventspage_raffle_timelines: "Çekiliş takvimleri",
  eventspage_raffle_results: "Çekiliş sonuçları",
  eventspage_sign_up_before: "Önce kaydolun",
  eventspage_scan_and_sign_up: "Tarama yaparak kaydolun",

  instoreservicepage_heading: "Mağaza içi Hizmetlerimizi Keşfedin",
  instoreservicepage_sub_heading:
    "Özel hizmetlerimizle alışveriş deneyiminizin en iyisini elde edin.",

  membershippage_title: "ÜCRETSİZ ÜYELİK PROGRAMINA KATILIN",
  membershippage_body:
    "adiClub puanlarınızı harcayarak sevdiğiniz şeylerden daha fazlasını kazanın.",
  membershippage_feature_membership_discount: "üyelik indirimleri",
  membershippage_feature_freeshipping: "ücretsiz kargo",
  membershippage_feature_birthdaygifts: "doğum günü hediyeleri",
  membershippage_feature_priorityshopping: "öncelikli alışveriş",
  membershippage_feature_specialevents: "özel etkinlikler",

  change_language: "dil değiştir",
};
