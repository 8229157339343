import APIBase from "../APIBase/APIBase";
import {
  transformMGWProductList,
  transformMGWFilters,
  transformMGWProduct,
  transformRetailProductList,
  transformRetailCatalogFilters,
  transformSAMProductDescription,
  transformMGWProductDescriptionData,
} from "../../../utils/dataTransformations";

import {
  translateFilters,
  translateSortOptions,
  getRefRefinementsAndSortOptions,
  translateFiltersAppliedTitle,
} from "../../../utils/dataTranslations";

class ProductsAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi({
      url: _url,
      cache: {
        maxAge: 60 * 60 * 1000, // set cache time to 60 minutes
        exclude: { query: false }, // cache requests with query parameters
        store: this.localforageStore, // pass `localforage` store to `axios-cache-adapter`
        clearOnStale: true,
      },
    }).then((response) => {
      return response;
    });
  };

  #getRetailProducts = async (_api, query, refinements, sort, page, ssmid) => {
    await this.#getHeaders();

    let queryStrings = [];

    if (query) {
      queryStrings.push(`query=${query}`);
    }

    if (page) {
      queryStrings.push(`page=${page}`);
    }

    if (ssmid) {
      queryStrings.push(`ssmid=${ssmid}`);
    }

    // refinements = refinements?.replace("sportsCategory", "categoryCluster");

    if (refinements) {
      queryStrings.push(`${refinements}`);
    }

    let _url = `/retail-catalog/products`;

    if (queryStrings.length > 0) {
      let queryStringFlattened = queryStrings.join("&");

      queryStringFlattened = encodeURI(queryStringFlattened);

      _url = `${_url}?${queryStringFlattened}`;
    }

    let productListResponse = await this.#handleAPIGetRequest(_url);

    /* now lets get the filters*/
    let retailFiltersUrl = `/retail-catalog/filters?${queryStrings.join("&")}`;

    let filterDataResponse = await this.#handleAPIGetRequest(retailFiltersUrl);

    let transformedRetailFilters = transformRetailCatalogFilters(
      filterDataResponse?.data
    );

    const transformedRetailProductList = transformRetailProductList(
      productListResponse?.data?.content
    );

    let returnObject = {
      total: productListResponse?.data?.page?.totalPages * 10, // total pages * no. of items per page currently its 10
      limit: 10, // value not coming from api, but it is seen to be fixed size 10
      products: transformedRetailProductList,
      title: null,
      refinements: transformedRetailFilters,
      sorting_options: [],
    };

    return returnObject;
  };

  #getProductFromRetailSam = async (_api, articleId, ssmid) => {
    await this.#getHeaders();

    let _url = `/sam/articles/${articleId}`;

    if (ssmid) {
      _url = `/sam/articles/${articleId}?ssmid=${ssmid}`;
    }

    let response = await this.#handleAPIGetRequest(_url);

    console.log(response);
    const transformedProductData = transformSAMProductDescription(
      response?.data?.data
    );

    console.log("transformed SAM Api data ", transformedProductData);
    return transformedProductData;
  };

  #getProducts = async (
    _,
    query,
    refinements,
    sort,
    page,
    locale,
    refLocale = null
  ) => {
    await this.#getHeaders();

    let queryStrings = [];

    if (query) {
      queryStrings.push(`query=${query}`);
    }

    if (refinements) {
      queryStrings.push(`${refinements}`);
    }

    if (sort) {
      queryStrings.push(`sort_option_id=${sort}`);
    }

    if (page) {
      queryStrings.push(`page=${page}`);
    }

    if (locale) {
      queryStrings.push(`locale=${locale}`);
    }

    let _url = `/products`;

    if (queryStrings.length > 0) {
      let queryStringFlattened = queryStrings.join("&");

      queryStringFlattened = encodeURI(queryStringFlattened);

      _url = `${_url}?${queryStringFlattened}`;
    }

    const response = await this.#handleAPIGetRequest(_url);

    const transformedProductList = transformMGWProductList(
      response?.data.data?._embedded?.products
    );

    const transformedRefinementList = transformMGWFilters(
      response?.data.data?.refinements
    );

    let returnObject = {
      total: response?.data?.data?.total,
      limit: response?.data?.data?.limit,
      products: transformedProductList,
      title: response?.data.data?.title || null,
      refinements: transformedRefinementList,
      sorting_options: response?.data.data?.sorting_options,
    };

    /* translate filter, applied-filter-title, sort option
       using API data of filter from other market
     */

    let filtersTitle = response?.data.data?.title || null;
    let selectedRefinmentsFromApiResponse =
      response?.data.data?.selected_refinements || null;

    if (refLocale !== locale) {
      try {
        if (
          refLocale &&
          refLocale.indexOf("_") !== -1 &&
          refLocale.length === 5
        ) {
          const refMarketProductListURL = _url.replace(locale, refLocale);

          let { referenceRefinements, referenceSortOptions } =
            getRefRefinementsAndSortOptions();

          let refMarketRefinements;
          let refSortingOptions;

          if (!referenceRefinements || referenceRefinements?.length === 0) {
            const response = await this.#handleAPIGetRequest(
              refMarketProductListURL
            );

            refMarketRefinements = response?.data?.data?.refinements;
            refSortingOptions = response?.data?.data?.sorting_options;
          } else {
            refMarketRefinements = referenceRefinements;
            refSortingOptions = referenceSortOptions;
          }

          if (selectedRefinmentsFromApiResponse) {
            const translatedTitle = translateFiltersAppliedTitle(
              filtersTitle,
              selectedRefinmentsFromApiResponse
            );
            returnObject.title = translatedTitle;
          }
          /* translate the filter display name also its options in other language(of market let say x), 
            looking up the data from other market x */
          try {
            translateFilters(
              refMarketRefinements,
              refLocale,
              returnObject?.refinements,
              locale
            );
          } catch (e) {
            console.log("error in translating filters (plp data)", e);
          }

          try {
            translateSortOptions(
              refSortingOptions,
              refLocale,
              returnObject?.sorting_options,
              locale
            );
          } catch (e) {
            console.log("error in translating sorting option (plp data)", e);
          }
        }
      } catch (e) {
        console.log(
          "error occured - filter translation using reference locale/filter"
        );
        console.log(e);
      }
    }
    return returnObject;
  };

  #getProduct = async (_, productId, locale, refLocale) => {
    await this.#getHeaders();

    if (refLocale) {
      locale = refLocale;
    }

    let _url = `/products/${productId}`;

    if (locale) {
      _url = `${_url}?locale=${locale}`;
    }

    const response = await this.#handleAPIGetRequest(_url);
    const transformedProductData = transformMGWProduct(response?.data?.data);
    return transformedProductData;
  };

  #getProductDescription = async (_, productId, locale, refLocale) => {
    let _base = `/products/${productId}/description`;
    let _url;

    if (refLocale) {
      _url = `${_base}?locale=${refLocale}`;
    } else if (locale) {
      _url = `${_base}?locale=${locale}`;
    }

    try {
      const response = await this.#handleAPIGetRequest(_url);
      const transforedDescriptionData = transformMGWProductDescriptionData(
        productId,
        response
      );
      return transforedDescriptionData;
    } catch (_) {
      /* try now from actual locale (no additional translations) */
      _url = `${_base}?locale=${locale}`;

      try {
        const response = await this.#handleAPIGetRequest(_url);
        const transforedDescriptionData = transformMGWProductDescriptionData(
          productId,
          response
        );

        return transforedDescriptionData;
      } catch (_) {
        return null;
      }
    }
  };

  #getProductSizeGuide = async (_, productId, locale, refLocale) => {
    let _base = `/products/${productId}/sizeguide`;
    let _url;

    if (refLocale) {
      _url = `${_base}?locale=${refLocale}`;
    } else if (locale) {
      _url = `${_base}?locale=${locale}`;
    }

    try {
      const response = await this.#handleAPIGetRequest(_url);

      return response;
    } catch (_) {
      /* try now from actual locale (no additional translations) */
      _url = `${_base}?locale=${locale}`;

      try {
        const response = await this.#handleAPIGetRequest(_url);

        return response;
      } catch (_) {
        return null;
      }
    }
  };

  #getProductReviews = async (
    _,
    productId,
    locale,
    page = null,
    refLocale = null
  ) => {
    let _base = `/products/${productId}/reviews`;
    let _url;

    let queryStringArray = [];

    if (refLocale) {
      queryStringArray.push(`locale=${refLocale}`);
    } else if (locale) {
      queryStringArray.push(`locale=${locale}`);
    }

    if (page) {
      queryStringArray.push(`page=${page}`);
    }

    if (queryStringArray.length > 0) {
      _url = _base + "?" + queryStringArray.join("&");
    }

    try {
      const response = await this.#handleAPIGetRequest(_url);

      return response;
    } catch (_) {
      queryStringArray = [];

      if (locale) {
        queryStringArray.push(`locale=${locale}`);
      }

      if (page) {
        queryStringArray.push(`page=${page}`);
      }

      if (queryStringArray.length > 0) {
        _url = _base + "?" + queryStringArray.join("&");
      }

      /* try now from actual locale (no additional translations) */

      try {
        const response = await this.#handleAPIGetRequest(_url);

        return response;
      } catch (_) {
        return null;
      }
    }
  };

  #getNextSetProductReviews = async (_, productId, locale, page, refLocale) => {
    return this.#getProductReviews(_, productId, locale, page, refLocale);
  };

  #getProductReviewStatistics = async (
    _,
    productId,
    modelId,
    locale,
    refLocale
  ) => {
    let _base = `/review/statistics?productId=${productId}&modelNumber=${modelId}`;
    let _url;

    if (refLocale) {
      _url = `${_base}&locale=${refLocale}`;
    } else if (locale) {
      _url = `${_base}&locale=${locale}`;
    }

    try {
      const response = await this.#handleAPIGetRequest(_url);

      return response;
    } catch (_) {
      /* try now from actual locale (no additional translations) */
      _url = `${_base}&locale=${locale}`;

      try {
        const response = await this.#handleAPIGetRequest(_url);

        return response;
      } catch (_) {
        return null;
      }
    }
  };

  #getProductUGC = async (_, productId, modelId, locale, refLocale) => {
    let _base = `/content/product_ugc?resource=${productId}/${modelId}`;
    let _url;

    if (refLocale) {
      _url = `${_base}&locale=${refLocale}`;
    } else if (locale) {
      _url = `${_base}&locale=${locale}`;
    }

    try {
      const response = await this.#handleAPIGetRequest(_url);

      return response;
    } catch (_) {
      /* try now from actual locale (no additional translations) */
      _url = `${_base}&locale=${locale}`;

      try {
        const response = await this.#handleAPIGetRequest(_url);

        return response;
      } catch (_) {
        return null;
      }
    }
  };

  #getSearchSuggestions = async (query, locale) => {
    let _url = `/search-suggestions?query=${query}`;

    if (locale && query) {
      _url = `/search-suggestions?query=${query}&locale=${locale}`;

      _url = encodeURI(_url);

      return this.#handleAPIGetRequest(_url);
    }
  };

  getAllFunctions = () => {
    return {
      getProducts: this.#getProducts,
      getProduct: this.#getProduct,
      getProductDescription: this.#getProductDescription,
      getProductSizeGuide: this.#getProductSizeGuide,
      getProductReviews: this.#getProductReviews,
      getNextSetProductReviews: this.#getNextSetProductReviews,
      getProductReviewStatistics: this.#getProductReviewStatistics,
      getProductUGC: this.#getProductUGC,
      getSearchSuggestions: this.#getSearchSuggestions,
      getRetailProducts: this.#getRetailProducts,
      getProductFromRetailSam: this.#getProductFromRetailSam,
      deleteProductsDatabase: this.deleteProductsDatabase,
    };
  };
}

export default ProductsAPI;
