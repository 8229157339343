import APIBase from "../APIBase/APIBase";

class MapsAPI extends APIBase {
  axiosApi;

  #getHeaders = async () => {
    if (!this.requestHeaders) {
      return await this.initConfig();
    } else {
      return;
    }
  };

  #handleAPIGetRequest = (_url) => {
    return this.axiosApi.get(_url).then((response) => {
      return response;
    });
  };

  #getGooglePlace = async (searchText) => {
    await this.#getHeaders();

    let _url = `/google/place/findplacefromtext?search_text=${searchText}`;

    this.#handleAPIGetRequest(_url);
  };

  #getAdidasNearestStoresList = async (
    checkoutId,
    latitude,
    longitude,
    locale
  ) => {
    await this.#getHeaders();
    let _url = `/checkouts/${checkoutId}/nearest_stores?locale=${locale}&latitude=${latitude}&longitude=${longitude}`;
    this.#handleAPIGetRequest(_url);
  };

  #getAdidasNearestPickupPointsList = async (
    checkoutId,
    latitude,
    longitude,
    locale
  ) => {
    await this.#getHeaders();

    let _url = `/checkouts/${checkoutId}/nearest_pick_up_points?locale=${locale}&latitude=${latitude}&longitude=${longitude}`;

    this.#handleAPIGetRequest(_url);
  };

  getAllFunctions = () => {
    return {
      getGooglePlace: this.#getGooglePlace,
      getAdidasNearestStoresList: this.#getAdidasNearestStoresList,
      getAdidasNearestPickupPointsList: this.#getAdidasNearestPickupPointsList,
    };
  };
}

export default MapsAPI;
