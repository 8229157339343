export default class MembershipBannerModel {
  constructor(
    title,
    qrCodeUrl,
    backgroundImageAssetUrl,
    description,
    features,
    footerBackgroundImageAssetUrl
  ) {
    this.title = title;
    this.qrCodeUrl = qrCodeUrl;
    this.backgroundImageAssetUrl = backgroundImageAssetUrl;
    this.description = description;
    this.features = features;
    this.footerBackgroundImageAssetUrl = footerBackgroundImageAssetUrl;
  }
}
