import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

import { log, MSG_TYPE } from "../../../utils/logger";
import { LOCAL_BFF_API_URL } from "../constants";

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "ssk-app-settings",
});

const api = setup({
  baseURL: LOCAL_BFF_API_URL,
  cache: {
    maxAge: 1 * 60 * 1000, // set cache time to 1 min
    exclude: { query: false }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

let remoteAppSettingsRef = {
  baseUrl: null,
  apiKey: null,
  configId: null,
  configName: null,
};

export const setRemoteAppSettingsRef = ({
  baseUrl,
  apiKey,
  configId,
  configName,
}) => {
  remoteAppSettingsRef.baseUrl = baseUrl;
  remoteAppSettingsRef.apiKey = apiKey;
  remoteAppSettingsRef.configId = configId;
  remoteAppSettingsRef.configName = configName;
};

export const getAppSettings = () => {
  log("get /getAppSettings called", MSG_TYPE.READY);

  if (remoteAppSettingsRef?.baseUrl && remoteAppSettingsRef?.apiKey) {
    console.log(
      `...fetching appsettings remotely from : `,
      remoteAppSettingsRef
    );
  } else {
    console.log(`...fetching appsettings from local nodejs`);
  }

  let url = `${remoteAppSettingsRef?.baseUrl}/app-configurations/?configName=${remoteAppSettingsRef.configName}`;

  if (remoteAppSettingsRef.configId) {
    url = `${remoteAppSettingsRef?.baseUrl}/app-configurations/?configId=${remoteAppSettingsRef.configId}`;
  }
  return api({
    url: url,
    method: "get",
    headers: {
      "API-Key": remoteAppSettingsRef.apiKey,
    },
  })
    .then(async (response) => {
      return {
        response: {
          data: response?.data?.configuration?.app_settings,
        },
      };
    })
    .catch((e) => {
      console.log("app settings fetch error ");
      return null;
    });
};
