import React, { useState } from "react";
import { GlCarousel } from "@adl/foundation";

import UgcOverlay from "./component/UgcOverlay";

import "./Ugc.css";

function Ugc(props) {
  const [clickedImage, setClickedImage] = useState(null);
  const productUGC = props.productUGC;

  return (
    <div className="ugc-container">
      {clickedImage && (
        <UgcOverlay
          productUGC={productUGC}
          initialPicture={clickedImage}
          onClose={() => {
            setClickedImage(null);
          }}
        ></UgcOverlay>
      )}

      <h4>{productUGC?.label}</h4>
      {/* {JSON.stringify(clickedImage)} */}
      <div className="ugc-gallery-container">
        <GlCarousel
          activeItem={0}
          className="st-carousel__four-items"
          currentPage={0}
          itemsToScroll={0}
          itemsVisiblePerPage={4}
          onMovedToPageN={(page) => console.log("page", page)}
          arrowType="square"
        >
          {productUGC?.gallery?.map((x) => (
            <img
              key={x.url + x.id}
              src={x.url}
              alt="ugc"
              onClick={() => {
                setClickedImage(x.id);
              }}
            />
          ))}
        </GlCarousel>
      </div>
    </div>
  );
}

export default Ugc;
