import React, { Component } from "react";
import EmptyBag from "./components/EmptyBag/EmptyBag";
import CartItems from "./components/CartItems/CartItems";
import CheckoutConfirmationModal from "./components/CheckoutConfirmationModal/CheckoutConfirmationModal";
import DeleteCartConfirmationModal from "./components/DeleteCartConfirmationModal/DeleteCartConfirmationModal";

import { removeAllFromCart } from "../../../../actions/cartActions";

import { connect } from "react-redux";

import "./ShoppingBag.css";

import {
  TRANSLATIONS_KEY_LIST,
  translateString,
} from "../../../../utils/translationCopies";

import {
  setSideBarActiveItem,
  MENU_ITEM_NAMES,
} from "../../../../actions/sidebarMenuActions";

import * as analytics from "../../../../analytics";

import * as helpers from "../../../../utils/helpers";

class ShoppingBag extends Component {
  state = {
    cartItems: this.props.cartItems,
    showCheckoutConfirmation: false,
    showDeleteCartConfirmation: false,
  };
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.cartItems) !==
      JSON.stringify(this.props.cartItems)
    ) {
      this.setState({
        cartItems: this.props.cartItems,
      });
    }
  }

  onDeleteBag = () => {
    const result = this.props.removeAllFromCart();
    this.setState({
      items: result,
    });
  };

  getTranslation(key) {
    return translateString(key);
  }

  render() {
    let { showCheckoutConfirmation, showDeleteCartConfirmation } = this.state;

    return (
      <>
        <div
          className="shopping-bag-container animate-right"
          onClick={(e) => {
            this.props.resetTimoutTimer();
            this.props.onClose();
          }}
        >
          <div
            className="shopping-bag"
            onClick={(e) => {
              this.props.resetTimoutTimer();
              e.stopPropagation();
            }}
          >
            <section className="header">
              <div>
                <h4>
                  {this.getTranslation(TRANSLATIONS_KEY_LIST.shopping_bag)}
                </h4>
              </div>
              {this.props.cartItems?.length === 0 && (
                <EmptyBag
                  selectedLocale={this.props.selectedLocale}
                  onClose={this.props.onClose}
                ></EmptyBag>
              )}
            </section>
            {/* {JSON.stringify(this.props.cartItems.length)} */}

            {this.props.cartItems?.length > 0 && (
              <>
                <CartItems
                  onProceedToCheckout={(_totalAmount) => {
                    this.setState({ showCheckoutConfirmation: true });
                    //this.props.setSideBarActiveItem(MENU_ITEM_NAMES.checkout);

                    //Log Start Checkout analytics
                    analytics.startCheckout({
                      hostname: this.props.appSettings.value.player.hostname,
                      quantity: helpers.getCartItemsCount(this.props.cartItems),
                      products:
                        this.props.cartItems
                          .map((cartItem) => cartItem.product.product_id)
                          .toString() || "",
                      value: _totalAmount,
                      currency: helpers
                        .formatPrice(_totalAmount, this.props.selectedLocale)
                        .split(" ")[0],
                    });
                  }}
                  onShowDeleteCartConfirmation={() => {
                    this.setState({ showDeleteCartConfirmation: true });
                  }}
                ></CartItems>
                {this.props?.selectedLocale &&
                  this.props?.appSettings &&
                  this.props?.cartItems &&
                  showCheckoutConfirmation && (
                    <CheckoutConfirmationModal
                      show={true}
                      onClose={() => {
                        if (window._setUserActionTime)
                          window._setUserActionTime();

                        this.setState({ showCheckoutConfirmation: false });
                      }}
                      onConfirm={() => {
                        if (window._setUserActionTime)
                          window._setUserActionTime();

                        this.props.setSideBarActiveItem(
                          MENU_ITEM_NAMES.checkout
                        );
                      }}
                      selectedLocale={this.props.selectedLocale}
                      appSettings={this.props.appSettings}
                      cartItems={this.props.cartItems}
                    ></CheckoutConfirmationModal>
                  )}

                {showDeleteCartConfirmation && (
                  <DeleteCartConfirmationModal
                    show={true}
                    onClose={() => {
                      if (window._setUserActionTime)
                        window._setUserActionTime();
                      this.setState({ showDeleteCartConfirmation: false });
                    }}
                    onConfirm={() => {
                      if (window._setUserActionTime)
                        window._setUserActionTime();
                      this.onDeleteBag();
                    }}
                    selectedLocale={this.props.selectedLocale}
                  ></DeleteCartConfirmationModal>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps({ cartItems, selectedLocale, appSettings }) {
  return {
    cartItems,
    selectedLocale,
    appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setSideBarActiveItem: (val) => dispatch(setSideBarActiveItem(val)),
    removeAllFromCart: () => dispatch(removeAllFromCart()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBag);
