import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

import { log, MSG_TYPE } from "../../../utils/logger";
import { BFF_API } from "../constants";

// Create `localforage` instance
const localforageStore = localforage.createInstance({
  // List of drivers used
  driver: [
    localforage.INDEXEDDB,
    localforage.LOCALSTORAGE,
    memoryDriver._driver,
  ],
  // Prefix all storage keys to prevent conflicts
  name: "ssk-app-settings",
});

const api = setup({
  baseURL: BFF_API,
  cache: {
    maxAge: 10 * 60 * 1000, // set cache time to 10 min
    exclude: { query: false }, // cache requests with query parameters
    store: localforageStore, // pass `localforage` store to `axios-cache-adapter`
    clearOnStale: true,
  },
});

export const getAppSettings = () => {
  log("get /getAppSettings called", MSG_TYPE.READY);

  return api({
    url: `${BFF_API}/app_settings`,
    method: "get",
  })
    .then(async (response) => {
      return { response };
    })
    .catch((e) => {
      console.log("app settings fetch error ");
      return null;
    });
};
