import React, { Component } from "react";

import { Events, animateScroll as scroll, scroller } from "react-scroll";

import ModalView from "../../../../../../ModalView/ModalView";

class UgcOverlay extends Component {
  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });

    setTimeout(() => {
      this.scrollToWithContainer(this.props.initialPicture);
    }, 200);
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  scrollToTop() {
    console.log("scroll to top requested !");
    scroll.scrollToTop();
  }

  scrollToWithContainer(initialPicture) {
    scroller.scrollTo(initialPicture, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "ugc-overlay-content",
    });
  }

  render() {
    const { productUGC, onClose } = this.props;
    return (
      <>
        <ModalView show={true} onClose={onClose}>
          <div style={{ paddingBottom: "1rem" }}></div>
          <div
            name="ugc-overlay-content"
            id="ugc-overlay-content"
            className="ugc-overlay-content"
            onScroll={() => {
              if (window._setUserActionTime) window._setUserActionTime();
            }}
          >
            {productUGC?.gallery?.map((x) => (
              <>
                <img name={x.id} src={x.url} alt="ugc" />
              </>
            ))}
            <div style={{ paddingBottom: "3rem" }}></div>
          </div>
        </ModalView>
      </>
    );
  }
}

export default UgcOverlay;
