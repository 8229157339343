export const CART_ADD_SKU = "CART_ADD_SKU";
export const CART_REMOVE_SKU = "CART_REMOVE_SKU";
export const CART_UPDATE_SKU = "CART_UPDATE_SKU";
export const CART_REMOVE_ALL = "CART_REMOVE_ALL";

export function addToCart(item) {
  return { type: CART_ADD_SKU, item };
}

export function updateCartSku(item) {
  return { type: CART_UPDATE_SKU, item };
}

export function removeFromCart(item) {
  return { type: CART_REMOVE_SKU, item };
}

export function removeAllFromCart() {
  const item = [];
  return { type: CART_REMOVE_ALL, item };
}
