import { connect } from "react-redux";
import { useEffect } from "react";

import CheckoutView from "./components/CheckoutView/CheckoutView";
import ViewRouter from "./components/ViewRouter/ViewRouter";

import { Switch, Route } from "react-router-dom";
import AppKeyboard from "./components/AppKeyboard/AppKeyboard";

import { setUserActionTime } from "./actions/pageActions";

import "./App.css";

let handleResetActionTime;

function App(props) {
  useEffect(() => {
    /* handle scroll event */
    const handleScroll = (event) => {
      window._setUserActionTime();
    };

    /* listen for scroll event */
    window.addEventListener("scroll", handleScroll);

    /* the fn that resets the user action time */
    const resetFn = props?.setUserActionTime;

    /* call the window fun that resets the user action time*/
    window._setUserActionTime = function () {
      if (handleResetActionTime) {
        clearTimeout(handleResetActionTime);
      }
      const _resetFn = resetFn;

      /* reset timer only after 2 second delay 
      this is to avoid redux store changes too frequently*/
      if (_resetFn) {
        handleResetActionTime = setTimeout(() => {
          _resetFn();
        }, 1000);
      }
    };

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props?.setUserActionTime]);

  let shouldShowSideBar = !props?.currentPage?.includes("interactionVideo");

  return (
    <div className="App">
      <div
        className={`${
          shouldShowSideBar ? "main-container" : "main-container-full-screen"
        }`}
        onClick={() => {
          window._setUserActionTime();

          console.log("propagated click event caught in parent app.js");
        }}
      >
        {props.selctedInputWithKeyboardId && <AppKeyboard />}

        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <ViewRouter
                resetTimoutTimer={window._setUserActionTime}
              ></ViewRouter>
            )}
          />
          <Route exact path="/checkout" component={CheckoutView} />
        </Switch>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setUserActionTime: (val) => dispatch(setUserActionTime(val)),
  };
}

function mapStateToProps({ selctedInputWithKeyboardId, currentPage }) {
  return {
    selctedInputWithKeyboardId,
    currentPage,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
